import IonIcon from "@reacticons/ionicons";
import LogIn from "../login/login";
import { useUI } from "../context/UIContext";
import { useUser } from "../context/UserContext";

export default function LogInUserButton({
  className = "",
  site,
}: {
  className?: string;
  site?: string;
}) {
  const { setLocation, setPopUp } = useUI();
  const { user } = useUser();

  function RSCButton() {
    return (
      <div className="flex flex-col items-center justify-center">
        <IonIcon
          className={`translate-y-[0px] ${
            user ? "text-rsc-yellow" : "text-white"
          }`}
          name="person"
        />
      </div>
    );
  }

  function RapidButton() {
    if (!!user) return null;
    return (
      <div className="flex flex-row items-center">
        <div>
          <IonIcon
            className="translate-y-[0px] text-[18px] text-rsc-light-blue"
            name="person"
          />
        </div>
      </div>
    );
  }

  return (
    <div className={className}>
      <button
        onClick={() =>
          user
            ? setLocation("/dashboard")
            : setPopUp(
                <div
                  className="flex flex-col bg-white rounded-lg mx-8 max-w-[400px] w-full overflow-hidden"
                  onClick={e => e.stopPropagation()}
                >
                  <LogIn pop_up />
                </div>
              )
        }
        className="leading-none"
      >
        {site === "rsc_health" ? <RSCButton /> : <RapidButton />}
      </button>
    </div>
  );
}
