import { createContext, useContext, useEffect, useState } from "react";
import { compact, filter, isEmpty, join, keys, map, upperFirst } from "lodash";
//import { useUI } from "../../context/UIContext";

interface BraintreeValues {
  clientInstance: any | null;
  clientToken: any | null;
  hostedFieldsInstance: any | null;
  setClientInstance: (clientInstance: any) => void;
  setHostedFieldsInstance: (fieldInstance: any) => void;
  submitPayment: () => any;
}

export const BraintreeContext = createContext<BraintreeValues>({
  clientInstance: null,
  clientToken: null,
  hostedFieldsInstance: null,
  setClientInstance: () => {},
  setHostedFieldsInstance: () => {},
  submitPayment: () => {},
});

export const useBraintree = () => useContext(BraintreeContext);

const BraintreeContextProvider: React.FC<{
  children: any;
}> = ({ children }) => {
  // const { is_testing_order } = useUI();
  const [clientInstance, setClientInstance] = useState<any | null>(null);
  const [clientToken, setClientToken] = useState(null);
  const [hostedFieldsInstance, setHostedFieldsInstance] = useState<any | null>(
    null
  );

  useEffect(() => {
    // if (is_testing_order) return;
    (async () => {
      const response = await (
        await fetch(
          "https://react-paypal-js-storybook.fly.dev/api/braintree/generate-client-token",
          { method: "POST" }
        )
      ).json();
      setClientToken(response?.client_token || response?.clientToken);
    })();
  }, []);

  // if (is_testing_order) return children;

  async function submitPayment() {
    try {
      if (!hostedFieldsInstance) {
        // await renderPayment();
        return {
          pay_errors: "Portal error. Please try again.",
        };
      }
      const { fields } = hostedFieldsInstance.getState();
      const fields_keys = keys(filter(fields, { isValid: false }));
      const error_names: { [key: string]: string } = {
        cvv: "Card CVV code",
        expirationDate: "Card expiration date",
        number: "Credit card number",
        postalCode: "Payment zip code",
      };
      const pay_errors = compact(
        map(fields_keys, function (field: string) {
          return `${
            error_names[field] || "Payment item"
          } is not formatted correctly.`;
        })
      );

      if (!isEmpty(pay_errors)) {
        return { pay_errors };
      }
      //const { nonce } = await hostedFieldsInstance.tokenize();

      return await hostedFieldsInstance
        .tokenize()
        .then((payload: any) => {
          return { nonce: payload.nonce };
        })
        .catch((err: any) => {
          const error_text: { [key: string]: string } = {
            HOSTED_FIELDS_FIELDS_EMPTY: "Credit card fields are all empty.",
            HOSTED_FIELDS_FIELDS_INVALID: `${upperFirst(
              join(err?.details?.invalidFieldKeys, ", ")
            )} payment input ${
              err?.details?.invalidFieldKeys?.length > 1
                ? "fields are"
                : "field is"
            } invalid.`,
          };
          const pay_errors = [error_text[err.code] || "Payment input error."];
          return { pay_errors };
        });
    } catch (err) {
      console.log("ERROR SUBMITTING CREDIT CARD PAYMENT", err);
    }
  }

  return (
    <BraintreeContext.Provider
      value={{
        clientInstance,
        clientToken,
        hostedFieldsInstance,
        setClientInstance,
        setHostedFieldsInstance,
        submitPayment,
      }}
    >
      {children}
    </BraintreeContext.Provider>
  );
};

export default BraintreeContextProvider;
