import { isEmpty, join, replace, slice } from "lodash";
import { useRegistration } from "../RegistrationContext";
import LoginHolder from "../holder";
import RSCLoader from "../loader_circle";
import { useUI } from "../../context/UIContext";

export default function CodeForm() {
  const { setLocation } = useUI();
  const {
    auth_code,
    loading,
    username,
    verify_ready,
    setAuthCode,
    setUsername,
    verifyCode,
  } = useRegistration();

  const input_class = `rounded-lg border border-slate-400 h-10 w-full shadow-inner px-2 ${
    loading ? "bg-slate" : "bg-none"
  }`;
  const disabled = !isEmpty(verify_ready) || loading;
  return (
    <LoginHolder
      header="Verify Authorization Code"
      loading_text="Verifying code..."
    >
      <>
        <div className="">
          <p className="text-xs">Verify code sent to:</p>
          <input
            className={input_class}
            disabled={loading}
            id={"code"}
            placeholder={"Email or Phone Number"}
            value={username}
            onChange={(e: any) => setUsername(e.target.value)}
          />
        </div>
        <div className="">
          <p className="text-xs">Enter code sent:</p>
          <input
            className={input_class}
            disabled={loading}
            id={"code"}
            placeholder={"Enter code sent to Email or Phone Number"}
            value={auth_code || ""}
            onChange={(e: any) => {
              const value = e.target.value;
              const sanitize = join(
                slice(replace(value, /[^0-9]/g, ""), 0, 6),
                ""
              );
              setAuthCode(sanitize);
            }}
          />
        </div>
        {verify_ready?.length > 0 && (
          <div className="flex flex-col text-xs text-red-600 pl-3 italic">
            {verify_ready.map((msg: string, idx: number) => {
              return <div key={idx}>{msg}</div>;
            })}
          </div>
        )}
        <div>
          <button
            onClick={() => verifyCode()}
            disabled={disabled}
            className={`p-3 font-semibold text-center text-white rounded-md shadow-md ${
              disabled ? "bg-gray-400" : "bg-amber-400"
            } w-full`}
          >
            {loading ? (
              <div className="flex flex-row gap-2 items-center justify-center">
                <RSCLoader /> Verifying code...
              </div>
            ) : (
              "Verify Code"
            )}
          </button>
        </div>
        <div className="flex flex-col pt-5">
          <div className="flex flex-row text-xs">
            <div className="grow">
              <button
                className="text-rsc-light-blue font-semibold"
                onClick={() => setLocation("./login")}
              >
                Log In
              </button>
              {" or "}
              <button
                className="text-rsc-light-blue font-semibold"
                onClick={() => setLocation("./register")}
              >
                Register
              </button>
            </div>
            <div>
              <button
                className="text-rsc-light-blue font-semibold"
                onClick={() => setLocation("/resend")}
              >
                Resend Code
              </button>
            </div>
          </div>
        </div>
      </>
    </LoginHolder>
  );
}
