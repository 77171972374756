import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { isEmpty } from "lodash";
import { OrderReducer } from "./OrderReducer";
import { blank_order, Order } from "../../utils/types";
import test_selected_center from "../samples/selected_center";
import test_user from "../samples/user";
import { useAccount } from "../../context/AccountContext";
import { processAccountUser, validate_order } from "../../utils/functions";
import { useCart } from "../../context/CartContext";
import { useUI } from "../../context/UIContext";

const pay_later_available = true;

interface OrderValues {
  cart_is_empty: boolean;
  errors: any;
  order: Order;
  order_validation: any;
  pay_later_available: boolean;
  view_errors: boolean;
  handleOrderChange: (field: string | string[], value: any) => void;
  handleUserChange: (field: string | string[], value: any) => void;
  setViewErrors: (view_errors: boolean) => void;
}

export const OrderContext = createContext<OrderValues>({
  cart_is_empty: true,
  errors: null,
  order: blank_order,
  order_validation: null,
  pay_later_available,
  view_errors: false,
  handleOrderChange: () => {},
  handleUserChange: () => {},
  setViewErrors: () => {},
});

export const useOrder = () => useContext(OrderContext);

const OrderContextProvider: React.FC<{
  children: any;
}> = ({ children }) => {
  const { is_testing_order } = useUI();
  const { account } = useAccount();

  const initial_order = is_testing_order
    ? {
        acknowledged: true,
        selected_center: test_selected_center,
        user: test_user,
      }
    : blank_order;

  const { shopping_cart } = useCart();
  const [order, dispatch] = useReducer(OrderReducer, initial_order);
  const [view_errors, setViewErrors] = useState<boolean>(false);
  function handleOrderChange(field: string | string[], value: any) {
    dispatch({
      type: "HANDLE_CHANGE",
      order: {
        field,
        value,
      },
    });
  }

  function handleUserChange(field: string | string[], value: any) {
    dispatch({
      type: "HANDLE_USER_CHANGE",
      user: {
        field,
        value,
      },
    });
  }

  useEffect(() => {
    if (!order?.user && !!account?.Id) {
      console.log("ACCOUNT", { account });
      handleOrderChange("user", processAccountUser(account));
    }
  });
  const order_validation = validate_order.safeParse(order);
  const errors = (order_validation as any)?.error?.issues.map(
    (issue: any) => issue.message
  );

  const cart_is_empty = isEmpty(shopping_cart);
  return (
    <OrderContext.Provider
      value={{
        cart_is_empty,
        errors,
        order,
        order_validation,
        pay_later_available,
        view_errors,
        handleOrderChange,
        handleUserChange,
        setViewErrors,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export default OrderContextProvider;
