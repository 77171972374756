import ChangePassword from "./password";
import Personal from "./personal";
import PopUpTest from "./delete_popup";
import { useUI } from "../../context/UIContext";
import { useDashboard } from "../DashboardContext";
import { kebabCase } from "lodash";
import { PanelHolder } from "../layout";

export function SettingsPanel() {
  const { setLocation, setPopUp } = useUI();
  const { view } = useDashboard();
  return (
    <PanelHolder header_text="My Settings">
      <div className="flex flex-col gap-5">
        {["Patient Info", "Change Password"].map((item: any) => {
          const Id = kebabCase(item);
          const isActive = Id === view;
          return (
            <div
              key={item}
              className={`p-1 min-w-[110px] hover:bg-rsc-light-blue/10 hover:font-semibold ${
                isActive ? "bg-rsc-light-blue/10 font-semibold" : ""
              }`}
              onClick={() => setLocation(`/dashboard/settings/${Id}`)}
              style={{
                cursor: "pointer",
              }}
            >
              {item}
            </div>
          );
        })}
      </div>
      <div className="flex flex-col justify-end grow">
        <button
          className="bg-red-700 px-12 py-2 uppercase text-white font-bold rounded-full shadow-xl"
          onClick={() => {
            setPopUp(<PopUpTest />);
          }}
        >
          Delete Portal Account
        </button>
      </div>
    </PanelHolder>
  );
}

export default function SettingsDetails() {
  const { view } = useDashboard();
  const view_settings: { [key: string]: any } = {
    "patient-nfo": <Personal />,
    "change-password": <ChangePassword />,
  };
  return (
    <div className="flex flex-col justify-items-stretch p-5 pb-12 gap-6 bg-white md:basis-2/3">
      {view_settings[view] || <Personal />}
    </div>
  );
}
