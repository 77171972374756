import SetZipCode from "./zipcode_set";

export default function SelectPharmacy({
  enterPharmacy,
}: {
  enterPharmacy: any;
}) {
  return (
    <>
      <div className="text-sm text-slate-700">
        To locate a pharmacy, enter a zip code into the search box below and
        click the search icon or{" "}
        <button
          className="font-semibold text-rsc-light-blue underline"
          onClick={() => enterPharmacy(true)}
        >
          Click Here
        </button>{" "}
        to provide your pharmacy.
      </div>

      <SetZipCode />
    </>
  );
}
