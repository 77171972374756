import IonIcon from "@reacticons/ionicons";
import { useMessages } from "../MessagesContext";
import MessageInfo from "./info";
import MessageContent from "./content";
import MessageAttachments from "./attachments";

export default function MessageDetailsPage() {
  const { viewing_message } = useMessages();

  return (
    <div className="flex flex-col justify-items-stretch p-5 pb-12 gap-6 bg-white md:basis-2/3">
      {viewing_message ? (
        <>
          {/* <div className=" md:hidden">
            <button
              className="text-sky-600 font-semibold uppercase"
              onClick={() => setViewingMessage(undefined)}
            >
              <IonIcon
                name="caret-back-outline"
                style={{ transform: "translateY(3px)" }}
              />{" "}
              Back
            </button>
          </div> */}
          <MessageInfo />
          <hr className="shadow-md border-0 h-2" />
          <MessageContent />
          <MessageAttachments />
        </>
      ) : (
        <div className="flex flex-row w-full my-16 justify-center text-2xl text-gray-300 font-semibold gap-2">
          <div>
            <IonIcon
              name="information-circle"
              style={{ transform: "translateY(4px)" }}
            />
          </div>
          <div>Select a message to see contents</div>
        </div>
      )}
    </div>
  );
}
