const test_tx_data = {
  type: "consultation",
  tx: {
    id: "nh1f1w29",
    status: "submitted_for_settlement",
    type: "sale",
    currencyIsoCode: "USD",
    amount: "99.00",
    amountRequested: "99.00",
    merchantAccountId: "maximeassociates",
    subMerchantAccountId: null,
    masterMerchantAccountId: null,
    orderId: null,
    createdAt: "2023-03-03T19:10:04Z",
    updatedAt: "2023-03-03T19:10:05Z",
    customer: {
      id: null,
      firstName: null,
      lastName: null,
      company: null,
      email: null,
      website: null,
      phone: null,
      fax: null,
    },
    billing: {
      id: null,
      firstName: null,
      lastName: null,
      company: null,
      streetAddress: null,
      extendedAddress: null,
      locality: null,
      region: null,
      postalCode: "12345",
      countryName: null,
      countryCodeAlpha2: null,
      countryCodeAlpha3: null,
      countryCodeNumeric: null,
    },
    refundId: null,
    refundIds: [],
    refundedTransactionId: null,
    partialSettlementTransactionIds: [],
    authorizedTransactionId: null,
    settlementBatchId: null,
    shipping: {
      id: null,
      firstName: null,
      lastName: null,
      company: null,
      streetAddress: null,
      extendedAddress: null,
      locality: null,
      region: null,
      postalCode: null,
      countryName: null,
      countryCodeAlpha2: null,
      countryCodeAlpha3: null,
      countryCodeNumeric: null,
    },
    customFields: "",
    accountFundingTransaction: false,
    avsErrorResponseCode: null,
    avsPostalCodeResponseCode: "M",
    avsStreetAddressResponseCode: "I",
    cvvResponseCode: "M",
    gatewayRejectionReason: null,
    processorAuthorizationCode: "ZVT15H",
    processorResponseCode: "1000",
    processorResponseText: "Approved",
    additionalProcessorResponse: null,
    voiceReferralNumber: null,
    purchaseOrderNumber: null,
    taxAmount: null,
    taxExempt: false,
    scaExemptionRequested: null,
    processedWithNetworkToken: false,
    creditCard: {
      token: null,
      bin: "601100",
      last4: "0009",
      cardType: "Discover",
      expirationMonth: "12",
      expirationYear: "2025",
      customerLocation: "US",
      cardholderName: null,
      imageUrl:
        "https://assets.braintreegateway.com/payment_method_logo/discover.png?environment=sandbox",
      isNetworkTokenized: false,
      prepaid: "Unknown",
      healthcare: "Unknown",
      debit: "Unknown",
      durbinRegulated: "Unknown",
      commercial: "Unknown",
      payroll: "Unknown",
      issuingBank: "Unknown",
      countryOfIssuance: "Unknown",
      productId: "Unknown",
      globalId: null,
      graphQLId: null,
      accountType: null,
      uniqueNumberIdentifier: null,
      venmoSdk: false,
      accountBalance: null,
      maskedNumber: "601100******0009",
      expirationDate: "12/2025",
    },
    statusHistory: [
      {
        timestamp: "2023-03-03T19:10:04Z",
        status: "authorized",
        amount: "99.00",
        user: "MaximeAndAssociates",
        transactionSource: "api",
      },
      {
        timestamp: "2023-03-03T19:10:05Z",
        status: "submitted_for_settlement",
        amount: "99.00",
        user: "MaximeAndAssociates",
        transactionSource: "api",
      },
    ],
    planId: null,
    subscriptionId: null,
    subscription: {
      billingPeriodEndDate: null,
      billingPeriodStartDate: null,
    },
    addOns: [],
    discounts: [],
    descriptor: {
      name: null,
      phone: null,
      url: null,
    },
    recurring: false,
    channel: null,
    serviceFeeAmount: null,
    escrowStatus: null,
    disbursementDetails: {
      disbursementDate: null,
      settlementAmount: null,
      settlementCurrencyIsoCode: null,
      settlementCurrencyExchangeRate: null,
      settlementBaseCurrencyExchangeRate: null,
      fundsHeld: null,
      success: null,
    },
    disputes: [],
    achReturnResponses: [],
    authorizationAdjustments: [],
    paymentInstrumentType: "credit_card",
    processorSettlementResponseCode: "",
    processorSettlementResponseText: "",
    networkResponseCode: "XX",
    networkResponseText: "sample network response text",
    threeDSecureInfo: null,
    shipsFromPostalCode: null,
    shippingAmount: null,
    discountAmount: null,
    networkTransactionId: "020230303191004",
    processorResponseType: "approved",
    authorizationExpiresAt: "2023-04-02T19:10:04Z",
    retryIds: [],
    retriedTransactionId: null,
    refundGlobalIds: [],
    partialSettlementTransactionGlobalIds: [],
    refundedTransactionGlobalId: null,
    authorizedTransactionGlobalId: null,
    globalId: "dHJhbnNhY3Rpb25fbmgxZjF3Mjk",
    graphQLId: "dHJhbnNhY3Rpb25fbmgxZjF3Mjk",
    retryGlobalIds: [],
    retriedTransactionGlobalId: null,
    retrievalReferenceNumber: "1234567",
    achReturnCode: null,
    installmentCount: null,
    installments: [],
    refundedInstallments: [],
    responseEmvData: null,
    acquirerReferenceNumber: null,
    merchantIdentificationNumber: null,
    terminalIdentificationNumber: null,
    merchantName: "DESCRIPTOR",
    merchantAddress: {
      streetAddress: null,
      locality: null,
      region: null,
      postalCode: null,
      phone: null,
    },
    pinVerified: false,
    debitNetwork: null,
    processingMode: null,
    paymentReceipt: {
      id: "nh1f1w29",
      globalId: "dHJhbnNhY3Rpb25fbmgxZjF3Mjk",
      amount: "99.00",
      currencyIsoCode: "USD",
      processorResponseCode: "1000",
      processorResponseText: "Approved",
      processorAuthorizationCode: "ZVT15H",
      merchantName: "DESCRIPTOR",
      merchantAddress: {
        streetAddress: null,
        locality: null,
        region: null,
        postalCode: null,
        phone: null,
      },
      merchantIdentificationNumber: null,
      terminalIdentificationNumber: null,
      type: "sale",
      pinVerified: false,
      processingMode: null,
      networkIdentificationCode: null,
      cardType: "Discover",
      cardLast4: "0009",
      accountBalance: null,
    },
    paypalAccount: {},
    paypalHereDetails: {},
    localPayment: {},
    applePayCard: {},
    androidPayCard: {},
    visaCheckoutCard: {},
    samsungPayCard: {},
  },
  mx: {
    patient: {
      address: "647 Spice Trader Way",
      address_state: "FL",
      allergies: "Dustiness",
      city: "Orlando",
      dob: "12/07/1980",
      email: "maxime.and.associates@gmail.com",
      email_message: true,
      family_name: "Maxime",
      given_name: "Samuel",
      no_allergies: false,
      no_medications: false,
      notes: "Just want some meds and I'm cool",
      phone_message: false,
      phone_number: "",
      medications: "Jogging",
      race_ethnicity: "Hispanic or Latino",
      sex: "Male",
      surgeries: "",
      symptoms: "Amped up",
      test_date: "03/03/2023",
      treating: ["Chlamydia", "Herpes II"],
      zip_code: "32818",
      pharmacy: {
        __typename: "Pharmacy",
        _id: "632dcf5a756bafb7287d0f17",
        direction_url:
          "https://www.google.com/maps/dir/Current+Location/7001+Old+Winter+Garden+Rd,+Orlando,+FL,+32835,+USA/",
        name: "CVS Pharmacy",
        Street: "7001 Old Winter Garden Rd",
        City: "Orlando",
        State: "FL",
        Zip_Code: "32835",
        phone: "419-468-3139",
        location: {
          __typename: "CenterPoint",
          coordinates: [-81.4766, 28.5397],
        },
      },
      partners: [
        {
          address: "647 Spice Trader Way",
          address_state: "FL",
          allergies: "Dustiness",
          city: "Orlando",
          dob: "01/21/2009",
          email: "maxime.and.associates@gmail.com",
          email_message: true,
          family_name: "Riviera",
          given_name: "Cherise",
          no_allergies: false,
          no_medications: false,
          notes: "Just want some meds and I'm cool",
          phone_message: false,
          phone_number: "",
          medications: "Jogging",
          race_ethnicity: "Hispanic or Latino",
          sex: "Male",
          surgeries: "",
          symptoms: "Amped up",
          test_date: "03/03/2023",
          treating: ["Chlamydia", "Herpes II"],
          zip_code: "32818",
          pharmacy: {
            __typename: "Pharmacy",
            _id: "632dcf5a756bafb7287d0f17",
            direction_url:
              "https://www.google.com/maps/dir/Current+Location/7001+Old+Winter+Garden+Rd,+Orlando,+FL,+32835,+USA/",
            name: "CVS Pharmacy",
            Street: "7001 Old Winter Garden Rd",
            City: "Orlando",
            State: "FL",
            Zip_Code: "32835",
            phone: "330-688-6140",
            location: {
              __typename: "CenterPoint",
              coordinates: [-81.4766, 28.5397],
            },
          },
        },
        {
          address: "647 Spice Trader Way",
          address_state: "FL",
          allergies: "Dustiness",
          city: "Orlando",
          dob: "01/21/2009",
          email: "maxime.and.associates@gmail.com",
          email_message: false,
          family_name: "Brinson",
          given_name: "Dionne",
          no_allergies: false,
          no_medications: false,
          notes: "Just want some meds and I'm cool",
          phone_message: true,
          phone_number: "4079692176",
          medications: "Jogging",
          race_ethnicity: "Hispanic or Latino",
          sex: "Male",
          surgeries: "",
          symptoms: "Amped up",
          test_date: "03/03/2023",
          treating: ["Chlamydia", "Herpes II"],
          zip_code: "32818",
          pharmacy: {
            __typename: "Pharmacy",
            _id: "632dcf5a756bafb7287d0f17",
            direction_url:
              "https://www.google.com/maps/dir/Current+Location/7001+Old+Winter+Garden+Rd,+Orlando,+FL,+32835,+USA/",
            name: "CVS Pharmacy",
            Street: "7001 Old Winter Garden Rd",
            City: "Orlando",
            State: "FL",
            Zip_Code: "32835",
            phone: "330-688-6140",
            location: {
              __typename: "CenterPoint",
              coordinates: [-81.4766, 28.5397],
            },
          },
        },
      ],
    },
  },
};

export default test_tx_data;
