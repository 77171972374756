import { useState } from "react";
import IonIcon from "@reacticons/ionicons";
import { upperFirst } from "lodash";
import { useUserEdit } from "./UserEditContext";
import ContentHolder from "./content_holder";
import { useUI } from "../context/UIContext";

export default function AdminCreateUserPage() {
  const { setLocation } = useUI();
  const { createUser } = useUserEdit();
  const [new_user, setNewUser] = useState<any>({});

  function updateNewUser(field: string, value: any) {
    setNewUser({ ...new_user, [field]: value });
  }
  function makeInputFormElement(field: string) {
    return (
      <input
        className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
        value={new_user[field]}
        onChange={(e: any) => updateNewUser(field, e.target.value)}
      />
    );
  }

  function makeRadioFormElement(field: string) {
    return (
      <div className="flex flex-row items-center gap-5 h-10">
        {["Male", "Female"].map((item: string) => {
          const isActive = new_user[field] === item;
          return (
            <div className="flex flex-row gap-2 items-center" key={item}>
              <div style={{ lineHeight: 0 }}>
                <button
                  className="text-2xl"
                  onClick={() => updateNewUser(field, item)}
                >
                  <IonIcon
                    className={`${
                      isActive ? "text-rsc-blue" : "text-gray-500"
                    } `}
                    style={{ transform: "translateY(2px)" }}
                    name={isActive ? "radio-button-on" : "radio-button-off"}
                  />
                </button>
              </div>
              <div className="grow">
                <label htmlFor={item}>{upperFirst(item)}</label>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
  const new_user_info_fields = [
    {
      id: "given_name",
      label: "First Name",
      form: makeInputFormElement("given_name"),
    },
    {
      id: "middle_name",
      label: "Middle Name",
      form: makeInputFormElement("middle_name"),
    },
    {
      id: "family_name",
      label: "Last Name",
      form: makeInputFormElement("family_name"),
    },
    { id: "gender", label: "Gender", form: makeRadioFormElement("gender") },
  ];

  const new_user_acct_fields = [
    {
      id: "password",
      label: "Temporary Password",
      form: makeInputFormElement("given_name"),
    },
    {
      id: "delivery",
      label: "Delivery",
      form: makeInputFormElement("phone_number"),
    },
    {
      id: "email",
      label: "Email",
      form: makeInputFormElement("email"),
    },
    {
      id: "phone_number",
      label: "Mobile Phone",
      form: makeInputFormElement("phone_number"),
    },
  ];

  function UserDisplay() {
    return (
      <div className="text-xl text-rsc-blue/90 md:text-2xl font-bold">
        Create User
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 md:gap-8 w-full p-6 bg-slate-100">
      <div className="flex flex-row">
        <UserDisplay />
      </div>
      <ContentHolder
        back={{ onClick: () => setLocation("/users"), label: "Cancel" }}
        forward={{
          disabled: true,
          onClick: () => createUser({}),
          label: "Create User",
        }}
      >
        <div className="flex flex-row">
          <div className="flex flex-col gap-6 md:min-w-[400px] pr-8 mr-8 border-r border-rsc-light-blue">
            {new_user_info_fields.map((field: any) => {
              const { id, form, label } = field;
              return (
                <div key={id}>
                  <label htmlFor={id}>{label}</label>
                  {form}
                </div>
              );
            })}
          </div>
          <div className="flex flex-col gap-6 md:min-w-[400px]">
            {new_user_acct_fields.map((field: any) => {
              const { id, form, label } = field;
              return (
                <div key={id}>
                  <label htmlFor={id}>{label}</label>
                  {form}
                </div>
              );
            })}
          </div>
        </div>
      </ContentHolder>
    </div>
  );
}
