import { useState } from "react";
import AdminInput from "./AccountInput";
import { useUI } from "../../../context/UIContext";
import { compact, startsWith } from "lodash";
import Can from "../../../utils/auth/Can";
import { useUser } from "../../../context/UserContext";
import { useAccount } from "../../../context/AccountContext";
import { CognitoClient } from "../../../utils/aws";
import { formatPhone, makeUser } from "../../../utils/functions";
import {
  AdminGetUserCommand,
  AdminGetUserCommandInput,
} from "@aws-sdk/client-cognito-identity-provider";
export default function AdminControls() {
  const { location, setLocation } = useUI();
  const { simulated_role, user, UserPoolId } = useUser();
  const { account } = useAccount();

  const [portal, setPortal] = useState<any | null>(null);
  const [loading_portal, setLoadingPortal] = useState<boolean>(false);

  async function getCommand(input: any) {
    try {
      const command = new AdminGetUserCommand(input);
      const response = await CognitoClient.send(command);
      return response;
    } catch (emailError: any) {
      if (emailError.name !== "UserNotFoundException") {
        console.log("Failed to get user by email:", emailError);
        console.log("Payload:", input);
      } else {
        console.log("No User", input);
      }
    }
  }

  async function getUser(account: any) {
    const { Id, PersonEmail: email, Phone: phone } = account;
    let response;
    setLoadingPortal(true);
    try {
      let input: AdminGetUserCommandInput | undefined = undefined;
      const formatted_phone = formatPhone(phone);
      if (email) {
        input = {
          UserPoolId,
          Username: email,
        };
        response = await getCommand(input);
      }

      if (!response && formatted_phone) {
        input = {
          UserPoolId,
          Username: formatted_phone,
        };
        response = await getCommand(input);
      }
    } catch (error: any) {
      console.log("FAILED TO GET USER", error);
    } finally {
      setPortal({ Id, data: response && makeUser(response) });
      setLoadingPortal(false);
    }
  }

  !!account?.Id &&
    account.Id !== portal?.Id &&
    !loading_portal &&
    getUser(account);
  const username = portal?.data?.Username;

  const page_set = compact([
    account &&
      username && {
        label: "Manage User",
        link: `/user/${username}`,
      },
  ]);

  return (
    <div
      className={`flex flex-col md:flex-row md:items-center gap-3 md:gap-4 md:border-t md:border-white/10 md:mt-2 md:pt-1 py-2`}
    >
      <Can
        role={simulated_role || user?.roles}
        perform="market:accounts"
        yes={() => <AdminInput />}
      />
      {false && (
        <div className="flex flex-row items-end text-xs text-white/70">
          {page_set.map((item: any) => {
            const { label, link, perform } = item;
            const isActive = startsWith(link, location);
            return (
              <Can
                key={link}
                role={simulated_role || user?.roles}
                perform={perform}
                yes={() => (
                  <div className="ml-3 md:ml-0">
                    <button
                      className={`hover:underline uppercase  ${
                        isActive ? "font-semibold" : ""
                      }`}
                      onClick={() => setLocation(link)}
                    >
                      {label}
                    </button>
                  </div>
                )}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
