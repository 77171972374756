import IonIcon from "@reacticons/ionicons";
import moment from "moment";
import Logo from "./images/rst-white-logo.png";
export default function CopyrightCredits() {
  const social_icons = [
    {
      icon: "logo-twitter",
      link: "https://twitter.com/RapidSTD",
    },
    {
      icon: "logo-facebook",
      link: "https://www.facebook.com/Rapidstdtestingcom-2109577359331189",
    },
    {
      icon: "logo-instagram",
      link: "https://instagram.com/talkaboutsafesex",
    },
    {
      icon: "logo-pinterest",
      link: "https://www.pinterest.com/RapidSTD",
    },
    {
      icon: "logo-youtube",
      link: "https://www.youtube.com/channel/UC-FmkyE2oQ5N8ycjNCaGE4A",
    },
  ];

  return (
    <div className="flex flex-col gap-8 md:flex-row mt-12 p-4 items-center mx-auto max-w-[1280px] w-full text-white">
      <div className="order-1 md:basis-1/3">
        <a href="https://rapidstdtesting.com">
          <img src={Logo} alt="RST Logo" className="h-10" />
        </a>
      </div>
      <div className="order-3 md:order-2 md:basis-1/3 text-xs text-center">
        Copyright © {moment().year()}
        <br />
        Rapid STD Testing
        <br />
        All Rights Reserved
      </div>
      <div className="order-2 md:order-3 md:basis-1/3 flex flex-row gap-6 justify-end">
        {social_icons.map((button: any) => {
          const { icon, link } = button;
          return (
            <div className="text-3xl" key={icon}>
              <a href={link}>
                <button className="hover:text-amber-400">
                  <IonIcon name={icon} />
                </button>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}
