import { useState } from "react";
import { useUser } from "../context/UserContext";
import Can from "../utils/auth/Can";
export default function AdminSettings() {
  const { user, simulated_role, setSimulatedRole } = useUser();
  const [view, setView] = useState<string>("simulate_role");
  const user_menu = [
    {
      id: "simulate_role",
      label: "View as Role",
      perform: "simulate:role",
    },
  ];

  const role = user?.roles;
  function SettingsMenu() {
    return (
      <div className="md:min-w-[200px] border-r border-rsc-light-blue/30  min-h-[550px]">
        <div className="flex flex-col pr-2 pb-4">
          {user_menu.map((item: any) => {
            const { id, className, label, perform } = item;
            const isActive = id === view;
            return (
              <Can
                key={id}
                role={role}
                perform={perform}
                yes={() => (
                  <div
                    className={`p-2 ${className} ${
                      isActive ? "bg-rsc-light-blue/10 font-bold" : ""
                    } hover:bg-rsc-light-blue/10 cursor-pointer`}
                    onClick={() => {
                      setView(id);
                    }}
                  >
                    {label}
                  </div>
                )}
              />
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 md:gap-8 w-full p-6 bg-slate-100">
      {/* <div className="flex flex-row">
            <UserDisplay />
          </div> */}
      <div className="flex flex-row gap-8">
        <SettingsMenu />
        <div className="flex flex-col gap-8 w-[400px]">
          <select
            className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
            value={simulated_role || role}
            onChange={(e: any) => {
              const { value } = e.target;
              const role_ = value === role ? null : value;
              setSimulatedRole(role_);
              !!role_
                ? localStorage.setItem("admin:role", role_)
                : localStorage.removeItem("admin:role");
            }}
          >
            <option>Super Administrator</option>
            <option value="Administrator">Administrator</option>
            <option value="Agent">Agent</option>
          </select>
        </div>
      </div>
    </div>
  );
}
