export default function MiniOrderCard({
  action,
  bleed,
  card_layout,
  id,
  layout,
  red,
  title,
  children,
}: {
  action?: any;
  bleed?: boolean;
  card_layout?: string;
  id?: string;
  layout?: string;
  red?: boolean;
  title?: any;
  children: any;
}) {
  function Header() {
    return (
      <div
        className={`p-2 flex flex-row items-center  bg-rsc-blue text-white ${
          red ? "bg-rsc-red" : "bg-rsc-blue"
        }`}
      >
        {title && <div className={`text-2xl font-bold `}>{title}</div>}
        {action && (
          <div
            className={`text-xs text-rsc-light-blue font-bold grow text-right`}
          >
            {action}
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      id={id}
      className={`rounded-md overflow-hidden border border-slate-300/60 bg-slate-50 shadow-md flex flex-col ${card_layout}`}
    >
      {(title || action) && <Header />}
      <div className={`${!bleed ? "p-2" : ""} ${layout}`}>{children}</div>
    </div>
  );
}
