import moment from "moment";
import InformationCell from "../TD_Cell";
import { join, map } from "lodash";
const backgroundColor = "rgb(17 102 155 / 0.025)";
const color = "rgb(17 102 155 / 0.8)";
function PatientPharmacy({
  is_purchaser,
  user,
}: {
  is_purchaser?: boolean;
  user: any;
}) {
  const {
    address,
    address_state,
    city,
    family_name,
    given_name,
    pharmacy,
    zip_code,
  } = user;

  function Pharmacy({ pharmacy }: { pharmacy: any }) {
    if (!pharmacy) return null;
    const { City, State, Street, Zip_Code, name } = pharmacy;

    return (
      <InformationCell
        value={
          <>
            <span
              style={{
                color: "rgb(0 0 0 / 0.65)",
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              {name}
            </span>
            <br />
            {Street}
            <br />
            {City}, {State} {Zip_Code}
          </>
        }
      />
    );
  }

  return (
    <table style={{ display: "flex", width: "100%" }}>
      <tbody>
        <tr>
          <td style={{ flexGrow: 1, verticalAlign: "top" }}>
            <InformationCell
              value={
                <>
                  <span style={{ color, fontSize: 16, fontWeight: 600 }}>
                    {given_name} {family_name}{" "}
                    {is_purchaser && (
                      <sup className="text-gray-400 font-normal text-[9px] uppercase">
                        Purchaser
                      </sup>
                    )}
                  </span>
                  <br />
                  {address}
                  <br />
                  {city}, {address_state} {zip_code}
                </>
              }
            />
          </td>
          <td>
            <table>
              <tbody>
                <tr>
                  <td>
                    <Pharmacy pharmacy={pharmacy} />
                  </td>
                  <td>
                    <InformationCell
                      label="Pharmacy Phone"
                      link="phone"
                      value={pharmacy?.phone}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default function ConsultationPatientInformation({
  is_purchaser,
  user,
}: {
  is_purchaser?: boolean;
  user: any;
}) {
  // const padding = "6px 16px";
  if (!user) return null;
  const {
    allergies,
    dob,
    email,
    medications,
    no_allergies,
    no_medications,
    notes,
    phone_number,
    race_ethnicity,
    sex,
    symptoms,
    test_date,
    treating,
  } = user;

  return (
    <>
      <table
        style={{
          backgroundColor,
          fontSize: "11pt",
          width: "100%",
        }}
      >
        <tbody>
          <tr>
            <td>
              <PatientPharmacy is_purchaser={is_purchaser} user={user} />
            </td>
          </tr>
          <tr>
            <td>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <InformationCell
                                        label="Phone"
                                        link="phone"
                                        value={phone_number}
                                      />
                                    </td>
                                    <td>
                                      <InformationCell
                                        label="Email"
                                        link="email"
                                        value={email}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <InformationCell
                                        label="Date of Birth"
                                        value={moment(dob).format("LL")}
                                      />
                                    </td>
                                    <td>
                                      <InformationCell
                                        label="Sex"
                                        value={sex}
                                      />
                                    </td>
                                    <td>
                                      <InformationCell
                                        label="Race/Ethnicity"
                                        value={race_ethnicity}
                                      />
                                    </td>
                                    <td>
                                      <InformationCell
                                        label="Drug Allergies"
                                        value={
                                          no_allergies ? "None" : allergies
                                        }
                                      />
                                    </td>
                                    <td>
                                      <InformationCell
                                        label="Current Medications"
                                        value={
                                          no_medications ? "None" : medications
                                        }
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <InformationCell
                                        label="Test Date"
                                        value={moment(test_date).format("LL")}
                                      />
                                    </td>
                                    <td>
                                      <InformationCell
                                        label="Treating"
                                        value={join(map(treating), ", ")}
                                      />
                                    </td>{" "}
                                    <td>
                                      <InformationCell
                                        label="Symptoms"
                                        value={symptoms || "None"}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <InformationCell
                                label="Notes for Doctor"
                                value={notes || "None"}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <table>
        <tbody>
          <tr>
            <td style={{ paddingBottom: 40 }}></td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
