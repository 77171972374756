import { CognitoIdentityProviderClient } from "@aws-sdk/client-cognito-identity-provider";

const { REACT_APP_AWS_ACCESS_KEY_ID, REACT_APP_AWS_SECRET_ACCESS_KEY } =
  process.env;

const client_settings = {
  region: "us-east-1",
  credentials: {
    accessKeyId: REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
};

export const CognitoClient = new CognitoIdentityProviderClient(client_settings);
