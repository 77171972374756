// import IonIcon from "@reacticons/ionicons";
// import { kebabCase, map, upperFirst } from "lodash";
import CopyrightCredits from "./credits";
//import IonIcon from "@reacticons/ionicons";
//import { useUser } from "./UserContext";
import BottomFooterMenu from "./bottom_footer_menus";

export default function BottomFooter() {
  //const { setLocation } = useUser();
  function TopBottomFooter() {
    return (
      <div className="text-white text-center font-bold">
        <h1 className="text-3xl leading-10">Ready to Get Started</h1>
        <h2 className="text-xl font-semibold leading-10">
          Find a nearby lab, or look at our pricing.
        </h2>
        <div className="flex flex-col md:flex-row items-center justify-center my-6 gap-6">
          <div>
            <button
              className="bg-rsc-red hover:bg-amber-400 w-72 py-4 rounded-full uppercase shadow-md shadow-black text-lg"
              onClick={() => {
                window.location.href =
                  "https://rapidstdtesting.com/std-testing-near-you";
              }}
            >
              See Tests & Prices
            </button>
          </div>
          <div>
            <button
              className="border-2 border-white rounded-full w-72 py-4 uppercase text-lg hover:bg-rsc-red"
              onClick={() => {
                window.location.href =
                  "https://rapidstdtesting.com/std-testing-near-you";
              }}
            >
              Find a Lab
            </button>
          </div>
        </div>
      </div>
    );
  }

  // function FooterMenu({ list }: { list: any }) {
  //   const { header, items } = list;
  //   return (
  //     <div className="flex flex-col items-center text-white md:basis-1/2 2xl:basis-1/4">
  //       <div>
  //         <div className="text-2xl font-semibold">
  //           <IonIcon
  //             name="square"
  //             className="text-amber-400"
  //             style={{ marginRight: 4, transform: "translateY(3px)" }}
  //           />
  //           {header}
  //         </div>
  //         <div>
  //           <ul className="text-lg">
  //             {items.map((item: any, idx: number) => {
  //               const { label, link, uri, url } = item;
  //               return (
  //                 <li key={link || url}>
  //                   {link ? (
  //                     <button
  //                       className="hover:text-amber-400"
  //                       onClick={() => setLocation(link)}
  //                     >
  //                       {label}
  //                     </button>
  //                   ) : (
  //                     <a href={url ? `https://rapidstdtesting.com${url}` : uri}>
  //                       <button className="text-lg hover:text-amber-400">
  //                         {label}
  //                       </button>
  //                     </a>
  //                   )}
  //                 </li>
  //               );
  //             })}
  //           </ul>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="bg-rsc-light-blue p-6 pb-2">
      <TopBottomFooter />
      <BottomFooterMenu />
      <CopyrightCredits />
    </div>
  );
}
