import { useUI } from "./context/UIContext";

export default function PopUp() {
  const { popUp, setPopUp } = useUI();

  function PopUpHolder({ children }: { children: any }) {
    return (
      <div
        className="fixed top-0 left-0 h-screen w-screen bg-black/60 z-50 flex flex-row justify-center items-center"
        onClick={(e: any) => {
          console.log("THIS ACTION");
          e.stopPropagation();
          setPopUp(null);
        }}
      >
        {children}
      </div>
    );
  }
  if (!popUp) return null;
  return <PopUpHolder>{popUp}</PopUpHolder>;
}
/* <div className="m-6 bg-slate-50 min-h-40 min-w-[90%] md:min-w-[unset] md:min-w-[450px] md:max-w-[600px] rounded-lg overflow-hidden"> */
