import FindATestCenter from "./Find_Test_Center";
import PaymentInformation from "./PaymentInformation";
import { useOrder } from "./OrderContext";
import ContactInformation from "./ContactInformation";
import Acknowledgement from "../../components/acknowledgement";
import ShopProducts from "./ShopProducts";
import PersonalInformation from "./PersonalInformation";
import OrderSummary from "../Summary";
import SupportButton from "../support_button";

export default function ScheduleTesting() {
  const { handleOrderChange, order } = useOrder();

  return (
    <div className="flex flex-col gap-6 w-full">
      <FindATestCenter />
      <ShopProducts />
      <PersonalInformation />
      <ContactInformation />
      <Acknowledgement
        checked={order?.acknowledged}
        className="my-6"
        onChange={(e: any) => {
          handleOrderChange("acknowledged", e.target.checked);
        }}
      />
      <div className="md:hidden">
        <OrderSummary />
      </div>
      <PaymentInformation />
      <div>
        <SupportButton className="w-max mx-auto my-4" />
      </div>
    </div>
  );
}
