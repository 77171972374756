import IonIcon from "@reacticons/ionicons";
import { useUI } from "../../context/UIContext";

export default function BottomFooterMenu() {
  const { setLocation } = useUI();
  function FooterMenu({ list }: { list: any }) {
    const { header, items } = list;
    return (
      <div className="flex flex-col items-start text-white md:basis-1/2 2xl:basis-1/4">
        <div>
          <div className="text-2xl font-semibold leading-[3.75rem]">
            <IonIcon
              name="square"
              className="text-amber-400"
              style={{ marginRight: 4, transform: "translateY(3px)" }}
            />
            {header}
          </div>
          <div>
            <ul className="text-[16px]">
              {items.map((item: any, idx: number) => {
                const { label, link, uri, url } = item;
                return (
                  <li
                    key={link || url}
                    className="leading-none py-[7px] font-[500]"
                  >
                    {link ? (
                      <button
                        className="hover:text-amber-400"
                        onClick={() => setLocation(link)}
                      >
                        {label}
                      </button>
                    ) : (
                      <a href={url ? `https://rapidstdtesting.com${url}` : uri}>
                        <button className="text-[16px] hover:text-amber-400">
                          {label}
                        </button>
                      </a>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 md:max-w-[600px] md:mx-auto lg:grid-cols-4 lg:max-w-[1200px] gap-8 pt-6">
      <FooterMenu
        list={{
          header: "Explore Pages",
          items: [
            { url: "/about-us/", label: "About Us" },
            { url: "/how-it-works/", label: "How it Works" },
            { url: "/test-and-panels/", label: "Text & Panels" },
            { url: "/std-testing-near-you/", label: "Find a Lab" },
            { url: "/std-symptoms/", label: "Symptoms of STD" },
            { url: "/faqs/", label: "FAQ" },
            { url: "/blog/", label: "Blog" },
            {
              link: "/dashboard",
              label: "My Account",
            },
          ],
        }}
      />
      <FooterMenu
        list={{
          header: "Our Services",
          items: [
            {
              url: "/confidential-std-testing/",
              label: "Confidential STD Testing",
            },
            {
              url: "/couples-std-testing/",
              label: "STD Testing for Couples",
            },
            {
              url: "/saturday-std-testing/",
              label: "STD Testing on Saturday",
            },
            { url: "/free-std-testing/", label: "Free STD Testing" },
          ],
        }}
      />
      <FooterMenu
        list={{
          header: "Symptoms",
          items: [
            { url: "/chlamydia-symptoms/", label: "Chlamydia" },
            { url: "/gonorrhea-symptoms/", label: "Gonorrhea" },
            { url: "/hepatitis-b-symptoms/", label: "Hepatitis B" },
            { url: "/hepatitis-c-symptoms/", label: "Hepatitis C" },
            { url: "/herpes-symptoms/", label: "Herpes" },
            { url: "/std-symptoms/hiv-symptoms/", label: "HIV" },
            { url: "/syphilis-symptoms/", label: "Syphilis" },
            { url: "/trichomoniasis-symptoms/", label: "Trichomoniasis" },
          ],
        }}
      />
      <div className="lg:basis-1/4">
        <div className="flex flex-col text-white basis-1/4">
          <div className="flex flex-col items-start gap-4">
            <div>
              <div className="text-2xl font-semibold leading-[3.75rem]">
                <IonIcon
                  name="square"
                  className="text-amber-400"
                  style={{ marginRight: 4, transform: "translateY(3px)" }}
                />
                Contact Us
              </div>
              <div className="leading-6 py-[7px] font-[500]">
                Need help? Have a question?
                <br />
                Speak with a health specialist today.
              </div>
              <div>
                <a href={"tel:+1888-872-1888"}>
                  <button className="bg-rsc-red px-6 py-2 mt-3 rounded-full uppercase shadow-md shadow-black text-lg font-bold">
                    (888) 872-1888
                  </button>
                </a>
              </div>
            </div>
            <div>
              <div className="text-2xl font-semibold leading-[3.75rem]">
                <IonIcon
                  name="square"
                  className="text-amber-400"
                  style={{ marginRight: 4, transform: "translateY(3px)" }}
                />
                Partner With Us
              </div>
              <div>
                <ul className="text-lg">
                  {[
                    {
                      uri: "https://www.shareasale.com/join/109883",
                      label: "Affiliate Program",
                    },
                    { link: "#media", label: "Media Relations" },
                    { link: "#contact", label: "Contact" },
                  ].map((item: any) => {
                    const { label, link, uri, url } = item;
                    const key = link || url || uri;
                    return (
                      <li
                        key={key}
                        className="leading-none py-[7px] font-[500]"
                      >
                        {link ? (
                          <button
                            className="hover:text-amber-400"
                            onClick={() => setLocation(link)}
                          >
                            {label}
                          </button>
                        ) : (
                          <a
                            href={
                              url ? `https://rapidstdtesting.com${url}` : uri
                            }
                          >
                            <button className="hover:text-amber-400">
                              {label}
                            </button>
                          </a>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
