import IonIcon from "@reacticons/ionicons";
import { useUI } from "../../../../../../context/UIContext";

export default function WhereResults() {
  const { setPopUp } = useUI();

  return (
    <>
      <div className="p-4 text-lg text-gray-600 bg-gray-100 border-b border-gray-200 flex flex-row">
        <div className="grow">Where are my test results?</div>
        <button onClick={() => setPopUp(null)}>
          <IonIcon
            name="close-circle"
            style={{ fontSize: "1.4rem", transform: "translateY(2px)" }}
          />
        </button>
      </div>
      <div className="p-4 flex flex-col gap-4">
        <p>
          Test results are generally available within 1-2 business days after
          your lab visit, with some exceptions. HIV RNA and Trichomoniasis tests
          may take an extra day or two to process, and certain states have
          regulations that may add additional time to the process. Please allow
          for these potential delays when awaiting your test results.
        </p>
      </div>
    </>
  );
}
