const menu_items = [
  {
    primary: {
      label: "How It Works",
      url: "/how-it-works",
    },
    sub_items: [
      {
        label: "Simple STD Testing Process",
        url: "/our-std-testing-process-is-simple",
      },
      {
        label: "Right Time To Test",
        url: "/when-to-test",
      },
      {
        label: "STD Test Results",
        url: "/std-test-results",
      },
      {
        label: "Payment Options",
        url: "/payment-options",
      },
      {
        label: "Treatment If Needed",
        url: "/treatment-options",
      },
      {
        label: "Speak With Our Doctors",
        url: "/doctor-consultations",
      },
    ],
  },
  {
    primary: {
      label: "Test & Pricing",
      url: "/test-and-panels",
    },
    sub_items: [
      {
        label: "Testing for Chlamydia",
        url: "/testing-for-chlamydia",
      },
      {
        label: "Testing for Gonorrhea",
        url: "/testing-for-gonorrhea",
      },
      {
        label: "Chlamydia & Gonorrhea Panel",
        url: "/chlamydia-gonorrhea-panel",
      },
      {
        label: "Testing for Hepatitis B",
        url: "/testing-for-hepatitis-b",
      },
      {
        label: "Testing for Hepatitis C",
        url: "/testing-for-hepatitis-c",
      },
      {
        label: "Testing for Herpes",
        url: "/testing-for-herpes",
      },
      {
        label: "Testing for HIV",
        url: "/testing-for-hiv",
      },
      {
        label: "Early Detection Testing for HIV",
        url: "/early-detection-testing-for-hiv",
      },
      {
        label: "Testing for Syphilis",
        url: "/testing-for-syphilis",
      },
      {
        label: "Testing for Trichomoniasis",
        url: "/testing-for-trichomoniasis",
      },
      {
        label: "Custom STD Testing Panels",
        url: "/comprehensive-10-panel-test",
      },
    ],
  },
  {
    primary: {
      label: "Our Services",
      url: "/our-services",
    },
    sub_items: [
      {
        label: "Confidential STD Testing",
        url: "confidential-std-testing",
      },
      {
        label: "STD Testing for Couples",
        url: "/couples-std-testing",
      },
      {
        label: "STD Testing on Saturday",
        url: "/saturday-std-testing",
      },
      {
        label: "Free STD Testing",
        url: "/free-std-testing",
      },
      {
        label: "About Us",
        url: "/about-us",
      },
    ],
  },
  {
    primary: {
      label: "Symptoms of STDS",
      url: "/std-symptoms",
    },
    sub_items: [
      {
        label: "Chlamydia",
        url: "/chlamydia-symptoms",
      },
      {
        label: "Gonorrhea",
        url: "/gonorrhea-symptoms",
      },
      {
        label: "Hepatitis B",
        url: "/hepatitis-b-symptoms",
      },
      {
        label: "Hepatitis C",
        url: "/hepatitis-c-symptoms",
      },
      {
        label: "Herpes",
        url: "/herpes-symptoms",
      },
      {
        label: "HIV",
        url: "/std-symptoms/hiv-symptoms",
      },
      {
        label: "Syphilis",
        url: "/syphilis-symptoms",
      },
      {
        label: "Trichomoniasis",
        url: "/trichomoniasis-symptoms",
      },
    ],
  },
];

export default menu_items;
/*

*/
