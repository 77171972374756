import { useState } from "react";
import { compact, concat, flatMap } from "lodash";
import {
  ConfirmForgotPasswordCommand,
  ConfirmForgotPasswordCommandInput,
} from "@aws-sdk/client-cognito-identity-provider";
import { CognitoClient } from "../../../utils/aws";
import Section from "../form_section";
import passwordValidator from "password-validator";
import { useUser } from "../../../context/UserContext";

const { REACT_APP_AWS_COGNITO_CLIENT_ID: ClientId } = process.env;
const pw_schema = new passwordValidator();
pw_schema
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(16) // Maximum length 100
  .has()
  .uppercase() // Must have uppercase letters
  .has()
  .lowercase() // Must have lowercase letters
  .has()
  .digits(2) // Must have at least 2 digits
  .has()
  .not()
  .spaces() // Should not have spaces
  .is()
  .not()
  .oneOf(["Passw0rd", "Password123"]);

export default function SettingsChangePassword({
  username,
  setCodeSent,
}: {
  username: string;
  setCodeSent: any;
}) {
  const { logoutUser, setMessage, setErrorMessage } = useUser();

  const [change_password, setChangePassword] = useState<any>({
    password: "",
    password2: "",
  });
  const [auth_code, setAuthCode] = useState("");

  const { password, password2 } = change_password;

  function changePassword(field: string, value: any) {
    setChangePassword({ ...change_password, [field]: value });
  }

  async function confirmResetCode() {
    try {
      //!isEmail && setSMS(username);

      const input: ConfirmForgotPasswordCommandInput = {
        ClientId,
        ConfirmationCode: auth_code,
        Password: password,
        Username: username,
      };
      console.log("CONFIRMING", input);
      const command = new ConfirmForgotPasswordCommand(input);
      const response = await CognitoClient.send(command);
      setMessage(
        <>Your password was changed. Log in using updated password.</>
      );
      logoutUser();
      console.log("CODE CONFIRMATION RESPONSE", response);
    } catch (error: any) {
      const error_messages: { [key: string]: any } = {
        ExpiredCodeException: (
          <>
            {error.message}{" "}
            <button onClick={() => setCodeSent(false)}>
              <span className="font-bold underline">Resend code.</span>
            </button>
          </>
        ),
        // NotAuthorizedException: (
        //   <>
        //     {error.message}{" "}
        //     <button onClick={() => setLocation("./verify")}>
        //       <span className="font-bold underline">Verify user.</span>
        //     </button>
        //   </>
        // ),
      };
      setErrorMessage(error_messages[error.name] || error.message);
      console.log("ERROR CONFIRMING CODE", JSON.stringify(error), error);
    }
  }

  function readyToSubmit() {
    const matching_pw = password === password2;
    const good_pw = pw_schema.validate(password);

    const pw_errors = pw_schema.validate(password, { details: true }) as any[];
    const error_list = flatMap(pw_errors, "message");

    const error_array = concat([
      password.length > 0 && !matching_pw && "Passwords entered do not match.",
    ]);
    const error_2 = concat(
      error_array,
      password.length > 0 && !good_pw && error_list
    );

    return {
      ready: matching_pw && good_pw,
      errors: compact(error_2),
    };
  }

  const is_ready_to_submit = readyToSubmit();
  const className =
    "rounded-lg border border-rsc-light-blue/30 h-10 w-full shadow-inner px-2";

  return (
    <>
      <Section>
        {" "}
        <p className="text-sm">
          A reset code was sent to{" "}
          <span className="font-semibold">{username}</span>. Enter it below and
          your new password.
        </p>
      </Section>
      <Section>
        <div className="flex flex-col gap-6 ">
          <div>
            <label htmlFor="current">Code:</label>
            <input
              id="current"
              className={className}
              placeholder="Verify Code Sent"
              value={auth_code}
              onChange={(e: any) => setAuthCode(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="password">Password:</label>
            <input
              className={className}
              id="password"
              placeholder="New Password"
              value={password}
              onChange={(e: any) => changePassword("password", e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="password2">Re-enter Password:</label>
            <input
              className={className}
              id="password2"
              placeholder="Re-enter New Password"
              value={password2}
              onChange={(e: any) => changePassword("password2", e.target.value)}
            />
          </div>
        </div>
      </Section>
      <Section>
        <div className="flex flex-row items-center">
          <div className="grow">
            <button
              className="text-xs text-rsc-light-blue font-semibold"
              onClick={() => setCodeSent(false)}
            >
              Resend Code
            </button>
          </div>
          <div className="">
            <button
              className={`${
                is_ready_to_submit.ready
                  ? "bg-rsc-blue text-white shadow-md"
                  : "bg-gray-200 text-gray-400"
              } font-bold px-6 py-2 rounded-md`}
              disabled={!is_ready_to_submit.ready}
              onClick={() => confirmResetCode()}
            >
              Update Password
            </button>
          </div>
        </div>
      </Section>
    </>
  );
}
