import { useMessages } from "../MessagesContext";
import { find } from "lodash";

export default function LabDetails() {
  const { messages, viewing_message } = useMessages();
  console.log("viewing", viewing_message);
  if (!viewing_message) return null;
  const { Description } = find(messages, ["Id", viewing_message]);
  return (
    <div className="w-full" style={{ whiteSpace: "pre-wrap" }}>
      {Description}
    </div>
  );
}
