import { useRegistration } from "../RegistrationContext";
import { validEmail, validPhone } from "../../utils/functions";
import { useUI } from "../../context/UIContext";
import LoginHolder from "../holder";
export default function ForgotPassword() {
  const { setLocation } = useUI();
  const { username, resendCode, setUsername } = useRegistration();
  const is_ready = validPhone(username) || validEmail(username);
  return (
    <LoginHolder header="Forgot Password">
      <div>
        <p className="text-xs text-center">
          Enter the email or phone number of the account.
        </p>
      </div>
      <div className="">
        <input
          className="w-full h-10 px-2 border rounded-lg shadow-inner border-slate-400"
          id={"code"}
          placeholder={`Email address or 10-digit Phone Number`}
          value={username}
          onChange={(e: any) => setUsername(e.target.value)}
        />
      </div>
      <button
        disabled={!is_ready}
        onClick={() => resendCode({ route: "/reset-password" })}
        className={`p-3 font-semibold text-center text-white rounded-md shadow-md ${
          is_ready ? "bg-rsc-blue text-white" : "bg-gray-300 text-white"
        }`}
      >
        Send Reset Code
      </button>
      <div className="flex flex-row text-xs text-rsc-light-blue font-semibold ">
        <div className="grow">
          <button
            className="text-rsc-light-blue font-semibold"
            onClick={() => setLocation("/login")}
          >
            Log In
          </button>
          {" or "}
          <button
            className="text-rsc-light-blue font-semibold"
            onClick={() => setLocation("/register")}
          >
            Register
          </button>
        </div>
        <div>
          <button
            className="text-right"
            onClick={() => setLocation("/reset-password")}
          >
            Already have a code?
            <br />
            Reset Password
          </button>
        </div>
      </div>
    </LoginHolder>
  );
}
