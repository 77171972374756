import { useUI } from "../../../context/UIContext";

export default function TopFooter() {
  const { setLocation } = useUI();
  const menu_buttons = [
    {
      label: "Search",
      route: "https://www.rschealth.com/search",
    },
    {
      label: "Terms of Service",
      route: "https://www.rschealth.com/pages/terms-of-service",
    },
    {
      label: "Privacy Policy",
      route: "https://www.rschealth.com/pages/your-privacy-is-protected",
    },
    {
      label: "Cancellation Policy",
      route: "https://www.rschealth.com/pages/cancellation-policy",
    },
    {
      label: "Contact Us",
      route: "https://www.rschealth.com/pages/contact-us",
    },
  ];
  const services_buttons = [
    {
      label: "Tests and Panels",
      link: "/test-and-panels",
    },
    {
      label: "Find A Test Center",
      link: "/find-a-lab",
    },
    {
      label: "Order Testing",
      link: "/order",
    },
    {
      label: "Order Consultation",
      link: "/consultation",
    },
    {
      label: "DNA Testing",
      route: "https://dna-testing.rschealth.com",
    },
  ];
  return (
    <div className="flex flex-col gap-8 md:flex-row">
      <div className="flex flex-col md:flex-row gap-8 md:gap-16">
        <div className="flex flex-col gap-4 grow">
          <div className="font-bold text-[1.125rem]">Services</div>
          <div className="flex flex-col gap-2">
            {services_buttons.map((btn: any) => {
              const { label, link, route } = btn;
              return (
                <div key={link || route}>
                  {link ? (
                    <button onClick={() => setLocation(link)}>{label}</button>
                  ) : (
                    <a href={route}>{label}</a>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col gap-4 grow">
          <div className="font-bold text-[1.125rem]">Information</div>
          <div className="flex flex-col gap-2">
            {menu_buttons.map((btn: any) => {
              const { label, route } = btn;
              return (
                <div key={route}>
                  <a href={route}>{label}</a>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-end gap-4 md:grow">
        <div className="text-[1.125rem] text-right">
          <span className="font-bold">Subscribe</span>
          <br />
          Join our mailing list.
        </div>
        <div className="flex flex-col items-end gap-3">
          <div className="md:max-w-[230px]">
            <input
              className="text-[1.125rem] py-[0.875rem] px-[0.9375rem] rounded-lg w-full"
              placeholder="Email address"
            />
          </div>
          <div>
            <button className="bg-rsc-yellow text-[1.125rem] font-bold rounded-lg py-[0.875rem] px-[0.9375rem]">
              Sign Up
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
