import { createContext, useContext, useReducer, useState } from "react";
import { PatientConsultationReducer } from "./ConsultationReducer";
import {
  compact,
  concat,
  flattenDeep,
  intersection,
  isEmpty,
  map,
  merge,
  pickBy,
  reject,
} from "lodash";
import {
  blank_person,
  default_partners,
  default_person,
  default_pharmacy,
  Person,
} from "../../../utils/types";
import test_tx_data from "./transaction";
import { validate_consultation } from "../../../utils/functions";
import { useUI } from "../../../context/UIContext";
import { useAccount } from "../../../context/AccountContext";
const test_consultation = test_tx_data?.mx?.patient;
const isTestingPatient = false;
const isTestingPartners = false;
const isTestingNoPaymentConsultation = false;

interface ConsultationValues {
  acknowledged: boolean;
  alert_message?: string;
  errors: any[];
  existing: boolean;
  partner: boolean;
  isSubmitting: boolean;
  isTesting: boolean;
  partners: Person[];
  patient: Person;
  payment_required: boolean;
  total_due: number;
  valid_contact: boolean;
  view_errors: boolean;
  clearForm: () => void;
  handlePatientChange: (field: string, value: any) => void;
  removePartner: (_id: string) => void;
  setAcknowledged: (checked: boolean) => void;
  setAlertMessage: (message: string | undefined) => void;
  setIsSubmitting: (setting: boolean) => void;
  setExisting: (setting: boolean) => void;
  setViewErrors: (view_errors: boolean) => void;
  toggleTreating: (value: string) => void;
  // validatePerson: (person: Person) => ValidationErrors | false;
  // validateConsultation: () => any[];
}

export const ConsultationContext = createContext<ConsultationValues>({
  acknowledged: false,
  alert_message: "",
  errors: [],
  existing: false,
  partner: false,
  isSubmitting: false,
  isTesting: false,
  partners: [],
  patient: blank_person,
  payment_required: false,
  total_due: 0,
  valid_contact: false,
  view_errors: false,
  clearForm: () => {},
  handlePatientChange: () => {},
  removePartner: () => {},
  setAcknowledged: () => {},
  setAlertMessage: () => {},
  setExisting: () => {},
  setIsSubmitting: () => {},
  setViewErrors: () => {},
  toggleTreating: () => {},
  // validatePerson: () => false,
  // validateConsultation: () => [],
});

export const useConsultation = () => useContext(ConsultationContext);

const ConsultationContextProvider: React.FC<{
  children: any;
  partner: boolean;
}> = ({ children, partner }) => {
  const { is_testing_consultation } = useUI();
  const { account } = useAccount();
  const user_patient = merge(
    { ...(isTestingPatient ? default_person : blank_person) },
    pickBy({
      partners: isTestingPartners ? default_partners : [],
      pharmacy: isTestingPatient ? default_pharmacy : null,
    })
  );

  const cached_consultation = window.localStorage.getItem("patient");

  const consultation_from_cache =
    typeof cached_consultation === "string" &&
    cached_consultation !== "undefined" &&
    JSON.parse(cached_consultation);

  const cached_consultation_to_load =
    (!!account?.Id && consultation_from_cache?.Id === !!account.Id) ||
    consultation_from_cache;

  const initial_consultation = is_testing_consultation
    ? test_consultation
    : cached_consultation_to_load || user_patient;

  const [patient, dispatch] = useReducer(
    PatientConsultationReducer,
    initial_consultation
  );

  const partners = patient?.partners;

  const [existing, setExisting] = useState<boolean>(false);
  const [acknowledged, setAcknowledged] = useState<boolean>(
    is_testing_consultation
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [alert_message, setAlertMessage] = useState<string | undefined>();

  const [view_errors, setViewErrors] = useState<boolean>(false);

  function toggleTreating(event: any) {
    const options = Array.from(
      event.target.selectedOptions,
      (option: any) => option.value
    );

    options.forEach(option => {
      if (!patient.treating.includes(option)) {
        dispatch({ type: "ADD_OPTION", payload: option });
      }
    });

    patient.treating.forEach((option: any) => {
      if (!options.includes(option)) {
        dispatch({ type: "REMOVE_OPTION", payload: option });
      }
    });
  }

  function handlePatientChange(field: string, value: any) {
    dispatch({
      type: "HANDLE_CHANGE",
      patient: {
        field,
        value,
      },
    });
  }

  function removePartner(_id: string) {
    const old_array = [...partners];
    const new_array = reject(old_array, ["_id", _id]);
    handlePatientChange("partners", new_array);
  }

  const clearForm = () => {
    dispatch({
      type: "CLEAR_PATIENT",
      patient: blank_person,
    });
  };

  const all_treating = compact(
    concat(patient?.treating, flattenDeep(map(patient?.partners, "treating")))
  );
  const payment_required =
    isTestingNoPaymentConsultation ||
    !isEmpty(
      intersection(
        ["Chlamydia", "Gonorrhea", "Herpes I", "Herpes II", "Trichomoniasis"],
        all_treating
      )
    );

  const validation_subject = {
    acknowledged,
    patient,
    partners: patient?.partners || [],
  };

  const consultation_validation =
    validate_consultation.safeParse(validation_subject);
  const errors = (consultation_validation as any)?.error?.issues.map(
    (issue: any) => {
      if (issue.path.length > 0) {
        // If the error has a path, use the first element of the path as the field name
        return `${issue.path}: ${issue.message}`;
      } else {
        // If the error doesn't have a path, use the default error message
        return issue.message;
      }
    }
  );

  const total_due = 99.0 * concat(patient, partners).length;
  return (
    <ConsultationContext.Provider
      value={{
        acknowledged,
        alert_message,
        clearForm,
        errors,
        existing,
        handlePatientChange,
        isSubmitting,
        isTesting: isTestingPatient,
        partner,
        partners: patient?.partners || [],
        patient,
        payment_required,
        total_due,
        valid_contact: true,
        view_errors,
        removePartner,
        setAcknowledged,
        setAlertMessage,
        setIsSubmitting,
        setExisting,
        setViewErrors,
        toggleTreating,
        // validateConsultation,
        // validatePerson,
      }}
    >
      {children}
    </ConsultationContext.Provider>
  );
};

export default ConsultationContextProvider;
