import IonIcon from "@reacticons/ionicons";

export default function ShopAbout() {
  const about_list = [
    {
      title: "HIV (Type 1 and 2)",
      description:
        "Scans antibodies created by the immune system to diagnose HIV.",
    },
    {
      title: "Herpes (1 and 2)",
      description:
        "Screens antibodies your body develops in response to the viral infection.",
    },
    {
      title: "Hepatitis A, B, C",
      description:
        "Measures the level of Hepatitis A, Hepatitis B, Hepatitis C, and antibodies for infection.",
    },
    {
      title: "Syphilis",
      description:
        "Detects antibodies your body produces to fight the infection.",
    },
    {
      title: "Chlamydia and Gonorrhea",
      description:
        "The blood or urine test scans for the bacteria that cause chlamydia/gonorrhea infection.",
    },
  ];

  return (
    <div className="bg-white">
      <div className="flex flex-col gap-6 lg:flex-row items-center justify-center mx-auto my-32 text-rsc-blue max-w-[1024px]">
        <div className="lg:flex-1 lg:order-last">
          <img
            src="https://res.cloudinary.com/maxime-associates/image/upload/v1665377220/RapidSTDTesting/comprehensive-panel-patients.png"
            alt="comprehensive panel"
            className="w-full"
          />
        </div>
        <div className="px-8 md:px-0 lg:flex-1">
          <p className="text-[1.5625rem] font-semibold mb-[1.9375rem]">
            As A Comprehensive Panel, Patients Will Be Screened For Several
            Different STDs, Including:
          </p>
          <ul className="text-[1.0625rem]">
            {about_list.map((item: any) => {
              const { title, description } = item;
              return (
                <li key={title} className="mb-[.375rem]">
                  <div className="flex flex-row gap-3">
                    <div>
                      <IonIcon
                        name="checkmark-sharp"
                        className="text-rsc-yellow translate-y-[2px]"
                      />
                    </div>
                    <div>
                      <strong>{title}</strong>
                      <br />
                      {description}
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
