import { compact, concat, find, join, map, sum } from "lodash";
export default function ComboTestItem({
  all_products,
  test,
}: {
  all_products: any;
  test: any;
}) {
  const { cpt, items, name, price } = test;

  function getComboValue(individual_lines: any) {
    return sum(
      compact(
        map(individual_lines, function (s: any) {
          return find(all_products, ["sku", s]).price;
        })
      )
    );
  }

  const combo_price = getComboValue(items);

  return (
    <tr>
      <td style={{ borderBottom: "1px solid rgb(0 0 0 / .05)", padding: 16 }}>
        <table style={{ fontSize: 12, width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ width: "50%" }}>
                <span style={{ fontWeight: 600 }}>{name}</span>
                <span
                  style={{ color: "#888", fontSize: 10, fontStyle: "italic" }}
                >
                  {" "}
                  - ${(combo_price || 0).toFixed(2)} value
                </span>
                <br />
                {join(
                  map(items, function (s: any) {
                    return find(all_products, ["sku", s]).name;
                  }),
                  ", "
                )}{" "}
                <br />
                <span
                  style={{ color: "#888", fontSize: 9, fontStyle: "italic" }}
                >
                  {join(
                    compact(
                      concat(
                        map(items, function (s: any) {
                          return find(all_products, ["sku", s]).cpt;
                        }),
                        cpt
                      )
                    ),
                    ", "
                  )}
                </span>
              </td>
              <td
                style={{
                  fontWeight: 600,
                  textAlign: "right",
                  verticalAlign: "top",
                }}
              >
                ${(price || 0).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
}
