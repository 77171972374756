import { Fragment } from "react";
import IonIcon from "@reacticons/ionicons";
import LabMap from "./lab_map";
import moment from "moment";
import { useOrder } from "../../OrderContext";
export default function SelectedCenter({ test_center }: { test_center: any }) {
  const { handleOrderChange } = useOrder();
  const { hours, location, name, address, city, state, zip_code, fax, url } =
    test_center;

  function isOpen(hours: any) {
    const now = moment();
    const today = now.format("dddd");

    if (!hours?.[today]?.[0]) return false;
    const { start, end } = hours[today];
    if (!start) return false;
    const start_ = moment(start, "LT");
    const end_ = moment(end, "LT");
    const is_open = now.isBetween(start_, end_);
    return is_open;
  }

  const is_open = isOpen(hours);
  const open_saturdays = hours?.Saturday?.[0]?.start;
  function LabAddress() {
    return (
      <div className="md:grow text-gray-600">
        <div className="flex flex-col mb-4 gap-2">
          <div className="flex flex-row gap-4 items-start md:items-center">
            <div className="text-xl font-semibold italic grow">{name}</div>
            {(open_saturdays || is_open) && (
              <div className="flex flex-col md:flex-row items-end md:items-center gap-2 md:gap-4">
                {open_saturdays && (
                  <div className="bg-rsc-light-blue rounded-md py-1 px-2 text-white font-bold shadow-md">
                    Saturday Hours
                  </div>
                )}
                {is_open && (
                  <div className="bg-green-600 rounded-md py-1 px-2 text-white font-bold shadow-md">
                    Open
                  </div>
                )}
              </div>
            )}
          </div>
          <p className="my-1">
            {address}
            <br />
            {city}, {state} {zip_code}
            <br /> Fax: {fax}
          </p>
        </div>
        <div className="text-blue-600 font-semibold">Text Me Lab Details</div>
        <div className="text-blue-600 font-semibold">
          <a href={url} target="_blank" rel="noreferrer">
            <IonIcon
              name="globe-sharp"
              style={{ transform: "translateY(2px)" }}
            />{" "}
            Directions
          </a>
        </div>
        {/* <div className="text-blue-600 font-semibold">
          <button onClick={() => toggleView("map")}>
            <IonIcon
              name="navigate-circle-sharp"
              style={{ transform: "translateY(2px)" }}
            />{" "}
            {`${showMap ? "Hide" : "View"} Map`}
          </button>
        </div> */}
      </div>
    );
  }

  function LabHours() {
    return (
      <div className="text-gray-600">
        <div className="mt-2 flex flex-col font-normal">
          {[
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ].map((day: string, idx: number) => {
            const bg = idx % 2 === 0 ? "bg-white" : "bg-gray-200";
            const all_hours = hours[day];
            return (
              <div
                className={`flex flex-row gap-6 ${bg} border border-gray-200`}
                key={idx}
              >
                <div className="font-semibold italic pl-1 grow">{day}</div>
                <div className="pr-1 text-right">
                  {all_hours ? (
                    all_hours.map((t: any, idx: number) => {
                      const { end, start } = t;
                      if (!start)
                        return <div key={"closed_" + idx}>Closed</div>;
                      return (
                        <Fragment key={idx}>
                          {start} - {end}
                          <br />
                        </Fragment>
                      );
                    })
                  ) : (
                    <div key={"closed_" + idx}>Closed</div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6 p-3">
      <div
        className="text-blue-600 font-bold cursor-pointer"
        onClick={() => handleOrderChange("selected_center", null)}
      >
        <IonIcon name="caret-back" className="translate-y-[2px]" /> Change Lab
      </div>
      <div className="flex flex-col md:flex-row gap-8">
        <LabAddress /> <LabHours />
      </div>
      <LabMap
        center={{
          lat: location.coordinates[1],
          lng: location.coordinates[0],
        }}
      />
    </div>
  );
}
