import IonIcon from "@reacticons/ionicons";
import { includes } from "lodash";
import { useDashboard } from "./DashboardContext";
import { useUI } from "../context/UIContext";

export default function AccountMenu() {
  const { setLocation } = useUI();
  const { main_view, view } = useDashboard();

  return (
    <div className="flex flex-row  border border-b-0 border-slate-50 overflow-hidden mx-auto items-end w-full max-w-screen-lg bg-white min-h-24 md:mb-6 shadow-lg md:rounded-tl-lg md:rounded-tr-lg">
      {[
        {
          id: "my-orders",
          alt_opt:
            main_view === "my-orders" && includes(["orders", "tests"], view),
          alt_route: `/dashboard/my-orders/${view}`,
          label: "My Orders",
          icon: (
            <IonIcon name="cart" style={{ transform: "translateY(3px)" }} />
          ),
        },
        {
          id: "messages",
          label: `Messages`, // (${new_messages || 0})`,
          icon: (
            <IonIcon
              name="chatbubble"
              style={{ transform: "translateY(3px)" }}
            />
          ),
        },
        {
          id: "settings",
          label: "Settings",
          icon: (
            <IonIcon name="settings" style={{ transform: "translateY(3px)" }} />
          ),
        },
      ].map((btn: any) => {
        const { alt_opt, alt_route, id, icon, label } = btn;
        const isActive = main_view === id;
        return (
          <div
            className={`basis-1/3 h-14 flex items-center justify-center border-b ${
              isActive &&
              "border-b-2 bg-rsc-light-blue/10 border-rsc-light-blue"
            }`}
            onClick={() => {
              if (alt_opt) {
                setLocation(alt_route);
              } else {
                setLocation(`/dashboard/${id}`);
              }
            }}
            style={{ cursor: "pointer" }}
            key={id}
          >
            <span
              className={`${
                isActive ? "text-rsc-light-blue font-semibold" : "text-gray-500"
              }`}
            >
              <span className="hidden md:inline mr-1">{icon}</span> {label}
            </span>
          </div>
        );
      })}
    </div>
  );
}
