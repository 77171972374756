import IonIcon from "@reacticons/ionicons";
import { kebabCase, orderBy, uniqBy } from "lodash";
import { useDashboard } from "../../../DashboardContext";
import { getRecordsCount } from "../../../../utils/functions";
import { useUI } from "../../../../context/UIContext";

export default function MyOrdersList() {
  const { setLocation } = useUI();
  const { all_tests, viewing_test } = useDashboard();
  const tests = orderBy(uniqBy(all_tests, "Sub_Name__c"), "Sub_Name__c");

  return (
    <>
      {(tests || []).map((test: any) => {
        const { Sub_Name__c } = test;
        const id = kebabCase(Sub_Name__c);
        const isActive = id === viewing_test;
        return (
          <div
            className={`flex flex-row w-full rounded-md overflow-hidden shadow-lg ${
              isActive && "border-2 border-sky-600"
            }`}
            key={Sub_Name__c}
            style={{ cursor: "pointer" }}
            onClick={() => setLocation(`/dashboard/my-orders/tests/${id}`)}
          >
            <div className={`flex flex-col bg-white grow`}>
              <div className="flex flex-col px-2 py-2 bg-sky-50 text-gray-500 shadow">
                <div className="font-semibold uppercase">{Sub_Name__c}</div>
                <div
                  className="font-light italic"
                  style={{ fontSize: ".64rem" }}
                >
                  {getRecordsCount(Sub_Name__c, all_tests || [])}
                </div>
              </div>
              {/* <OrderDetails opportunity={opportunity} order={orders?.[Id][0]} /> */}
            </div>
            {isActive && (
              <div
                className={`flex px-1 items-center justify-center bg-sky-600 text-white`}
              >
                <IonIcon name="caret-forward" />
              </div>
            )}
          </div>
        );
      })}
    </>
  );
}
