export default function PaymentOverride({ opportunity }: { opportunity: any }) {
  const { Amount, Balance_Amount__c, Total_Price_Override__c } = opportunity;
  const discount = Amount - Total_Price_Override__c;
  const is_discount = discount > 0;

  function processTableRows(data: any[]) {
    return (
      <>
        {data.map((row: any) => {
          const { label, amount } = row;
          return (
            <tr key={label}>
              <td>{label}</td>
              <td className="text-right">${(amount || 0).toFixed(2)}</td>
            </tr>
          );
        })}
      </>
    );
  }

  if (is_discount) {
    return processTableRows([
      {
        label: "Subtotal",
        amount: Amount,
      },
      {
        label: "Discounts and Savings",
        amount: discount,
      },
      {
        label: "Total",
        amount: Total_Price_Override__c,
      },
      {
        label: "Remaining Balance",
        amount: Balance_Amount__c,
      },
    ]);
  } else {
    return processTableRows([
      {
        label: "Subtotal",
        amount: Amount,
      },
      {
        label: "Taxes and Fees",
        amount: discount,
      },
      {
        label: "Total",
        amount: Total_Price_Override__c,
      },
      {
        label: "Credit Due",
        amount: Balance_Amount__c,
      },
    ]);
  }
}
