import MyOrders from "../Orders/my_orders";
import MyTests from "../Tests/my_tests";
import { useDashboard } from "../../DashboardContext";
import { useUI } from "../../../context/UIContext";
import { upperFirst } from "lodash";
import { PanelHolder } from "../../layout";
import { useEffect } from "react";
export default function MyOrdersPanel() {
  const { setLocation } = useUI();
  const { view } = useDashboard();

  useEffect(() => {
    !view && setLocation(`/dashboard/my-orders/orders`);
  }, [view, setLocation]);

  const panel_display: { [key: string]: any } = {
    orders: <MyOrders />,
    tests: <MyTests />,
  };

  return (
    <PanelHolder
      header_text=" View Results By"
      header_menu={
        <div className="flex flex-row justify-center gap-2 w-full">
          {["orders", "tests"].map((btn: string) => {
            const isActive = view === btn;
            return (
              <button
                key={btn}
                className={`px-10 py-2 w-36 shadow-md font-semibold ${
                  isActive
                    ? "bg-rsc-light-blue text-white"
                    : "bg-white text-rsc-light-blue"
                }
              ${
                btn === "orders"
                  ? "rounded-tl-md rounded-bl-md"
                  : "rounded-tr-md rounded-br-md"
              }
              `}
                onClick={() => setLocation(`/dashboard/my-orders/${btn}`)}
              >
                {upperFirst(btn)}
              </button>
            );
          })}
        </div>
      }
    >
      {panel_display[view]}
    </PanelHolder>
  );
}
