import Directions from "./directions";
import { split } from "lodash";
import LogIn from "./login";
import Register from "./register";
import CodeForm from "./verify";
import ForgotPassword from "./forgot";
import ResetPassword from "./reset";
import { useUI } from "../context/UIContext";
import ResendCodeForm from "./resend";

export default function UserLogin() {
  const { location } = useUI();
  const view = split(location, "/")[1];

  const login_views: { [key: string]: any } = {
    "forgot-password": <ForgotPassword />,
    login: <LogIn />,
    register: <Register />,
    resend: <ResendCodeForm />,
    "reset-password": <ResetPassword />,
    verify: <CodeForm />,
  };

  return (
    <>
      <div className="bg-white my-6 mx-auto w-full md:min-w-[440px] sm:max-w-md md:border sm:border-rsc-light-blue/10 sm:rounded-xl shadow-lg overflow-hidden">
        {login_views[view || "login"]}
      </div>
      <Directions />
    </>
  );
}
