export default function SkeletonList() {
  return (
    <>
      {[1, 2].map((idx: number) => {
        return (
          <div
            className={`flex flex-row animate-pulse bg-gray-50 w-full h-40 rounded-md overflow-hidden`}
            key={idx}
          />
        );
      })}
    </>
  );
}
