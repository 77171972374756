import { createContext, useContext, useState } from "react";
import { CognitoClient } from "../utils/aws";
import { useUser } from "../context/UserContext";
import {
  AdminDeleteUserCommand,
  AdminDeleteUserCommandInput,
  AdminGetUserCommand,
  AdminGetUserCommandInput,
  AdminUpdateUserAttributesCommand,
  AdminUpdateUserAttributesCommandInput,
} from "@aws-sdk/client-cognito-identity-provider";
import { keys, map } from "lodash";
import { makeUser } from "../utils/functions";
const attributes = {
  account: ["email_verified", "phone_number_verified", "roles"],
  information: [
    "email",
    "family_name",
    "gender",
    "given_name",
    "middle_name",
    "phone_number",
  ],
};
interface AccountValues {
  attributes: any;
  deleted: boolean;
  editing: boolean;
  loading: boolean;
  user: any | null;
  username: string;
  view: string;
  createUser: (new_user: any) => void;
  deleteUser: (username: string) => void;
  setEditing: (editing: boolean) => void;

  updateUserAttributes: (username: string, update_user: any) => void;
}

export const UserEditContext = createContext<AccountValues>({
  attributes,
  deleted: false,
  editing: false,
  loading: false,
  user: null,
  username: "",
  view: "profile",
  createUser: () => {},
  deleteUser: () => {},
  setEditing: () => {},

  updateUserAttributes: () => {},
});

export const useUserEdit = () => useContext(UserEditContext);

const UserEditContextProvider: React.FC<{
  children: any;
  username: string;
  view?: string;
}> = ({ children, username, view }) => {
  const { UserPoolId } = useUser();
  const [editing, setEditing] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any | null>(null);
  const [deleted, setDeleted] = useState<boolean>(false);
  const [user_error, setError] = useState<any | null>(null);

  async function createUser(new_user: any) {}

  async function deleteUser(username: string) {
    try {
      setLoading(true);
      const input: AdminDeleteUserCommandInput = {
        UserPoolId,
        Username: username,
      };
      const command = new AdminDeleteUserCommand(input);
      const deleted_user = await CognitoClient.send(command);
      setDeleted(true);
      console.log("DELETE USER", deleted_user);
    } catch (error) {
      console.log("FAILED TO DELETE USER", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function updateUserAttributes(username: string, update_user: any) {
    const UserAttributes = map(keys(update_user), function (Name: string) {
      const Value = update_user[Name];
      if (Name === "roles") {
        return { Name: "custom:roles", Value };
      }
      return { Name, Value };
    });
    console.log("User Attributes", UserAttributes);
    try {
      setLoading(true);
      const input: AdminUpdateUserAttributesCommandInput = {
        UserPoolId,
        UserAttributes,
        Username: username,
      };
      const command = new AdminUpdateUserAttributesCommand(input);
      const updated_user = await CognitoClient.send(command);
      console.log("UPDATED USER", updated_user);
    } catch (error) {
      console.log("FAILED TO GET USERS", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  async function getUser(username: string) {
    setLoading(true);
    try {
      const input: AdminGetUserCommandInput = {
        UserPoolId,
        Username: username,
      };
      const command = new AdminGetUserCommand(input);
      const response = await CognitoClient.send(command);
      const user = makeUser(response);
      setUser(user);
    } catch (error: any) {
      console.log("FAILED TO GET USERS", error);
      setError(error);
    }
    setLoading(false);
  }

  !!username && !user_error && !loading && !user && getUser(username);

  return (
    <UserEditContext.Provider
      value={{
        attributes,
        createUser,
        deleted,
        editing,
        loading,
        username,
        user,
        view: view || "profile",
        deleteUser,
        setEditing,

        updateUserAttributes,
      }}
    >
      {children}
    </UserEditContext.Provider>
  );
};

export default UserEditContextProvider;
