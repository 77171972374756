const user = {
  address: "647 Spice Trader Way",
  address_state: "FL",
  allergies: "",
  city: "Orlando",
  dob: "2023-03-26T04:00:00.000Z",
  email: "samuel.s.maxime@gmail.com",
  email_message: true,
  family_name: "Portal",
  given_name: "LabTesting",
  no_allergies: false,
  no_medications: false,
  notes: "",
  phone_message: false,
  phone_number: "4079691276",
  medications: "",
  race_ethnicity: "",
  sex: "Male",
  surgeries: "",
  symptoms: "",
  test_date: "2023-04-24T14:39:35.217Z",
  treating: [],
  zip_code: "32818",
  partners: [],
};
export default user;
