import { isEqual, pick } from "lodash";
import ContentHolder from "./content_holder";
import { useUserEdit } from "./UserEditContext";
import RSCLoader from "../components/loader_circle";
import "./form.css";
import { useState } from "react";

export default function AccountView() {
  const { attributes, loading, user, setEditing, updateUserAttributes } =
    useUserEdit();
  const [user_update, setUserUpdate] = useState<any>(user);

  function updateUser(field: string, value: any) {
    setUserUpdate({ ...user_update, [field]: value });
  }
  const old_user = {
    Enabled: user?.Enabled,
    roles: user?.roles,
    UserStatus: user?.UserStatus,
  };

  const new_user = {
    Enabled: user_update?.Enabled,
    roles: user_update?.roles,
    UserStatus: user_update?.UserStatus,
  };

  user?.sub && user.sub !== user_update?.sub && setUserUpdate(user);

  const no_changes = isEqual(old_user, new_user);

  return (
    <ContentHolder
      back={{ onClick: () => setEditing(false), label: "Cancel" }}
      forward={{
        disabled: no_changes,
        onClick: () =>
          updateUserAttributes(
            user?.username,
            pick(user_update, attributes.account)
          ),
        label: "Save Changes",
      }}
    >
      <div className="flex flex-col text-lg gap-4">
        <div>
          <label htmlFor="role">Role:</label>
          <select
            id="role"
            className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
            value={user_update?.roles || "User"}
            onChange={(e: any) => updateUser("roles", e.target.value)}
          >
            <option>User</option>
            <option>Agent</option>{" "}
            {user?.roles === "Super Administrator" && (
              <option>Administrator</option>
            )}
            {user?.roles === "Super Administrator" && (
              <option>Super Administrator</option>
            )}
          </select>
        </div>
        <div className="flex flex-row justify-end">
          <button
            className={`${
              no_changes || loading
                ? "bg-gray-200 text-gray-500"
                : "bg-rsc-light-blue text-white"
            } md:min-w-[100px] px-6 font-semibold text-center p-3 rounded-md shadow-md`}
            disabled={no_changes || loading}
            onClick={() =>
              updateUserAttributes(
                user?.Username,
                pick(user_update, attributes.account)
              )
            }
          >
            {loading ? (
              <div className="flex flex-row gap-2 items-center">
                <RSCLoader /> Saving...
              </div>
            ) : (
              "Save Changes"
            )}
          </button>
        </div>
      </div>
    </ContentHolder>
  );
}
