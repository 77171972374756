import { useState } from "react";
import {
  ForgotPasswordCommand,
  ForgotPasswordCommandInput,
} from "@aws-sdk/client-cognito-identity-provider";
import { CognitoClient } from "../../../utils/aws";
import { validEmail, validPhone } from "../../../utils/functions";
import { useUser } from "../../../context/UserContext";
import NewPassword from "./change_password";
import SendCode from "./send_code";
const { REACT_APP_AWS_COGNITO_CLIENT_ID: ClientId } = process.env;
export default function ChangePassword() {
  const { user, clearMessages, setErrorMessage, setMessage } = useUser();

  const [code_sent, setCodeSent] = useState<boolean>(false);
  const username = user?.email || user?.phone;
  function phoneOrEmail(username: string) {
    const is_email = validEmail(username) && "email";
    const is_phone = validPhone(username) && "phone";
    return is_email || is_phone;
  }
  const is_ready_to_send = phoneOrEmail(username);
  async function sendVerificationCode() {
    if (is_ready_to_send) {
      try {
        const input: ForgotPasswordCommandInput = {
          ClientId,
          Username: username,
        };

        const command = new ForgotPasswordCommand(input);
        const response = await CognitoClient.send(command);
        setMessage(
          <>
            A reset code was sent to {username} if it exists.{" "}
            <button
              className="font-semibold underline"
              onClick={() => setCodeSent(true)}
            >
              Click to verify code
            </button>
            .
          </>
        );
        setCodeSent(true);
        clearMessages();
        console.log("VERIFIED CODE RESPONSE", response);
      } catch (error: any) {
        console.log("ERROR VERIFYING CODE", error.name, "MSG", error.message);
        setErrorMessage(error.message || "Code Verification Error");
      }
    } else {
      setErrorMessage("You did not enter a valid Email or Phone Number");
    }
  }

  return (
    <div className="flex flex-col gap-6 w-full md:w-1/2">
      {code_sent ? (
        <NewPassword username={username} setCodeSent={setCodeSent} />
      ) : (
        <SendCode
          code_sent={code_sent}
          username={username}
          sendVerificationCode={sendVerificationCode}
          setCodeSent={setCodeSent}
        />
      )}
    </div>
  );
}
