import IonIcon from "@reacticons/ionicons";
import FindLab from "./FindLab";
//import RSTLogo from "./images/rst-logo.png";
import MenuItem from "./menu_item";
import menu_items from "./menu_settings";
import { useUI } from "../../context/UIContext";
import { useAccount } from "../../context/AccountContext";
import { useUser } from "../../context/UserContext";
import ShoppingCartButton from "../shopping_cart";
export default function BottomHeader() {
  const { setDrawerOpen, setLocation } = useUI();
  const { is_admin } = useUser();
  const { account } = useAccount();

  return (
    <div className="flex flex-row text-sm text-rsc-blue uppercase">
      <div className="py-4 md:py-3 mx-auto w-full max-w-7xl">
        <div className="flex flex-row px-5 gap-5 w-full items-center">
          <div className="grow">
            <button
              className="leading-none"
              onClick={() => {
                window.location.href = "https://rapidstdtesting.com";
              }}
            >
              <img
                src="https://res.cloudinary.com/maxime-associates/image/upload/v1665381954/RapidSTDTesting/rst_logo_med.png"
                alt="Rapid STD Testing logo"
                className="max-h-8 md:max-h-10"
              />
            </button>
          </div>
          <div className="flex flex-row md:hidden flex-none items-center gap-4">
            <ShoppingCartButton />
            {(account?.Id || is_admin) && (
              <div className="text-rsc-light-blue">
                <button
                  onClick={() => setLocation("/order")}
                  className="leading-none"
                >
                  <IonIcon
                    className="translate-y-[2px] text-[20px]"
                    name="person-circle"
                  />
                </button>
              </div>
            )}
            <div>
              <button
                className="leading-none"
                onClick={() => setDrawerOpen(true)}
              >
                <IonIcon
                  className="translate-y-[2px]"
                  name="menu"
                  size="large"
                />
              </button>
            </div>
          </div>
          <div className="flex flex-row grow gap-4 hidden md:contents">
            <FindLab />
            {menu_items.map((item: any) => {
              const { link, url } = item.primary;
              return <MenuItem item={item} key={link || url} />;
            })}
            <div>
              <button
                onClick={() => {
                  window.location.href =
                    "https://rapidstdtesting.com/test-and-panels";
                }}
                className="px-8 py-1 bg-rsc-red rounded-full text-white md:text-xs lg:text-sm font-bold uppercase"
              >
                Get Tested
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
