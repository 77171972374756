import { useState } from "react";
import FindALabInput from "./lab_input";

export default function FindALab() {
  const [showLabInput, setShowLabInput] = useState<boolean>(false);
  return (
    <div
      onMouseEnter={() => setShowLabInput(true)}
      onMouseLeave={() => setShowLabInput(false)}
      className="hover:text-amber-500"
    >
      <p className="font-medium md:text-[0.875rem] lg:text-[1rem] uppercase">
        Find a Lab
      </p>
      {showLabInput && <FindALabInput />}
    </div>
  );
}
