import IonIcon from "@reacticons/ionicons";
import { useState } from "react";
import { useUser } from "../../../context/UserContext";
import { useUI } from "../../../context/UIContext";
import AdminActions from "./admin_actions";
import AdminUser from "./admin_user";
export default function AdminHeaderTop() {
  const { is_mobile } = useUI();
  const { user, simulated_role } = useUser();
  const { roles } = user;

  const [show_menu, setShowMenu] = useState<boolean>(false);

  const toggleShowMenu = () => {
    const flip = !show_menu;
    setShowMenu(flip);
  };

  const header_title = `${simulated_role || roles} Dashboard`;

  return (
    <div
      className={`flex flex-col md:flex-row md:items-center gap-6 text-white`}
    >
      {is_mobile ? (
        <div className="flex flex-row text-xl md:text-2xl">
          <div className="font-bold normal-case grow">{header_title}</div>
          <button onClick={toggleShowMenu}>
            <IonIcon
              name={show_menu ? "caret-up" : "caret-down"}
              style={{ transform: "translateY(4px)" }}
            />
          </button>
        </div>
      ) : (
        <div className="text-xl md:text-2xl font-bold normal-case">
          {header_title}
        </div>
      )}
      <AdminActions show_menu={show_menu} /> {!is_mobile && <AdminUser />}
    </div>
  );
}
