import { useState } from "react";
import ListHeader from "./list_header";
import ListBody from "./list_body";
import { useUsers } from "../UsersContext";
import { isEmpty, slice } from "lodash";

export default function UsersResults() {
  const { view_users } = useUsers();
  const [items_per_page, setItemsPerPage] = useState<number>(25);
  const [current_page, setPage] = useState<number>(1);
  const start = (current_page - 1) * items_per_page;
  const end = current_page * items_per_page;
  const users = slice(view_users, start, end);
  const number_of_pages = view_users.length / items_per_page;
  const pages = Array.from({ length: number_of_pages }, (_, i) => i + 1);
  return (
    <>
      <table className="w-full">
        <ListHeader />
        <ListBody users={users} />
      </table>
      {!isEmpty(view_users) && (
        <div className="flex flex-row items-center">
          {view_users.length > items_per_page && (
            <div className="grow">
              <select
                className="rounded-lg border  border-slate-400 h-10 w-[150px] shadow-inner px-2"
                value={items_per_page}
                onChange={(e: any) => {
                  setItemsPerPage(e.target.value);
                }}
              >
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={view_users.length}>All</option>
              </select>
            </div>
          )}
          <div className="flex flex-row gap-4 items-center justify-center p-8">
            {pages.map((page: number) => {
              const isActive = page === current_page;
              return (
                <div
                  key={page}
                  className={`flex flex-col items-center justify-center h-8 w-8 border border-rsc-light-blue ${
                    isActive
                      ? "bg-rsc-light-blue text-white shadow-md"
                      : "text-rsc-light-blue"
                  } cursor-pointer rounded-md hover:bg-rsc-light-blue hover:text-white hover:shadow-md`}
                  onClick={() => setPage(page)}
                >
                  <div>{page}</div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
