import IonIcon from "@reacticons/ionicons";
import PaymentIncomplete from "../components/pay_incomplete";
import { useUI } from "../../../../../../../context/UIContext";
import { useCart } from "../../../../../../../context/CartContext";

export default function ActionsLabResults({ data }: { data: any }) {
  const { setLocation } = useUI();
  const { setReorderDiscount } = useCart();
  const { opportunity, order } = data;

  function PartialResults() {
    if (order?.Status !== "Partial Results Received") return null;
    return (
      <div className="flex flex-row justify-end text-right">
        <div className="w-64 p-3 border border-yellow-500 rounded-md shadow-md">
          <div className="flex flex-row justify-center items-center text-yellow-500 text-xs text-left">
            <div className="grow text-2xl" style={{ lineHeight: 0 }}>
              <IonIcon name="alert-circle" />
            </div>
            <div className="font-semibold uppercase">
              Partial Results Available
            </div>
          </div>{" "}
          <p className="py-2 text-sm text-gray-700">
            Part of your order's results are available. The rest of your order's
            test results will be available soon.
          </p>
        </div>
      </div>
    );
  }

  function PaidInFull() {
    const pay_status = opportunity?.Payment_Status__c;
    if (pay_status === "Paid in Full") {
      return (
        <div className="flex flex-col gap-2">
          <div className="text-right">
            <button
              className="w-64 py-2 bg-amber-500 text-white rounded-md hover:shadow-md"
              onClick={() => {
                setReorderDiscount(true);
                setLocation("/test-and-panels");
              }}
            >
              <IonIcon
                name="cart"
                style={{ marginRight: 12, transform: "translateY(2px)" }}
              />{" "}
              Reorder with 10% off
            </button>
          </div>
          <div className="flex flex-row justify-end text-right">
            <div className="w-64 p-3 border border-green-600 rounded-md shadow-md">
              <div className="flex flex-row items-center text-green-600 text-xs text-left">
                <div className="grow text-base">
                  <IonIcon name="checkmark-done-circle" />
                </div>
                <div className="font-semibold uppercase">{pay_status}</div>
              </div>
              <button
                className="text-right text-sm font-semibold text-blue-600"
                onClick={() =>
                  setLocation(
                    `/dashboard/my-orders/orders/${opportunity?.Id}/invoice`
                  )
                }
              >
                View Invoice
              </button>
            </div>
          </div>
        </div>
      );
    }
    return <PaymentIncomplete />;
  }

  return (
    <div className="flex flex-col gap-4">
      <PaidInFull />
      <PartialResults />
    </div>
  );
}
