import { useState } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import OrderCard from "../order_card";
import { useConsultation } from "../../context/ConsultationContext";
import Section from "../form_section";
import { usePartnerConsultation } from "../../context/PartnerConsultationContext";
import "react-datepicker/dist/react-datepicker.css";
import TreatingMedicalHistory from "./treating";
import AllergiesMedications from "./allergies_medications";
export default function MedicalHistory() {
  const {
    handlePatientChange,
    partner: isPartner,
    patient,
  } = useConsultation();
  const { handlePartnerChange, partner } = usePartnerConsultation();

  const handlePersonChange = isPartner
    ? handlePartnerChange
    : handlePatientChange;
  const person = isPartner ? partner : patient;

  const {
    notes: notes_,
    race_ethnicity,
    symptoms: symptoms_,
    test_date,
    treating,
  } = person;

  const [symptoms, setSymptoms] = useState<string>(symptoms_);
  const [notes, setNotes] = useState<string>(notes_);

  const title = (
    <>
      Medical History{" "}
      {isPartner && (
        <sup className="align-middle text-amber-400 text-sm uppercase">
          Partner
        </sup>
      )}
    </>
  );

  const testing_date = test_date
    ? moment(test_date, "YYYY-MM-DD").toDate()
    : null;

  return (
    <OrderCard title={title}>
      <div className="p-3 bg-slate-50">
        <Section>
          <div className="">
            <label htmlFor="race">Race/Ethnicity:</label>
            <select
              className="rounded-lg border  border-rsc-light-blue/20 h-10 w-full shadow-inner px-2"
              id="race_ethnicity"
              value={race_ethnicity}
              onChange={(e: any) =>
                handlePersonChange("race_ethnicity", e.target.value)
              }
            >
              <option>Select</option>
              {[
                "American Indian or Alaska Native",
                "Asian",
                "Black or African American",
                "Hispanic or Latino",
                "Native Hawaiian or Other Pacific Islander",
                "White",
              ].map((race: string) => {
                return (
                  <option key={race} value={race}>
                    {race}
                  </option>
                );
              })}
            </select>
          </div>
        </Section>
        <AllergiesMedications
          person={person}
          handlePersonChange={handlePersonChange}
        />
        <Section>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col md:flex-row gap-4">
              <TreatingMedicalHistory
                treating={treating}
                handlePersonChange={handlePersonChange}
              />
              <div className="basis-1/3">
                <label htmlFor="test_date">
                  Test Date:{" "}
                  <span className="text-sm text-gray-400 italic">
                    (MM/DD/YYYY)
                  </span>
                </label>
                <div>
                  <DatePicker
                    customInput={
                      <input
                        className="bg-white rounded-lg border border-rsc-light-blue/30 h-10 w-full shadow-inner px-2"
                        id="data-picker"
                        placeholder="MM/DD/YYYY"
                      />
                    }
                    className="w-full"
                    selected={testing_date}
                    showYearDropdown
                    onChange={(e: any) => {
                      const test_date = moment(e).format("YYYY-MM-DD");
                      handlePersonChange("test_date", test_date);
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="symptoms"
                className="text-rsc-blue text-xl font-bold"
              >
                Describe any symptoms you are currently experiencing:
              </label>
              <textarea
                className="rounded-lg border border-rsc-light-blue/20 w-full shadow-inner px-2"
                id="symptoms"
                placeholder="List symptoms..."
                rows={4}
                value={symptoms}
                onBlur={() => handlePersonChange("symptoms", symptoms)}
                onChange={(e: any) => setSymptoms(e.target.value)}
              />
            </div>
            <div className="">
              <label htmlFor="notes">
                List any additional information the doctor may need to know:
              </label>
              <textarea
                className="rounded-lg border border-rsc-light-blue/20 w-full shadow-inner px-2"
                id="misc_information"
                placeholder="Additional information..."
                rows={4}
                value={notes}
                onBlur={() => handlePersonChange("notes", notes)}
                onChange={(e: any) => setNotes(e.target.value)}
              />
            </div>
          </div>
        </Section>
      </div>
    </OrderCard>
  );
}

// <>
//   <div className="">
//     <label htmlFor="surgeries">Previous Surgeries:</label>
//     <textarea
//       className="rounded-lg border border-rsc-light-blue/20 w-full shadow-inner px-2"
//       id="surgeries"
//       placeholder="List previous surgeries"
//       rows={3}
//       value={surgeries}
//       onChange={(e: any) =>
//         handlePersonChange("surgeries", e.target.value)
//       }
//     />
//   </div>
//   <div className="">
//     <label htmlFor="conditions">Medical Conditions:</label>
//     <textarea
//       className="rounded-lg border border-rsc-light-blue/20 w-full shadow-inner px-2"
//       id="conditions"
//       placeholder="List medical conditions"
//       rows={3}
//       value={conditions}
//       onChange={(e: any) =>
//         handlePersonChange("conditions", e.target.value)
//       }
//     />
//   </div>
// </>

//     <Section>
//   <div className="grid md:grid-cols-2 gap-3 ">
//     <div className="">
//       <label htmlFor="race">Race:</label>
//       <select
//         className="rounded-lg border  border-rsc-light-blue/20 h-10 w-full shadow-inner px-2"
//         id="race"
//         value={race}
//         onChange={(e: any) =>
//           handlePersonChange("race", e.target.value)
//         }
//       >
//         <option>Select</option>
//         <option value="asian">Asian</option>
//         <option value="black">Black (not Hispanic)</option>
//         <option value="hispanic">Hispanic</option>
//         <option value="white">White (not Hispanic)</option>
//       </select>
//     </div>
//     <div className="">
//       <label htmlFor="ethnicity">Ethnicity:</label>
//       <select
//         className="rounded-lg border  border-rsc-light-blue/20 h-10 w-full shadow-inner px-2"
//         id="ethnicity"
//         value={ethnicity}
//         onChange={(e: any) =>
//           handlePersonChange("ethnicity", e.target.value)
//         }
//       >
//         <option>Select</option>
//         <option value="asian">Asian</option>
//         <option value="black">Black (not Hispanic)</option>
//         <option value="hispanic">Hispanic</option>
//         <option value="white">White (not Hispanic)</option>
//       </select>
//     </div>
//     <div className="md:col-span-2">
//       <label htmlFor="partners">I have sex with ...</label>
//       <select
//         className="rounded-lg border border-rsc-light-blue/20 h-10 w-full shadow-inner px-2"
//         id="partners"
//         value={partners}
//         //   onChange={(e: any) => setPartners(e.target.value)}
//       >
//         <option>Select</option>
//         <option value="men">Men</option>
//         <option value="women">Women</option>
//         <option value="both">Both</option>
//       </select>
//     </div>{" "}
//   </div>
// </Section>
