import { useCallback, useState } from "react";
import DetailContainer from "../../../../DetailContainer";
import Section from "../../../../../../components/Section";
import { useDashboard } from "../../../../../DashboardContext";
import CCPaymentInfo from "../../../../../../components/Payment/cc_payment";
import PayPalPayment from "../../../../../../components/Payment/paypal_payment";
import { usePayment } from "../../../../../../components/Payment/PaymentInfoContext";
import Acknowledgement from "../../../../../../components/acknowledgement";
import { validate_payment } from "../../../../../../utils/functions";
import FinalizePayment from "./finalize";
import { useAccount } from "../../../../../../context/AccountContext";

export default function PayLaterPaymentForm() {
  const { opportunity } = useDashboard();
  const { account } = useAccount();
  const { method, processConfirmation, setLoading, setMethod } = usePayment();
  const [view_errors, setViewErrors] = useState<boolean>(false);
  const [acknowledged, setAcknowledged] = useState<boolean>(false);

  const { Balance_Amount__c: amount } = opportunity;
  const forceReRender = [account, amount, opportunity];
  const createOrder = useCallback(
    async (data: any, action: any) => {
      return action.braintree
        .createPayment({
          flow: "checkout",
          amount,
          currency: "USD",
          intent: "capture",
        })
        .then((orderId: any) => {
          // Your code here after create the order
          return orderId;
        });
    },
    [amount]
  );

  const onApprove = useCallback(
    async (data: any, actions: any) => {
      const capture_data = await actions.order.capture();
      const tx = { ...capture_data, type: "paypal" };
      const mx = { account, opportunity, type: "payment" };
      await processConfirmation({
        mx,
        oid: opportunity.Id,
        totals: { total_due: amount },
        type: "payment",
        tx,
      });
    },
    [account, amount, opportunity, processConfirmation]
  );

  const onPayPalClick = useCallback(
    async (data: any, actions: any) => {
      try {
        const element = document.getElementById("preloader");
        setLoading(true);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      } catch (err) {
        console.log("ERROR IN THE FORM");
      } finally {
        setLoading(false);
      }
    },
    [setLoading]
  );

  const payment_validation = validate_payment.safeParse({ acknowledged });
  const errors = (payment_validation as any)?.error?.issues.map(
    (issue: any) => issue.message
  );
  return (
    <DetailContainer title="Make a Payment">
      <Acknowledgement
        checked={acknowledged}
        onChange={(e: any) => {
          setAcknowledged(e.target.checked);
        }}
      />
      <div className="m-2 rounded-md overflow-hidden border border-slate-300">
        <div className="p-4 bg-slate-50">
          <Section>
            <div className="gap-3">
              <label htmlFor="first_name">Choose Payment Method:</label>
              <select
                name="notification"
                id="notification"
                className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
                value={method}
                onChange={(e: any) => setMethod(e.target.value)}
              >
                {[
                  { id: "credit_card", label: "Credit Card" },
                  { id: "pay_pal", label: "PayPal" },
                ].map((type: any) => {
                  const { id, label } = type;
                  return (
                    <option key={id} value={id}>
                      {label}
                    </option>
                  );
                })}
              </select>
            </div>
          </Section>
          {method === "credit_card" ? (
            <CCPaymentInfo
              submit={
                <FinalizePayment
                  errors={errors}
                  view_errors={view_errors}
                  setViewErrors={setViewErrors}
                />
              }
            />
          ) : (
            <PayPalPayment
              errors={errors}
              view_errors={view_errors}
              createOrder={createOrder}
              forceReRender={forceReRender}
              onApprove={onApprove}
              onPayPalClick={onPayPalClick}
            />
          )}
        </div>
      </div>
    </DetailContainer>
  );
}
