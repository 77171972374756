import axios from "axios";
import PatientLoginButton from "../PatientLogIn";
import { useState } from "react";
import VerificationFail from "./fail";
import { useUI } from "../../context/UIContext";

type QueryParams = {
  [key: string]: string;
};

function getQueryParams(query: string): QueryParams {
  let params: QueryParams = {};
  new URLSearchParams(query).forEach((value, key) => {
    params[key] = value;
  });
  return params;
}

export default function UserVerification() {
  const { setLocation } = useUI();
  const [result, setResult] = useState<any | null>(null);
  const [verifying, setVerifying] = useState<boolean>(false);

  const query_string = window.location.search.substring(1); // remove the leading '?'

  const { token } = getQueryParams(query_string);
  async function confirmUser() {
    let data;
    try {
      setVerifying(true);
      await axios
        .post("/api/user/verification", {
          token,
        })
        .then((result: any) => {
          console.log("THE RESULT", result);
          data = result.data;
        });
    } catch (err) {
      console.log("THERE WAS AN ERROR VERIFYING", err);
      data = err;
    } finally {
      setResult({ id: token, data });
      setVerifying(false);
    }
  }

  !!token && token !== result?.id && !verifying && confirmUser();

  if (!result) return <>Loading...</>;
  const { message, response } = result.data;
  if (result.data.success) {
    return (
      <div className="flex flex-col gap-2 items-center justify-center h-full">
        <div className="text-4xl font-bold text-rsc-light-blue">{message}</div>
        <div>{response}</div>
        <PatientLoginButton
          callback={() => setLocation("/dashboard")}
          text="Log In"
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2 items-center justify-center h-full">
      <VerificationFail data={result.data} />
    </div>
  );
}
