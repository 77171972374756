import { createContext, useContext, useState } from "react";
import { useLocation } from "wouter";
import { useWindowWidth } from "@react-hook/window-size";
const {
  REACT_APP_ENV,
  //REACT_APP_IS_TESTING_CONFIRMATION,
  // REACT_APP_IS_TESTING_ORDER,
} = process.env;
const is_development = REACT_APP_ENV === "development";
const is_testing_order = false; // is_development;
const is_testing_consultation = false; // is_development;
const is_testing_confirmation = false;
const mute_emails = false; // is_development;
const mute_payment = false;
const us_states: { [key: string]: string } = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
  AS: "American Samoa",
  DC: "District of Columbia",
  FM: "Federated States of Micronesia",
  GU: "Guam",
  MH: "Marshall Islands",
  MP: "Northern Mariana Islands",
  PW: "Palau",
  PR: "Puerto Rico",
  VI: "Virgin Islands",
};

const menu_buttons = [
  {
    label: "Tests and Panels",
    link: "/test-and-panels",
  },
  {
    label: "Find A Test Center",
    link: "/find-a-lab",
  },
  {
    label: "Order Testing",
    link: "/order",
  },
  {
    label: "Order Consultation",
    link: "/consultation",
  },
  {
    label: "DNA Testing",
    route: "https://dna-testing.rschealth.com",
  },
];

interface UIValues {
  client: any;
  is_development: boolean;
  is_mobile: boolean;
  is_testing_order: boolean;
  is_testing_confirmation: boolean;
  is_testing_consultation: boolean;
  isTablet: boolean;
  location: string;
  menu_buttons: any[];
  mute_emails: boolean;
  mute_payment: boolean;
  navigateTo: (link: string) => void;
  open: boolean;
  popUp: any | null;
  us_states: any;
  setDrawerOpen: (setting: boolean) => void;
  setLocation: (loc: string) => void;
  setPopUp: (element: any) => void;
  // signInCheck: () => any | false;
}

export const UIContext = createContext<UIValues>({
  client: {},
  is_development: true,
  is_mobile: true,
  is_testing_order,
  is_testing_confirmation,
  is_testing_consultation,
  isTablet: false,
  location: "",
  menu_buttons,
  mute_emails,
  mute_payment,
  navigateTo: () => {},
  open: false,
  popUp: <></>,
  us_states,
  setDrawerOpen: () => {},
  setLocation: () => {},
  setPopUp: () => {},
});

export const useUI = () => useContext(UIContext);

const UIContextProvider: React.FC<{
  children: any;
  client: any;
}> = ({ children, client }) => {
  const onlyWidth = useWindowWidth();
  const is_mobile = onlyWidth < 641;
  const isTablet = !is_mobile && onlyWidth < 1025;
  const [location, setLocation] = useLocation();
  const [popUp, setPopUp] = useState<any | null>(null);
  const [open, setDrawerOpen] = useState<boolean>(false);
  function navigateTo(link: string) {
    window.location.href = link;
    open && setDrawerOpen(false);
  }
  return (
    <UIContext.Provider
      value={{
        client,
        is_development,
        is_mobile,
        is_testing_order,
        is_testing_confirmation,
        is_testing_consultation,
        isTablet,
        location,
        menu_buttons,
        mute_emails,
        mute_payment,
        navigateTo,
        open,
        us_states,
        popUp,
        setDrawerOpen,
        setLocation,
        setPopUp,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export default UIContextProvider;
