import {
  compact,
  filter,
  flattenDeep,
  head,
  includes,
  isEmpty,
  map,
  orderBy,
} from "lodash";
import Package from "./Upsells";
import ShoppingCart from "./Cart";
import { useCart } from "../../context/CartContext";
import { useUI } from "../../context/UIContext";

export default function OrderSidePanel() {
  const { isTablet } = useUI();
  const { package_products, shopping_cart } = useCart();
  const skus = map(shopping_cart, "sku");

  const packages_in_cart = filter(shopping_cart, function (item: any) {
    return includes(map(package_products, "sku"), item.sku);
  });

  const no_packages = isEmpty(packages_in_cart);

  const single_h =
    (includes(skus, "3636") || includes(skus, "3640")) &&
    !includes(skus, "6447");

  const empty_cart = isEmpty(skus);
  const no_trichomoniasis =
    !includes(skus, "P-22") &&
    !includes(skus, "P-24") &&
    !includes(skus, "P-23");
  const single_trichomoniasis =
    includes(skus, "P-24") && !includes(skus, "P-22");

  function upSellPackages() {
    const most_expensive_package = head(
      orderBy(packages_in_cart, "price")
    )?.price;
    const upgrades = filter(package_products, function (p: any) {
      return most_expensive_package < p.price;
    });
    return map(upgrades, function (p: any) {
      return <Package sku={p.sku} />;
    });
  }

  const upsell_list = [
    upSellPackages(),
    no_packages && single_h && <Package sku="6447" />,
    !empty_cart &&
      no_trichomoniasis && [<Package sku="P-22" />, <Package sku="P-24" />],
    single_trichomoniasis && <Package sku="P-22" />,
  ];

  if (isTablet) {
    return (
      <div className="grid grid-cols-2 gap-8" style={{ pointerEvents: "auto" }}>
        <div className="flex flex-col gap-4">
          <ShoppingCart />
        </div>
        {!isEmpty(skus) &&
          flattenDeep(compact(upsell_list)).map((card: any, idx: number) => {
            return <div key={idx}>{card}</div>;
          })}
      </div>
    );
  }

  return (
    <div
      className="flex flex-col items-center gap-6"
      style={{ pointerEvents: "auto" }}
    >
      <ShoppingCart />
      {!isEmpty(skus) &&
        flattenDeep(compact(upsell_list)).map((card: any, idx: number) => {
          return (
            <div key={idx} className="w-full">
              {card}
            </div>
          );
        })}
    </div>
  );
}
