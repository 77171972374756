import OrderCard from "../order_card";
import { useConsultation } from "../../context/ConsultationContext";
import { usePartnerConsultation } from "../../context/PartnerConsultationContext";
import Section from "../form_section";
const states = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
export default function ContactInformation() {
  const {
    handlePatientChange,
    partner: isPartner,
    patient,
  } = useConsultation();
  const { handlePartnerChange, partner } = usePartnerConsultation();

  const handlePersonChange = isPartner
    ? handlePartnerChange
    : handlePatientChange;
  const person = isPartner ? partner : patient;
  const {
    address,
    address_state,
    city,
    email,
    email_message,
    phone_message,
    phone_number,
    zip_code,
  } = person;

  const title = (
    <>
      Contact Information{" "}
      {isPartner && (
        <sup className="align-middle text-amber-400 text-sm uppercase">
          Partner
        </sup>
      )}
    </>
  );

  return (
    <OrderCard title={title} card_layout={"z-0"}>
      <div className="p-3 pb-0 flex flex-col bg-slate-50">
        <Section>
          <div className="grid md:grid-cols-4 gap-4">
            <div className="md:col-span-4">
              <label>Address:</label>
              <input
                className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
                id="address"
                placeholder="Enter your address"
                value={address}
                onChange={(e: any) =>
                  handlePersonChange("address", e.target.value)
                }
              />
            </div>
            <div className="md:col-span-2">
              <label>City:</label>
              <input
                className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
                id="city"
                placeholder="Enter your city"
                value={city}
                onChange={(e: any) =>
                  handlePersonChange("city", e.target.value)
                }
              />
            </div>
            <div className="md:col-span-1">
              <label>State:</label>
              <select
                id="state"
                className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
                onChange={(e: any) =>
                  handlePersonChange("address_state", e.target.value)
                }
                value={address_state}
              >
                <option>Select</option>
                {states.map((state: string) => {
                  return (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="md:col-span-1">
              <label>Zip Code:</label>
              <input
                className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
                id="zip_code"
                placeholder="Enter Zip Code"
                value={zip_code}
                onChange={(e: any) =>
                  handlePersonChange("zip_code", e.target.value)
                }
              />
            </div>
          </div>
        </Section>
        <Section>
          <div className="grid md:grid-cols-2 gap-4">
            <div className="">
              <label>Email:</label>
              <input
                className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
                id="first_name"
                placeholder="Enter your email"
                value={email}
                onChange={(e: any) =>
                  handlePersonChange("email", e.target.value)
                }
              />
              <div className="my-2">
                <input
                  type="checkbox"
                  className="ml-1 mr-2"
                  checked={email_message}
                  onChange={(e: any) => {
                    handlePersonChange("email_message", e.target.checked);
                  }}
                />
                <label>Can email treatment plan</label>
              </div>
            </div>
            <div className="">
              <label>Phone Number:</label>
              <input
                className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
                id="last_name"
                placeholder="Enter your phone number"
                value={phone_number}
                onChange={(e: any) =>
                  handlePersonChange("phone_number", e.target.value)
                }
              />
              <div className="my-2">
                <input
                  type="checkbox"
                  className="ml-1 mr-2"
                  checked={phone_message}
                  onChange={(e: any) => {
                    handlePersonChange("phone_message", e.target.checked);
                  }}
                />
                <label>Can leave phone message</label>
              </div>
            </div>
          </div>
        </Section>
      </div>
    </OrderCard>
  );
}
