const selected_center = {
  __typename: "TestCenter",
  _id: "63d1560bd51d81e49962c5f1",
  name: "Quest Diagnostics",
  address: "3101 W Princeton St",
  city: "Orlando",
  state: "FL",
  zip: "32808-5600",
  country: "USA",
  fax: "407-299-2676",
  url: "https://www.google.com/maps/dir/Current+Location/3101+W+Princeton+St,+Orlando,+FL,+32808,+USA/",
  hours: {
    __typename: "Hours",
    Sunday: [
      {
        __typename: "Open",
        start: null,
        end: null,
      },
    ],
    Monday: [
      {
        __typename: "Open",
        start: "7:00 AM",
        end: "4:00 PM",
      },
    ],
    Tuesday: [
      {
        __typename: "Open",
        start: "7:00 AM",
        end: "4:00 PM",
      },
    ],
    Wednesday: [
      {
        __typename: "Open",
        start: "7:00 AM",
        end: "4:00 PM",
      },
    ],
    Thursday: [
      {
        __typename: "Open",
        start: "7:00 AM",
        end: "4:00 PM",
      },
    ],
    Friday: [
      {
        __typename: "Open",
        start: "7:00 AM",
        end: "4:00 PM",
      },
    ],
    Saturday: [
      {
        __typename: "Open",
        start: "7:00 AM",
        end: "12:00 PM",
      },
    ],
  },
  location: {
    __typename: "CenterPoint",
    coordinates: [-81.418076, 28.57134],
  },
};
export default selected_center;
