import { useEffect } from "react";
import OrderDetails from "../Orders/details_orders";
import TestDetails from "../Tests/details_test";
import { useDashboard } from "../../DashboardContext";
import { useUI } from "../../../context/UIContext";

export default function MyOrdersDetails() {
  const { location, setLocation } = useUI();
  const { view } = useDashboard();
  const layout_view: { [key: string]: any } = {
    orders: <OrderDetails />,
    tests: <TestDetails />,
  };

  useEffect(() => {
    !!location &&
      location === "/dashboard/my-orders" &&
      setLocation("/dashboard/my-orders/orders");
  }, [location, setLocation]);

  return <>{layout_view[view]}</>;
}
