import UsersResults from "./UsersResults";
import AdminUsersSidePanel from "./side_panel";

export default function AdminUser() {
  return (
    <div className="flex flex-col md:flex-row mx-auto max-w-7xl">
      <AdminUsersSidePanel />
      <UsersResults />
    </div>
  );
}
