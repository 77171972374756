import { useState } from "react";
import SubmitButton from "./button";
export default function AdminAccountInput() {
  const [UserName, setUserName] = useState<string>("");

  return (
    <div className="flex flex-row gap-4 items-center md:w-96">
      <input
        className="h-6 bg-transparent border border-white/60 px-2 text-white text-xs rounded-[3px] shadow-inner grow"
        id={"code"}
        placeholder={"Enter Patient Email or Phone to View"}
        value={UserName || ""}
        onChange={(e: any) => setUserName(e.target.value)}
      />
      <SubmitButton UserName={UserName} />
    </div>
  );
}
