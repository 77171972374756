import IonIcon from "@reacticons/ionicons";
import { startsWith } from "lodash";
import { useUser } from "../../../context/UserContext";
import Can from "../../../utils/auth/Can";
import { useUI } from "../../../context/UIContext";

export default function AdminActions({ show_menu }: { show_menu: boolean }) {
  const { is_mobile, location, setLocation } = useUI();
  const { user, simulated_role } = useUser();
  const { roles } = user;

  const admin_menu = [
    {
      label: is_mobile ? (
        "Dashboard"
      ) : (
        <IonIcon name="home-sharp" style={{ transform: "translateY(2px)" }} />
      ),
      link: "/dashboard",
    },
    { label: "Users", link: "/users", perform: "view:users" },
    { label: "Settings", link: "/settings" },
  ];

  return (
    <div
      className={`flex-col gap-2 pb-6 md:pb-0 ${
        show_menu ? "flex" : "hidden"
      } md:flex-row md:gap-6 md:flex grow`}
    >
      {admin_menu.map((item: any) => {
        const { label, link, perform } = item;
        const isActive = startsWith(location, link);
        return (
          <Can
            key={link}
            role={simulated_role || roles}
            perform={perform}
            yes={() => (
              <div>
                <button
                  className={`${
                    isActive && "text-amber-400"
                  } hover:text-amber-400 hover:underline uppercase`}
                  onClick={() => setLocation(link)}
                >
                  {label}
                </button>
              </div>
            )}
          />
        );
      })}
    </div>
  );
}
