import { createContext, useContext, useState } from "react";
import moment from "moment";
import { gql } from "@apollo/client";
import {
  AdminConfirmSignUpCommand,
  AdminConfirmSignUpCommandInput,
  AdminGetUserCommand,
  AdminUpdateUserAttributesCommand,
  AdminUpdateUserAttributesCommandInput,
  AdminSetUserPasswordCommand,
  AdminSetUserPasswordCommandInput,
  SignUpCommand,
  SignUpCommandInput,
} from "@aws-sdk/client-cognito-identity-provider";
import { CognitoClient } from "../utils/aws";
import passwordValidator from "password-validator";
import libphonenumber, {
  PhoneNumberUtil,
  PhoneNumberFormat,
  PhoneNumber,
} from "google-libphonenumber";
import { compact, concat, flatMap, includes } from "lodash";
import { useUI } from "../context/UIContext";
import { useUser } from "../context/UserContext";
import { makeUser, validEmail, validPhone } from "../utils/functions";
import axios from "axios";

const { REACT_APP_AWS_COGNITO_CLIENT_ID: ClientId } = process.env;

const VERIFY_USER_QUERY = gql`
  query VerifyUser($code: String!, $username: String!) {
    verifyUser(code: $code, username: $username) {
      _id
      updatedAt
    }
  }
`;

const UPDATE_OR_INSERT_USER_MUTATION = gql`
  mutation ResendCode($code: String, $username: String!) {
    resendCode(code: $code, username: $username) {
      _id
    }
  }
`;

// After confirming, delete the user record in Apollo.
const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($username: String!) {
    deleteUser(username: $username) {
      _id
    }
  }
`;

const phoneUtil = new PhoneNumberUtil();

const pw_schema = new passwordValidator();

pw_schema
  .is()
  .min(8, "Your password should have a minimum of 8 characters.") // Minimum length 8
  .is()
  .max(16, "Your password should not be longer than 16 characters.") // Maximum length 100
  .has()
  .uppercase(1, "Your password should include at least 1 uppercase letter.") // Must have uppercase letters
  .has()
  .lowercase(1, "Your password should include at least 1 lowercase letter.") // Must have lowercase letters
  .has()
  .digits(2, "Your password should include at least 2 numbers.") // Must have at least 2 digits
  .has()
  .not()
  .spaces(1, "Your password should not include any spaces.") // Should not have spaces
  .is()
  .not()
  .oneOf(["Passw0rd", "Password123"]);
interface RegistrationValues {
  acknowledged: boolean;
  acknowledged_mobile: boolean;
  alert: any | null;
  auth_code: string;
  codeDeliveryDetails: any | null;
  family_name: string;
  forgot_password_ready: string[];
  given_name: string;
  is_email: boolean;
  loading: boolean;
  login_type: string;
  password: string;
  password2: string;
  registration_ready: string[];
  resend_ready: string[];
  username: string;
  verify_ready: string[];
  handleLogIn: (props?: any) => void;
  registerNewUser: () => void;
  resendCode: (options?: any) => void;
  resetPassword: () => void;
  setAcknowledged: (acknowledged: boolean) => void;
  setAcknowledgedMobile: (acknowledged_mobile: boolean) => void;
  setAlert: (alert: any | null) => void;
  setAuthCode: (code: string) => void;
  setCodeDeliveryDetails: (cdd: any | null) => void;
  setFamilyName: (family_name: string) => void;
  setGivenName: (given_name: string) => void;
  setLoading: (setting: boolean) => void;
  setLoginType: (login_type: string) => void;
  setPassword: (password: string) => void;
  setPassword2: (password2: string) => void;
  setUsername: (username: string) => void;
  verifyCode: () => void;
}

export const RegistrationContext = createContext<RegistrationValues>({
  acknowledged: false,
  acknowledged_mobile: false,
  alert: null,
  auth_code: "",
  codeDeliveryDetails: null,
  family_name: "",
  forgot_password_ready: [],
  given_name: "",
  is_email: true,
  loading: false,
  login_type: "Email",
  password: "",
  password2: "",
  registration_ready: [],
  resend_ready: [],
  username: "",
  verify_ready: [],
  handleLogIn: () => {},
  registerNewUser: () => {},
  resendCode: () => {},
  resetPassword: () => {},
  setAcknowledged: () => {},
  setAcknowledgedMobile: () => {},
  setAlert: () => {},
  setAuthCode: () => {},
  setCodeDeliveryDetails: () => {},
  setFamilyName: () => {},
  setGivenName: () => {},
  setLoading: () => {},
  setLoginType: () => {},
  setPassword: () => {},
  setPassword2: () => {},
  setUsername: () => {},
  verifyCode: () => {},
});

export const useRegistration = () => useContext(RegistrationContext);

const RegistrationContextProvider: React.FC<{
  children: any;
}> = ({ children }) => {
  const { client, is_development, setLocation } = useUI();
  const { UserPoolId, loginUser } = useUser();
  const [acknowledged, setAcknowledged] = useState<boolean>(false);
  const [acknowledged_mobile, setAcknowledgedMobile] = useState<boolean>(false);
  const [auth_code, setAuthCode] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [given_name, setGivenName] = useState<string>("");
  const [family_name, setFamilyName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [password2, setPassword2] = useState<string>("");
  const [login_type, setLoginType] = useState<string>("Email");

  const [alert, setAlert] = useState<any | null>();
  // const [show_alert,setShowAlert] = useState<boolean>(false)
  const is_email = login_type === "Email";
  const [codeDeliveryDetails, setCodeDeliveryDetails] = useState<any | null>();
  const [loading, setLoading] = useState<boolean>(false);

  async function handleLogIn(props?: any) {
    setLoading(true);
    try {
      if (!is_email) {
        const phone_number_formatted =
          username &&
          phoneUtil.format(
            username as unknown as PhoneNumber,
            PhoneNumberFormat.E164
          );
        setUsername(phone_number_formatted || "");
        await loginUser({
          callback: props?.callback,
          setAlert,
          user: { username: phone_number_formatted, password },
          user_callback: props?.user_callback, // () => setLocation("./dashboard"),
        });
        return;
      }

      await loginUser({
        callback: props?.callback,
        setAlert,
        user: { username, password },
        user_callback: props?.user_callback,
      });
    } finally {
      setLoading(false);
    }
  }

  async function resetPassword() {
    setAlert(null);
    setLoading(true);

    const variables = {
      code: auth_code,
      username,
    };

    const deleteUserVariables = {
      username,
    };
    try {
      const { data } = await client
        .query({
          query: VERIFY_USER_QUERY,
          variables,
        })
        .catch((error: any) => {
          console.log("ERROR QUERYING USER", error, error.message);
        });

      if (data?.verifyUser?._id) {
        const { updatedAt } = data.verifyUser;
        const oneDayAgo = moment().subtract(1, "days");
        if (moment(updatedAt).isBefore(oneDayAgo)) {
          setAlert({ message: "Code Expired" });
        } else {
          const input: AdminSetUserPasswordCommandInput = {
            UserPoolId, // required
            Username: username, // required
            Password: password, // required
            Permanent: true,
          };
          const command = new AdminSetUserPasswordCommand(input);
          const response = await client.send(command).catch((error: any) => {
            console.log("ERROR UPDATING USER PASSWORD", error);
            setAlert({
              message:
                "There was an error updating this password. Please try again.",
            });
          });
          console.log("CONFIRMED PASSWORD CHANGE RESPONSE", response);
          setAlert({
            message: (
              <>Your password was changed. Log in using updated password.</>
            ),
            success: true,
          });
          // Send a mutation to delete the user record.
          const deleted_response = await client
            .mutate({
              mutation: DELETE_USER_MUTATION,
              variables: deleteUserVariables,
            })
            .catch((error: any) => {
              console.log("ERROR DELETE USER", error);
            });
          console.log("DELETED FROM USER RESPONSE", deleted_response);
          setLocation("./login");
          console.log("VERIFIED CODE RESPONSE", response);
        }
      } else {
        setAlert({ message: "Incorrect information was entered." });
      }
    } catch (error: any) {
      const error_messages: { [key: string]: any } = {
        ExpiredCodeException: (
          <>
            {error.message}{" "}
            <button onClick={() => resendCode({ route: "/reset-password" })}>
              <span className="font-bold underline">Resend code.</span>
            </button>
          </>
        ),
        NotAuthorizedException: (
          <>
            {error.message}{" "}
            <button onClick={() => setLocation("/verify")}>
              <span className="font-bold underline">Verify user.</span>
            </button>
          </>
        ),
      };
      setAlert({ message: error_messages[error.name] || error.message });
      console.log("ERROR CONFIRMING CODE", JSON.stringify(error), error);
    } finally {
      setLoading(false);
    }
  }

  function registrationReady() {
    const phone_check = !validPhone(username) && "Phone number is invalid.";
    const email_check = !validEmail(username) && "Email is invalid.";
    const username_check = login_type === "Email" ? email_check : phone_check;
    const names_check = given_name && family_name;
    const matching_pw = password === password2;
    const pw_errors = pw_schema.validate(password, { details: true }) as any[];
    const pw_error_list = flatMap(pw_errors, "message");

    const error_array = compact(
      concat(
        username_check,
        !names_check && "Provide a First and Last Name.",
        password.length > 0 &&
          !matching_pw &&
          "Passwords entered do not match.",
        pw_error_list,
        !password && "A password is required for registration.",
        !acknowledged && "Agree to Notice of Privacy, Consent and Terms",
        !is_email && !acknowledged_mobile && "Agree to mobile communications"
      )
    );

    return error_array;
  }

  function forgotPasswordReady() {
    const matching_pw = password === password2;
    const pw_errors = pw_schema.validate(password, { details: true }) as any[];
    const pw_error_list = flatMap(pw_errors, "message");

    const error_array = compact(
      concat(
        auth_code.length !== 6 && "Enter 6-digit authorization code.",
        password.length > 0 &&
          !matching_pw &&
          "Passwords entered do not match.",
        pw_error_list,
        !password && "Enter a new password."
      )
    );

    return error_array;
  }

  function verifyCodeReady() {
    const username_check =
      !validEmail(username) &&
      !validPhone(username) &&
      "Email or phone number is invalid.";

    const error_array = compact(
      concat(
        username_check,
        auth_code.length !== 6 && "Enter 6-digit authorization code."
      )
    );

    return error_array;
  }

  function resendReady() {
    const username_check =
      !validEmail(username) &&
      !validPhone(username) &&
      "Enter valid Email or Phone Number.";

    const error_array = compact(concat(username_check));

    return error_array;
  }

  function formatPhoneNumber(input_number: string) {
    // Parse the input phone number
    const phoneNumber = phoneUtil.parseAndKeepRawInput(input_number, "US");

    // Check if the phone number is valid
    if (phoneUtil.isValidNumber(phoneNumber)) {
      // Format the phone number as E.164
      const formattedNumber = phoneUtil.format(
        phoneNumber,
        libphonenumber.PhoneNumberFormat.E164
      );
      return formattedNumber;
    } else {
      return input_number;
    }
  }

  async function registerNewUser() {
    setAlert(null);
    setLoading(true);

    try {
      const phone_number_formatted = !is_email && formatPhoneNumber(username);
      const email_formatted = validEmail(username);
      const user_data = is_email
        ? {
            Name: "email",
            Value: email_formatted || undefined,
          }
        : {
            Name: "phone_number",
            Value: phone_number_formatted || undefined,
          };

      const user_atts = [
        {
          Name: "given_name",
          Value: given_name,
        },
        {
          Name: "family_name",
          Value: family_name,
        },
        {
          Name: "custom:roles",
          Value: includes(
            ["dhines@rapidscreenings.com", "samuel.s.maxime@gmail.com"],
            user_data?.Value
          )
            ? "Super Administrator"
            : "User",
        },
      ];

      const input: SignUpCommandInput = {
        ClientId,
        Username: email_formatted || phone_number_formatted || undefined,
        Password: password,
        UserAttributes: concat(user_data, user_atts),
      };
      const command = new SignUpCommand(input);
      const response = await CognitoClient.send(command);
      setCodeDeliveryDetails(response?.CodeDeliveryDetails);
      !is_email && setUsername(phone_number_formatted as string);
      setLocation("./verify");
      console.log("USER REGISTERED", response);
    } catch (error: any) {
      console.log("Error", error.name, error.message);
      setAlert({ message: error.message });
    } finally {
      setLoading(false);
    }
  }

  async function resendCode({ route }: { route: string }) {
    // IF ACCOUNT UNVERIFIED SET ALERT

    const admin_get_user_input = {
      Username: validEmail(username) || formatPhoneNumber(username),
      UserPoolId,
    };

    const admin_get_user_command = new AdminGetUserCommand(
      admin_get_user_input
    );
    const admin_get_user_response = await CognitoClient.send(
      admin_get_user_command
    );

    if (admin_get_user_response.UserStatus === "UNCONFIRMED") {
      setAlert({
        message: (
          <>
            The account {username} was never confirmed. You must first confirm
            your login identity to reset your password.{" "}
            <button
              className="text-bold underline"
              onClick={() => {
                setAlert(null);
                setLocation("./verify");
              }}
            >
              Confirm Identity Now
            </button>
          </>
        ),
      });
      return;
    }

    setLoading(true);

    function generateSixDigitPIN() {
      const randomNumber = Math.floor(100000 + Math.random() * 900000);
      return randomNumber.toString().padStart(6, "0");
    }

    const variables = {
      username: validEmail(username) || formatPhoneNumber(username),
      code: generateSixDigitPIN(),
    };
    try {
      console.log("THE VARIABLES", variables);
      // !is_email && setUsername(username);
      const resend_response = await client.mutate({
        mutation: UPDATE_OR_INSERT_USER_MUTATION,
        variables,
      });
      // .catch((error: any) => {
      //   console.log("ERROR UPDATING OR INSERTING USER", error, error.message);
      // });
      console.log("QUERY USER RESPONSE", resend_response);
      if (resend_response?.data?.resendCode?._id) {
        console.log("UPDATED USER RESPONSE", resend_response?.data.resendCode);
        setAlert({ message: `A code was sent to ${username}`, success: true });
        setLocation(route || "./verify");
      } else {
        setAlert({ message: `${username} is not correct.` });
        console.log("FAILED TO UPDATE");
      }
    } catch (error: any) {
      setAlert({ message: `An error occurred.` });
      console.log("ERROR RESENDING CODE", JSON.stringify(error), error);
    } finally {
      setLoading(false);
    }
  }

  async function verifyCode() {
    setAlert(null);
    setLoading(true);
    const username_ = validEmail(username) || formatPhoneNumber(username);
    const variables = {
      code: auth_code,
      username: username_,
    };
    const deleteUserVariables = {
      username: username_,
    };
    try {
      const { data } = await client
        .query({
          query: VERIFY_USER_QUERY,
          variables,
        })
        .catch((error: any) => {
          console.log("ERROR QUERYING USER", error, error.message);
        });
      console.log("QUERY USER RESPONSE", data);
      if (data?.verifyUser?._id) {
        const { updatedAt } = data.verifyUser;
        const oneDayAgo = moment().subtract(1, "days");
        if (moment(updatedAt).isBefore(oneDayAgo)) {
          setAlert({ message: "Code Expired" });
        } else {
          const input: AdminConfirmSignUpCommandInput = {
            UserPoolId, // required
            Username: username_, // required
          };
          const update_input: AdminUpdateUserAttributesCommandInput = {
            UserPoolId,
            Username: username_,
            UserAttributes: [
              {
                Name: validEmail(username_)
                  ? "email_verified"
                  : "phone_number_verified",
                Value: "true",
              },
            ],
          };
          const command = new AdminConfirmSignUpCommand(input);
          const update_command = new AdminUpdateUserAttributesCommand(
            update_input
          );
          const response = await CognitoClient.send(command).catch(
            (error: any) => {
              console.log("ERROR CONFIRMING USER", error);
            }
          );

          await CognitoClient.send(update_command).catch((error: any) => {
            console.log("ERROR UPDATING USER CONFIRMATION", error);
          });

          const get_user_command = new AdminGetUserCommand({
            UserPoolId,
            Username: username,
          });
          const get_user_response = await CognitoClient.send(
            get_user_command
          ).catch((error: any) => {
            console.log("ERROR GETTING USER", error);
          });
          const new_user = makeUser(get_user_response);

          // SEND EMAIL
          new_user?.roles !== "Super Administrator" &&
            (await axios.post("/api/email/send", {
              subject: `New Portal User Confirmed`,
              to: is_development
                ? "maxime.and.associates@gmail.com"
                : "vince@rapidscreenings.com",
              //  bcc: !is_development && "maxime.and.associates@gmail.com",
              html: `${new_user?.given_name} ${new_user?.family_name} has signed up for a portal account.\n<a href="https://my-account.rschealth.com/user/${new_user?.Username}">View Profile</a>`,
            }));
          setAlert({
            message:
              "Account has been confirmed. Log in to access your account.",
            success: true,
          });
          // Send a mutation to delete the user record.
          const deleted_response = await client
            .mutate({
              mutation: DELETE_USER_MUTATION,
              variables: deleteUserVariables,
            })
            .catch((error: any) => {
              console.log("ERROR DELETE USER", error);
            });
          console.log("DELETED FROM USER RESPONSE", deleted_response);
          setLocation("./login");
          console.log("VERIFIED CODE RESPONSE", response);
        }
      } else {
        setAlert({ message: "Incorrect information was entered." });
      }
    } catch (error: any) {
      console.log(
        "ERROR VERIFYING CODE",
        JSON.stringify(error),
        error,
        "MSG",
        error.message
      );
      setAlert({ message: error.message || "Code Verification Error" });
    } finally {
      setLoading(false);
    }
  }

  return (
    <RegistrationContext.Provider
      value={{
        acknowledged,
        acknowledged_mobile,
        alert,
        auth_code,
        codeDeliveryDetails,
        family_name,
        forgot_password_ready: forgotPasswordReady(),
        given_name,
        is_email,
        registration_ready: registrationReady(),
        resend_ready: resendReady(),
        loading,
        login_type,
        password,
        password2,
        username,
        verify_ready: verifyCodeReady(),
        handleLogIn,
        registerNewUser,
        resendCode,
        resetPassword,
        setAcknowledged,
        setAcknowledgedMobile,
        setAlert,
        setAuthCode,
        setCodeDeliveryDetails,
        setFamilyName,
        setGivenName,
        setLoading,
        setLoginType,
        setPassword,
        setPassword2,
        setUsername,
        verifyCode,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};

export default RegistrationContextProvider;
