import { useCallback, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useTestCenter } from "./TestCenterContext";
import FindSettings from "./settings";
import FindList from "./results_list";
import MapMarker from "./map/marker";
import grayscaleMap from "./map/map_style";
import { useUI } from "../../../context/UIContext";
//import _ from "lodash";
const { REACT_APP_GOOGLE_MAPS_KEY } = process.env;

function MapView() {
  const { is_mobile } = useUI();
  const [mapref, setMapRef] = useState<any | null>(null);
  //  const [active_item, setActiveItem] = useState<any | undefined>();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY as string,
  });

  const { center, setBox, setMap, test_centers } = useTestCenter();

  const onLoad = useCallback(function callback(this_map: any) {
    setMapRef(this_map);
  }, []);

  const onUnmount = useCallback(
    function callback(map: any) {
      setMap(null);
    },
    [setMap]
  );

  if (!isLoaded) return null;
  // console.log("WINDOW HEIGHT", window.innerHeight);
  function setMapHeight() {
    const ww = window.innerWidth;
    switch (true) {
      case ww > 1300:
        return "1000px";
      case 800 < ww && ww < 1299:
        return "800px";
      default:
        return "600px";
    }
  }

  return (
    <>
      <GoogleMap
        //      ref={map}

        mapContainerStyle={{
          width: "100%",
          height: setMapHeight(),
        }}
        onBoundsChanged={() => {
          if (!mapref || !center) return;
          let { north, south, east, west } = mapref.getBounds().toJSON();
          const box = [
            [west, south],
            [east, north],
          ];
          setBox(JSON.stringify(box));
        }}
        options={{
          mapTypeControl: false,
          fullscreenControl: false,
          styles: grayscaleMap,
        }}
        center={center || { lng: -98.5795, lat: 39.8283 }}
        zoom={center ? 11 : 4}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {test_centers.map((center: any) => {
          return <MapMarker key={center._id} center={center} />;
        })}
        <div className="absolute h-full w-full pointer-events-none">
          <div className="flex flex-row justify-end mx-auto h-full max-w-7xl pointer-events-none">
            <div
              className="flex flex-col flex-grow m-3 gap-4 max-h-full max-w-xs pointer-events-auto"
              style={{ height: "-webkit-fill-available" }}
            >
              {!is_mobile && (
                <>
                  <div className="p-3 rounded-md bg-white drop-shadow-lg">
                    <FindSettings />
                  </div>
                  <div
                    className="p-3 rounded-md bg-white drop-shadow-lg flex flex-grow overflow-hidden"
                    // onScroll={(e: any) =>
                    //   console.log("Scrolling", e.target.scrollTop)
                    // }
                  >
                    <FindList />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* {test_centers.map((center: any) => {
          const { _id, location } = center;
          const { coordinates } = location;
          return (
            <Marker
              key={center._id}
              position={{ lat: coordinates[1], lng: coordinates[0] }}
              onMouseOver={(e: any) => {
                // const mouse_info = { _id, x: e.clientX, y: e.clientY };
                // console.log("mouse", mouse_info);
                setActiveItem(_id);
              }}
              onMouseOut={(e: any) => {
                setActiveItem(undefined);
              }}
            >
              {active_item && (
                <InfoWindow>
                  <div
                    className="bg-white text-black"
                    //style={{ top: globalCoords.y, left: globalCoords.x }}
                  >
                    Here is something
                  </div>
                </InfoWindow>
              )}
            </Marker>
          );
        })} */}
      </GoogleMap>
    </>
  );
}

export default MapView;
