import IonIcon from "@reacticons/ionicons";
import { find } from "lodash";
import { useCart } from "../../context/CartContext";
import { useUI } from "../../context/UIContext";
import { addPackage } from "../../utils/functions";
export default function ShopCards() {
  const { setLocation } = useUI();
  const {
    individual_products,
    package_products,
    shopping_cart,
    setShoppingCart,
  } = useCart();

  const card_1 = {
    sku: "P-1",
    icon: "basic-panel",
    style: "bg-white h-[500px]",
  };

  const card_2 = {
    sku: "P-20",
    icon: "test-panel",
    style: "bg-amber-400 h-[500px]",
  };

  const card_3 = {
    sku: "P-21",
    icon: "panel-plus",
    style: "bg-white h-[500px]",
  };

  const shop_cards = [card_1, card_2, card_3];

  return (
    <div className="flex flex-col py-20 gap-8 bg-slate-100 w-full relative before:block before:bg-[url('https://res.cloudinary.com/maxime-associates/image/upload/v1665368620/RapidSTDTesting/arrow-down-triangle.png')] before:bg-no-repeat before:absolute before:w-[3.625rem] before:h-[3.625rem] before:mx-auto before:bg-contain before:right-0 before:left-0 before:top-[-1.75rem] before:z-10">
      <div className="text-center text-rsc-blue">
        <h1 className="mb-6 text-[1.875rem] font-bold">
          Choose One of Our 4,000 Testing Centers and Select One of Our
          Packages:
        </h1>
      </div>
      <div className="flex flex-col gap-8 text-rsc-blue items-center justify-center">
        {shop_cards.map((item: any, idx: number) => {
          const { icon, sku, style } = item;
          const pkg = find(package_products, ["sku", sku]);
          const { items, name, price } = pkg;
          return (
            <div
              className={`flex flex-col justify-center w-full lg:w-[340px] shadow-lg ${style}`}
              key={idx}
            >
              <div className="flex flex-col py-10 gap-4 items-center">
                <div>
                  <img
                    src={`https://res.cloudinary.com/maxime-associates/image/upload/h_124/v1665069483/RapidSTDTesting/${icon}.png`}
                    alt={icon}
                    className="md:h-[107px]"
                  />
                </div>
                <div className="text-center font-bold text-[1.5625rem]">
                  {name}
                </div>
                <div>
                  <ul className="text-[0.9375rem]">
                    {items.map((item: string, idx: number) => {
                      const { name } = find(individual_products, ["sku", item]);
                      return (
                        <li key={idx}>
                          <IonIcon
                            className="font-bold translate-y-[2px]"
                            name="checkmark-sharp"
                          />{" "}
                          {name}
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="text-center font-bold text-2xl">${price}</div>
                <div className="text-center">
                  <button
                    className="bg-rsc-red font-bold uppercase text-white px-10 py-3 shadow-lg rounded-full"
                    onClick={() =>
                      addPackage({
                        pkg,
                        shopping_cart,
                        callback: setLocation("./order"),
                        setShoppingCart,
                      })
                    }
                  >
                    Get Tested Now
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
