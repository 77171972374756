import ContentHolder from "../content_holder";
import { useUserEdit } from "../UserEditContext";

export default function InformationView() {
  const { user } = useUserEdit();

  const {
    email,
    email_verified,
    Enabled,
    given_name,
    family_name,
    middle_name,
    phone_number,
    roles,
  } = user || {};

  function ContactVerified({ status_ }: { status_: string }) {
    const status =
      status_ === "true-" ? (
        <div className="bg-green-600 py-1 px-2 rounded-full shadow-sm">
          Verified
        </div>
      ) : (
        <div className="bg-gray-400/70 py-1 px-2 rounded-full shadow-sm italic">
          Unverified
        </div>
      );
    return <div className="text-xs text-white">{status}</div>;
  }

  const info_display = [
    {
      id: "given_name",
      label: "First Name",
      data: given_name || (
        <span className="text-gray-400 italic">not provided</span>
      ),
    },
    {
      id: "middle_name",
      label: "Middle Name",
      data: middle_name,
    },
    {
      id: "family_name",
      label: "Last Name",
      data: family_name || (
        <span className="text-gray-400 italic">not provided</span>
      ),
    },
    {
      id: "email",
      label: "Email",
      data: email ? (
        <div className="flex flex-row items-center gap-4">
          <div>
            <a href={`mailto:${email}`} className="text-blue-600 underline">
              {email}
            </a>
          </div>
          <ContactVerified status_={email_verified} />
        </div>
      ) : (
        <span className="text-gray-400 italic">not provided</span>
      ),
    },
    {
      id: "phone_number",
      label: "Phone",
      data: phone_number ? (
        <>{phone_number}</>
      ) : (
        <span className="text-gray-400 italic">not provided</span>
      ),
    },
    // {
    //   id: "roles",
    //   label: "Role",
    //   data: roles || "User",
    // },
    // {
    //   id: "status",
    //   data: (
    //     <div className="flex flex-row gap-8 font-bold uppercase">
    //       {/* <div>
    //         {UserStatus ? (
    //           <span className="bg-green-800">Confirmed</span>
    //         ) : (
    //           <span className="text-red-600">Unconfirmed</span>
    //         )}
    //       </div>{" "} */}
    //       <div className="font-bold">

    //       </div>
    //     </div>
    //   ),
    // },
  ];
  return (
    <ContentHolder
      title={
        <div className="flex flex-row gap-6 items-center">
          {user ? (
            <div className="text-xl text-rsc-blue/90 md:text-2xl font-bold">
              <span className="text-gray-400/70">{roles || "User"}</span>{" "}
              {given_name} {family_name}
            </div>
          ) : (
            <div className="text-xl text-rsc-blue/90 md:text-2xl font-bold">
              User
            </div>
          )}
          <div>
            {Enabled ? (
              <span className="bg-green-600 text-white text-sm px-4 py-1 rounded-md shadow-md">
                Enabled
              </span>
            ) : (
              <span className="bg-red-600 text-white text-sm px-4 py-1 rounded-md shadow-md">
                Disabled
              </span>
            )}
          </div>
        </div>
      }
    >
      <div className="flex flex-col text-lg gap-4 md:w-[400px]">
        {info_display.map((item: any) => {
          const { id, label, data } = item;
          if (!data || !user?.[id]) return null;
          return (
            <div key={id} className="flex flex-row items-center gap-2">
              <div className="font-bold">{label}:</div> {data}
            </div>
          );
        })}
      </div>
    </ContentHolder>
  );
}
