import IonIcon from "@reacticons/ionicons";
export default function SupportButton({
  className = "",
}: {
  className?: string;
}) {
  return (
    <div
      className={`flex flex-row gap-2 border border-slate-200 items-center justify-center px-6 p-2 bg-slate-50 text-rsc-blue shadow-lg rounded-full cursor-pointer ${className}`}
    >
      <div className="text-3xl">
        <IonIcon name="call-sharp" className="translate-y-[3px]" />
      </div>
      <div className="leading-none pr-1 text-sm">
        <a href="tel:+18668721888">
          <span className="">Need Support?</span>
          <br />
          <span className="text-lg font-bold">(866) 872-1888</span>
        </a>
      </div>
    </div>
  );
}
