import { useUI } from "../context/UIContext";
import ShopAbout from "./about";
import ShopCards from "./Cards/cards_desktop";
import ShopCardsMobile from "./Cards/cards_mobile";
import ShopHero from "./hero";
import ShopProducts from "./products";

export default function Shop() {
  const { is_mobile } = useUI();
  return (
    <>
      <ShopHero />
      {is_mobile ? <ShopCardsMobile /> : <ShopCards />}
      <ShopProducts />
      <ShopAbout />
    </>
  );
}
