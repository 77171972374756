import { merge, pickBy } from "lodash";
import moment from "moment";
import { useAccount } from "../context/AccountContext";
import { useUser } from "../context/UserContext";
import SupportButton from "../order/support_button";
import ScheduleConsultation from "./ScheduleConsultation/ScheduleForm";
import ConsultationSummary from "./Summary";
import { blank_person } from "../utils/types";

export default function ConsultationApp() {
  const { is_admin, user } = useUser();
  const { account } = useAccount();
  /// *** SETS DEFAULT PERSON ***
  is_admin
    ? merge(
        blank_person,
        pickBy({
          Id: account?.Id,
          dob: moment(account?.PersonBirthdate).format("YYYY-MM-DD"),
          sex: account?.Gender__pc,
          given_name: account?.FirstName,
          family_name: account?.LastName,
          email: account?.PersonEmail,
          phone_number: account?.PersonMobilePhone,
        })
      )
    : merge(
        blank_person,
        pickBy({
          Id: account?.Id,
          dob: moment(account?.PersonBirthdate).format("YYYY-MM-DD"),
          sex: account?.Gender__pc,
          given_name: user?.given_name,
          family_name: user?.family_name,
          email: user?.email || account?.PersonEmail,
          phone_number: user?.phone_number || account?.PersonMobilePhone,
        })
      );

  return (
    <div className="flex flex-col gap-6 p-4 mx-auto max-w-7xl w-full">
      <div>
        <h1 className="text-3xl font-bold text-rsc-blue">
          Order Consultation and Treatment
        </h1>
        <p className="text-gray-700">Fast, private and secure STD testing.</p>
      </div>

      <div className="flex flex-col lg:flex-row gap-6">
        <ScheduleConsultation />
        <div className="hidden md:block">
          <ConsultationSummary />
          <SupportButton className="my-8 w-max m-auto" />
        </div>
      </div>
    </div>
  );
}
