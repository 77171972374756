import moment from "moment";

export default function BasicOrderTemplate({
  opportunity,
  order,
}: {
  opportunity: any;
  order: any;
}) {
  const { Description, Payment_Status__c } = opportunity;
  const { CreatedDate, OrderNumber, Status } = order;

  const status_button: { [key: string]: any } = {
    "Not Paid": (
      <>
        {Description !== "Pay Later" ? (
          <div className="border-2 border-red-600 bg-red-600/5 rounded-md text-center text-red-600 uppercase">
            Payment Incomplete
          </div>
        ) : (
          <div className="border-2 border-rsc-light-blue bg-rsc-light-blue/5 rounded-md text-center text-rsc-light-blue uppercase">
            Not Paid
          </div>
        )}
      </>
    ),
    "Paid in Full": (
      <div className="border-2 border-green-600 bg-green-600/5 rounded-md text-center text-green-600 uppercase">
        Payment Complete
      </div>
    ),
    "Pay Later": (
      <div className="border-2 border-red-600 bg-red-600/5 rounded-md text-center text-red-600 uppercase">
        Payment Incomplete
      </div>
    ),
    Refunded: (
      <div className="border-2 border-rsc-light-blue bg-rsc-light-blue/5 rounded-md text-center text-rsc-light-blue uppercase">
        Refunded
      </div>
    ),
  };

  return (
    <>
      <div className="flex flex-row text-xs uppercase">
        <div className="basis-1/2 text-rsc-light-blue font-semibold">
          Order <span className="normal-case">#{OrderNumber}</span>
        </div>
        <div className="basis-1/2 text-rsc-light-blue text-right">
          {moment(CreatedDate).format("lll")}
        </div>
      </div>
      <div className="py-4 text-center text-gray-500 text-sm font-semibold uppercase">
        {Status}
      </div>
      {status_button[Payment_Status__c]}
    </>
  );
}
