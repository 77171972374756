import { useCallback } from "react";
import IonIcon from "@reacticons/ionicons";
import { compact, concat } from "lodash";
import { usePayment } from "../../../components/Payment/PaymentInfoContext";
import PreLoader from "../../../PreLoader";
import { GET_DOCTOR } from "../../../utils/queries";
import { useCart } from "../../../context/CartContext";
import { useOrder } from "../OrderContext";
import { useUI } from "../../../context/UIContext";
import { useUser } from "../../../context/UserContext";

export default function FinalizeCreditCardOrder() {
  const { client, us_states } = useUI();
  const { user } = useUser();
  const { errors, order, view_errors, setViewErrors } = useOrder();
  const { reorder_discount, shopping_cart } = useCart();
  const {
    errors: pay_errors,
    loading,
    processCreditCard,
    setLoading,
  } = usePayment();
  const { totals } = useCart();

  const handleCreditCardPayment = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await client.query({
        query: GET_DOCTOR,
        variables: {
          state: us_states[order?.user?.address_state || "NY"],
        },
      });
      await processCreditCard({
        amount: totals.total_due,
        data: {
          oid: "",
          doctor: data?.getDoctor,
          mx: { shopping_cart, order },
          reorder_discount,
          totals,
          type: "order",
          user_: user,
        },
        setViewErrors,
      });
    } catch (err) {
      console.log("ERROR PROCESSING ORDER", err);
    } finally {
      setLoading(false);
    }
  }, [
    client,
    order,
    shopping_cart,
    reorder_discount,
    totals,
    us_states,
    user,
    processCreditCard,
    setLoading,
    setViewErrors,
  ]);

  const after_order_list = [
    <>
      Access your <strong>Lab Requisition form and your test code</strong> in
      your online account.
    </>,
    <>
      The <strong>address of the test center</strong> you chose will also{" "}
      <strong>be visible in your account</strong>.
    </>,
    <>
      Take your <strong>Lab Requisition Form or your test code</strong> to the
      testing center.
    </>,
    <>
      <strong>Get Tested</strong>. STD tests usually take 5 minutes or less.
    </>,
  ];
  const all_errors = compact(concat(errors, pay_errors)) || [];

  return (
    <>
      {view_errors && (
        <div className="w-full">
          <div className="mb-2 font-semibold">
            Please correct the following information:
          </div>
          <div className="flex flex-col gap-2 mx-2">
            {all_errors.map((error: any, idx: number) => {
              // const { id, validation } = error;
              return (
                <div
                  key={idx}
                  className="border border-rsc-red bg-rsc-red/30 rounded-md p-2 text-xs"
                >
                  {/* <h3 className="text-sm font-semibold">{id}</h3> */}
                  <p>{error}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {loading ? (
        <PreLoader text="Finalizing order..." />
      ) : (
        <>
          <div className="py-3 bg-slate-50">
            <button
              className="rounded rounded-lg p-4 bg-red-700 text-center text-white text-xl font-bold shadow-md w-full"
              onClick={handleCreditCardPayment}
            >
              <div className="flex flex-row gap-8 items-center icon-text text-3xl">
                <div className="absolute">
                  <IonIcon name="lock-closed" />
                </div>
                <div className="text-center text-2xl md:text-3xl grow">
                  6. Place Your Order
                </div>
              </div>
            </button>
          </div>
          <div className="p-4 bg-slate-50">
            <h4 className="text-xl font-bold">
              What Happens After You Place Your Order?
            </h4>
            <ul className="py-3 list-disc list-inside">
              {after_order_list.map((item: any, idx: number) => {
                return (
                  <li key={idx} className="pb-3">
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}
    </>
  );
}
