import { useState } from "react";

export default function LabInput() {
  const [zip_code, setZipCode] = useState<string>("");
  const isReady = zip_code.length === 5;
  return (
    <div className="absolute pt-2 z-50">
      <div className="flex flex-col p-2 gap-2 bg-rsc-light-blue text-white font-medium rounded-b-md shadow-lg">
        <div>
          <input
            className="p-1 text-center text-black"
            value={zip_code}
            onChange={(e: any) =>
              setZipCode(
                e.target.value
                  .replace(/[^0-9.]/g, "")
                  .replace(/(\..*)\./g, "$1")
              )
            }
            style={{
              MozAppearance: "textfield",
            }}
          />
        </div>
        <div>
          <button
            className={`${
              isReady ? "bg-rsc-red text-white" : "bg-gray-300 text-rsc-blue"
            } py-1 text-xs text-center font-semibold uppercase rounded-full w-full`}
            disabled={!isReady}
            onClick={() =>
              (window.location.href = `https://rapidstdtesting.com/std-testing-near-you/?zip_search=${zip_code}`)
            }
          >
            Find a Lab Near You
          </button>
        </div>
      </div>
    </div>
  );
}
