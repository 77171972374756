import { concat, find, includes, map } from "lodash";
import { useCart } from "../../context/CartContext";
import ComboTestItem from "./combo";
import IndividualTestItem from "./individual";
import PackageTestItem from "./package";
import Totals from "./totals";
export default function InvoiceTesting({ data }: { data: any }) {
  // const color = "rgb(17 102 155 / 0.8)";
  // const backgroundColor = "rgb(17 102 155 / 0.025)";
  const padding = "6px 16px";

  const { reorder_discount, shopping_cart, totals } = data;
  const { individual_products, package_products } = useCart();
  const all_products = concat(individual_products, package_products);
  function TestItem({ sub, test }: { sub: boolean; test: any }) {
    const { name, price, cpt, sku } = test;

    if (sku === "5124988472") return null;
    return (
      <tr style={{ fontSize: "9pt" }}>
        <td
          style={{
            border: "1px solid",
            borderColor: "rgb(17 102 155 / 0.05)",
            borderCollapse: "collapse",
            padding: "2px 4px",
            verticalAlign: "top",
          }}
        >
          1
        </td>
        <td
          style={{
            border: "1px solid",
            borderColor: "rgb(17 102 155 / 0.05)",
            borderCollapse: "collapse",
            padding: "2px 4px",
            verticalAlign: "top",
          }}
        >
          {sub ? <>--- {name}</> : name}
        </td>
        <td
          style={{
            border: "1px solid",
            borderColor: "rgb(17 102 155 / 0.05)",
            borderCollapse: "collapse",
            padding: "2px 4px",
            verticalAlign: "top",
          }}
        >
          {cpt}
        </td>
        <td
          style={{
            border: "1px solid",
            borderColor: "rgb(17 102 155 / 0.05)",
            borderCollapse: "collapse",
            padding: "2px 4px",
            textAlign: "right",
            textDecorationLine: sub ? "line-through" : "none",
            verticalAlign: "top",
          }}
        >
          ${(price || 0).toFixed(2)}
        </td>
      </tr>
    );
  }

  function TestRow({ sub, test }: { sub?: boolean; test: any }) {
    const { items } = test;
    return (
      <>
        <TestItem sub={!!sub} test={test} />
        {items &&
          items.map((item: any, idx: number) => {
            const test = find(all_products, ["sku", item]);
            return <TestRow key={idx} sub test={test} />;
          })}
      </>
    );
  }

  const individual_lines = [
    "512",
    "498",
    "8472",
    "91431",
    "16185",
    "36126",
    "3636",
    "3640",
    "11361",
    "11362",
    "P-23",
    "P-22",
    "P-24",
  ];
  const combo_lines = ["11363", "6447"];
  const package_lines = [
    {
      id: "P-1",
      individual_lines: ["11361", "11362", "91431", "36126"],
      combo_lines: [],
    },
    {
      id: "P-2",
      individual_lines: ["91431", "11363", "36126", "3640"],
      combo_lines: [],
    },
    {
      id: "P-9",
      individual_lines: [
        "91431",
        "16185",
        "90849",
        "6447",
        "11363",
        "P-23",
        "36126",
        "8472",
      ],
      combo_lines: [],
    },
    {
      id: "P-20",
      individual_lines: ["91431", "36126"],
      combo_lines: ["6447", "11363"],
    },
    {
      id: "P-21",
      individual_lines: ["91431", "36126", "16185"],
      combo_lines: ["6447", "11363"],
    },
    {
      id: "P-25",
      individual_lines: ["11363", "36126", "3640"],
      combo_lines: [],
    },
  ];
  console.log({ shopping_cart });
  return (
    <table style={{ width: "100%" }}>
      <tbody>
        <tr>
          <td
            style={{
              borderBottom: "1px solid rgb(0 0 0 / 0.2)",
              color: "rgb(0 0 0 / 0.7)",
              fontSize: 24,
              fontWeight: 600,
              padding,
            }}
          >
            Order
          </td>
        </tr>
        {(shopping_cart || []).map((test: any, idx: number) => {
          switch (true) {
            case includes(individual_lines, test?.sku):
              return <IndividualTestItem key={idx} test={test} />;
            case includes(combo_lines, test?.sku):
              return (
                <ComboTestItem
                  key={idx}
                  all_products={all_products}
                  test={test}
                />
              );
            case includes(map(package_lines, "id"), test?.sku):
              return (
                <PackageTestItem
                  key={idx}
                  all_products={all_products}
                  test={{
                    ...test,
                    package_info: find(package_lines, { id: test.sku }),
                  }}
                />
              );
            default:
              return null;
          }
        })}
        <Totals reorder_discount={reorder_discount} totals={totals} />
      </tbody>
    </table>
  );
}
