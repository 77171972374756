export function EmailFooter() {
  return (
    <table style={{ color: "#777" }} width="100%">
      <tbody>
        <tr>
          <td style={{ padding: "40px 0 20px" }}>
            <a
              target="_blank"
              style={{ ...footer_links, padding: "0 8px 0 0" }}
              href="https://my-account.rschealth.com"
              rel="noopener noreferrer"
            >
              My Account
            </a>{" "}
            |{" "}
            <a
              target="_blank"
              style={footer_links}
              href="https://my-account.rschealth.com/test-and-panels"
              rel="noopener noreferrer"
            >
              STD Testing
            </a>{" "}
            |{" "}
            <a
              target="_blank"
              style={footer_links}
              href="https://dna-testing.rschealth.com"
              rel="noopener noreferrer"
              data-auth="NotApplicable"
              data-linkindex="6"
            >
              DNA Testing
            </a>{" "}
            |{" "}
            <a
              target="_blank"
              style={footer_links}
              href="https://www.rschealth.com/pages/terms-of-service"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <p style={{ fontSize: "12px" }}>
              © 2023 RSC Health. All rights reserved.
              <br />
              310 Pelican Avenue, St Louis, MO 63122, USA
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default function VerificationEmail({ data }: { data: any }) {
  const { code = `<%= code %>`, link = `<%= link %>` } = data;
  return (
    <div
      id="order-confirmation"
      className="order-confirmation"
      style={{ fontFamily: `'Poppins', Arial, sans-serif`, padding: "6.65%" }}
    >
      <table
        style={{
          fontSize: "11pt",
          width: "100%",
        }}
      >
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td colSpan={2} style={{ paddingBottom: 24 }}>
              <img
                src="https://res.cloudinary.com/maxime-associates/image/upload/v1677713572/RapidSTDTesting/RSCHealth_logo.png"
                alt="RST logo"
                style={{ width: "36%" }}
              />
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <p
                style={{
                  color: "rgb(17 102 155 / 0.8)",
                  fontSize: 30,
                  fontWeight: 600,
                }}
              >
                Confirm Your Account
              </p>
            </td>
          </tr>
          <tr>
            <td colSpan={2} style={{ color: "#000", padding: "20px 0" }}>
              Your verfication code is below.
            </td>
          </tr>
          <tr>
            <td
              colSpan={2}
              style={{
                background: "rgb(245, 244, 245)",
                borderRadius: "4px",
                fontFamily: `"Courier New", "Lucida Console", "Consolas"`,
                fontSize: 44,
                fontWeight: 600,
                marginRight: "50px",
                marginBottom: "30px",
                padding: "43px 23px",
                textAlign: "center",
                verticalAlign: "middle",
              }}
            >
              {code}
            </td>
          </tr>
          <tr>
            <td style={{ color: "#000", padding: "30px 0" }}>
              Enter the above code on the{" "}
              <a
                href="https://my-account.rschealth.com/verify"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "rgb(17 102 155 / 0.8)",
                  textDecoration: "underline",
                }}
              >
                verification page
              </a>{" "}
              or click the button to automatically verifiy.
            </td>
            <td style={{ textAlign: "center", width: "50%" }}>
              <a
                href={link}
                style={{
                  background: "rgb(17, 102, 155)",
                  borderRadius: "4px",
                  color: "white",
                  display: "inline-block",
                  fontFamily: "Poppins",
                  fontSize: "14pt",
                  fontWeight: 600,
                  padding: "15px 30px",
                  textDecoration: "none",
                }}
              >
                Verify Account
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <EmailFooter />
    </div>
  );
}

const footer_links = {
  fontSize: "13px",
  padding: "0 8px",
  textDecoration: "underline",
};
