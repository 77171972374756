import IonIcon from "@reacticons/ionicons";
import { concat } from "lodash";
import { useDashboard } from "../../../../../DashboardContext";
import { usePayment } from "../../../../../../components/Payment/PaymentInfoContext";
import { useAccount } from "../../../../../../context/AccountContext";
import PreLoader from "../../../../../../PreLoader";

export default function PayLaterPaymentForm({
  errors,
  view_errors,
  setViewErrors,
}: {
  errors: any[];
  view_errors: boolean;
  setViewErrors: any;
}) {
  const { account } = useAccount();
  const { opportunity } = useDashboard();
  const {
    loading,
    errors: pay_errors,
    processCreditCard,
    setLoading,
  } = usePayment();
  const { Balance_Amount__c: amount } = opportunity;
  const order_id = opportunity?.Order?.records?.[0]?.Id;
  return (
    <>
      {view_errors && (
        <div className="w-full">
          <div className="mb-2 font-semibold">
            Please correct the following information:
          </div>
          <div className="flex flex-col gap-2 mx-2">
            {concat(errors, pay_errors).map((error: any, idx: number) => {
              return (
                <div
                  key={idx}
                  className="border border-rsc-red bg-rsc-red/30 rounded-md p-2 text-xs"
                >
                  <p>{errors}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {loading ? (
        <PreLoader text="Submitting payment..." />
      ) : (
        <div className="bg-slate-50">
          <button
            className="rounded rounded-lg p-4 bg-green-700 text-center text-white text-xl font-bold shadow-md w-full"
            onClick={async () => {
              try {
                setLoading(true);
                await processCreditCard({
                  amount,
                  data: {
                    account,
                    mx: { account, opportunity },
                    oid: opportunity?.Id,
                    opportunity,
                    order_id,
                    type: "payment",
                  },
                  setViewErrors,
                });
              } catch (error) {
                console.log("ERROR PROCESSING", error);
              } finally {
                setLoading(false);
              }
            }}
          >
            <span className="icon-text">
              <IonIcon name="lock-closed" /> Submit Payment
            </span>
          </button>
        </div>
      )}
    </>
  );
}
