export default function OrderCard({
  bleed,
  card_layout = "",
  id,
  layout = "",
  red,
  title,
  children,
}: {
  bleed?: boolean;
  card_layout?: string;
  id?: string;
  layout?: string;
  red?: boolean;
  title?: any;
  children: any;
}) {
  return (
    <div
      id={id}
      className={`rounded-md border border-slate-300/60 bg-slate-50 drop-shadow-xl flex flex-1 flex-col w-full ${card_layout} ${
        bleed ? "w-full" : ""
      }`}
    >
      {title && (
        <div
          className={`p-3 md:px-5 ${
            red ? "bg-rsc-red" : "bg-rsc-blue"
          } text-xl md:text-2xl lg:text-3xl rounded-t-md text-white font-bold`}
        >
          {title}
        </div>
      )}
      <div className={`${!bleed ? "pt-2 pb-3" : ""} ${layout}`}>{children}</div>
    </div>
  );
}
