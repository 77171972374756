import moment from "moment";
import InformationCell from "../TD_Cell";

export default function PaymentsInformation({ data }: { data: any }) {
  const color = "rgb(17 102 155 / 0.8)";
  const backgroundColor = "rgb(17 102 155 / 0.025)";
  const padding = "6px 16px";
  const { payment_data, tx } = data;
  const pay_later = payment_data?.pay_later;
  const is_paypal = tx?.type === "paypal";
  const {
    id,
    amount,
    create_time,
    creditCard = {},
    payer,
    purchase_units,
    updatedAt,
  } = tx;

  return (
    <table
      style={{
        backgroundColor,
        fontSize: "11pt",
        width: "100%",
      }}
    >
      <tbody>
        <tr>
          <td
            style={{
              color,
              fontSize: 24,
              fontWeight: 600,

              padding,
            }}
          >
            {pay_later ? "Pay Later" : "Payment"}
          </td>
        </tr>
        <tr>
          <td>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td>
                    {" "}
                    {is_paypal ? (
                      <InformationCell
                        label="PayPal ID"
                        value={
                          pay_later ? null : (
                            <>
                              {payer?.payer_id} |{" "}
                              <a
                                href={`mailto:${payer?.email_address}`}
                                style={{ color, textDecoration: "underline" }}
                              >
                                {payer?.email_address}
                              </a>
                            </>
                          )
                        }
                      />
                    ) : (
                      <InformationCell
                        label="Method"
                        value={
                          pay_later
                            ? null
                            : `${creditCard.cardType} ${creditCard.maskedNumber}`
                        }
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{}}>
                    <InformationCell
                      label="Date"
                      value={moment(create_time || updatedAt).format(
                        "MM/DD/YYYY hh:mm a"
                      )}
                    />
                  </td>
                  <td>
                    <InformationCell label="Transaction ID" value={id} />
                  </td>
                  <td style={{}}>
                    <InformationCell
                      label="Amount"
                      value={
                        is_paypal ? (
                          <>${purchase_units?.[0]?.amount?.value}</>
                        ) : (
                          <>${amount}</>
                        )
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
