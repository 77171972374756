export function PatientConsultationReducer(state: any, action: any) {
  function patientActions() {
    switch (action.type) {
      case "LOAD_PATIENT":
        return action.patient;
      case "ADD_OPTION":
        return { treating: [...state.treating, action.payload] };
      case "REMOVE_OPTION":
        return {
          treating: state.treating.filter(
            (option: any) => option !== action.payload
          ),
        };
      case "HANDLE_CHANGE":
        return {
          ...state,
          [action.patient.field]: action.patient.value,
        };
      case "CLEAR_PATIENT":
        return action.patient;
      default:
        return state;
    }
  }
  let new_patient = patientActions();
  window.localStorage.setItem("patient", JSON.stringify(new_patient));
  return new_patient;
}

export function PartnerConsultationReducer(state: any, action: any) {
  function partnerActions() {
    switch (action.type) {
      case "LOAD_PARTNER":
        return action.partner;
      case "HANDLE_CHANGE":
        return {
          ...state,
          [action.partner.field]: action.partner.value,
        };
      case "CLEAR_PARTNER":
        return action.partner;
      default:
        return state;
    }
  }
  let new_partner = partnerActions();
  window.localStorage.setItem("partner", JSON.stringify(new_partner));
  return new_partner;
}
