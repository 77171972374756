import OrderCard from "../order_card";
import Mobile from "./mobile";
import SelectedCenter from "./selected";
import { useUI } from "../../../../context/UIContext";
import { useConsultation } from "../../context/ConsultationContext";
import { usePartnerConsultation } from "../../context/PartnerConsultationContext";
import MapView from "./map";
function FindTestCenter() {
  const { is_mobile } = useUI();

  const { partner: isPartner, patient } = useConsultation();
  const { partner } = usePartnerConsultation();
  const pharmacy =
    isPartner && !partner?.same_pharmacy
      ? partner?.pharmacy
      : patient?.pharmacy;

  const title = (
    <>
      Choose a Pharmacy{" "}
      {isPartner && (
        <sup className="align-middle text-amber-400 text-sm uppercase">
          Partner
        </sup>
      )}
    </>
  );

  return (
    <>
      {pharmacy ? (
        <OrderCard title={"Pharmacy"}>
          <SelectedCenter pharmacy={pharmacy} />
        </OrderCard>
      ) : (
        <OrderCard bleed title={title}>
          {is_mobile ? <Mobile /> : <MapView />}
        </OrderCard>
      )}
    </>
  );
}

export default FindTestCenter;
