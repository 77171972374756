import IonIcon from "@reacticons/ionicons";
import OpportunityOrderItem from "./item";
import { useAccount } from "../../../../context/AccountContext";
import { useDashboard } from "../../../DashboardContext";
import { useUI } from "../../../../context/UIContext";

export default function MyOrdersList() {
  const { setLocation } = useUI();
  const { account } = useAccount();
  const { opportunities, viewing_id } = useDashboard();
  if (!opportunities) return <>Loading...</>;
  return (
    <>
      {opportunities.map((opportunity: any) => {
        if (!opportunity) return null;
        const { Id, Orders } = opportunity;
        const isActive = Id === viewing_id;
        const order = Orders?.records?.[0];

        const link = `/dashboard/my-orders/orders/${Id}`;
        return (
          <div
            className={`flex flex-row w-full rounded-md overflow-hidden shadow-md ${
              isActive && "border border-rsc-light-blue/50 shadow-lg"
            }`}
            key={Id}
            style={{ cursor: "pointer" }}
            onClick={() => setLocation(link)}
          >
            <div className={`flex flex-col bg-white grow`}>
              <div className="flex flex-col px-2 py-2 bg-rsc-light-blue text-white shadow">
                <div className="font-semibold uppercase">{account?.Name}</div>
                {false && (
                  <div
                    className="text-gray-300 italic"
                    style={{ fontSize: ".64rem" }}
                  >
                    {Id}
                  </div>
                )}
              </div>
              <OpportunityOrderItem opportunity={opportunity} order={order} />
            </div>
            {isActive && (
              <div
                className={`flex bg-rsc-light-blue px-1 items-center justify-center text-white shadow-md`}
              >
                <IonIcon name="caret-forward" />
              </div>
            )}
          </div>
        );
      })}
    </>
  );
}
