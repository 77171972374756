import IonIcon from "@reacticons/ionicons";
import { formatPhoneNumberToHref } from "../utils/functions";
const color = "rgb(17 102 155 / 0.8)";
export default function InformationCell({
  label,
  link,
  value,
}: {
  label?: string;
  link?: string;
  value: any;
}) {
  if (!value) return null;

  const value_display: any = link
    ? {
        email: (
          <a
            href={`mailto:${value}`}
            target="_blank"
            rel="noreferrer"
            style={{
              color,
              textDecoration: "underline",
            }}
          >
            {value}
          </a>
        ),
        link: (
          <a
            href={value}
            target="_blank"
            rel="noreferrer"
            style={{ color, textDecoration: "underline" }}
          >
            Link{" "}
            <IonIcon name="navigate" style={{ transform: "translateY(2px)" }} />
          </a>
        ),
        phone:
          link === "phone" &&
          formatPhoneNumberToHref(value, {
            color,
            textDecoration: "underline",
          }),
      }[link || "link"]
    : value;

  return (
    <table>
      <tbody>
        <tr>
          <td
            className="information-cell"
            style={{
              fontSize: 14,
              lineHeight: 1.4,
              padding: "8px 16px",
              verticalAlign: "top",
            }}
          >
            {label && (
              <p
                style={{
                  fontSize: 10,
                  color: "rgb(102,102,102)",
                  margin: 0,
                  padding: 0,
                  textTransform: "uppercase",
                }}
              >
                {label}
              </p>
            )}
            {value_display}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
