import { createContext, useContext, useEffect, useState } from "react";
import {
  compact,
  concat,
  difference,
  find,
  includes,
  isEqual,
  isObject,
  map,
  reject,
  sum,
  uniq,
} from "lodash";
import test_shopping_cart from "../order/samples/shopping_cart";
import { useUI } from "./UIContext";
import {
  individual_products as rsc_individual_products,
  package_products as rsc_package_products,
} from "./Cart/rsc_products";
import {
  individual_products as rst_individual_products,
  package_products as rst_package_products,
} from "./Cart/rst_products";
const { REACT_APP_SITE } = process.env;
const is_testing_shopping_cart = false;

export const individual_products: any[] =
  REACT_APP_SITE === "rsc_health"
    ? rsc_individual_products
    : rst_individual_products;

export const package_products: any[] =
  REACT_APP_SITE === "rsc_health" ? rsc_package_products : rst_package_products;

const all_products = concat(individual_products, package_products);

interface CartValues {
  all_products: any[];

  individual_products: any[];
  package_products: any[];
  reorder_discount: boolean;

  shopping_cart: any[];

  totals: {
    cart_total: number;
    grand_total: number;
    reorder_discount_total: number;
    total_due: number;
  };
  addItem: (sku: string) => void;
  removeItem: (sku: string) => void;
  resetCart: () => void;
  setReorderDiscount: (reorder_discount: boolean) => void;
  setShoppingCart: (shopping_cart: any[]) => void;
  upgradePackage: (pkg: any) => void;
}

export const CartContext = createContext<CartValues>({
  all_products: [],

  individual_products,
  package_products,
  reorder_discount: false,

  shopping_cart: [],

  totals: {
    cart_total: 0,
    grand_total: 0,
    reorder_discount_total: 0,
    total_due: 0,
  },
  addItem: () => {},
  removeItem: () => {},
  resetCart: () => {},
  setReorderDiscount: () => {},
  setShoppingCart: () => {},
  upgradePackage: () => {},
});

export const useCart = () => useContext(CartContext);

const CartContextProvider: React.FC<{
  children: any;
}> = ({ children }) => {
  const cache = window.localStorage.getItem("shopping_cart");
  const cached_cart =
    typeof cache === "string" && cache !== "undefined" ? JSON.parse(cache) : [];
  const { is_testing_order } = useUI();
  const [reorder_discount, setReorderDiscount] = useState<boolean>(false);
  const [shopping_cart, setShoppingCart] = useState<any[]>(
    is_testing_shopping_cart || is_testing_order
      ? test_shopping_cart
      : cached_cart
  ); //(["3636"]);

  useEffect(() => {
    const filteredCart = shopping_cart.filter(item => isObject(item));
    if (!isEqual(filteredCart, shopping_cart)) {
      setShoppingCart(filteredCart);
    }
    window.localStorage.setItem("shopping_cart", JSON.stringify(shopping_cart));
  }, [shopping_cart]);

  function removeItem(sku: string) {
    setShoppingCart(shopping_cart.filter(item => item.sku !== sku));
  }

  function upgradePackage(pkg: any) {
    const { hidden_items, items } = pkg;
    const remove_items = compact(concat(items, hidden_items));

    const new_array = reject(shopping_cart, (p: any) => {
      return includes(remove_items, p.sku);
    });
    setShoppingCart(concat(new_array, pkg));
  }

  function addItem(sku: string) {
    const productToAdd = all_products.find(item => item.sku === sku);
    if (productToAdd) {
      setShoppingCart([...shopping_cart, productToAdd]);
    }
  }

  // function addItem(sku: string) {
  //   if (!includes(shopping_cart, sku)) {
  //     const new_cart = concat(
  //       reject(shopping_cart, function (s: string) {
  //         return includes(map(package_products, "sku"), s);
  //       }),
  //       sku
  //     );
  //     setShoppingCart(new_cart);
  //   }
  // }

  function resetCart() {
    setShoppingCart([]);
    setReorderDiscount(false);
  }

  const grand_total = sum(
    map(shopping_cart, function (test: any) {
      //  const test = find(all_products, ["sku", i]);
      if (test?.items) {
        const item_codes = difference(
          uniq(concat(test?.hidden_items, test?.items)),
          ["P-1", "P-2", "P-9", "P-20", "P-21", "P-25"]
        );
        const package_value = sum(
          compact(
            map(item_codes, function (i: string) {
              const sub_test = find(all_products, ["sku", i])?.price;
              return sub_test;
            })
          )
        );
        return package_value;
      }
      return test.price;
    })
  );

  const cart_total = sum(map(shopping_cart, "price"));
  const reorder_discount_total = reorder_discount ? cart_total * 0.1 : 0;
  const total_due = cart_total - reorder_discount_total;

  return (
    <CartContext.Provider
      value={{
        all_products,
        individual_products,
        package_products,
        reorder_discount,
        shopping_cart,
        totals: { cart_total, grand_total, reorder_discount_total, total_due },
        addItem,
        removeItem,
        resetCart,
        setReorderDiscount,
        setShoppingCart,
        upgradePackage,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartContextProvider;
