import IonIcon from "@reacticons/ionicons";

export default function ConfirmButton({
  disabled,
  icon,
  label,
  onClick,
}: {
  disabled?: boolean;
  icon: any;
  label: string;
  onClick: any;
}) {
  return (
    <button
      disabled={disabled}
      className={`${
        disabled ? "bg-gray-400" : "bg-rsc-light-blue"
      } text-sm text-white px-4 h-8 rounded-md shadow-md`}
    >
      <div
        className="flex flex-row gap-2 items-center justify-center"
        onClick={onClick}
      >
        <div>{label}</div>
        <div>
          <IonIcon
            className="text-xl"
            name={icon}
            style={{ transform: "translateY(3px)" }}
          />
        </div>
      </div>
    </button>
  );
}
