import { useCallback, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { usePharmacy } from "../PharmacyContext";
import FindSettings from "../settings";
import FindList from "../list";
import MapMarker from "./marker";
import grayscaleMap from "./map_style";
import { useUI } from "../../../../../context/UIContext";
//import _ from "lodash";
const { REACT_APP_GOOGLE_MAPS_KEY } = process.env;

export default function MapView() {
  const { is_mobile } = useUI();
  const [mapref, setMapRef] = useState<any | null>(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY as string,
  });

  const { center, setBox, setMap, pharmacies } = usePharmacy();

  const onLoad = useCallback(function callback(this_map: any) {
    setMapRef(this_map);
  }, []);

  const onUnmount = useCallback(
    function callback(map: any) {
      setMap(null);
    },
    [setMap]
  );

  if (!isLoaded) return null;

  return (
    <>
      <GoogleMap
        //      ref={map}
        mapContainerStyle={{
          width: "100%",
          height: is_mobile ? "400px" : "800px",
        }}
        onBoundsChanged={() => {
          if (!mapref || !center) return;
          let { north, south, east, west } = mapref.getBounds().toJSON();
          const box = [
            [west, south],
            [east, north],
          ];
          setBox(JSON.stringify(box));
        }}
        options={{
          mapTypeControl: false,
          fullscreenControl: false,
          styles: grayscaleMap,
        }}
        center={center || { lng: -98.5795, lat: 39.8283 }}
        zoom={center ? 11 : 4}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <div
          className="absolute flex flex-col flex-grow m-3 gap-4 w-5/12"
          style={{ height: "-webkit-fill-available" }}
        >
          {!is_mobile && (
            <>
              <div className="p-3 flex flex-col gap-4 rounded-md bg-white drop-shadow-lg">
                <FindSettings />
              </div>
              <div className="p-3 rounded-md bg-white drop-shadow-lg flex flex-grow overflow-y-auto">
                <FindList />
              </div>
            </>
          )}
        </div>
        {pharmacies.map((pharmacy: any) => {
          return <MapMarker key={pharmacy._id} pharmacy={pharmacy} />;
        })}
      </GoogleMap>
    </>
  );
}
