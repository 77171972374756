export default function SelectType({
  setInput,
  login_input,
  text,
}: {
  login_input: string;
  setInput: any;
  text: string;
}) {
  return (
    <div>
      <label className="text-xs font-semibold" htmlFor="login_type_group">
        {text}
      </label>
      <div className="flex gap-5 mt-2">
        {["Email", "Phone Number"].map((item: string) => {
          const isActive = item === login_input;
          return (
            <div className="" key={item}>
              <input
                className="mr-1"
                type="radio"
                id={item}
                name="login_type_group"
                value={item}
                checked={isActive}
                onChange={() => setInput(item)}
              />
              <label htmlFor={item}>{item}</label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
