import InvoicePatient from "../PatientInformation";
import InvoicePayments from "../Payments";
import DocumentHeader from "../Header";
import InvoicePromotions from "../Promotions";

export default function PurchaseInvoice({
  data,
  for_pdf,
}: {
  data: any;
  for_pdf?: boolean;
}) {
  const { opportunity } = data;
  return (
    <div
      id="order-confirmation"
      style={{ fontFamily: "Poppins", padding: "6.65%" }}
    >
      <DocumentHeader
        for_pdf={for_pdf}
        label="Payment Receipt"
        sublabel={opportunity?.Id}
      />
      <InvoicePatient user={data} />
      <InvoicePromotions />
      <InvoicePayments data={data} />
    </div>
  );
}
