import AdminTop from "./top";
import AdminBottom from "./bottom";

export default function AdminHeader({ bg_color }: { bg_color?: string }) {
  return (
    <div
      className={`flex flex-col py-2 px-4 md:px-8 ${
        bg_color || "bg-rsc-blue"
      } shadow-md`}
    >
      <AdminTop />
      <AdminBottom />
    </div>
  );
}
