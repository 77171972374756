import moment from "moment";

export default function OrderPendingTemplate({
  opportunity,
}: {
  opportunity: any;
}) {
  const {
    CreatedDate: OpportunityDate,
    Description,
    Payment_Status__c,
    StageName,
  } = opportunity;

  const status_button: { [key: string]: any } = {
    "Not Paid": (
      <div className="border-2 border-red-600 bg-red-600/5 rounded-md text-center text-red-600 uppercase">
        {Description !== "Pay Later" ? "Payment Incomplete" : "Not Paid"}
      </div>
    ),
    "Paid in Full": (
      <div className="border-2 border-green-600 bg-green-600/5 rounded-md text-center text-green-600 uppercase">
        Payment Complete
      </div>
    ),
    "Pay Later": (
      <div className="border-2 border-red-600 bg-red-600/5 rounded-md text-center text-red-600 uppercase">
        Payment Incomplete
      </div>
    ),
    Refunded: (
      <div className="border-2 border-rsc-light-blue bg-rsc-light-blue/5 rounded-md text-center text-rsc-light-blue uppercase">
        Refunded
      </div>
    ),
  };

  return (
    <>
      <div className="flex flex-row text-xs uppercase">
        <div className="basis-1/2 text-rsc-light-blue font-semibold">
          Details Pending
        </div>
        <div className="basis-1/2 text-rsc-light-blue text-right">
          {moment(OpportunityDate).format("lll")}
        </div>
      </div>
      <div className="py-4 text-center text-gray-500 text-sm font-semibold uppercase">
        {StageName}
      </div>
      {status_button[Payment_Status__c]}
    </>
  );
}
