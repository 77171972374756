import moment from "moment";
import PaymentOptions from "./payment_icons";
import RSCLogo from "../rsc-logo-white.png";
export default function BottomFooter() {
  const menu_buttons = [
    {
      label: "Search",
      route: "https://www.rschealth.com/search",
    },
    {
      label: "Terms of Service",
      route: "https://www.rschealth.com/pages/terms-of-service",
    },
    {
      label: "Privacy Policy",
      route: "https://www.rschealth.com/pages/your-privacy-is-protected",
    },
    {
      label: "Cancellation Policy",
      route: "https://www.rschealth.com/pages/cancellation-policy",
    },
    {
      label: "Contact Us",
      route: "https://www.rschealth.com/pages/contact-us",
    },
  ];
  function FooterLeft() {
    return (
      <div className="text-[#fffc] text-[.8125rem] leading-[1.15] grow">
        <nav>
          <ul>
            {menu_buttons.map((btn: any) => {
              const { label, route } = btn;
              return (
                <li
                  key={route}
                  className="inline-block pr-[11px] mb-[6px] mr-[10px] border-r border-white/50 last:border-r-0"
                >
                  <a href={route}>{label}</a>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    );
  }

  function Copyright() {
    return (
      <div className="text-center">
        <a className="leading-none" href={"https://rschealth.com"}>
          <img
            src={RSCLogo}
            alt="RSC Health Logo"
            className="w-[150px] mx-auto pointer-events-auto"
          />
        </a>
        <p className="mt-[2px]">
          All Rights Reserved
          <br />
          Copyright © {moment().format("YYYY")}
        </p>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6 md:gap-16 text-[#fffc] text-[.8125rem] leading-[1.15] ">
      <div className="flex flex-col md:flex-row md:items-center gap-6">
        <FooterLeft />
        <div className="flex flex-col gap-4">
          <PaymentOptions />
        </div>
      </div>
      <Copyright />
    </div>
  );
}
