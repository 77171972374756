import { useUI } from "../../../context/UIContext";

export default function DesktopMenu() {
  const { menu_buttons, setLocation } = useUI();
  return (
    <div className="flex flex-row items-center justify-end gap-x-8 gap-y-3 text-[16px] grow">
      {menu_buttons.map((btn: any, idx: number) => {
        const { label, link, route } = btn;
        return (
          <div key={idx}>
            {link ? (
              <button onClick={() => setLocation(link)}>{label}</button>
            ) : (
              <a href={route}>{label}</a>
            )}
          </div>
        );
      })}
    </div>
  );
}
