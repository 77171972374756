import { useRegistration } from "./RegistrationContext";

export default function LoginHolder({
  children,
  header,
  loading_text,
}: {
  children: any;
  header: string;
  loading_text?: string;
}) {
  // const { alert: login_alert } = useUser();
  const { alert: registration_alert, loading } = useRegistration();
  const alert = false || registration_alert;

  return (
    <>
      <div className="p-4 text-lg font-bold text-white shadow bg-rsc-light-blue">
        {loading && loading_text ? loading_text : header}
      </div>
      <div className="flex flex-col gap-4 p-8">
        {alert && (
          <div
            className={`border ${
              alert.success
                ? "border-green-400 bg-green-100 text-green-800"
                : "border-rsc-red bg-red-200 text-red-800"
            } p-3 font-semibold text-xs rounded-md`}
          >
            {alert.message}
          </div>
        )}
        {children}
      </div>
    </>
  );
}
