import { useUI } from "../../../context/UIContext";
import IonIcon from "@reacticons/ionicons";
import { useUser } from "../../../context/UserContext";

export default function SiteMenu() {
  const { menu_buttons, setDrawerOpen, setLocation } = useUI();
  const { user, logoutUser } = useUser();

  return (
    <div className="md:hidden flex flex-col items-end gap-6 text-white font-semibold h-full">
      <div className="mb-2 font-medium">
        <button onClick={() => setDrawerOpen(false)}>
          <IonIcon name="close-sharp" size="large" />
        </button>
      </div>
      <div className="flex flex-col items-end gap-6 grow">
        {menu_buttons.map((item: any) => {
          const { label, link, route } = item;
          return (
            <div
              className={`font-medium ${
                false ? "text-amber-400" : "text-white"
              }`}
              key={label}
            >
              {link ? (
                <button
                  className="uppercase"
                  onClick={() => {
                    setLocation(link);
                    setDrawerOpen(false);
                  }}
                >
                  {label}
                </button>
              ) : (
                <a href={route}>
                  <button className="uppercase">{label}</button>
                </a>
              )}
            </div>
          );
        })}
      </div>
      <div>
        {user ? (
          <button
            className="text-rsc-yellow uppercase"
            onClick={() => {
              logoutUser();
              setDrawerOpen(false);
            }}
          >
            Log Out{" "}
            <IonIcon
              name="exit"
              className="ml-2"
              style={{ transform: "translateY(2px)" }}
            />
          </button>
        ) : (
          <button
            className="uppercase"
            onClick={() => {
              setLocation("/login");
              setDrawerOpen(false);
            }}
          >
            <IonIcon
              name="lock-closed"
              className="mr-2"
              style={{ transform: "translateY(2px)" }}
            />{" "}
            Log In
          </button>
        )}
      </div>
    </div>
  );
}
