import { Fragment } from "react";
import { groupBy, includes, kebabCase, keys, sortBy, toLower } from "lodash";
import { useDashboard } from "../../../../../DashboardContext";
import NestedTest from "../nested";
import { useUI } from "../../../../../../context/UIContext";

export default function TestResultsList() {
  const { setLocation } = useUI();
  const { order, restricted_results, results } = useDashboard();

  const grouped_results = groupBy(
    results,
    (item: any) => item.Test_Name__c || item.Sub_Name__c
  );
  const tests = keys(grouped_results);
  function TestResult({ result }: { result?: any }) {
    if (!result) return null;
    const { Result__c, Sub_Name__c, Test_Name__c, Reference_Range__c } = result;
    return (
      <div
        className="flex flex-col md:flex-row gap-2 md:gap-0 md:items-center border border-gray-200 shadow rounded-md p-3"
        onClick={() => {
          setLocation(`/dashboard/my-orders/tests/${kebabCase(Sub_Name__c)}`);
        }}
        style={{ cursor: "pointer" }}
      >
        <div className="flex flex-col grow text-gray-500">
          <div className=" font-semibold">{Sub_Name__c}</div>
          {false && <div className="text-xs">{Test_Name__c}</div>}
        </div>
        <div className="flex flex-col justify-end md:justify-center items-end uppercase italic text-gray-500 text-sm">
          <div>
            {(
              <span
                className={`font-semibold ${
                  Result__c === "Negative" ? "text-green-600" : "text-red-600"
                }`}
              >
                {Result__c}
              </span>
            ) || <span className="text-gray-300">pending</span>}
          </div>
          {false && Reference_Range__c && (
            <div className="text-xs">{Reference_Range__c}</div>
          )}
        </div>
      </div>
    );
  }

  function NoResult({ result }: { result?: any }) {
    const { Sub_Name__c, Test_Name__c } = result;
    return (
      <div
        className="flex flex-col md:flex-row gap-2 md:gap-0 md:items-center border border-gray-200 shadow rounded-md p-3"
        onClick={() => {
          setLocation(`/dashboard/my-orders/tests/${kebabCase(Sub_Name__c)}`);
        }}
        style={{ cursor: "pointer" }}
      >
        <div className="flex flex-col grow text-gray-500">
          <div className=" font-semibold">{Test_Name__c || Sub_Name__c}</div>
        </div>
        <div className="flex flex-col justify-end md:justify-center items-end uppercase italic text-gray-500 text-sm">
          <div>
            <span className="text-gray-300">pending</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {sortBy(tests, function (r: any) {
        return toLower(r.Sub_Name__c);
      }).map((test: string) => {
        const test_set = grouped_results[test];
        if (test_set.length === 1) {
          const test = test_set[0];
          const { Id, Result__c, Sub_Name__c } = test;
          const hold_result =
            includes(restricted_results, Sub_Name__c) &&
            order.Status !== "Results Delivered" &&
            Result__c === "Positive";
          return (
            <Fragment key={Id}>
              {hold_result ? (
                <NoResult result={test} />
              ) : (
                <TestResult result={test} />
              )}
            </Fragment>
          );
        } else {
          return <NestedTest key={test} name={test} tests={test_set} />;
        }
      })}
    </>
  );
}
