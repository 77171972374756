import Settings from "./settings";
import ListView from "./list";
import MapView from "./map";
import { usePharmacy } from "./PharmacyContext";

function FindTestCenterMobile() {
  const { view } = usePharmacy();

  return (
    <div className="p-3 bg-slate-50">
      <Settings />
      <div className="overflow-y-auto" style={{ maxHeight: "400px" }}>
        {view === "map" ? <MapView /> : <ListView />}
      </div>
    </div>
  );
}

export default FindTestCenterMobile;
