export default function EnterPharmacy({
  enterPharmacy,
}: {
  enterPharmacy: any;
}) {
  return (
    <>
      <div className="text-sm text-slate-700">
        To provide your pharmacy, enter its name and phone number below and
        click the submit button or{" "}
        <button
          className="font-semibold text-rsc-light-blue underline"
          onClick={() => enterPharmacy(false)}
        >
          Click Here
        </button>{" "}
        to enter a zip code and locate a pharmacy.
      </div>

      <div className="flex flex-col">
        <div className="border-b border-rsc-light-blue/30 pb-2 mb-2 text-rsc-light-blue text-2xl">
          Provide Pharmacy
        </div>
        <div className="mb-4">
          <label htmlFor="name">Name:</label>
          <input
            className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
            id="name"
            placeholder="Enter pharmacy name"
            // value={email}
            // onChange={(e: any) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="name">Address:</label>
          <textarea
            className="rounded-lg border  border-slate-400 w-full shadow-inner px-2"
            id="name"
            rows={4}
            placeholder="Enter pharmacy address"
            // value={email}
            // onChange={(e: any) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="phone_number">Phone Number:</label>
          <input
            className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
            id="phone_number"
            placeholder="Enter pharmacy phone number"
            // value={phone_number}
            // onChange={(e: any) => setPhoneNumber(e.target.value)}
          />
        </div>
        <div className="text-center">
          <button
            // disabled={!isReady}
            // onClick={() => setPopUp(null)}
            className={`${
              true ? "bg-rsc-blue text-white" : "bg-gray-200 text-gray-500"
            } my-6 font-semibold text-center p-3 rounded-md shadow-md`}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
}
