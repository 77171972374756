import IonIcon from "@reacticons/ionicons";
import { concat, find, sum, map } from "lodash";
import { useState } from "react";
import { useCart } from "../../../context/CartContext";

export default function OrderSummaryItem({ item }: { item: any }) {
  const [open_delete, setOpenDelete] = useState<boolean>(false);
  const { individual_products, package_products, removeItem } = useCart();
  const all_products = concat(individual_products, package_products);
  const { items, name, price } = item;
  const toggleOpen = () => {
    const flip = !open_delete;
    setOpenDelete(flip);
  };

  return (
    <tr className="border-t border-dotted border-slate-300 first:border-t-0 text-sm hover:bg-slate-200">
      <td>
        <div className="flex flex-col">
          <div className="flex flex-row p-1">
            <div
              className="flex flex-row gap-2 items-center grow cursor-pointer"
              onClick={toggleOpen}
            >
              <div>
                {items && (
                  <>
                    {open_delete ? (
                      <IonIcon name="caret-up" className="translate-y-[2px]" />
                    ) : (
                      <IonIcon
                        name="caret-down"
                        className="translate-y-[2px]"
                      />
                    )}{" "}
                  </>
                )}
                {name}
              </div>
              <div
                className="cursor-pointer"
                onClick={() => removeItem(item.sku)}
              >
                {open_delete && (
                  <span className="text-red-600 font-bold ml-2 uppercase text-xs">
                    Remove
                  </span>
                )}
              </div>
            </div>
            <div>${price.toFixed(2)}</div>
          </div>
          {open_delete && items && (
            <div className="flex flex-col pb-2 px-2 text-xs">
              <div className="flex flex-row font-bold">
                <div className="grow">Test</div>
                <div>Value</div>
              </div>
              {items.map((item: string) => {
                const { name, price } = find(all_products, ["sku", item]);
                if (item === "5124988472") {
                  const cprice = sum(
                    map(["512", "498", "8472"], function (i: string) {
                      return find(all_products, ["sku", i])?.price;
                    })
                  );
                  return (
                    <div key={item} className="flex flex-row gap-2">
                      <div className="grow">{name}</div>
                      <div>${cprice?.toFixed(2)}</div>
                    </div>
                  );
                }
                return (
                  <div key={item} className="flex flex-row gap-2">
                    <div className="grow">{name}</div>
                    <div>${price?.toFixed(2)}</div>
                  </div>
                );
              })}
              <div className="flex flex-row font-bold">
                <div className="grow">Total</div>
                <div>
                  $
                  {sum(
                    map(items, function (item: string) {
                      return find(all_products, ["sku", item])?.price || 0;
                    })
                  )?.toFixed(2)}
                </div>
              </div>
            </div>
          )}
        </div>
      </td>
    </tr>
  );
}
