import { useState } from "react";
import { filter, head } from "lodash";
import IonIcon from "@reacticons/ionicons";
import LabMap from "./lab_map";
import LabHours from "./lab_hours";
import LabAddress from "./lab_address";
import OrderSubmitted from "./order_submitted";
import DetailContainer from "../../../DetailContainer";
import { useDashboard } from "../../../../DashboardContext";

export default function LabOrderDetails() {
  const { laboratory } = useDashboard();

  return (
    <DetailContainer title="Lab Details">
      {!laboratory ? <LabSkeleton /> : <LabData />}
    </DetailContainer>
  );
}
function LabData() {
  const { docs, laboratory, order } = useDashboard();
  const doc = head(filter(docs, ["Title", "Order Submission"]))?.VersionData;

  const [showMap, setShowMap] = useState<boolean>(false);
  const {
    Lab_Directions_Link__c: directions_link,
    Lab_Location__Latitude__s: lat,
    Lab_Location__Longitude__s: lng,
  } = laboratory;
  function toggleView() {
    const flip = !showMap;
    setShowMap(flip);
  }

  return (
    <div className="flex flex-col gap-8">
      <>
        {(order as any)?.Status === "Order Submitted" && (
          <OrderSubmitted doc={doc} laboratory={laboratory} />
        )}
      </>
      <div className="flex flex-col md:flex-row gap-4 md:gap-16">
        <LabAddress laboratory={laboratory} />
        <LabHours laboratory={laboratory} />
      </div>
      <div className="flex flex-row">
        <div className="flex flex-col grow text-blue-600 font-semibold gap-2">
          <div>
            <a href={directions_link} target="_blank" rel="noreferrer">
              <IonIcon
                name="globe-sharp"
                style={{ transform: "translateY(2px)" }}
              />{" "}
              Directions
            </a>
          </div>
          <div>
            <button onClick={() => toggleView()}>
              <IonIcon
                name="navigate-circle-sharp"
                style={{ transform: "translateY(2px)" }}
              />{" "}
              View Map
            </button>
          </div>
        </div>
        <div className="flex flex-col text-blue-600 font-semibold text-right gap-2">
          {false && <div>Change Lab</div>}
          <div>Text Me Lab Details</div>
        </div>
      </div>
      {showMap && <LabMap center={{ lat, lng }} />}
    </div>
  );
}

function LabSkeleton() {
  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-2">
        <div className="bg-gray-100 h-4 w-10/12 rounded-md" />
        <div className="bg-gray-100 h-4 w-11/12 rounded-md" />
        <div className="bg-gray-100 h-4 w-9/12 rounded-md" />
      </div>
      <div className="flex flex-row justify-center my-8 w-full">
        <div className="h-10 w-[250px] bg-gray-100 rounded-md"></div>
      </div>
      <div className="flex flex-col gap-2 pb-4 mb-4 border-b border-rsc-light-blue/10">
        <div className="bg-gray-100 h-6 max-w-[220px] rounded-md" />
        <div className="bg-gray-100 h-6 max-w-[240px] rounded-md" />
        <div className="bg-gray-100 h-6 max-w-[170px] rounded-md" />
      </div>
      <div className="flex flex-col gap-2">
        <div className="bg-gray-100 h-5 max-w-[180px] rounded-md" />
        <div className="bg-gray-100 h-5 max-w-[160px] rounded-md" />
        <div className="bg-gray-100 h-5 max-w-[170px] rounded-md" />
      </div>
    </div>
  );
}
