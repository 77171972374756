import { useState } from "react";
import Section from "../form_section";

export default function MedicalHistory({
  person,
  handlePersonChange,
}: {
  person: any;
  handlePersonChange: any;
}) {
  const {
    allergies: allergies_,
    medications: medications_,
    no_allergies,
    no_medications,
  } = person;

  const [allergies, setAllergies] = useState<string>(allergies_);
  const [medications, setMedications] = useState<string>(medications_);

  function textareaClassName(value: boolean) {
    const className = `rounded-lg border border-rsc-light-blue/20 ${
      value && "bg-rsc-light-blue/10"
    } w-full shadow-inner px-2`;
    return className;
  }

  return (
    <Section>
      <div className="grid md:grid-cols-2 gap-4">
        <div className="">
          <label htmlFor="allergies">Drug Allergies:</label>
          <textarea
            className={textareaClassName(no_allergies)}
            id="allergies"
            rows={3}
            disabled={no_allergies}
            placeholder={
              no_allergies
                ? "No known drug allergies."
                : "List drug allergies..."
            }
            value={no_allergies ? "No known drug allergies." : allergies}
            onBlur={() => handlePersonChange("allergies", allergies)}
            onChange={(e: any) => setAllergies(e.target.value)}
          />
          <div className="">
            <input
              type="checkbox"
              className="ml-1 mr-2"
              checked={no_allergies}
              onChange={(e: any) => {
                handlePersonChange("no_allergies", e.target.checked);
                setAllergies("");
              }}
            />
            <label htmlFor="no_allergies">No known drug allergies.</label>
          </div>
        </div>
        <div className="">
          <label htmlFor="medications">Current Medications / Dosage:</label>
          <textarea
            className={textareaClassName(no_medications)}
            id="medications"
            disabled={no_medications}
            placeholder={
              no_medications
                ? "No medications."
                : "List current medications and doses..."
            }
            rows={3}
            value={no_medications ? "No medications." : medications}
            onBlur={() => handlePersonChange("medications", medications)}
            onChange={(e: any) => setMedications(e.target.value)}
          />
          <div className="">
            <input
              type="checkbox"
              className="ml-1 mr-2"
              checked={no_medications}
              onChange={(e: any) => {
                handlePersonChange("no_medications", e.target.checked);
                setMedications("");
              }}
            />
            <label htmlFor="no_medications">No medications.</label>
          </div>
        </div>
      </div>
    </Section>
  );
}
