import { useState } from "react";
import axios from "axios";
import IonIcon from "@reacticons/ionicons";
import { formatPhone } from "../../../../../utils/functions";
import { getPDF } from "../../../../../utils/requests";
import { useUI } from "../../../../../context/UIContext";

export default function PopUp({
  invoice,
  laboratory,
  subject,
}: {
  invoice: any;
  laboratory?: any;
  subject: string;
}) {
  const { is_development, setPopUp } = useUI();

  const [sending_fax, setSendingFax] = useState<boolean>(false);
  const [fax_sent, setFaxSent] = useState<boolean>(false);

  const pdf = invoice;

  function LabInformation({ laboratory }: { laboratory: any }) {
    if (!laboratory) return null;
    const { Fax, Name } = laboratory;
    return (
      <div className="text-sm max-w-xs text-center w-full">
        <span className="font-bold">{Name}</span>
        <br />
        {Fax}
      </div>
    );
  }

  function FaxLabButton({ laboratory }: { laboratory: any }) {
    if (!laboratory) return null;
    const { Fax, Name } = laboratory;
    const fax = formatPhone(Fax || "");

    return (
      <button
        className="w-56 bg-sky-600 py-2 text-white rounded-md"
        disabled={!pdf || (!fax && !is_development) || sending_fax}
        onClick={async () => {
          if (sending_fax) return;
          console.log("SENDING FAX", is_development, pdf);
          setSendingFax(true);
          try {
            const response = await axios.post("/api/salesforce/fax_pdf", {
              contact: Name,
              faxNumber: is_development ? "+16783981998" : (fax as string),
              file: pdf,
              subject,
            });
            !!response?.data?.id && setFaxSent(true);
          } catch (err: any) {
            console.log("ERROR SENDING FAX", err);
          } finally {
            setSendingFax(false);
          }
        }}
      >
        {sending_fax ? (
          "Sending fax..."
        ) : (
          <>
            <IonIcon
              name="business"
              style={{ marginRight: 12, transform: "translateY(2px)" }}
            />{" "}
            Send Fax to Lab
          </>
        )}
      </button>
    );
  }
  console.log("popup", { laboratory });
  return (
    <>
      <div className="p-4 text-lg text-gray-600 bg-gray-100 border-b border-gray-200 flex flex-row">
        <div className="grow">Lab Forms</div>
        <button onClick={() => setPopUp(null)}>
          <IonIcon
            name="close-circle"
            style={{ fontSize: "1.4rem", transform: "translateY(2px)" }}
          />
        </button>
      </div>
      <div className="flex flex-col items-center gap-6 p-6 text-center">
        <div className="max-w-lg">
          Please sign in. If your lab forms are not available at the lab. You
          can either print them out, or fax them electronically now.
        </div>
        <LabInformation laboratory={laboratory} />
        <div className="flex flex-col md:flex-row gap-8 mb-2 items-center justify-center">
          <button
            disabled={!pdf}
            className="w-56 bg-sky-600 py-2 text-white rounded-md"
            onClick={() => getPDF(pdf)}
          >
            <IonIcon
              name="print"
              style={{ marginRight: 12, transform: "translateY(2px)" }}
            />{" "}
            Print Lab Form
          </button>
          {fax_sent ? (
            <>
              <button
                className="w-56 bg-green-600 py-2 text-white rounded-md"
                onClick={() => setFaxSent(false)}
              >
                <IonIcon
                  name="checkmark-done-circle"
                  style={{ marginRight: 12, transform: "translateY(2px)" }}
                />{" "}
                Fax Sent
              </button>
            </>
          ) : (
            <FaxLabButton laboratory={laboratory} />
          )}
        </div>
      </div>
    </>
  );
}
