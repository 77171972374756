import IonIcon from "@reacticons/ionicons";

import { useUI } from "../../../../../../../context/UIContext";

export default function ActionsLabResults({
  opportunity,
}: {
  opportunity: any;
}) {
  const { setLocation } = useUI();
  const { Id, Amount, Balance_Amount__c, Total_Price_Override__c } =
    opportunity;

  function PayButton() {
    return (
      <div
        className="w-64 p-3 border border-green-600 rounded-md shadow-md cursor-pointer"
        onClick={() => setLocation(`/dashboard/my-orders/orders/${Id}/payment`)}
      >
        <div className="flex flex-row items-center text-green-600 text-xs text-left">
          <div className="grow text-base">
            <IonIcon name="card" />
          </div>
          <div className="font-semibold uppercase">Make a Payment</div>
        </div>{" "}
      </div>
    );
  }

  const price = Total_Price_Override__c ? (
    <>
      <span className="text-xl line-through font-thin text-gray-400">
        ${Amount.toFixed(2)}
      </span>{" "}
      ${Total_Price_Override__c.toFixed(2)}
    </>
  ) : (
    <>${Amount.toFixed(2)}</>
  );

  return (
    <div className="flex flex-col items-end gap-4">
      <div className="flex flex-col items-end">
        <div className="text-2xl font-semibold">{price}</div>
        <div className="text-2xl">
          Balance due:{" "}
          <span className="text-3xl font-semibold text-green-600">
            ${Balance_Amount__c.toFixed(2)}
          </span>
        </div>
      </div>

      <div className="flex flex-row justify-end text-right">
        <PayButton />
      </div>
    </div>
  );
}
