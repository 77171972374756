import mongoose from "mongoose";
import IonIcon from "@reacticons/ionicons";
import { useUI } from "../../../../context/UIContext";
import { useConsultation } from "../../context/ConsultationContext";
//import { usePartnerConsultation } from "../../context/PartnerConsultationContext";
import { Person } from "../../../../utils/types";
import OrderCard from "../order_card";

export default function Partners() {
  const { setLocation } = useUI();
  const { partners, removePartner } = useConsultation();
  //const { default_partner, loadPartner } = usePartnerConsultation();
  return (
    <OrderCard id={"partners"} title={"Partners"}>
      <div className="p-3 flex flex-col gap-6 bg-slate-50">
        <div className="text-rsc-blue text-xl font-bold">
          Sexual partner(s) you wish to get treatment for:
        </div>
        {partners.length > 0 && (
          <div className="flex flex-col my-4">
            {partners.map((partner: Person, idx: number) => {
              const { _id, given_name, family_name } = partner;
              return (
                <div
                  className={`flex flex-row gap-6 px-8 py-4 items-center ${
                    idx % 2 === 0 && "bg-gray-100"
                  }`}
                  key={idx}
                >
                  <div className="grow">
                    {given_name} {family_name}
                  </div>
                  <div>
                    <button
                      className="bg-gray-300 px-4 py-2 text-xs text-rsc-blue rounded-md shadow-sm"
                      onClick={() => {
                        //  loadPartner(partner);
                        setLocation(`/consultation/${_id}`);
                      }}
                    >
                      <IonIcon
                        name="create"
                        style={{
                          marginRight: 6,
                          transform: "translateY(2px)",
                        }}
                      />{" "}
                      Edit
                    </button>
                  </div>
                  <div>
                    <button
                      className="bg-rsc-red px-4 py-2 text-xs text-white rounded-md shadow-sm"
                      onClick={() => removePartner(_id as string)}
                    >
                      <IonIcon
                        name="trash"
                        style={{
                          marginRight: 6,
                          transform: "translateY(2px)",
                        }}
                      />{" "}
                      Remove
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="text-center">
          <button
            className="w-64 py-2 bg-green-700 text-white rounded-md shadow-sm hover:shadow-lg"
            onClick={() => {
              //loadPartner(default_partner);
              const _id = new mongoose.Types.ObjectId().toString();
              setLocation(`/consultation/${_id}`);
            }}
          >
            <IonIcon
              name="add"
              style={{ marginRight: 12, transform: "translateY(2px)" }}
            />{" "}
            Add Partner
          </button>
        </div>
      </div>
    </OrderCard>
  );
}
