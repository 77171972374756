import { Fragment, useEffect } from "react";
import MyDashboardApp from ".";
import DashboardContextProvider from "./DashboardContext";
import Can from "../utils/auth/Can";
import { useUI } from "../context/UIContext";
import { useDashboard } from "./DashboardContext";
import NotAuthorized from "../NotAuthorized";
import { useUser } from "../context/UserContext";

export default function MainDashboard({ children }: { children?: any }) {
  const { setLocation } = useUI();
  const { user } = useUser();
  useEffect(() => {
    !children && setLocation("/dashboard/my-orders");
  });

  return (
    <Can
      role={user?.roles}
      yes={() => (
        <DashboardContextProvider>
          <MyDashboardApp>{children}</MyDashboardApp>
        </DashboardContextProvider>
      )}
      no={() => (
        <div className="flex flex-col items-center justify-center min-h-[350px]">
          <NotAuthorized />
        </div>
      )}
    />
  );
}

export function PanelHolder({
  children,
  header_menu,
  header_text,
}: {
  children: any;
  header_menu?: any;
  header_text: string;
}) {
  return (
    <div className="flex flex-col justify-start p-5 gap-4 bg-rsc-light-blue/10 md:basis-1/3">
      <div>
        <h3 className="font-semibold text-rsc-light-blue text-center uppercase">
          {header_text}
        </h3>
      </div>
      {header_menu}
      {children}
    </div>
  );
}
export function DashboardView({
  context: OptionalContext = Fragment,
  details,
  panel,
}: {
  context?: any;
  details: any;
  panel: any;
}) {
  const { is_mobile } = useUI();
  const { viewing_opportunity, viewing_test } = useDashboard();

  function MobileBlock() {
    return (
      <>
        {!!(viewing_opportunity || viewing_test) ? (
          <>{details}</>
        ) : (
          <>{panel}</>
        )}
      </>
    );
  }

  function DesktopBlock() {
    return (
      <>
        <>{panel}</>
        <>{details}</>
      </>
    );
  }
  return (
    <OptionalContext>
      {is_mobile ? <MobileBlock /> : <DesktopBlock />}
    </OptionalContext>
  );
}

// export function LayoutView() {
//   const { location } = useUI();
//   const loc = split(location, "/");
//   const layout_view: { [key: string]: any } = {
//     orders: {
//       orders: <OrderDetails />,
//       tests: <TestDetails />,
//     },
//   };
//   return <>{layout_view[loc[2]]}</>;
// }
