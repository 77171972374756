import { find } from "lodash";
import DeleteUser from "./delete";
import Profile from "./Information";
import EditInformation from "./Information/edit";
import EditRole from "./change_role";
import { useUserEdit } from "./UserEditContext";
import Can from "../utils/auth/Can";
import { useUser } from "../context/UserContext";
import { useUI } from "../context/UIContext";
export default function AdminUserPage() {
  const { setLocation } = useUI();
  const { user: logged_in } = useUser();
  const { deleted, user, view } = useUserEdit();

  const user_menu: any[] = [
    {
      view: "profile",
      label: "Profile",
      className: "text-rsc-light-blue",
      component: <Profile />,
    },
    {
      view: "information",
      label: "Edit Information",
      className: "text-rsc-light-blue",
      component: <EditInformation />,
    },
    {
      view: "role",
      label: "Change Role",
      className: "text-rsc-light-blue",
      component: <EditRole />,
    },
    {
      view: "delete",
      label: "Delete User",
      perform: "delete:user",
      className: "text-red-600",
      component: <DeleteUser />,
    },
  ];

  function UserMenu() {
    if (!user || deleted) return null;
    const { sub } = user;
    return (
      <div className="md:min-w-[200px] border-r border-rsc-light-blue/30  min-h-[550px]">
        <div className="flex flex-col pr-2 pb-4">
          {user_menu.map((item: any) => {
            const { className, label, perform, view: id } = item;
            const isActive = id === view;
            return (
              <Can
                key={id}
                role={logged_in?.roles}
                perform={perform}
                yes={() => (
                  <div
                    className={`p-2 ${className} ${
                      isActive ? "bg-rsc-light-blue/10 font-bold" : ""
                    } hover:bg-rsc-light-blue/10 cursor-pointer`}
                    onClick={() => {
                      if (id === "profile") return setLocation(`/user/${sub}`);
                      setLocation(`/user/${sub}/${id}`);
                    }}
                  >
                    {label}
                  </div>
                )}
              />
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 md:gap-8 w-full p-6 bg-slate-100 min-h-full">
      <div className="flex flex-row gap-8">
        <UserMenu />
        {find(user_menu, { view })?.component}
      </div>
    </div>
  );
}
