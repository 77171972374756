import DetailContainer from "../../../../DetailContainer";
import { useDashboard } from "../../../../../DashboardContext";
import PreLoader from "../../../../../../PreLoader";

export default function TestingDetailsNoResults() {
  const { products } = useDashboard();

  if (!products)
    return (
      <div className="max-h-[400px]">
        <PreLoader text="Loading texting..." />
      </div>
    );
  return (
    <DetailContainer title="Testing Ordered">
      <div className="flex flex-col gap-4">
        {products.map((test: any) => {
          const { Id, Name, Description, TotalPrice } = test;

          return (
            <div
              key={Id}
              className="flex flex-col md:flex-row gap-2 md:gap-0 md:items-center border border-gray-200 shadow-md rounded-md p-3"
            >
              <div className="flex flex-col grow text-gray-500">
                <div className="font-semibold">{Name}</div>
                {Description && <div className="text-sm">{Description}</div>}
              </div>
              {false && (
                <div className="flex flex-col justify-end md:justify-center items-end uppercase text-sm">
                  <div>
                    <span className="text-gray-600">
                      ${TotalPrice?.toFixed(2)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </DetailContainer>
  );
}
