import ActionsLabResults from "./Actions";
import DetailContainer from "../../../../DetailContainer";
import InformationLabResults from "./Information";
import { useDashboard } from "../../../../../DashboardContext";

export default function OverviewPayLater() {
  const { opportunity } = useDashboard();
  return (
    <DetailContainer title={"Order Information"}>
      <div className="flex flex-col gap-8 md:gap-0 md:flex-row">
        <InformationLabResults opportunity={opportunity} />
        <ActionsLabResults opportunity={opportunity} />
      </div>
    </DetailContainer>
  );
}
