import InformationCell from "../TD_Cell";
import { formatPhoneDisplay } from "../../utils/functions";
export default function LaboratoryInformation({ lab }: { lab: any }) {
  const color = "rgb(17 102 155 / 0.8)";
  const backgroundColor = "rgb(17 102 155 / 0.025)";
  const padding = "6px 16px";
  if (!lab) return null;
  const { address, fax, state, city, name, url, zip, zip_code } = lab;
  return (
    <table
      style={{
        backgroundColor,
        fontSize: "11pt",
        width: "100%",
      }}
    >
      <tbody>
        <tr>
          <td
            style={{
              color,
              fontSize: 24,
              fontWeight: 600,

              padding,
            }}
          >
            Laboratory
          </td>
        </tr>
        <tr>
          <td>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ width: 225, verticalAlign: "top" }}>
                    <InformationCell
                      value={
                        <>
                          <span
                            style={{ color, fontSize: 16, fontWeight: 600 }}
                          >
                            {name}
                          </span>
                          <br />
                          {address}
                          <br />
                          {city}, {state} {zip || zip_code}
                        </>
                      }
                    />
                  </td>
                  <td style={{ width: 150, verticalAlign: "top" }}>
                    <InformationCell
                      label="Fax"
                      value={formatPhoneDisplay(fax)}
                    />
                  </td>
                  <td style={{ verticalAlign: "top" }}>
                    <InformationCell
                      label="Directions"
                      link="link"
                      value={url}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
