import { useState } from "react";
import { find } from "lodash";
import { useCart } from "../../../../context/CartContext";
import { addPackage } from "../../../../utils/functions";

export default function ShopCardMobile({
  active,
  idx,
  pkg,
}: {
  active: boolean;
  idx: number;
  pkg: any;
}) {
  const { individual_products, shopping_cart, removeItem, setShoppingCart } =
    useCart();
  const [isHovered, setIsHovered] = useState(false);

  const { items, name, price, sku } = pkg;

  return (
    <div
      className={`flex flex-col gap-4 w-full ${
        active ? "bg-rsc-light-blue text-white p-4 rounded-lg" : "p-2"
      }`}
      key={idx}
    >
      <div
        className={`flex flex-col`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="font-bold text-[1.35rem]">{name}</div>
        <div className="text-[0.9375rem]">
          {items.map((item: string, idx: number) => {
            const pkg = find(individual_products, ["sku", item]);
            if (!pkg) return null;
            return (
              <span key={idx}>
                {pkg.name}
                {idx + 1 !== items.length && ", "}
              </span>
            );
          })}
        </div>
      </div>
      <div className="flex flex-row items-center justify-center">
        <div className="text-center font-bold text-3xl border-r border-rsc-light-blue pr-2 mr-2">
          ${price}
        </div>
        <div className="text-center">
          {active ? (
            <button
              className="uppercase hover:underline"
              onClick={() => removeItem(sku)}
            >
              {isHovered ? "Remove" : "Selected"}
            </button>
          ) : (
            <button
              className="bg-red-600 font-bold uppercase text-white px-4 py-1 shadow-lg rounded-full"
              onClick={() =>
                addPackage({ pkg, shopping_cart, setShoppingCart })
              }
            >
              Select
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
