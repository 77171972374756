import IonIcon from "@reacticons/ionicons";
import { GET_DOCTOR } from "../../../utils/queries";
import { usePayment } from "../../../components/Payment/PaymentInfoContext";
import { useCart } from "../../../context/CartContext";
import { useOrder } from "../OrderContext";
import PreLoader from "../../../PreLoader";
import { useUI } from "../../../context/UIContext";
import { useUser } from "../../../context/UserContext";
export default function PayLater() {
  const { client, us_states } = useUI();
  const { user } = useUser();
  const { errors, order, pay_later_available, view_errors, setViewErrors } =
    useOrder();
  const { reorder_discount, shopping_cart, totals } = useCart();
  const { loading, pay_later, processCreditCard, setLoading } = usePayment();
  const pay_later_list = pay_later_available
    ? [
        <>You can get tested at the lab before you have to pay.</>,
        <>You can’t pay at the lab. Only your samples will be taken.</>,
        <>
          You can make payment after your visit to the lab by logging into your
          secure online account or by calling us at 1-866-872-1888.
        </>,
        <>
          Payment can be made using Credit Card, PayPal, Pre-Paid Gift Cards,
          HSA, or FSA.
        </>,
        <>Payment is required before results are released.</>,
      ]
    : [
        <>
          Finalize your order and have your paperwork prepared for testing or a
          panel tests.
        </>,
        <>
          You can make payment anytime by logging into your secure online
          account or by calling us at 1-866-872-1888.
        </>,
        <>Payment is required before giving samples at the lab.</>,
        <>
          Payment can be made using Credit Card, PayPal, Pre-Paid Gift Cards,
          HSA, or FSA.
        </>,
      ];

  return (
    <>
      {view_errors && (
        <div className="w-full">
          <div className="mb-2 font-semibold">
            Please correct the following information:
          </div>
          <div className="flex flex-col gap-2 mx-2">
            {errors.map((error: any, idx: number) => {
              const { id, validation } = error;
              return (
                <div
                  key={idx}
                  className="border border-rsc-red bg-rsc-red/30 rounded-md p-2 text-xs"
                >
                  <h3 className="text-sm font-semibold">{id}</h3>
                  <p>
                    {validation.map((message: string, idx: number) => {
                      return <span key={idx}>{message} </span>;
                    })}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {loading ? (
        <PreLoader text="Finalizing order..." />
      ) : (
        <>
          <div className="p-4 bg-slate-50">
            <button
              className="rounded rounded-lg p-4 bg-red-700 text-center text-white text-xl font-bold shadow-md w-full"
              onClick={async () => {
                try {
                  setLoading(true);
                  const { data } = await client.query({
                    query: GET_DOCTOR,
                    variables: {
                      state: us_states[order?.user?.address_state || "NY"],
                    },
                  });
                  await processCreditCard({
                    amount: totals.total_due,
                    data: {
                      oid: "",
                      doctor: data?.getDoctor,
                      mx: { shopping_cart, order },
                      payment_data: {
                        nonce: "pay_later",
                        pay_later,
                        pay_later_available,
                        pay_errors: [],
                      },
                      reorder_discount,
                      totals,
                      type: "order",
                      user_: user,
                    },
                    setViewErrors,
                  });
                } catch (err) {
                  console.log("ERROR PROCESSING ORDER", err);
                } finally {
                  setLoading(false);
                }
              }}
            >
              <div className="flex flex-row gap-8 items-center icon-text text-3xl">
                <div className="absolute">
                  <IonIcon name="lock-closed" />
                </div>
                <div className="text-center text-2xl md:text-3xl grow">
                  6.{" "}
                  {pay_later_available
                    ? "Get Tested, Pay Later"
                    : "Order Testing, Pay Later"}
                </div>
              </div>
            </button>
          </div>
          <div className="p-4 bg-slate-50">
            <h4 className="text-lg font-bold">How Does Pay Later Work?</h4>
            <ul className="py-3 list-disc list-inside">
              {pay_later_list.map((item: any, idx: number) => {
                return (
                  <li key={idx} className="pb-3">
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}
    </>
  );
}
