export default function IndividualTestItem({ test }: { test: any }) {
  const { name, price, cpt } = test;

  return (
    <tr>
      <td style={{ borderBottom: "1px solid rgb(0 0 0 / .05)", padding: 16 }}>
        <table style={{ fontSize: 12, width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ width: "50%" }}>
                <span style={{ fontWeight: 600 }}>{name}</span>
                <br />
                <span
                  style={{ color: "#888", fontSize: 9, fontStyle: "italic" }}
                >
                  {cpt}
                </span>
              </td>
              <td
                style={{
                  fontWeight: 600,
                  textAlign: "right",
                  verticalAlign: "top",
                }}
              >
                ${(price || 0).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
}
