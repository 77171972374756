import TopHeader from "./top_header";
import BottomHeader from "./bottom_header";
import Can from "../../utils/auth/Can";
import AdminHeader from "../Admin/Header";
import TopFooter from "./top_footer";
import MenuDrawer from "./drawer";
import BottomFooter from "./bottom_footer";
import { useUI } from "../../context/UIContext";
import { useUser } from "../../context/UserContext";
import "../../rapid_testing.css";

function SiteLayout({ children }: { children: any }) {
  const { user } = useUser();
  const { open } = useUI();

  return (
    <>
      <div className="flex flex-col  w-screen h-screen">
        <header className="sticky bg-white/90 top-0 shadow-lg z-50">
          <TopHeader />
          <BottomHeader />
          <Can
            perform="view:users"
            role={user?.roles}
            yes={() => <AdminHeader />}
          />
        </header>
        {open && (
          <div
            className="w-screen h-screen absolute top-0 bg-rsc-blue p-8 z-50"
            style={{
              opacity: 0.97,
              transform: open
                ? "translate3d(0vw, 0, 0)"
                : "translate3d(100vw, 0, 0)",
              transition: "transform .3s cubic-bezier(0, .52, 0, 1)",
            }}
          >
            <MenuDrawer />
          </div>
        )}
        <div className="grow mx-auto w-full z-0 bg-slate-50">
          {/* {(is_admin || isDevLoggedIn) && <AdminControls />} */}
          {children}
        </div>
        {false && <TopFooter />}
        <BottomFooter />
      </div>
    </>
  );
}

export default SiteLayout;
