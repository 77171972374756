import { useState } from "react";
import { pick } from "lodash";
import ContentHolder from "../content_holder";
import { useUserEdit } from "../UserEditContext";

export default function AccountEdit() {
  const { attributes, user, setEditing, updateUserAttributes } = useUserEdit();
  const [user_update, setUserUpdate] = useState<any>(user);

  function updateUser(field: string, value: any) {
    setUserUpdate({ ...user_update, [field]: value });
  }
  const input_display = [
    {
      id: "given_name",
      label: "First Name",
      form: (
        <input
          className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
          value={user_update?.given_name}
          onChange={(e: any) => updateUser("given_name", e.target.value)}
        />
      ),
    },
    {
      id: "middle_name",
      label: "Middle Name",
      form: (
        <input
          className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
          value={user_update?.middle_name}
          onChange={(e: any) => updateUser("middle_name", e.target.value)}
        />
      ),
    },
    {
      id: "family_name",
      label: "Last Name",
      form: (
        <input
          className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
          value={user_update?.family_name}
          onChange={(e: any) => updateUser("family_name", e.target.value)}
        />
      ),
    },
    {
      id: "email",
      label: "Email",
      form: (
        <input
          className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
          value={user_update?.email}
          onChange={(e: any) => updateUser("email", e.target.value)}
        />
      ),
    },
    {
      id: "phone_number",
      label: "Phone",
      form: (
        <input
          className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
          value={user_update?.phone_number}
          onChange={(e: any) => updateUser("phone_number", e.target.value)}
        />
      ),
    },
  ];
  return (
    <ContentHolder
      back={{ onClick: () => setEditing(false), label: "Cancel" }}
      forward={{
        onClick: () =>
          updateUserAttributes(
            user?.username,
            pick(user_update, attributes.information)
          ),
        label: "Save Changes",
      }}
    >
      <div className="flex flex-col text-lg gap-4">
        {input_display.map((item: any) => {
          const { id, label, form } = item;
          return (
            <div key={id}>
              <label htmlFor="html">{label}</label>
              {form}
            </div>
          );
        })}
      </div>
    </ContentHolder>
  );
}
