import { concat, find, map, sum } from "lodash";
import IonIcon from "@reacticons/ionicons";
import { useCart } from "../../../context/CartContext";
import { useUI } from "../../../context/UIContext";
import { addPackage } from "../../../utils/functions";
export default function Package({ sku }: { sku: string }) {
  const {
    individual_products,
    package_products,
    shopping_cart,
    setShoppingCart,
  } = useCart();
  const { setPopUp } = useUI();
  const all_products = concat(individual_products, package_products);

  const pkg = find(all_products, ["sku", sku]);
  const { items, name, price, whats_this } = pkg;

  const sum_items = sum(
    map(items, function (item: string) {
      return find(individual_products, ["sku", item])?.price;
    })
  );

  function PopUp() {
    return (
      <div className="flex flex-col">
        {" "}
        <div className="p-4 text-lg text-gray-600 bg-gray-100 border-b border-gray-200 flex flex-row">
          <div className="grow">{name}</div>
          <button onClick={() => setPopUp(null)}>
            <IonIcon
              name="close-circle"
              style={{ fontSize: "1.4rem", transform: "translateY(2px)" }}
            />
          </button>
        </div>
        <div className="p-4"> {whats_this}</div>
      </div>
    );
  }

  const value: { [key: string]: any } = {
    "P-22": <span className="drop-shadow-md">Free Wellness Panel</span>,
    "P-24": <span className="drop-shadow-md">$100 Savings</span>,
  };

  return (
    <div className="flex flex-col border border-slate-200 items-center bg-slate-50 text-rsc-blue font-bold text-center shadow-lg rounded-lg overflow-hidden min-w-[320px]">
      <div className="bg-rsc-yellow text-xl py text-white text-center w-full">
        {value[sku] || (
          <span className="drop-shadow-md">${sum_items} Value</span>
        )}
      </div>
      <div className="p-4 w-full">
        <p className="text-xl">
          <span className="text-sm font-semibold uppercase">Upgrade to</span>
          <br />
          {name}
        </p>
        <p className="text-rsc-light-blue text-2xl font-bold">
          ${price.toFixed(2)}
        </p>
        <div className="flex flex-row gap-3 mt-4 mb-2 w-full">
          <div
            className="bg-rsc-red py-2 text-xs text-white uppercase  grow rounded-full shadow-lg cursor-pointer"
            onClick={() => addPackage({ pkg, shopping_cart, setShoppingCart })}
          >
            Upgrade
          </div>
          <div
            className="border border-rsc-red py-2 text-xs text-rsc-red  uppercase grow rounded-full cursor-pointer"
            onClick={() =>
              !!whats_this &&
              setPopUp(
                <div
                  className="bg-white my-6 mx-auto w-full md:min-w-[440px] sm:max-w-md md:border sm:border-rsc-light-blue/10 sm:rounded-xl shadow-lg overflow-hidden"
                  onClick={e => e.stopPropagation()}
                >
                  <PopUp />
                </div>
              )
            }
          >
            What's This?
          </div>
        </div>
      </div>
    </div>
  );
}
