import moment from "moment";
import ContactSupport from "../../../../../../../components/ContactSupport";
import { useUI } from "../../../../../../../context/UIContext";

export default function InformationLabResults({ order }: { order: any }) {
  const { setPopUp } = useUI();
  if (!order) return null;

  const { CreatedDate, OrderNumber } = order;

  return (
    <div className="flex flex-col grow">
      <div className="text-rsc-light-blue">
        Order <span className="normal-case">#{OrderNumber}</span>
      </div>
      <div className="text-gray-500">{moment(CreatedDate).format("lll")}</div>
      <div className="mt-2 text-rsc-light-blue font-semibold">
        <button
          onClick={() =>
            setPopUp(
              <div
                className="bg-white my-6 mx-auto w-full md:min-w-[440px] sm:max-w-md md:border sm:border-rsc-light-blue/10 sm:rounded-xl shadow-lg overflow-hidden"
                onClick={e => e.stopPropagation()}
              >
                <ContactSupport />
              </div>
            )
          }
        >
          Contact Support
        </button>
      </div>
    </div>
  );
}
