import rules from "./rbac";
import { includes } from "lodash";

export const check = (role: string, perform: string, data?: any) => {
  const { static: static_permissions } = (rules as { [key: string]: any })[
    role
  ];

  if (includes(static_permissions, perform)) {
    return true;
  }

  // if (dynamicPermissions && dynamicPermissions.length > 0) {
  //   const dynamicCondition = _.map(dynamicPermissions, function (f: any) {
  //     return f(data);
  //   });

  //   return _.some(dynamicCondition);
  // }

  return false;
};

export default function Can({
  role,
  perform,
  data,
  yes = () => null,
  no = () => null,
}: {
  role?: string;
  perform?: string;
  data?: any;
  yes: () => any;
  no?: () => any;
}) {
  if (!role) return no();
  if (!perform) return yes();

  return check(role, perform, data) ? yes() : no();
}
