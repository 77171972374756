import OrderCard from "../order_card";
import Mobile from "./mobile";
import Desktop from "./desktop";
import SelectedCenter from "./selected";
import { useOrder } from "../OrderContext";
import { useUI } from "../../../context/UIContext";

export default function FindTestCenter() {
  const { is_mobile } = useUI();
  const { order } = useOrder();
  const { selected_center } = order;
  return (
    <OrderCard
      bleed={!is_mobile}
      title={
        selected_center ? "1. Your Test Center" : "1. Find an STD Test Center"
      }
    >
      {selected_center ? (
        <SelectedCenter test_center={selected_center} />
      ) : is_mobile ? (
        <Mobile />
      ) : (
        <Desktop />
      )}
    </OrderCard>
  );
}
