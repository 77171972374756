import { concat, map, sum } from "lodash";
import MiniOrderCard from "../components/MiniCardHolder";
import { useConsultation } from "./ScheduleConsultation/context/ConsultationContext";

export default function ConsultationSummary() {
  const { patient, partners, payment_required, clearForm } = useConsultation();
  const persons = concat(patient, partners);
  const order_list = map(persons, function (p: any, idx: number) {
    const { given_name, family_name } = p;
    const initial_price = payment_required ? 99 : 0;
    const item =
      !given_name && !family_name ? (
        <span className="text-gray-400 italic">name not given</span>
      ) : (
        <>{p.given_name + " " + p.family_name}</>
      );
    return {
      item,
      price: initial_price,
    };
  });

  const action = order_list?.length > 0 && (
    <button onClick={() => clearForm()}>Clear All</button>
  );

  return (
    <MiniOrderCard
      action={action}
      title="Consultation Order"
      card_layout="w-full min-w-[320px] sm:w-[unset]"
    >
      <div className="p-2">
        <table className="w-full">
          <thead>
            <tr className="">
              <th className="p-1 border-b border-slate-300 text-left">Test</th>
              <th className="p-1 border-b border-slate-300 text-right">
                Price
              </th>
            </tr>
          </thead>
          <tbody className="p-2">
            {order_list.map((row: any, idx: any) => {
              const { item, price } = row;
              return (
                <tr
                  key={idx}
                  className="border-b  border-dotted border-slate-300 last:border-b-0 text-sm"
                >
                  <td className="p-1 ">{item}</td>
                  <td className="p-1 text-right">${price.toFixed(2)}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr className="">
              <th className="p-1 border-t border-slate-300 text-left">Total</th>
              <th className="p-1 border-t border-slate-300 text-right">
                ${sum(map(order_list, "price")).toFixed(2)}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </MiniOrderCard>
  );
}
