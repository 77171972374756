import { useUsers } from "../UsersContext";
import UserSearch from "../search";
import UsersList from "./list";

export default function UsersResults() {
  const { users } = useUsers();
  return (
    <div className="flex flex-col gap-6 p-6 min-h-[550px] grow">
      {users ? (
        <>
          <div className="flex flex-row justify-end">
            <UserSearch />
          </div>
          <div className="flex flex-col gap-8 grow">
            <UsersList />
          </div>
        </>
      ) : (
        <>
          <div />
          <div className="flex flex-col gap-4 items-center justify-center h-full">
            <div className="text-2xl font-bold text-rsc-blue">
              Search Accounts
            </div>
            <div className="max-w-[400px]">
              <UserSearch />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
