import axios from "axios";
import { createContext, useContext, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { filter, find } from "lodash";

const { REACT_APP_GOOGLE_MAPS_KEY } = process.env;

const GET_TEST_CENTERS = gql`
  query getTestCenters($box: String!) {
    getTestCenters(box: $box) {
      _id
      name
      address
      city
      state
      zip_code
      country
      fax
      url
      hours {
        Sunday {
          start
          end
        }
        Monday {
          start
          end
        }
        Tuesday {
          start
          end
        }
        Wednesday {
          start
          end
        }
        Thursday {
          start
          end
        }
        Friday {
          start
          end
        }
        Saturday {
          start
          end
        }
      }
      location {
        coordinates
      }
    }
  }
`;

interface TestCenterValues {
  //
  loading: boolean;
  selected_center?: any;
  setBox: (box: string) => void;
  setLoading: (state: boolean) => void;
  setSelectedCenter: (id?: string) => void;
  test_centers: any[];
  //
  open_saturdays: boolean;
  view: string;
  zip_code: string;
  setOpenSaturdays: (open_saturdays: boolean) => void;
  setView: (view: string) => void;
  setZipCode: (view: string) => void;
  //
  center:
    | {
        lat: number;
        lng: number;
      }
    | undefined;
  getAndSetCenter: (zip_code: string) => void;
  getCurrentLocation: () => void;
  map: any;
  setMap: (data: any) => void;
  //
  page_0_ready: boolean;
}

export const TestCenterContext = createContext<TestCenterValues>({
  //
  loading: false,
  setBox: () => {},
  setLoading: () => {},
  setSelectedCenter: () => {},
  test_centers: [],
  //
  open_saturdays: false,
  view: "list",
  zip_code: "",
  setOpenSaturdays: () => {},
  setView: () => {},
  setZipCode: () => {},
  //
  center: undefined,
  getAndSetCenter: () => {},
  getCurrentLocation: () => {},
  map: {},
  setMap: () => {},
  //
  page_0_ready: false,
});

export const useTestCenter = () => useContext(TestCenterContext);

const TestCenterContextProvider: React.FC<{
  children: any;
}> = ({ children }) => {
  // TEST CENTER
  const [loading, setLoading] = useState<boolean>(false);
  const [zip_code, setZipCode] = useState<string>("");
  const [open_saturdays, setOpenSaturdays] = useState<boolean>(false);
  const [view, setView] = useState<string>("list");

  // MAP STATE
  const [map, setMap] = useState<any>({
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    geoCoder: null,
    places: [],
    center: [],
    zoom: 9,
    address: "",
    draggable: true,
    lat: null,
    lng: null,
  });

  // MAPPING DATA
  const [center, setCenter] = useState<
    | {
        lat: number;
        lng: number;
      }
    | undefined
  >(undefined);
  const [box, setBox] = useState<string | undefined>(undefined);

  const [selected_center, setSelectedCenter] = useState<any | undefined>();

  // {
  //   __typename: "TestCenter",
  //   _id: "62d9c49620ee7a1f3f488d70",
  //   name: "Quest Diagnostics",
  //   address: "2910 South Maguire Rd Suite1010",
  //   city: "Ocoee",
  //   state: "FL",
  //   zip: "34761-4719",
  //   country: "USA",
  //   fax: "407-654-0829",
  //   url: "https://www.google.com/maps/dir/Current+Location/2910+South+Maguire+Rd+Suite1010,+Ocoee,+FL,+34761,+USA/",
  //   location: {
  //     __typename: "CenterPoint",
  //     coordinates: [-81.541165, 28.528843],
  //   },
  // }

  const { data, loading: query_loading } = useQuery(GET_TEST_CENTERS, {
    variables: { box },
    skip: !box,
    onError: (e: any) => {
      console.log("Error", e);
    },
  });

  function findZipCode(response: any) {
    const zip_code_result = find(
      response.data.results[0].address_components,
      function (item: any) {
        return item.types[0] === "postal_code";
      }
    )?.long_name;

    return zip_code_result;
  }

  async function getCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        const { latitude: lat, longitude: lng } = position.coords;
        const browser_center = {
          lat,
          lng,
        };
        setCenter(browser_center);
        getAndSetZipCode(browser_center);
      });
    }
  }

  async function getAndSetZipCode(center: any) {
    if (!center) return;
    const { lat, lng } = center;
    const request = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=postal_code&key=${REACT_APP_GOOGLE_MAPS_KEY}`;

    await axios.get(request).then((response: any) => {
      const zip = findZipCode(response);
      view === "list" && setView("map");
      setZipCode(zip);
    });
    setLoading(false);
  }

  async function getAndSetCenter(zip_code: string) {
    const request = `https://maps.googleapis.com/maps/api/geocode/json?address=${zip_code}&result_type=postal_code&key=${REACT_APP_GOOGLE_MAPS_KEY}`;
    console.log("request", request);
    await axios.get(request).then((response: any) => {
      console.log("response", response);
      const coordinates = response?.data?.results?.[0]?.geometry?.location;
      setCenter(coordinates);
    });
  }

  const page_0_ready = !!selected_center;
  const test_centers = data?.getTestCenters || [];
  const filtered_test_centers = open_saturdays
    ? filter(test_centers, function (t: any) {
        return !!t?.hours?.Saturday?.[0]?.start;
      })
    : test_centers;

  return (
    <TestCenterContext.Provider
      value={{
        loading: loading || query_loading,
        selected_center,
        setBox,
        setLoading,
        setSelectedCenter,
        test_centers: filtered_test_centers,
        //
        open_saturdays,
        view,
        setOpenSaturdays,
        setView,
        setZipCode,
        zip_code,
        //
        center,
        getAndSetCenter,
        getCurrentLocation,
        map,
        setMap,
        //
        page_0_ready,
      }}
    >
      {children}
    </TestCenterContext.Provider>
  );
};

export default TestCenterContextProvider;
