import axios from "axios";
import { flatMap, groupBy, orderBy, pickBy, uniqBy } from "lodash";
import moment from "moment";

export async function getToken() {
  let result = await axios("/api/payment/initialize", {
    withCredentials: true,
  });
  const tokenObject = result.data.data;
  return tokenObject;
}

export async function confirmTransaction(transaction_data: any) {
  const response = await axios.post("/api/payment/confirm", transaction_data, {
    withCredentials: true,
  });
  const { errors, transaction: tx } = response.data;
  console.log("TX ONLY", tx);
  if (errors) {
    console.log("WE HAD ERRORS");
    return { errors };
  }
  return { tx };
}

export async function sendSupportMessage(props: any) {
  const { message } = props;
  try {
    return !!message;
  } catch (error) {
    console.log(error);
    alert("An error occured");
  }
}

export async function receivePDF(VersionData: string) {
  try {
    const { data } = await axios.post("/api/salesforce/pdf", {
      VersionData,
    });

    const byteCharacters: any = atob(data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const doc = new Blob([byteArray], { type: "application/pdf;base64" });

    return doc;
  } catch (error) {
    console.log(error);
    alert("An error occured");
  }
}

export async function sendFax(
  file: string,
  faxNumber: string = "+16783981998",
  setLoading?: any,
  setFaxSent?: any
) {
  // const doc = await receivePDF(file_);
  // const file = URL.createObjectURL(doc as Blob);
  const response = await axios.get("/api/salesforce/fax_pdf", {
    params: {
      faxNumber,
      file,
    },
  });
  !!response?.data?.id && setFaxSent(true);
  setLoading(false);
}

export async function emailInvoice({
  bucket,
  file_name,
  html,
  transaction,
  setLoading,
}: {
  html: any;
  bucket?: string;
  file_name?: string;
  transaction: string;
  setLoading?: any;
}) {
  try {
    if (!html) return alert("No invoice found.");
    // const response = await axios.post(
    //   "/api/pdf",
    //   { bucket, file_name, html },
    //   { responseType: "blob" }
    // );
    // const content = new Blob([response.data], {
    //   type: "application/pdf;base64",
    // });
  } catch (err) {
    console.log("ERROR SENDING INVOICE", err);
  }
}

export async function generatePDF({
  bucket,
  file_name,
  html,
  setLoading,
}: {
  html: any;
  bucket?: string;
  file_name?: string;
  setLoading?: any;
}) {
  if (!html) return alert("No invoice found.");
  try {
    console.log("Generating PDF");
    await axios
      .post("/api/pdf", { bucket, file_name, html }, { responseType: "blob" })
      .then(response => {
        const url = URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf;base64" })
        );
        console.log("OPEN PDF", url);
        window.open(url);
      });
  } catch (error) {
    console.log(error);
    alert("An error occured");
  } finally {
    setLoading?.(false);
  }
}

export async function getPDF(file?: string, setLoading?: any) {
  if (!file) return;
  setLoading?.(true);
  try {
    const doc = await receivePDF(file);
    const fileURL = URL.createObjectURL(doc as Blob);
    window.open(fileURL);
  } catch (error) {
    console.log(error);
    alert("An error occured");
  } finally {
    setLoading?.(false);
  }
}

export async function setUserAccount({
  user,
  setAccount,
}: {
  user: any;
  setAccount: any;
}) {
  const { Id, email, phone_number } = user;
  const params = pickBy({
    Id,
    PersonEmail: email,
    PersonMobilePhone: phone_number,
  });

  await axios
    .post(`/api/salesforce/user`, params)
    .then((response: any) => {
      const result = response.data;
      const user = result?.records?.[0];
      setAccount(user);
    })
    .catch((error: any) => {
      console.log("There was an error", error);
    });
}

export function getOrderDetails({
  setOrderDetails,
  Id,
}: {
  setOrderDetails: (details: any) => void;
  Id: string;
}) {
  axios
    .get(`${window.location.origin}/api/salesforce/order_details`, {
      params: { Id },
    })
    .then((response: any) => {
      const { data } = response;

      setOrderDetails(data);
    })
    .catch((error: any) => {
      console.log("There was an error", error);
    });
}

export function getOpportunities({
  setOpportunities,
  account,
}: {
  setOpportunities: (opps: any[]) => void;
  account: any;
}) {
  const { Id: AccountId } = account;
  axios
    .post(`/api/salesforce/opportunity`, {
      AccountId,
    })
    .then((response: any) => {
      const result = response.data.records;
      const opportunities = orderBy(
        result,
        function (o: any) {
          const stamp = moment(o.CreatedDate).unix();
          return stamp;
        },
        "desc"
      );
      setOpportunities(opportunities);
    })
    .catch((error: any) => {
      console.log("There was an error", error);
    });
}

export function getOrders({
  setOpportunities,
  setOrders,
  account,
}: {
  setOpportunities: (opps: any[]) => void;
  setOrders: (ords: any) => void;
  account: any;
}) {
  const { Id } = account;
  axios
    .get(`${window.location.origin}/api/salesforce/orders`, {
      params: { Id },
    })
    .then((response: any) => {
      const result = response.data;
      const opps = orderBy(
        account?.Opportunities?.records,
        function (o: any) {
          const stamp = moment(o.CreatedDate).unix();

          return stamp;
        },
        "desc"
      );

      const ords = result?.records?.[0]?.Orders?.records;
      const grouped = groupBy(
        orderBy(
          ords,
          function (o: any) {
            const stamp = moment(o.CreatedDate).unix();
            return stamp;
          },
          "desc"
        ),
        "OpportunityId"
      );

      setOpportunities(opps);
      setOrders(grouped);
    })
    .catch((error: any) => {
      console.log("There was an error", error);
    });
}

export function getTests({
  setTests,
  user,
}: {
  setTests: (test: any[]) => void;
  user: any;
}) {
  const { Id } = user;
  axios
    .get(`${window.location.origin}/api/salesforce/tests`, {
      params: { Id },
    })
    .then((response: any) => {
      const tests = response.data;
      setTests(orderBy(tests, "Name", "asc"));
    })
    .catch((error: any) => {
      console.log("There was an error", error);
    });
}

export function getOrderTests_({
  setOrderTests,
  Id,
}: {
  setOrderTests: (test: any) => void;
  Id: string;
}) {
  axios
    .get(`${window.location.origin}/api/salesforce/order_tests`, {
      params: { Id },
    })
    .then((response: any) => {
      const { records } = response.data;

      const all_tests1 = flatMap(records, "OrderItems.records");
      const all_tests = flatMap(all_tests1, "PricebookEntry.Product2");
      const order_tests = {
        Id: records?.[0]?.Id,
        tests: uniqBy(all_tests, "Id"),
      };
      setOrderTests(order_tests);
    })
    .catch((error: any) => {
      console.log("There was an error", error);
    });
}

export function getOrderTests({
  setOrderTests,
  Id,
}: {
  setOrderTests: (test: any) => void;
  Id: string;
}) {
  axios
    .get(`${window.location.origin}/api/salesforce/order_results`, {
      params: { Id },
    })
    .then((response: any) => {
      const { orders, results } = response.data;

      const order_tests = {
        Id,
        orders: orders?.records?.[0]?.OrderItems?.records,
        results: results.records,
      };
      setOrderTests(order_tests);
    })
    .catch((error: any) => {
      console.log("There was an error", error);
    });
}

export function getAllTests({
  setAllTests,
  Id,
}: {
  setAllTests: (test: any) => void;
  Id?: string;
}) {
  if (!Id) return null;
  axios
    .get(`${window.location.origin}/api/salesforce/all_results`, {
      params: { Id },
    })
    .then((response: any) => {
      const { records } = response.data;
      setAllTests(records);
    })
    .catch((error: any) => {
      console.log("There was an error", error);
    });
}

export function getTestsAccount({
  setTestHistory,
  Account,
  Sub_Name__c,
}: {
  setTestHistory: (test: any) => void;
  Account: string;
  Sub_Name__c: string;
}) {
  axios
    .get(`${window.location.origin}/api/salesforce/all_results_account`, {
      params: { Account, Sub_Name__c },
    })
    .then((response: any) => {
      const { records } = response.data;
      const results = orderBy(
        records,
        function (o: any) {
          const stamp = moment(o.Result_Date_Time__c).unix();
          return stamp;
        },
        "desc"
      );
      setTestHistory(results);
    })
    .catch((error: any) => {
      console.log("There was an error", error);
    });
}

export function getLab({
  Id,
  setLaboratory,
}: {
  Id: string;
  setLaboratory: (test: any) => void;
}) {
  axios
    .get(`${window.location.origin}/api/salesforce/laboratory`, {
      params: { Id },
    })
    .then((response: any) => {
      const { records } = response.data;
      setLaboratory(records?.[0]);
    })
    .catch((error: any) => {
      console.log("There was an error", error);
    });
}

export function getMessages({
  Id,
  setMessages,
}: {
  Id: string;
  setMessages: (test: any) => void;
}) {
  axios
    .get(`${window.location.origin}/api/salesforce/messages`, {
      params: { Id },
    })
    .then((response: any) => {
      const { records } = response.data;
      const messages = orderBy(
        records?.[0]?.ActivityHistories?.records,
        "CreatedDate",
        "desc"
      );
      setMessages({ Id, messages });
    })
    .catch((error: any) => {
      console.log("There was an error", error);
    });
}
