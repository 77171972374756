import {
  compact,
  concat,
  difference,
  find,
  join,
  map,
  sum,
  uniq,
} from "lodash";

export default function PackageTestItem({
  all_products,
  test,
}: {
  all_products: any;
  test: any;
}) {
  const { package_info } = test;

  const item_codes = difference(
    uniq(concat(package_info?.individual_lines, package_info?.combo_lines)),
    ["P-1", "P-2", "P-9", "P-20", "P-21", "P-25"]
  );

  const { name, price } = test;
  function getPackageValue(items: any) {
    return sum(
      compact(
        map(items, function (s: any) {
          return find(all_products, ["sku", s])?.price;
        })
      )
    );
  }

  const package_price = getPackageValue(item_codes);

  return (
    <tr>
      <td style={{ borderBottom: "1px solid rgb(0 0 0 / .05)", padding: 16 }}>
        <table style={{ fontSize: 12, width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ width: "50%" }}>
                <span style={{ fontWeight: 600 }}>{name}</span>
                <span
                  style={{ color: "#888", fontSize: 10, fontStyle: "italic" }}
                >
                  {" "}
                  - ${(package_price || 0).toFixed(2)} value
                </span>
                <br />
                {join(
                  compact(
                    map(item_codes, function (s: any) {
                      return find(all_products, ["sku", s])?.name;
                    })
                  ),
                  ", "
                )}{" "}
                <br />
                <span
                  style={{ color: "#888", fontSize: 9, fontStyle: "italic" }}
                >
                  {join(
                    compact(
                      map(item_codes, function (s: any) {
                        return find(all_products, ["sku", s]).cpt;
                      })
                    ),
                    ", "
                  )}
                </span>
              </td>
              <td
                style={{
                  fontWeight: 600,
                  textAlign: "right",
                  verticalAlign: "top",
                }}
              >
                ${(price || 0).toFixed(2)}
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  );
}
