import moment from "moment";
import DatePicker from "react-datepicker";
import IonIcon from "@reacticons/ionicons";
import { upperFirst } from "lodash";
import OrderCard from "../order_card";
import { useOrder } from "../OrderContext";

function PatientInfo() {
  const { order, handleUserChange } = useOrder();

  if (!order?.user) return null;

  const { dob, given_name, family_name, sex } = order.user;
  const date_of_birth = dob ? moment(dob, "YYYY-MM-DD").toDate() : null;

  return (
    <OrderCard title={"3. Your Information"} card_layout={"z-10"}>
      <div className="p-3 bg-slate-50">
        <div className="grid md:grid-cols-2 gap-3 ">
          <div className="">
            <label htmlFor="given_name">First Name:</label>
            <input
              autoComplete="off"
              className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
              id="given_name"
              placeholder="Enter your first name"
              value={given_name}
              onChange={(e: any) =>
                handleUserChange("given_name", e.target.value)
              }
            />
          </div>
          <div className="">
            <label htmlFor="family_name">Last Name:</label>
            <input
              className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
              id="family_name"
              placeholder="Enter your last name"
              value={family_name}
              onChange={(e: any) =>
                handleUserChange("family_name", e.target.value)
              }
            />
          </div>
          <div className="">
            <label htmlFor="date_of_group">
              Date of Birth:{" "}
              {false && (
                <span className="text-sm text-gray-400 italic">
                  (MM/DD/YYYY)
                </span>
              )}
            </label>
            <DatePicker
              customInput={
                <input
                  className="bg-white rounded-lg border border-slate-400 h-10 w-full shadow-inner px-2 z-50"
                  id="data-picker"
                  placeholder="MM/DD/YYYY"
                />
              }
              className="w-full"
              placeholderText="MM/DD/YYYY"
              selected={date_of_birth}
              showYearDropdown
              onChange={(e: any) => {
                const dob = moment(e).format("YYYY-MM-DD");
                handleUserChange("dob", dob);
              }}
            />
          </div>
          <div className="">
            <label htmlFor="sex_group">Sex:</label>
            <div className="flex flex-row items-center justify-center md:justify-start gap-5 h-10">
              {["Male", "Female"].map((item: string) => {
                const isActive = sex === item;
                return (
                  <div className="flex flex-row gap-2 items-center" key={item}>
                    <div style={{ lineHeight: 0 }}>
                      <button
                        className="text-2xl"
                        onClick={() => handleUserChange("sex", item)}
                      >
                        <IonIcon
                          className={`${
                            isActive
                              ? "text-rsc-blue"
                              : "text-slate-400 radio-shadow"
                          } `}
                          style={{
                            transform: "translateY(2px)",
                          }}
                          name={
                            isActive ? "radio-button-on" : "radio-button-off"
                          }
                        />
                      </button>
                    </div>
                    <div className="grow">
                      <label htmlFor={item}>{upperFirst(item)}</label>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </OrderCard>
  );
}

export default PatientInfo;
