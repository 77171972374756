import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import {
  GetUserCommand,
  InitiateAuthCommand,
  InitiateAuthCommandInput,
} from "@aws-sdk/client-cognito-identity-provider";
import { includes, isEmpty, startsWith } from "lodash";
import { CognitoClient } from "../utils/aws";
import { isValidJSONString, makeUser } from "../utils/functions";
import { LoginObject, UserLogin } from "../utils/types";
import { useUI } from "./UIContext";
import jwtDecode from "jwt-decode";

const { REACT_APP_AWS_COGNITO_CLIENT_ID, REACT_APP_AWS_COGNITO_POOL_ID } =
  process.env;

const ClientId = REACT_APP_AWS_COGNITO_CLIENT_ID;
const UserPoolId = REACT_APP_AWS_COGNITO_POOL_ID;

const { localStorage } = window;

const cached_role = localStorage.getItem("admin:role") || null;
const cma = localStorage.getItem("managing_account");
const cached_managing_account = (isValidJSONString(cma || "") && cma) || null;
const test_user = {
  email: "maxime.and.associates@gmail.com",
  family_name: "Maxime",
  given_name: "Samuel",
  roles: "Super Administrator",
};
//const isTestUser = false;
const is_dev_logged_in = false;
const dev_login = is_dev_logged_in ? test_user : null;

interface UserValues {
  accessToken?: string;
  admin: any | null;
  // alert: any | null;
  error_message?: any;
  is_admin: boolean;
  is_dev_logged_in: boolean;
  managing_account: string | null;
  message?: any;
  simulated_role: string | null;
  user?: any;
  UserPoolId?: string;
  clearMessages: () => void;
  loginUser: (
    values: any,
    callback?: any,
    user_callback?: any
  ) => Promise<void>;
  logoutUser: () => void;
  setErrorMessage: (msg: any | undefined) => void;
  setManagingAccount: (managing_account: string | null) => void;
  setMessage: (msg: any | undefined) => void;
  setSimulatedRole: (role: string | null) => void;
}

export const UserContext = createContext<UserValues>({
  admin: null,
  //alert: null,
  is_admin: false,
  is_dev_logged_in: false,
  managing_account: null,
  simulated_role: null,
  UserPoolId: UserPoolId,
  clearMessages: () => {},
  loginUser: () => Promise.resolve(),
  logoutUser: () => {},
  setErrorMessage: () => {},
  setManagingAccount: () => {},
  setMessage: () => {},
  setSimulatedRole: () => {},
});

export const useUser = () => useContext(UserContext);

const UserContextProvider: React.FC<{
  children: any;
}> = ({ children }) => {
  const { location, setLocation } = useUI();
  //const [accessToken, setAccessToken] = useState<string | undefined>();
  const [user, setUser] = useState<any | null>(dev_login || null);
  const [admin, setAdmin] = useState<any | null>(null);
  const [simulated_role, setSimulatedRole] = useState<string | null>(
    cached_role
  );
  const [managing_account, setManagingAccount] = useState<string | null>(
    cached_managing_account
  );
  const [error_message, setErrorMessage] = useState<any | undefined>();
  const [message, setMessage] = useState<string | undefined>();

  const [logging_in_user, setLoggingInUser] = useState<boolean>(false);
  const [loading_user, setLoadingUser] = useState<boolean>(false);
  const [loading_admin, setLoadingAdmin] = useState<boolean>(false);
  const [getting_authorization, setGettingAuthorization] =
    useState<boolean>(false);
  const [validating_token, setValidatingToken] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);

  function clearSession() {
    localStorage.removeItem("idToken");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  }

  async function getUser(AccessToken: string) {
    try {
      setLoadingUser(true);
      const input = {
        AccessToken,
      };
      const command = new GetUserCommand(input);
      const response = await CognitoClient.send(command);
      const user = makeUser(response);

      setUser(user);
    } catch (error: any) {
      if (
        error.name === "NotAuthorizedException" &&
        !includes(
          [
            "/confirmation",
            "/forgot_password",
            "/test-and-panels",
            "/order",
            "/register",
            "/verify",
          ],
          location
        )
      ) {
        clearSession();
        setLocation("/login");
      }
    } finally {
      setLoadingUser(false);
    }
  }

  async function getAuthorization(user: UserLogin, setAlert: any) {
    const { username, password } = user;
    try {
      setGettingAuthorization(true);
      const input: InitiateAuthCommandInput = {
        ClientId,
        AuthFlow: "USER_PASSWORD_AUTH",
        AuthParameters: {
          USERNAME: username,
          PASSWORD: password,
        },
      };
      const command = new InitiateAuthCommand(input);
      const response = await CognitoClient.send(command);
      if (response.AuthenticationResult) {
        const { IdToken, AccessToken, RefreshToken } =
          response.AuthenticationResult;
        IdToken && localStorage.setItem("idToken", IdToken);
        AccessToken && localStorage.setItem("accessToken", AccessToken);
        RefreshToken && localStorage.setItem("refreshToken", RefreshToken);
        //  setAccessToken(AccessToken);
        return AccessToken;
      }
    } catch (error: any) {
      const { name, message, type } = error;
      console.log(type);
      const error_messages: { [key: string]: any } = {
        NotAuthorizedException: (
          <div>
            {message}{" "}
            {message === "User is disabled." ? (
              <a className="underline" href="tel:+18668730879">
                Contact Support (866) 873-0879
              </a>
            ) : (
              <button
                className="text-bold underline"
                onClick={() => setLocation("./forgot_password")}
              >
                Forgot Password?
              </button>
            )}
          </div>
        ),
        UserNotConfirmedException: (
          <>
            {error.message} You must confirm your login identity.{" "}
            <button
              className="text-bold underline"
              onClick={() => {
                setAlert(null);
                setLocation("./verify");
              }}
            >
              Confirm Identity Now
            </button>
          </>
        ),
      };
      clearSession();
      setAlert?.({ message: error_messages[name] || error.message });
    } finally {
      setGettingAuthorization(false);
    }
  }

  async function loginUser({
    callback,
    user,
    user_callback,
    setAlert,
  }: LoginObject) {
    let success;
    clearMessages();
    try {
      setLoggingInUser(true);
      const AccessToken = await getAuthorization(user, setAlert);
      console.log("ACCESS TOKEN RESULT", AccessToken);
      if (AccessToken) {
        await getUser(AccessToken);
        success = true;
      }
      // else {
      //   console.log("AUTHENTICATION FAILED TO GET TOKEN");
      //   setAlert({ message: "Authentication Failed" });
      // }
    } catch (error: any) {
      console.log(
        "Authentication error",
        error.name,
        error.type,
        error.message
      );
      setAlert({ message: error.message });
      callback && callback(error.message);
    } finally {
      if (success) {
        callback?.();
        user_callback?.();
      }
      setLoggingInUser(false);
      return Promise.resolve();
    }
  }

  async function logoutUser() {
    startsWith(location, "/dashboard") && setLocation("/login");
    clearMessages();
    clearSession();
    setUser(null);
    setAdmin(null);
  }

  async function setUpAdminAccount(email: string) {
    let data;

    try {
      setLoadingAdmin(true);
      const response = await axios
        .post(`/api/salesforce/admin`, { email })
        .catch((error: any) => {
          console.log("There was an error", error);
        });

      data = response?.data?.records?.[0];
    } catch (err) {
      console.log("ERROR SETTING UP USER", err);
    } finally {
      setAdmin({
        Id: email,
        data,
      });

      setLoadingAdmin(false);
    }
  }

  useEffect(() => {
    managing_account && isValidJSONString(managing_account)
      ? localStorage.setItem("managing_account", managing_account)
      : localStorage.removeItem("managing_account");
  }, [managing_account]);

  function clearMessages() {
    setMessage(undefined);
    setErrorMessage(undefined);
  }

  const is_admin = includes(
    ["Super Administrator", "Administrator", "Agent"],
    user?.roles
  );

  !!user &&
    !!is_admin &&
    !loading_admin &&
    user.email !== admin?.Id &&
    setUpAdminAccount(user.email);

  async function refreshTokens(refreshToken: string | null) {
    if (!refreshToken) {
      console.log("No refresh token provided");
      return false;
    }

    const command = new InitiateAuthCommand({
      ClientId,
      AuthFlow: "REFRESH_TOKEN_AUTH",
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    });

    try {
      const response = await CognitoClient.send(command);

      if (response.AuthenticationResult) {
        const { AccessToken, IdToken, RefreshToken } =
          response.AuthenticationResult;

        AccessToken && localStorage.setItem("accessToken", AccessToken);
        IdToken && localStorage.setItem("idToken", IdToken);
        RefreshToken && localStorage.setItem("refreshToken", RefreshToken);
        RefreshToken && console.log("DID RECEIVED REFRESH", RefreshToken);
        return true; // Indicate success
      } else {
        console.log("Failed to refresh tokens: No authentication result found");
        return false;
      }
    } catch (error) {
      console.error("Error during token refresh:", error);
      return false;
    }
  }

  async function validateAccessToken(
    accessToken: string,
    refreshToken: string | null
  ) {
    try {
      setValidatingToken(true);

      const decodedToken = jwtDecode(accessToken) as any;
      const currentTime = Date.now() / 1000;
      const tokenExpiryTime = decodedToken.exp;
      const timeBeforeExpiry = tokenExpiryTime - currentTime;

      if (timeBeforeExpiry <= 0) {
        clearSession();
        return false;
      } else if (timeBeforeExpiry < 2700) {
        refreshTokens(refreshToken);
        return true;
      }

      return true;
    } catch (err: any) {
      console.log("ERROR VALIDATING TOKEN", err);
      clearSession();
      return false;
    } finally {
      setValidatingToken(false);
    }
  }

  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");

  useEffect(() => {
    if (
      !!user ||
      logging_in_user ||
      !accessToken ||
      getting_authorization ||
      loading_user ||
      validating_token
    )
      return;

    async function process() {
      setProcessing(true);
      const is_active = await validateAccessToken(
        accessToken as string,
        refreshToken
      );
      is_active && (await getUser(accessToken as string));
      setProcessing(false);
    }

    !processing && process();
  });
  const user_ = { ...user, ...admin?.data };

  return (
    <UserContext.Provider
      value={{
        admin: admin?.data,
        error_message,
        is_admin,
        is_dev_logged_in,
        managing_account,
        message,
        simulated_role,
        UserPoolId,
        user: !isEmpty(user_) && user_,
        loginUser,
        logoutUser,
        clearMessages,
        setErrorMessage,
        setManagingAccount,
        setMessage,
        setSimulatedRole,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
