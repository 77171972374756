import { compact, join, upperFirst } from "lodash";
import { useUser } from "../../../context/UserContext";

export default function AdminUser() {
  const { logoutUser, user } = useUser();
  const name = upperFirst(
    join(compact([user?.given_name, user?.family_name]), " ")
  );
  return (
    <div className="flex flex-row order-1 md:order-2 justify-end items-center">
      <div className="text-md md:text-2xl text-white ">
        {name || <span className="text-gray-400 italic">name unset</span>}
      </div>
      <div className="border-l border-rsc-white pl-3 ml-3 text-sky-600">
        <button
          className="text-sky-600 hover:text-white"
          onClick={() => {
            logoutUser();
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
}
