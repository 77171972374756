import { useState } from "react";
import { renderToString } from "react-dom/server";
import axios from "axios";
import PreLoader from "../PreLoader";
import ConfirmButton from "./print_button";
import ConsultationInvoice from "./ConsultationInvoice";
import OrderInvoice from "./OrderInvoice";
import PaymentReceipt from "./PaymentReceipt";
import VerificationEmail from "./VerificationEmail";
import { usePayment } from "../components/Payment/PaymentInfoContext";
import { useUI } from "../context/UIContext";
import { split } from "lodash";

export default function PageLayout() {
  const { is_development, location } = useUI();
  const { tx_data } = usePayment();
  const [email_loading, setEmailLoading] = useState<boolean>(false);
  const [print_loading, setPrintLoading] = useState<boolean>(false);
  const route = split(location, "/")[1];
  const { component: InvoiceComponent, title }: any = {
    "consultation-confirmation": {
      component: ConsultationInvoice,
      title: "Consultation Confirmation",
    },
    "order-confirmation": {
      component: OrderInvoice,
      title: "Order Confirmation",
    },
    "payment-confirmation": {
      component: PaymentReceipt,
      title: "Payment Confirmation",
    },
    "verification-email": {
      component: VerificationEmail,
      title: "Payment Confirmation",
    },
  }[route || "order-confirmation"];

  const global_styles = `<style>
  .header-title {
    color: rgb(17 102 155 / 0.8);
    font-weight: 600;
    font-size: 32px;
    letter-spacing: -1.1px;
    line-height: 14pt;
    text-align: right;
    vertical-align: middle;
  }
  
  .header-subtitle {
    color: rgb(0 0 0 / 0.6);
    fontSize: 10pt;
    fontWeight: 400;
    letterSpacing: -.1px;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  td {
    vertical-align: top;
  }

</style>`;

  if (!tx_data && route !== "verification-email")
    return <PreLoader text={`Loading ${title}...`} />;
  const html = global_styles + renderToString(<VerificationEmail data={{}} />);
  const emailWrapper = (html_body: string) =>
    `<table style="max-width: 680px;margin: 0 auto;"><tbody><tr><td>${html_body}</td></td></tbody></table>`;
  false && console.log(html);

  // const email_html_ = (global_styles +
  //   document.getElementById("order-confirmation")?.outerHTML) as string;

  const pdf_html =
    global_styles +
    emailWrapper(renderToString(<InvoiceComponent for_pdf data={tx_data} />));

  const email_html =
    global_styles +
    emailWrapper(renderToString(<InvoiceComponent data={tx_data} />));

  return (
    <div className="container mx-auto max-w-4xl flex flex-col gap-6 px-2 my-6">
      <div className="flex flex-row items-center gap-4">
        <div className="grow">
          {false && (
            <select className="rounded-lg border border-slate-400 h-10 w-full max-w-[140px] shadow-inner px-2">
              <option>View</option>
            </select>
          )}
        </div>
        <div>
          <ConfirmButton
            disabled={email_loading}
            icon="mail"
            label={email_loading ? "Sending email..." : "Email"}
            onClick={async () => {
              setEmailLoading(true);

              const { data: pdf } = await axios.post("/api/pdf", {
                html: pdf_html,
              });
              const patient_email =
                tx_data?.mx?.patient?.email || tx_data?.mx?.order?.user?.email;
              await axios
                .post("/api/email/send", {
                  subject: `${title} | ${tx_data?.tx.id}`,
                  bcc:
                    !is_development &&
                    "maxime.and.associates@gmail.com, dhines@rapidscreenings.com",
                  to: is_development
                    ? "maxime.and.associates@gmail.com"
                    : patient_email,
                  filename: `Invoice_${tx_data?.tx.id}.pdf`,
                  html: email_html,
                  pdf,
                })
                .catch((error: any) => {
                  console.log("ERROR SENDING EMAIL", error);
                });
              setEmailLoading(false);
            }}
          />
        </div>
        <div>
          <ConfirmButton
            disabled={print_loading}
            icon="print-sharp"
            label={print_loading ? "Preparing document..." : "Download/Print"}
            onClick={async () => {
              setPrintLoading(true);

              const { data: pdf } = await axios.post("/api/pdf", {
                html: pdf_html,
              });
              const binStr = atob(pdf);
              const len = binStr.length;
              const arr = new Uint8Array(len);
              for (let i = 0; i < len; i++) {
                arr[i] = binStr.charCodeAt(i);
              }
              // consoleLog("PDF GENERATE RESPONSE", pdf);
              const doc = new Blob([arr], { type: "application/pdf;base64" });
              const fileURL = URL.createObjectURL(doc);
              window.open(fileURL);
              setPrintLoading(false);
            }}
          />
        </div>
      </div>
      <div className="bg-white border border-gray-200 shadow-md p-[6.65%] aspect-letter">
        <InvoiceComponent data={tx_data} />
      </div>
    </div>
  );
}
