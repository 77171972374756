export const individual_products: any[] = [
  {
    name: "Hepatitis A",
    cpt: "86709",
    sku: "512",
    price: 89,
    ind: true,
  },
  {
    name: "Hepatitis B",
    cpt: "87340",
    sku: "498",
    price: 59,
    ind: true,
  },
  {
    name: "Hepatitis C",
    cpt: "86803",
    sku: "8472",
    price: 59,
    ind: true,
  },
  {
    name: "Hepatitis A, B and C",
    sku: "5124988472",
    items: ["512", "498", "8472"],
  },
  {
    name: "HIV 1 & 2 Antibody (4th Gen.)",
    cpt: "87389",
    sku: "91431",
    price: 79,
    ind: true,
  },
  {
    name: "HIV RNA Early Detection",
    cpt: "86709",
    sku: "16185",
    price: 199,
    ind: true,
  },
  {
    name: "Syphilis",
    cpt: "86592",
    sku: "36126",
    price: 49,
    ind: true,
  },
  {
    name: "Herpes I",
    cpt: "86696",
    sku: "3636",
    price: 49,
    ind: true,
  },
  {
    name: "Herpes II",
    cpt: "86696",
    sku: "3640",
    price: 69,
    ind: true,
  },
  {
    name: "Herpes Early Detection",
    cpt: "86694",
    sku: "90849",
    price: 229,
    // ind: true,
  },
  {
    name: "Chlamydia",
    cpt: "87491",
    sku: "11361",
    price: 79,
    ind: true,
  },

  {
    name: "Gonorrhea",
    cpt: "87491",
    sku: "11362",
    price: 79,
    ind: true,
  },

  {
    name: "Chlamydia & Gonorrhea",
    sku: "11363",
    price: 99,
    ind: true,
    items: ["11361", "11362"],
  },
  {
    name: "Herpes 1 & 2",
    sku: "6447",
    price: 89,
    items: ["3636", "3640"],
  },
  {
    name: "Trichomoniasis",
    cpt: "87798",
    sku: "P-23",
    price: 109,
    hidden_items: ["P-22", "P-23", "P-24"],
    ind: true,
    whats_this: (
      <>
        <p>
          Trichomoniasis (Trich) is a very common, highly contagious STD caused
          by a protozoan parasite.
        </p>
        <p>
          According to the CDC, trichomoniasis is the most common STD in the
          United States- affecting approximately 3.7 million people.
        </p>
        <p>
          Since most cases of trich are symptom-less, a vast majority of
          individuals carrying this bacteria are unaware that they are infected,
          so they unknowingly spread it to their partners. Rather than the
          typical invasive swab testing that is often performed for
          trichomoniasis testing, our trich test is a painless urine test. If
          you included chlamydia or gonorrhea in your order today, this
          Trichomoniasis test can be performed using the same urine sample you
          will already be providing at the lab.
        </p>
      </>
    ),
  },
  {
    name: "Trichomoniasis & Basic Wellness Panel",
    sku: "P-22",
    price: 299,
    hidden_items: ["P-22", "P-23", "P-24"],
    whats_this: (
      <>
        <p>
          Trichomoniasis (Trich) is a very common, highly contagious STD caused
          by a protozoan parasite.
        </p>
        <p>
          According to the CDC, trichomoniasis is the most common STD in the
          United States- affecting approximately 3.7 million people.
        </p>
        <p>
          Since most cases of trich are symptom-less, a vast majority of
          individuals carrying this bacteria are unaware that they are infected,
          so they unknowingly spread it to their partners. Rather than the
          typical invasive swab testing that is often performed for
          trichomoniasis testing, our trich test is a painless urine test. If
          you included chlamydia or gonorrhea in your order today, this
          Trichomoniasis test can be performed using the same urine sample you
          will already be providing at the lab.
        </p>
      </>
    ),
  },
  {
    name: "Trichomoniasis",
    sku: "P-24",
    price: 109,
    hidden_items: ["P-22", "P-23", "P-24"],
    whats_this: (
      <>
        <p>
          Trichomoniasis (Trich) is a very common, highly contagious STD caused
          by a protozoan parasite.
        </p>
        <p>
          According to the CDC, trichomoniasis is the most common STD in the
          United States- affecting approximately 3.7 million people.
        </p>
        <p>
          Since most cases of trich are symptom-less, a vast majority of
          individuals carrying this bacteria are unaware that they are infected,
          so they unknowingly spread it to their partners. Rather than the
          typical invasive swab testing that is often performed for
          trichomoniasis testing, our trich test is a painless urine test. If
          you included chlamydia or gonorrhea in your order today, this
          Trichomoniasis test can be performed using the same urine sample you
          will already be providing at the lab.
        </p>
      </>
    ),
  },
];

export const package_products: any[] = [
  {
    name: "Basic Panel",
    sku: "P-1",
    price: 139,
    items: ["11361", "11362", "91431", "36126"],
    whats_this: (
      <>
        <ul>
          <li>Chlamydia</li>
          <li>Gonorrhea</li>
          <li>HIV (Type 1 and 2)</li>
          {false && <li>Herpes (1 and 2)</li>}
          <li>Syphilis</li>
        </ul>
      </>
    ),
    grid_order: 0,
  },
  {
    name: "No HIV STD Panel",
    sku: "P-25",
    price: 139,
    items: ["11363", "36126", "3640"],
    hidden_items: ["11361", "11362"],
    whats_this: (
      <>
        <ul>
          <li>Chlamydia and Gonorrhea</li>
          <li>Syphilis</li>
          <li>Herpes II</li>
        </ul>
      </>
    ),
    grid_order: 4,
  },
  {
    name: "Comprehensive Panel",
    sku: "P-2",
    price: 169,
    items: ["91431", "11363", "36126", "3640"],
    hidden_items: ["11361", "11362"],
    whats_this: (
      <>
        <ul>
          <li>HIV (Type 1 and 2)</li>
          <li>Chlamydia and Gonorrhea</li>
          <li>Syphilis</li>
          <li>Herpes II</li>
        </ul>
      </>
    ),
    grid_order: 2,
  },
  {
    name: "Test Panel",
    sku: "P-20",
    price: 199,
    items: ["91431", "6447", "498", "8472", "36126", "11363"],
    hidden_items: ["P-1", "11361", "11362", "3636", "3640"],
    whats_this: (
      <>
        <ul>
          <li>HIV-1 Ab, HIV-1 Ag</li>
          <li>HIV-2 Ab</li>
          <li>Herpes (1 and 2)</li>
          <li>Hepatitis B and C</li>
          <li>Syphilis</li>
          <li>Chlamydia and Gonorrhea</li>
        </ul>
      </>
    ),
    grid_order: 5,
  },

  {
    name: "Test Panel Plus",
    sku: "P-21",
    price: 399,
    items: ["91431", "6447", "498", "8472", "36126", "11363", "16185"],
    hidden_items: ["P-1", "P-20", "11361", "11362"],
    whats_this: (
      <>
        <ul>
          <li>HIV-1 Ab, HIV-1 Ag</li>
          <li>HIV-2 Ab</li>
          <li>Herpes (1 and 2)</li>
          <li>Hepatitis B and C</li>
          <li>Syphilis</li>
          <li>Chlamydia and Gonorrhea</li>
          <li>HIV RNA Early Detection</li>
        </ul>
      </>
    ),
    grid_order: 3,
  },
  {
    name: "Early Detection Panel Plus",
    sku: "P-9",
    price: 439,
    items: [
      "91431",
      "16185",
      "90849",
      "6447",
      "11363",
      "P-23",
      "36126",
      "8472",
    ],
    hidden_items: ["11361", "11362", "3636", "3640"],
    whats_this: (
      <>
        <ul>
          <li>Early Detection HIV RNA</li> <li>HIV 1 & 2</li>
          <li>Early Detection Herpes 1 & 2</li> <li>Herpes I & II</li>
          <li>Chlamydia and Gonorrhea</li>
          <li>Syphilis</li>
          <li>Trichomoniasis</li>
          <li>Hepatitis C</li>
        </ul>
      </>
    ),
    grid_order: 6,
  },
];
