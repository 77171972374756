export default function Acknowledgement({
  checked,
  className,
  onChange,
}: {
  checked: any;
  className?: string;
  onChange: any;
}) {
  const link = "https://www.rschealth.com/pages/terms-of-service";
  const className_ = "flex flex-row items-center";
  return (
    <div className={className ? className_ + " " + className : className_}>
      <div className="px-4">
        <input type="checkbox" checked={checked} onChange={onChange} />
      </div>
      <div>
        I agree to the Notice of{" "}
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="text-rsc-light-blue hover:underline"
        >
          Privacy Practices
        </a>
        ,{" "}
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="text-rsc-light-blue hover:underline"
        >
          Informed Consent
        </a>
        , and the{" "}
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="text-rsc-light-blue hover:underline"
        >
          Terms & Conditions
        </a>
        .
      </div>
    </div>
  );
}
