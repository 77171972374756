import axios from "axios";
import { has, isEmpty, pickBy } from "lodash";
import { createContext, useContext, useState } from "react";
import test_messages from "../account/Messages/test_data";
import { useUser } from "./UserContext";
import { isValidJSONString } from "../utils/functions";

type Order = any;
type Message = any;
type User = {
  Id: string;
  FirstName: string;
  LastName: string;
  Name: string;
  Gender__pc: string;
  PersonBirthdate: string;
  PersonEmail: string;
  PersonMobilePhone: string;
  sub: string;
  family_name: string;
  given_name: string;
};

interface AccountValues {
  account: User | null;
  authCode?: string;
  authenticated?: any;

  isEmailAuth: boolean;
  loading: boolean;
  login?: any;
  opportunities?: any[];
  orders?: Partial<Order>;
  main_view: string;
  messages: Partial<Message>;
  viewing_message?: string;
  viewing_order?: string;
  // handleCodeSubmission: (settings: any) => void;
  setAccount: (account: any) => void;
  // sendAuthCode: (settings: any, callback?: any) => void;
  setAuthCode: (code: string) => void;

  setMainView: (main_view: string) => void;
  // setUser: (user: any | null) => void;
  //setUserInfo: () => void;
  setUserLogin: (setting?: any) => void;
  setViewingMessage: (message_id?: string) => void;
  user: any | null;
}

export const AccountContext = createContext<AccountValues>({
  account: null,
  loading: false,
  main_view: "orders",
  messages: test_messages,
  isEmailAuth: false,
  setAccount: () => {},
  setAuthCode: () => {},
  setMainView: () => {},
  setUserLogin: () => {},
  setViewingMessage: () => {},
  user: null,
});

export const useAccount = () => useContext(AccountContext);

const AccountContextProvider: React.FC<{
  children: any;
}> = ({ children }) => {
  // const { isAuthenticated, user: auth_user } = useAuth0();
  const { is_admin, managing_account, user } = useUser();
  const [authCode, setAuthCode] = useState<string | undefined>();
  const [login, setUserLogin] = useState<any | undefined>();
  const [account, setAccount] = useState<{
    Id: string;
    data: User | null;
  } | null>(null); //({ ...user });
  const [loading, setLoading] = useState<boolean>(false);

  const messages = test_messages;
  const [main_view, setMainView] = useState("orders");
  const [viewing_message, setViewingMessage] = useState<string | undefined>();

  const isEmailAuth = has(login, "PersonEmail");
  // If User is Logged In
  const user_object = pickBy({
    PersonEmail: user?.email,
    PersonMobilePhone: user?.phone_number,
  });
  const get_user_string = JSON.stringify(user_object);

  async function setUpUserAccount(user: string) {
    let data;

    try {
      setLoading(true);
      if (!isValidJSONString(user)) return;

      const user_ = JSON.parse(user);
      if (isEmpty(user_)) return;

      const response = await axios
        .post(`/api/salesforce/user`, user_)
        .catch((error: any) => {
          console.log("There was an error", error);
        });
      data = response?.data?.records?.[0];
    } catch (err) {
      console.log("ERROR SETTING UP USER", err);
    } finally {
      setAccount({
        Id: user,
        data,
      });
      setLoading(false);
    }
  }

  !!user &&
    !isEmpty(user_object) &&
    !is_admin &&
    account?.Id !== get_user_string &&
    !loading &&
    setUpUserAccount(get_user_string);

  !!is_admin &&
    !!managing_account &&
    account?.Id !== managing_account &&
    !loading &&
    setUpUserAccount(managing_account);

  return (
    <AccountContext.Provider
      value={{
        account: account?.data || null,
        authCode,

        isEmailAuth,
        loading,
        login,
        main_view,
        messages,
        viewing_message,
        user,
        //  handleCodeSubmission,
        setAccount,
        //  sendAuthCode,
        setAuthCode,

        setMainView,

        //  setUserInfo,
        setUserLogin,
        setViewingMessage,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export default AccountContextProvider;
