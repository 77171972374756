import SiteMenu from "./menu";
import { useUI } from "../../../context/UIContext";

export default function DrawerMenu() {
  const { is_mobile, open } = useUI();
  if (!is_mobile) return null;
  return (
    <div
      className={`w-screen h-screen fixed top-0 bg-rsc-blue p-8 z-50`}
      style={{
        opacity: 0.97,
        transform: open ? "translate3d(0vw, 0, 0)" : "translate3d(100vw, 0, 0)",
        transition: "transform .3s cubic-bezier(0, .52, 0, 1)",
      }}
    >
      <SiteMenu />
    </div>
  );
}
