import IonIcon from "@reacticons/ionicons";
import { useConsultation } from "../../../context/ConsultationContext";
import { usePartnerConsultation } from "../../../context/PartnerConsultationContext";
import LabMap from "./lab_map";

export default function SelectedCenter({ pharmacy }: { pharmacy: any }) {
  const { partner: isPartner, handlePatientChange } = useConsultation();
  const { handlePartnerChange } = usePartnerConsultation();
  const handlePersonChange = isPartner
    ? handlePartnerChange
    : handlePatientChange;
  const {
    location,
    name,
    Street,
    City,
    State,
    Zip_Code,
    phone,
    direction_url,
  } = pharmacy;

  function LabAddress() {
    return (
      <div className="md:grow text-blue-600 font-semibold">
        <div className="grow">
          <button
            className="text-blue-600 font-semibold"
            onClick={() => {
              isPartner && handlePartnerChange("same_pharmacy", false);
              handlePersonChange("pharmacy", null);
            }}
          >
            Change Pharmacy
          </button>
        </div>
        <div className="text-gray-600">
          <p className="my-1">
            <span className="text-xl font-semibold italic">{name}</span>
            <br />
            {Street}
            <br />
            {City}, {State} {Zip_Code}
            <br /> Phone: {phone}
          </p>
        </div>
        <div>
          <a href={direction_url} target="_blank" rel="noreferrer">
            <IonIcon
              name="globe-sharp"
              style={{ transform: "translateY(2px)" }}
            />{" "}
            Directions
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 p-3">
      <LabAddress />
      <LabMap
        center={{
          lat: location.coordinates[1],
          lng: location.coordinates[0],
        }}
      />
    </div>
  );
}
