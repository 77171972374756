export default function InvoicePromotions() {
  return (
    <table style={{ width: "100%" }}>
      <tbody>
        <tr>
          <td
            style={{
              fontSize: 24,
              fontWeight: 600,
              padding: "112px 0",
              textAlign: "center",
            }}
          >
            Save 10% on your next order.
          </td>
        </tr>
      </tbody>
    </table>
  );
}
