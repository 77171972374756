import IonIcon from "@reacticons/ionicons";
import OverviewOrderDetails from "./Overview";
import LabOrderDetails from "./Laboratory";
import TestsOrderDetails from "./Testing";
import { useDashboard } from "../../../DashboardContext";

export function MyOrdersOrderDetails({ message }: { message?: string }) {
  return (
    <div className="w-full flex flex-row my-16 items-center justify-center text-2xl text-rsc-light-blue/20 font-semibold gap-2 md:basis-2/3">
      <div>
        <IonIcon
          name="information-circle"
          style={{ transform: "translateY(4px)" }}
        />
      </div>
      <div>{"Select an order to see details"}</div>
    </div>
  );
}

export default function OrderDetails() {
  const { opportunity } = useDashboard();

  // function getLatestDoc(collection: any[]) {
  //   const ordered = sortBy(collection, function (doc: any) {
  //     const ts = Date.parse(doc.CreatedDate);
  //     return ts;
  //   });
  //   return last(ordered);
  // }

  if (!opportunity) return null;
  return (
    <div className="flex flex-col justify-items-stretch p-3 md:p-5 gap-6 bg-white md:basis-2/3">
      <OverviewOrderDetails />
      <LabOrderDetails />
      <TestsOrderDetails />
    </div>
  );
}
