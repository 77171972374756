import { isEmpty } from "lodash";
import { useRegistration } from "../RegistrationContext";
import { useUI } from "../../context/UIContext";
import LoginHolder from "../holder";
import RSCLoader from "../loader_circle";

export default function ResetPassword() {
  const { setLocation } = useUI();
  const {
    auth_code,
    forgot_password_ready,
    loading,
    username,
    password,
    password2,
    resetPassword,
    setAuthCode,
    setPassword,
    setPassword2,
    setUsername,
  } = useRegistration();

  const input_class = `rounded-lg border border-slate-400 h-10 w-full shadow-inner px-2 ${
    loading ? "bg-slate" : "bg-none"
  }`;

  return (
    <LoginHolder header="Change Password" loading_text="Changing password...">
      <div className="">
        <p className="text-xs">Enter the code sent to:</p>
        <input
          className={input_class}
          disabled={loading}
          id={"username"}
          placeholder={"Email or Phone Number"}
          value={username}
          onChange={(e: any) => setUsername(e.target.value)}
        />
      </div>
      <div className="">
        <input
          className={input_class}
          disabled={loading}
          id={"code"}
          placeholder={"Reset Code"}
          value={auth_code}
          onChange={(e: any) => setAuthCode(e.target.value)}
        />
      </div>
      <div>
        <input
          className={input_class + " mb-3"}
          disabled={loading}
          id={"password"}
          placeholder={"Password"}
          // type={"password"}
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
        />
        <input
          className={input_class}
          disabled={loading}
          id={"password2"}
          placeholder={"Re-Enter Password"}
          // type={"password"}
          value={password2}
          onChange={(e: any) => setPassword2(e.target.value)}
        />
      </div>
      {forgot_password_ready?.length > 0 && (
        <div className="flex flex-col text-xs text-red-600 pl-3 italic">
          {forgot_password_ready.map((msg: string, idx: number) => {
            return <div key={idx}>{msg}</div>;
          })}
        </div>
      )}
      <button
        disabled={!isEmpty(forgot_password_ready) || loading}
        onClick={() => resetPassword()}
        className={`p-3 font-semibold text-center text-white rounded-md shadow-md ${
          isEmpty(forgot_password_ready)
            ? "bg-amber-400 text-white"
            : "bg-gray-300 text-white"
        }`}
      >
        {loading ? (
          <div className="flex flex-row gap-2 items-center justify-center">
            <RSCLoader /> Changing password...
          </div>
        ) : (
          "Change Password"
        )}
      </button>
      <div className="flex flex-row text-xs text-rsc-light-blue font-semibold ">
        <div className="grow">
          <button
            className="text-rsc-light-blue font-semibold"
            onClick={() => setLocation("/login")}
          >
            Log In
          </button>
          {" or "}
          <button
            className="text-rsc-light-blue font-semibold"
            onClick={() => setLocation("/register")}
          >
            Register
          </button>
        </div>
        <div>
          <button onClick={() => setLocation("/forgot-password")}>
            Request Code
          </button>
        </div>
      </div>
    </LoginHolder>
  );
}
