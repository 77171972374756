import IonIcon from "@reacticons/ionicons";
import { usePharmacy } from "../PharmacyContext";

function SetZipCode() {
  const {
    getAndSetCenter,
    getCurrentLocation,
    loading,
    setLoading,
    setView,
    setZipCode,
    view,
    zip_code,
  } = usePharmacy();

  const canSubmit = zip_code.length === 5;

  return (
    <>
      <div className="border-b border-rsc-light-blue/30 pb-2 mb-2 text-rsc-light-blue text-2xl">
        Locate Pharmacy
      </div>
      <div className="">
        <button
          className="rounded border border-zinc-200 bg-gray-100 text-zinc-500 p-2"
          onClick={() => {
            if (loading) {
              setLoading(false);
            } else {
              setLoading(true);
              getCurrentLocation();
            }
          }}
        >
          <span className="icon-text text-base">
            {loading ? (
              <>
                <IonIcon name="time" /> Loading...
              </>
            ) : (
              <>
                <IonIcon name="location-sharp" /> Use My Location
              </>
            )}
          </span>
        </button>
      </div>
      <div className=" flex">
        <div className="flex-grow">
          <input
            className="rounded-tl-lg rounded-bl-lg border border-r-0 border-slate-400 h-10 w-full shadow-inner px-2"
            placeholder="Enter a zip code"
            type="number"
            value={zip_code}
            onChange={(e: any) => {
              setZipCode(e.target.value);
            }}
          />
        </div>
        <button
          className={`rounded-tr-lg rounded-br-lg ${
            canSubmit ? "bg-red-600" : "bg-gray-300"
          } text-white text-xl h-10 py-1.5 px-4`}
          disabled={!canSubmit}
          onClick={() => {
            view === "list" && setView("map");
            getAndSetCenter(zip_code);
          }}
        >
          <p className="icon-text">
            <IonIcon name="search-sharp" />
          </p>
        </button>
      </div>
    </>
  );
}

export default SetZipCode;
