import BottomFooter from "./footer_bottom";
import TopFooter from "./footer_top";
export default function FooterLayout() {
  return (
    <footer className="bg-[#33536b] text-white py-[1rem] md:py-[5rem]">
      <div className="flex flex-col gap-[3.25rem] p-3 md:px-12 mx-auto w-full max-w-7xl">
        <TopFooter />
        <BottomFooter />
      </div>
    </footer>
  );
}
