import { usePayment } from "./PaymentInfoContext";

export default function SelectPayNowLater() {
  const { pay_later, setPayLater } = usePayment();

  return (
    <div className="flex flex-col md:flex-row bg-white border-b border-slate-300">
      {[
        { id: "pay_now", label: "Pay Now", value: false },
        { id: "pay_later", label: "Pay Later", value: true },
      ].map((button: any) => {
        const { id, label, value } = button;
        const isActive =
          pay_later === value ? "bg-rsc-blue text-white" : "text-rsc-blue";

        return (
          <button
            onClick={() => setPayLater(value)}
            key={id}
            className={`text-left md:w-32 py-1.5 px-3 text-xs md:text-base md:text-center font-bold ${isActive}`}
            style={id === "pay_later" ? { color: "white" } : {}}
          >
            {label}
          </button>
        );
      })}
    </div>
  );
}
