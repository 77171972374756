import moment from "moment";

export type UserLogin = {
  username: string;
  password: string;
};

export type LoginObject = {
  user: UserLogin;
  callback?: any;
  user_callback?: any;
  setAlert?: any;
};

export type ValidationErrors = {
  id: string | null | undefined;
  validation: any[];
};

export type Person = {
  _id?: string | null;
  address: string;
  address_state: string;
  allergies: string;
  city: string;
  dob: string | null | undefined;
  email: string;
  email_message: boolean;
  family_name: string;
  given_name: string;
  no_allergies: boolean;
  no_medications: boolean;
  notes: string;
  pharmacy?: any | null;
  phone_message: boolean;
  phone_number: string;
  medications: string;
  race_ethnicity: string;
  same_pharmacy?: boolean;
  sex: string;
  surgeries: string;
  symptoms: string;
  test_date: string | null | undefined;
  treating: string[];
  type?: string;
  zip_code: string;
};

export type Order = {
  acknowledged: boolean;
  user: Person | null;
  selected_center?: any;
};

//const default_date = moment().format("YYYY-MM-DD");

export const blank_person: Person = {
  _id: undefined,
  address: "",
  address_state: "",
  allergies: "",
  city: "",
  dob: null,
  email: "",
  email_message: false,
  family_name: "",
  given_name: "",
  no_allergies: false,
  no_medications: false,
  notes: "",
  phone_message: false,
  phone_number: "",
  medications: "",
  race_ethnicity: "",
  sex: "",
  surgeries: "",
  symptoms: "",
  test_date: null,
  treating: [],
  zip_code: "",
};

export const default_person: Person = {
  _id: undefined,
  address: "123 American Way", //
  address_state: "NY", //
  allergies: "Dust and salt", //
  city: "Queens", //
  dob: "1982-12-07", //
  email: "leonel.classy@gmail.com", //
  email_message: true,
  family_name: "Lionel", //
  given_name: "Classy", //
  no_allergies: false, //
  no_medications: true, //
  notes: "Ambient temperature above 72 is preferred",
  phone_message: false,
  phone_number: "",
  medications: "", //
  race_ethnicity: "Native Hawaiian or Other Pacific Islander", //
  sex: "Male", //
  surgeries: "Knee, elbow, lung and foot", //
  symptoms: "Nausea, heartburn, indigestion, upset stomach and diarrhea", //
  test_date: moment().format("YYYY-MM-DD"),
  treating: ["Chlamydia", "Trichomoniasis"], //
  zip_code: "32808", //
};

export const default_center = {
  __typename: "TestCenter",
  _id: "62d9c49620ee7a1f3f488d70",
  name: "Quest Diagnostics",
  address: "2910 South Maguire Rd Suite1010",
  city: "Ocoee",
  state: "FL",
  zip: "34761-4719",
  country: "USA",
  fax: "407-654-0829",
  url: "https://www.google.com/maps/dir/Current+Location/2910+South+Maguire+Rd+Suite1010,+Ocoee,+FL,+34761,+USA/",
  hours:
    "Monday: 6:30 AM-3:30 PM. Tuesday: 6:30 AM-3:30 PM. Wednesday: 6:30 AM-3:30 PM. Thursday: 6:30 AM-3:30 PM. Friday: 6:30 AM-3:30 PM. Saturday: Closed. Sunday: Closed. ",
  location: { __typename: "CenterPoint", coordinates: [-81.541165, 28.528843] },
};

export const blank_order = {
  acknowledged: false,
  selected_center: null,
  user: blank_person,
};

export const default_partners = [
  {
    _id: "zero",
    given_name: "Jane",
    family_name: "Doe",
    dob: moment("1980-08-09T01:37:08Z").format("YYYY-MM-DD"),
    address: "1234 Proficiency Terr.",
    city: "Montgomery",
    address_state: "AL",
    zip_code: "21222",
    email: "janedoe@gmail.com",
    phone_number: "+13234561234",
    phone_message: true,
    no_allergies: true,
    race_ethnicity: "Hispanic or Latino",
    sex: "Female",
    treating: ["Chlamydia", "Gonorrhea", "Herpes I"],
  },
  {
    _id: "1",
    given_name: "John",
    family_name: "Doe",
    dob: moment("1980-08-09T01:37:08Z").format("YYYY-MM-DD"),
    sex: "Male",
    email: "reandom@aol.com",
  },
  {
    _id: "2",
    given_name: "David",
    family_name: "Pierre",
    dob: moment("1980-08-09T01:37:08Z").format("YYYY-MM-DD"),
    sex: "Male",
    address: "1221 Haters Lookout Way",
    city: "Big Mad",
    address_state: "AK",
    zip_code: "11999",
    phone_number: "2034456767",
    treating: ["Herpes II", "Trichomoniasis"],
  },
];
export const default_pharmacy = {
  __typename: "Pharmacy",
  _id: "62d9c49620ee7a1f3f488d70",
  name: "Selassie I 24 Hours",
  Street: "2910 South Maguire Rd Suite1010",
  City: "Ocoee",
  State: "FL",
  Zip_Code: "34761-4719",
  phone: "407-654-0829",
  direction_url:
    "https://www.google.com/maps/dir/Current+Location/2910+South+Maguire+Rd+Suite1010,+Ocoee,+FL,+34761,+USA/",
  location: {
    __typename: "CenterPoint",
    coordinates: [-81.541165, 28.528843],
  },
};

export const default_consultation_tx_data = {
  type: "consultation",
  tx: {
    id: "nhqag71y",
    status: "submitted_for_settlement",
    type: "sale",
    currencyIsoCode: "USD",
    amount: "99.00",
    amountRequested: "99.00",
    merchantAccountId: "maximeassociates",
    subMerchantAccountId: null,
    masterMerchantAccountId: null,
    orderId: null,
    createdAt: "2023-03-31T02:43:36Z",
    updatedAt: "2023-03-31T02:43:36Z",
    customer: {
      id: null,
      firstName: null,
      lastName: null,
      company: null,
      email: null,
      website: null,
      phone: null,
      fax: null,
    },
    billing: {
      id: null,
      firstName: null,
      lastName: null,
      company: null,
      streetAddress: null,
      extendedAddress: null,
      locality: null,
      region: null,
      postalCode: "12345",
      countryName: null,
      countryCodeAlpha2: null,
      countryCodeAlpha3: null,
      countryCodeNumeric: null,
    },
    refundId: null,
    refundIds: [],
    refundedTransactionId: null,
    partialSettlementTransactionIds: [],
    authorizedTransactionId: null,
    settlementBatchId: null,
    shipping: {
      id: null,
      firstName: null,
      lastName: null,
      company: null,
      streetAddress: null,
      extendedAddress: null,
      locality: null,
      region: null,
      postalCode: null,
      countryName: null,
      countryCodeAlpha2: null,
      countryCodeAlpha3: null,
      countryCodeNumeric: null,
    },
    customFields: "",
    accountFundingTransaction: false,
    avsErrorResponseCode: null,
    avsPostalCodeResponseCode: "M",
    avsStreetAddressResponseCode: "I",
    cvvResponseCode: "M",
    gatewayRejectionReason: null,
    processorAuthorizationCode: "5B40PR",
    processorResponseCode: "1000",
    processorResponseText: "Approved",
    additionalProcessorResponse: null,
    voiceReferralNumber: null,
    purchaseOrderNumber: null,
    taxAmount: null,
    taxExempt: false,
    scaExemptionRequested: null,
    processedWithNetworkToken: false,
    creditCard: {
      token: null,
      bin: "371449",
      last4: "8431",
      cardType: "American Express",
      expirationMonth: "12",
      expirationYear: "2025",
      customerLocation: "US",
      cardholderName: null,
      imageUrl:
        "https://assets.braintreegateway.com/payment_method_logo/american_express.png?environment=sandbox",
      isNetworkTokenized: false,
      prepaid: "Unknown",
      healthcare: "Unknown",
      debit: "Unknown",
      durbinRegulated: "Unknown",
      commercial: "Unknown",
      payroll: "Unknown",
      issuingBank: "Unknown",
      countryOfIssuance: "Unknown",
      productId: "Unknown",
      globalId: null,
      graphQLId: null,
      accountType: "credit",
      uniqueNumberIdentifier: null,
      venmoSdk: false,
      accountBalance: null,
      maskedNumber: "371449******8431",
      expirationDate: "12/2025",
    },
    statusHistory: [
      {
        timestamp: "2023-03-31T02:43:36Z",
        status: "authorized",
        amount: "99.00",
        user: "MaximeAndAssociates",
        transactionSource: "api",
      },
      {
        timestamp: "2023-03-31T02:43:36Z",
        status: "submitted_for_settlement",
        amount: "99.00",
        user: "MaximeAndAssociates",
        transactionSource: "api",
      },
    ],
    planId: null,
    subscriptionId: null,
    subscription: { billingPeriodEndDate: null, billingPeriodStartDate: null },
    addOns: [],
    discounts: [],
    descriptor: { name: null, phone: null, url: null },
    recurring: false,
    channel: null,
    serviceFeeAmount: null,
    escrowStatus: null,
    disbursementDetails: {
      disbursementDate: null,
      settlementAmount: null,
      settlementCurrencyIsoCode: null,
      settlementCurrencyExchangeRate: null,
      settlementBaseCurrencyExchangeRate: null,
      fundsHeld: null,
      success: null,
    },
    disputes: [],
    achReturnResponses: [],
    authorizationAdjustments: [],
    paymentInstrumentType: "credit_card",
    processorSettlementResponseCode: "",
    processorSettlementResponseText: "",
    networkResponseCode: "XX",
    networkResponseText: "sample network response text",
    threeDSecureInfo: null,
    shipsFromPostalCode: null,
    shippingAmount: null,
    discountAmount: null,
    networkTransactionId: "020230331024336",
    processorResponseType: "approved",
    authorizationExpiresAt: "2023-04-07T02:43:36Z",
    retryIds: [],
    retriedTransactionId: null,
    refundGlobalIds: [],
    partialSettlementTransactionGlobalIds: [],
    refundedTransactionGlobalId: null,
    authorizedTransactionGlobalId: null,
    globalId: "dHJhbnNhY3Rpb25fbmhxYWc3MXk",
    graphQLId: "dHJhbnNhY3Rpb25fbmhxYWc3MXk",
    retryGlobalIds: [],
    retriedTransactionGlobalId: null,
    retrievalReferenceNumber: "1234567",
    achReturnCode: null,
    installmentCount: null,
    installments: [],
    refundedInstallments: [],
    responseEmvData: null,
    acquirerReferenceNumber: null,
    merchantIdentificationNumber: "123456789012",
    terminalIdentificationNumber: "00000001",
    merchantName: "DESCRIPTORNAME",
    merchantAddress: {
      streetAddress: "",
      locality: "Braintree",
      region: "MA",
      postalCode: "02184",
      phone: "5555555555",
    },
    pinVerified: false,
    debitNetwork: null,
    processingMode: null,
    paymentReceipt: {
      id: "nhqag71y",
      globalId: "dHJhbnNhY3Rpb25fbmhxYWc3MXk",
      amount: "99.00",
      currencyIsoCode: "USD",
      processorResponseCode: "1000",
      processorResponseText: "Approved",
      processorAuthorizationCode: "5B40PR",
      merchantName: "DESCRIPTORNAME",
      merchantAddress: {
        streetAddress: "",
        locality: "Braintree",
        region: "MA",
        postalCode: "02184",
        phone: "5555555555",
      },
      merchantIdentificationNumber: "123456789012",
      terminalIdentificationNumber: "00000001",
      type: "sale",
      pinVerified: false,
      processingMode: null,
      networkIdentificationCode: null,
      cardType: "American Express",
      cardLast4: "8431",
      accountBalance: null,
    },
    paypalAccount: {},
    paypalHereDetails: {},
    localPayment: {},
    applePayCard: {},
    androidPayCard: {},
    visaCheckoutCard: {},
    samsungPayCard: {},
  },
  mx: {
    patient: {
      address: "647 Spice Trader Way",
      address_state: "FL",
      allergies: "Testing",
      city: "Orlando",
      dob: "2023-03-10T05:00:00.000Z",
      email: "maxime.and.associates@gmail.com",
      email_message: true,
      family_name: "Maxime",
      given_name: "Samuel",
      no_allergies: false,
      no_medications: true,
      notes: "where",
      phone_message: false,
      phone_number: "",
      medications: "",
      race_ethnicity: "Black or African American",
      sex: "Male",
      surgeries: "",
      symptoms: "What",
      test_date: "2023-03-31T02:40:46.927Z",
      treating: ["Herpes I"],
      zip_code: "32818",
      partners: [
        {
          _id: "6426485b2c5e87a8f6ca69f7",
          address: "647 Spice Trader Way",
          address_state: "FL",
          allergies: "Brisk",
          city: "Orlando",
          dob: "2023-02-28T05:00:00.000Z",
          email: "maxime.and.associates@gmail.com",
          email_message: true,
          family_name: "Bang",
          given_name: "Flash",
          no_allergies: true,
          no_medications: false,
          notes: "back",
          phone_message: false,
          phone_number: "",
          medications: "Winter",
          race_ethnicity: "Asian",
          sex: "Female",
          surgeries: "",
          symptoms: "Charge",
          test_date: "2023-03-31T02:40:46.927Z",
          treating: ["Herpes I"],
          zip_code: "32818",
          partners: [],
          same_pharmacy: true,
        },
      ],
      pharmacy: {
        __typename: "Pharmacy",
        _id: "632dcf5a756bafb7287d0f17",
        direction_url:
          "https://www.google.com/maps/dir/Current+Location/7001+Old+Winter+Garden+Rd,+Orlando,+FL,+32835,+USA/",
        name: "CVS Pharmacy",
        Street: "7001 Old Winter Garden Rd",
        City: "Orlando",
        State: "FL",
        Zip_Code: "32835",
        phone: "419-468-3139",
        location: {
          __typename: "CenterPoint",
          coordinates: [-81.4766, 28.5397],
        },
      },
    },
    partners: [
      {
        _id: "6426485b2c5e87a8f6ca69f7",
        address: "647 Spice Trader Way",
        address_state: "FL",
        allergies: "Brisk",
        city: "Orlando",
        dob: "2023-02-28T05:00:00.000Z",
        email: "maxime.and.associates@gmail.com",
        email_message: true,
        family_name: "Bang",
        given_name: "Flash",
        no_allergies: true,
        no_medications: false,
        notes: "back",
        phone_message: false,
        phone_number: "",
        medications: "Winter",
        race_ethnicity: "Asian",
        sex: "Female",
        surgeries: "",
        symptoms: "Charge",
        test_date: "2023-03-31T02:40:46.927Z",
        treating: ["Herpes I"],
        zip_code: "32818",
        partners: [],
        same_pharmacy: true,
      },
    ],
  },
};

export const default_no_payment_consultation_tx_data = {
  type: "consultation",
  tx: {
    id: "Consultation Only",
  },
  mx: {
    patient: {
      address: "647 Spice Trader Way",
      address_state: "FL",
      allergies: "Testing",
      city: "Orlando",
      dob: "2023-03-10T05:00:00.000Z",
      email: "maxime.and.associates@gmail.com",
      email_message: true,
      family_name: "Maxime",
      given_name: "Samuel",
      no_allergies: false,
      no_medications: true,
      notes: "where",
      phone_message: false,
      phone_number: "",
      medications: "",
      race_ethnicity: "Black or African American",
      sex: "Male",
      surgeries: "",
      symptoms: "What",
      test_date: "2023-03-31T02:40:46.927Z",
      treating: ["Herpes I"],
      zip_code: "32818",
      partners: [
        {
          _id: "6426485b2c5e87a8f6ca69f7",
          address: "647 Spice Trader Way",
          address_state: "FL",
          allergies: "Brisk",
          city: "Orlando",
          dob: "2023-02-28T05:00:00.000Z",
          email: "maxime.and.associates@gmail.com",
          email_message: true,
          family_name: "Bang",
          given_name: "Flash",
          no_allergies: true,
          no_medications: false,
          notes: "back",
          phone_message: false,
          phone_number: "",
          medications: "Winter",
          race_ethnicity: "Asian",
          sex: "Female",
          surgeries: "",
          symptoms: "Charge",
          test_date: "2023-03-31T02:40:46.927Z",
          treating: ["Herpes I"],
          zip_code: "32818",
          partners: [],
          same_pharmacy: true,
        },
      ],
    },
    partners: [
      {
        _id: "6426485b2c5e87a8f6ca69f7",
        address: "647 Spice Trader Way",
        address_state: "FL",
        allergies: "Brisk",
        city: "Orlando",
        dob: "2023-02-28T05:00:00.000Z",
        email: "maxime.and.associates@gmail.com",
        email_message: true,
        family_name: "Bang",
        given_name: "Flash",
        no_allergies: true,
        no_medications: false,
        notes: "back",
        phone_message: false,
        phone_number: "",
        medications: "Winter",
        race_ethnicity: "Asian",
        sex: "Female",
        surgeries: "",
        symptoms: "Charge",
        test_date: "2023-03-31T02:40:46.927Z",
        treating: ["Herpes I"],
        zip_code: "32818",
        partners: [],
        same_pharmacy: true,
      },
    ],
  },
};

export const default_order_tx_data = {
  tx: {
    id: "d2tc5yjk",
    status: "submitted_for_settlement",
    type: "sale",
    currencyIsoCode: "USD",
    amount: "99.00",
    amountRequested: "99.00",
    merchantAccountId: "maximeassociates",
    subMerchantAccountId: null,
    masterMerchantAccountId: null,
    orderId: null,
    createdAt: "2022-10-17T01:00:06Z",
    updatedAt: "2022-10-17T01:00:07Z",
    customer: {
      id: null,
      firstName: null,
      lastName: null,
      company: null,
      email: null,
      website: null,
      phone: null,
      fax: null,
    },
    billing: {
      id: null,
      firstName: null,
      lastName: null,
      company: null,
      streetAddress: null,
      extendedAddress: null,
      locality: null,
      region: null,
      postalCode: "12345",
      countryName: null,
      countryCodeAlpha2: null,
      countryCodeAlpha3: null,
      countryCodeNumeric: null,
    },
    refundId: null,
    refundIds: [],
    refundedTransactionId: null,
    partialSettlementTransactionIds: [],
    authorizedTransactionId: null,
    settlementBatchId: null,
    shipping: {
      id: null,
      firstName: null,
      lastName: null,
      company: null,
      streetAddress: null,
      extendedAddress: null,
      locality: null,
      region: null,
      postalCode: null,
      countryName: null,
      countryCodeAlpha2: null,
      countryCodeAlpha3: null,
      countryCodeNumeric: null,
    },
    customFields: "",
    accountFundingTransaction: false,
    avsErrorResponseCode: null,
    avsPostalCodeResponseCode: "M",
    avsStreetAddressResponseCode: "I",
    cvvResponseCode: "M",
    gatewayRejectionReason: null,
    processorAuthorizationCode: "0GQ7W6",
    processorResponseCode: "1000",
    processorResponseText: "Approved",
    additionalProcessorResponse: null,
    voiceReferralNumber: null,
    purchaseOrderNumber: null,
    taxAmount: null,
    taxExempt: false,
    scaExemptionRequested: null,
    processedWithNetworkToken: false,
    creditCard: {
      token: null,
      bin: "378282",
      last4: "0005",
      cardType: "American Express",
      expirationMonth: "12",
      expirationYear: "2030",
      customerLocation: "US",
      cardholderName: "TestFirst TestLast",
      imageUrl:
        "https://assets.braintreegateway.com/payment_method_logo/american_express.png?environment=sandbox",
      prepaid: "Unknown",
      healthcare: "Unknown",
      debit: "Unknown",
      durbinRegulated: "Unknown",
      commercial: "Unknown",
      payroll: "Unknown",
      issuingBank: "Unknown",
      countryOfIssuance: "Unknown",
      productId: "Unknown",
      globalId: null,
      graphQLId: null,
      accountType: "credit",
      uniqueNumberIdentifier: null,
      venmoSdk: false,
      accountBalance: null,
      maskedNumber: "378282******0005",
      expirationDate: "12/2030",
    },
    statusHistory: [
      {
        timestamp: "2022-10-17T01:00:07Z",
        status: "authorized",
        amount: "99.00",
        user: "MaximeAndAssociates",
        transactionSource: "api",
      },
      {
        timestamp: "2022-10-17T01:00:07Z",
        status: "submitted_for_settlement",
        amount: "99.00",
        user: "MaximeAndAssociates",
        transactionSource: "api",
      },
    ],
    planId: null,
    subscriptionId: null,
    subscription: { billingPeriodEndDate: null, billingPeriodStartDate: null },
    addOns: [],
    discounts: [],
    descriptor: { name: null, phone: null, url: null },
    recurring: false,
    channel: null,
    serviceFeeAmount: null,
    escrowStatus: null,
    disbursementDetails: {
      disbursementDate: null,
      settlementAmount: null,
      settlementCurrencyIsoCode: null,
      settlementCurrencyExchangeRate: null,
      settlementBaseCurrencyExchangeRate: null,
      fundsHeld: null,
      success: null,
    },
    disputes: [],
    achReturnResponses: [],
    authorizationAdjustments: [],
    paymentInstrumentType: "credit_card",
    processorSettlementResponseCode: "",
    processorSettlementResponseText: "",
    networkResponseCode: "XX",
    networkResponseText: "sample network response text",
    threeDSecureInfo: null,
    shipsFromPostalCode: null,
    shippingAmount: null,
    discountAmount: null,
    networkTransactionId: "020221017010007",
    processorResponseType: "approved",
    authorizationExpiresAt: "2022-10-24T01:00:07Z",
    retryIds: [],
    retriedTransactionId: null,
    refundGlobalIds: [],
    partialSettlementTransactionGlobalIds: [],
    refundedTransactionGlobalId: null,
    authorizedTransactionGlobalId: null,
    globalId: "dHJhbnNhY3Rpb25fZDJ0YzV5ams",
    graphQLId: "dHJhbnNhY3Rpb25fZDJ0YzV5ams",
    retryGlobalIds: [],
    retriedTransactionGlobalId: null,
    retrievalReferenceNumber: "1234567",
    installmentCount: null,
    installments: [],
    refundedInstallments: [],
    responseEmvData: null,
    acquirerReferenceNumber: null,
    merchantIdentificationNumber: "123456789012",
    terminalIdentificationNumber: "00000001",
    merchantName: "DESCRIPTORNAME",
    merchantAddress: {
      streetAddress: "",
      locality: "Braintree",
      region: "MA",
      postalCode: "02184",
      phone: "5555555555",
    },
    pinVerified: false,
    debitNetwork: null,
    processingMode: null,
    paymentReceipt: {
      id: "d2tc5yjk",
      globalId: "dHJhbnNhY3Rpb25fZDJ0YzV5ams",
      amount: "99.00",
      currencyIsoCode: "USD",
      processorResponseCode: "1000",
      processorResponseText: "Approved",
      processorAuthorizationCode: "0GQ7W6",
      merchantName: "DESCRIPTORNAME",
      merchantAddress: {
        streetAddress: "",
        locality: "Braintree",
        region: "MA",
        postalCode: "02184",
        phone: "5555555555",
      },
      merchantIdentificationNumber: "123456789012",
      terminalIdentificationNumber: "00000001",
      type: "sale",
      pinVerified: false,
      processingMode: null,
      networkIdentificationCode: null,
      cardType: "American Express",
      cardLast4: "0005",
      accountBalance: null,
    },
    paypalAccount: {},
    paypalHereDetails: {},
    localPayment: {},
    applePayCard: {},
    androidPayCard: {},
    visaCheckoutCard: {},
    samsungPayCard: {},
  },
  mx: {
    cart_total: 546,
    shopping_cart: ["3636", "11363", "P-20", "11361", "P-23"],
    order: {
      acknowledged: true,
      selected_center: {
        __typename: "TestCenter",
        _id: "62d9c49620ee7a1f3f488d70",
        name: "Quest Diagnostics",
        address: "2910 South Maguire Rd Suite1010",
        city: "Ocoee",
        state: "FL",
        zip: "34761-4719",
        country: "USA",
        fax: "407-654-0829",
        url: "https://www.google.com/maps/dir/Current+Location/2910+South+Maguire+Rd+Suite1010,+Ocoee,+FL,+34761,+USA/",
        hours:
          "Monday: 6:30 AM-3:30 PM. Tuesday: 6:30 AM-3:30 PM. Wednesday: 6:30 AM-3:30 PM. Thursday: 6:30 AM-3:30 PM. Friday: 6:30 AM-3:30 PM. Saturday: Closed. Sunday: Closed. ",
        location: {
          __typename: "CenterPoint",
          coordinates: [-81.541165, 28.528843],
        },
      },
      user: {
        address: "123 American Way",
        address_state: "NY",
        allergies: "Dust and salt",
        city: "Queens",
        dob: "1892-12-07T05:00:00.000Z",
        email: "leonel.classy@gmail.com",
        email_message: true,
        family_name: "Lionel",
        given_name: "Classy",
        no_allergies: false,
        no_medications: true,
        notes: "Ambient temperature above 72 is preferred",
        phone_message: false,
        phone_number: "",
        medications: "",
        race_ethnicity: "Native Hawaiian or Other Pacific Islander",
        sex: "Male",
        surgeries: "Knee, elbow, lung and foot",
        symptoms: "Nausea, heartburn, indigestion, upset stomach and diarrhea",
        test_date: "2021-01-11T05:00:00.000Z",
        treating: ["Chlamydia", "Trichomoniasis"],
        zip_code: "32808",
      },
    },
  },
};
