import { isEmpty } from "lodash";
import SelectType from "../select";
import LoginHolder from "../holder";
import RSCLoader from "../loader_circle";
import { useRegistration } from "../RegistrationContext";
import { useUI } from "../../context/UIContext";

export default function RegisterUser() {
  const { setLocation } = useUI();
  const {
    acknowledged,
    acknowledged_mobile,
    codeDeliveryDetails,
    family_name,
    given_name,
    is_email,
    loading,
    login_type,
    password,
    password2,
    registration_ready,
    username,
    registerNewUser,
    setAcknowledged,
    setAcknowledgedMobile,
    setGivenName,
    setFamilyName,
    setLoginType,
    setPassword,
    setPassword2,
    setUsername,
  } = useRegistration();

  // const validate_registration = z
  //   .object({
  //     username: z.string({
  //       required_error: `${is_email ? 'An email' : 'A mobile number'} is required.`,
  //     }),
  //     given_name: z.string({required_error:'A password is required.'}),
  //     family_name: z.string({required_error:'A password is required.'}),
  //     password: z.string({required_error:'A password is required.'}),
  //     password2: z.string({required_error:'Password must be re-entered.'}),
  //     acknowledged: z.boolean(),
  //     acknowledged_mobile: z.boolean(),

  //   }
  //   );

  const input_class = `rounded-lg border border-slate-400 h-10 w-full shadow-inner px-2 ${
    loading ? "bg-slate" : "bg-none"
  }`;
  const disabled = !registration_ready || loading;
  return (
    <LoginHolder
      header="Register Account"
      loading_text="Registering new user..."
    >
      <SelectType
        login_input={login_type}
        setInput={setLoginType}
        text="Choose Your Registration Type:"
      />
      <div>
        <input
          className={input_class}
          disabled={loading}
          id={is_email ? "email" : "phone_number"}
          placeholder={is_email ? "Email Address" : "Mobile Phone"}
          value={username}
          onChange={(e: any) => setUsername(e.target.value)}
        />
      </div>
      <div>
        <input
          className={input_class + " mb-3"}
          disabled={loading}
          id={"first_name"}
          placeholder={"First Name"}
          autoComplete="new-signup"
          value={given_name}
          onChange={(e: any) => setGivenName(e.target.value)}
        />
        <input
          className={input_class}
          disabled={loading}
          id={"family_name"}
          placeholder={"Last Name"}
          autoComplete="new-signup"
          value={family_name}
          onChange={(e: any) => setFamilyName(e.target.value)}
        />
      </div>
      <div>
        <input
          autoComplete="new-password"
          className={input_class + " mb-3"}
          disabled={loading}
          id={"password"}
          placeholder="Pa&#8203;ssword"
          type={"password"}
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
        />
        <input
          autoComplete="new-passwords"
          className={input_class}
          disabled={loading}
          id={"password2"}
          placeholder={"Re-Enter Password"}
          type={"password"}
          value={password2}
          onChange={(e: any) => setPassword2(e.target.value)}
        />
      </div>
      {!is_email && (
        <div className="flex flex-row items-center gap-6 my-2">
          <div>
            <input
              type="checkbox"
              checked={acknowledged_mobile}
              onChange={(e: any) => {
                setAcknowledgedMobile(e.target.checked);
              }}
            />
          </div>
          <div>
            By signing up via cell number you agree to receive text messages for
            verification used when signing up and signing in. Consent is not a
            condition of any purchase. Reply HELP for help and STOP to cancel.
            Msg & data rates may apply.
          </div>
        </div>
      )}
      <div className="flex flex-row items-center gap-6 my-2">
        <div>
          <input
            type="checkbox"
            checked={acknowledged}
            onChange={(e: any) => {
              setAcknowledged(e.target.checked);
            }}
          />
        </div>
        <div>
          I agree to the Notice of{" "}
          <a
            href="https://www.rapidstdtesting.com/terms-and-policies/"
            target="_blank"
            rel="noreferrer"
            className="text-rsc-light-blue hover:underline"
          >
            Privacy Practices, Informed Consent, and the Terms & Conditions
          </a>
          .
        </div>
      </div>
      {!isEmpty(registration_ready) && (
        <div className="flex flex-col text-red-600 text-xs pb-3 pl-3 italic">
          {registration_ready.map((msg: string, idx: number) => {
            return <div key={idx}>{msg}</div>;
          })}
        </div>
      )}
      <div className="flex flex-row gap-6">
        <div className="grow">
          <button
            onClick={() => registerNewUser()}
            className={`${
              disabled ? "bg-gray-200 text-gray-500" : "bg-rsc-blue text-white"
            } font-semibold text-center p-3 rounded-md shadow-md`}
            disabled={disabled}
          >
            {loading ? (
              <div className="flex flex-row gap-2 items-center">
                <RSCLoader /> Registering...
              </div>
            ) : (
              "Register"
            )}
          </button>
        </div>
        <div>
          <button
            onClick={() => setLocation("/login")}
            className="bg-red-700 font-semibold text-white text-center p-3 rounded-md shadow-md"
          >
            Cancel
          </button>
        </div>
      </div>
      {codeDeliveryDetails && (
        <div className="text-right">
          <button
            className="text-xs text-rsc-light-blue font-semibold"
            onClick={() => setLocation("/verify")}
          >
            Verify Sent Code
          </button>
        </div>
      )}
    </LoginHolder>
  );
}
