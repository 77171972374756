import { useState } from "react";
import { InfoWindow, Marker } from "@react-google-maps/api";
import { useOrder } from "../../OrderContext";

export default function MapMarker({ center }: { center: any }) {
  const { handleOrderChange } = useOrder();
  const [active_item, setActiveItem] = useState<boolean>(false);

  const { address, city, name, state, zip_code, location } = center;
  const { coordinates } = location;
  return (
    <Marker
      // animation={1}
      icon={{
        scaledSize: new window.google.maps.Size(24, 35),
        url: "https://res.cloudinary.com/maxime-associates/image/upload/v1665940788/RapidSTDTesting/dark-blue_2x.png",
      }}
      position={{ lat: coordinates[1], lng: coordinates[0] }}
      onClick={() => {
        console.log("CLICKED", center);
        handleOrderChange("selected_center", center);
      }}
      onMouseOver={() => {
        setActiveItem(true);
      }}
      onMouseOut={() => {
        setActiveItem(false);
      }}
    >
      {active_item && (
        <InfoWindow>
          <div
            className="bg-white text-black"
            //style={{ top: globalCoords.y, left: globalCoords.x }}
          >
            <h1 className="font-semibold">{name}</h1>
            <p>
              {address}
              <br />
              {`${city}, ${state} ${zip_code}`}
            </p>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
}
