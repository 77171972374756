import DocumentHeader from "../Header";
import InvoicePayment from "../Payments";
import ConsultationPatientInformation from "../PatientInformation/consultation";
import InvoicePromotions from "../Promotions";

export default function PurchaseInvoice({
  data,
  for_pdf,
}: {
  brief?: any;
  data: any;
  for_pdf?: boolean;
}) {
  const { tx, mx } = data;
  const people = [mx.patient, ...mx.patient.partners];
  const no_payment = tx?.id === "Consultation Only";

  return (
    <div id="order-confirmation" style={{ fontFamily: "Poppins" }}>
      <DocumentHeader
        for_pdf={for_pdf}
        label="Consultation Confirmation"
        sublabel="none"
      />
      {people.map((person: any, idx: any) => {
        return (
          <ConsultationPatientInformation
            is_purchaser={idx === 0}
            user={person}
            key={idx}
          />
        );
      })}
      <InvoicePromotions />
      {!no_payment && <InvoicePayment data={data} />}
    </div>
  );
}
