import { useState } from "react";
import axios from "axios";
import ConfirmButton from "../../../../../Confirmation/print_button";
import { useAccount } from "../../../../../context/AccountContext";

const { REACT_APP_ENV } = process.env;
const is_development = REACT_APP_ENV === "development";
export default function PageLayout({ pdf }: { pdf: string }) {
  const { account } = useAccount();
  const [email_loading, setEmailLoading] = useState<boolean>(false);
  const [print_loading, setPrintLoading] = useState<boolean>(false);

  return (
    <div className="flex flex-row">
      <div className="grow">
        <ConfirmButton
          disabled={email_loading}
          icon="mail"
          label={email_loading ? "Sending email..." : "Email"}
          onClick={async () => {
            setEmailLoading(true);
            await axios
              .post("/api/invoice/raw_email", {
                email: {
                  subject: `Invoice`,
                  bcc: is_development
                    ? "maxime.and.associates@gmail.com"
                    : "maxime.and.associates@gmail.com, dhines@rapidscreenings.com",
                  to: account?.PersonEmail,
                },
                html: "",
                pdf,
              })
              .catch((error: any) => {
                console.log("ERROR SENDING EMAIL", error);
              });
            setEmailLoading(false);
          }}
        />
      </div>
      <div>
        <ConfirmButton
          disabled={true || print_loading}
          icon="print-sharp"
          label={print_loading ? "Preparing document..." : "Download/Print"}
          onClick={async () => {
            setPrintLoading(true);
            const doc = new Blob([pdf], { type: "application/pdf;base64" });
            const fileURL = URL.createObjectURL(doc);
            window.open(fileURL);
            setPrintLoading(false);
          }}
        />
      </div>
    </div>
  );
}
