import moment from "moment";
import { getPDF } from "../../../../../../utils/requests";
import IonIcon from "@reacticons/ionicons";
import { head, orderBy, reject } from "lodash";
import { useUI } from "../../../../../../context/UIContext";
import { useDashboard } from "../../../../../DashboardContext";

export default function TestActions() {
  const { setLocation } = useUI();
  const { docs, results } = useDashboard();
  const test_result_docs = reject(docs, ["Title", "Order Submission"]);
  const latest_doc = head(
    orderBy(
      test_result_docs,
      [(obj: any) => moment(obj.CreatedDate).valueOf()],
      ["desc"]
    )
  );
  const doc = latest_doc?.VersionData;
  const showConsultationLink = (results || []).some(
    result =>
      result.Result__c === "Positive" &&
      [
        "CHLAMYDIA TRACHOMATIS RNA, TMA, UROGENITAL",
        "NEISSERIA GONORRHOEAE RNA, TMA, UROGENITAL",
        "TRICHOMONAS VAGINALIS RNA QUALITATIVE TMA, MALES",
        "TRICHOMONAS VAGINALIS RNA, QL TMA",
        "HSV 1 IGG, TYPE SPECIFIC AB",
        "HSV 2 IGG, TYPE SPECIFIC AB",
      ].includes(result.Test_Name__c)
  );

  return (
    <div className="flex flex-col md:flex-row items-center gap-4 md:gap-0 mb-8">
      <div className="grow">
        {showConsultationLink && (
          <button
            className="w-64 py-2 bg-[#d60d26] text-white rounded-md shadow-md hover:shadow-lg"
            onClick={() => setLocation("/consultation")}
          >
            <IonIcon
              name="medical"
              className="mr-3 text-white translate-y-[2px]"
            />
            GET TREATMENT NOW!
          </button>
        )}
      </div>
      <div>
        <button
          className="w-64 py-2 bg-rsc-light-blue text-white rounded-md shadow-sm hover:shadow-lg"
          onClick={() => getPDF(doc)}
        >
          <IonIcon
            name="download"
            style={{ marginRight: 12, transform: "translateY(2px)" }}
          />
          Download Results
        </button>
      </div>
    </div>
  );
}
