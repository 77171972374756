import IonIcon from "@reacticons/ionicons";
import { useAccount } from "../../../context/AccountContext";
import { find, isEmpty } from "lodash";
export default function Attachments() {
  const { messages, viewing_message } = useAccount();
  if (!viewing_message) return null;
  const { attachments } = find(messages, ["id", viewing_message]);
  if (isEmpty(attachments)) return null;
  return (
    <>
      <hr className="shadow-md border-0 h-2" />
      <div className="italic">
        {attachments.map((att: any) => {
          const { account_msg_attach_id, account_msg_attach_filename } = att;
          return (
            <div key={account_msg_attach_id}>
              <IonIcon name="attach" /> {account_msg_attach_filename}
            </div>
          );
        })}
      </div>
    </>
  );
}
