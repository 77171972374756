import moment from "moment";
import ContactSupport from "../../../../../../../components/ContactSupport";
import { useUI } from "../../../../../../../context/UIContext";

export default function InformationLabResults({
  opportunity,
}: {
  opportunity: any;
}) {
  const { setPopUp } = useUI();
  const { CreatedDate, Payment_Status__c, StageName } = opportunity;
  return (
    <div className="flex flex-col grow">
      <div className="text-rsc-light-blue">
        {StageName} {Payment_Status__c}
      </div>
      <div className="text-gray-500">{moment(CreatedDate).format("lll")}</div>
      <div className="mt-2 text-rsc-light-blue font-semibold">
        <button onClick={() => setPopUp(<ContactSupport />)}>
          Contact Support
        </button>
      </div>
    </div>
  );
}
