import IonIcon from "@reacticons/ionicons";
import PopUp from "./popup";
import { useUI } from "../../../../../context/UIContext";
import { useDashboard } from "../../../../DashboardContext";
import { useAccount } from "../../../../../context/AccountContext";

export default function OrderSubmitted({
  doc,
  laboratory,
}: {
  doc?: string;
  laboratory: any;
}) {
  const { setPopUp } = useUI();
  const { account } = useAccount();
  const { opportunity } = useDashboard();

  return (
    <div className="flex flex-col md:flex-row gap-4 md:gap-16">
      <div className="text-gray-600">
        We have electronically sent your lab forms to the lab. When you’re ready
        to visit the lab, you can also access your lab forms here. If needed you
        can choose between printing them or sending the forms by fax to the lab.
      </div>
      <div className="text-center py-12 md:py-0 md:text-right">
        <button
          className="w-64 py-2 bg-rsc-light-blue text-white rounded-md shadow-sm hover:shadow-lg"
          onClick={() =>
            setPopUp(
              <div
                className="bg-white my-6 mx-auto w-full md:min-w-[440px] sm:max-w-md md:border sm:border-rsc-light-blue/10 sm:rounded-xl shadow-lg overflow-hidden"
                onClick={e => e.stopPropagation()}
              >
                <PopUp
                  laboratory={laboratory}
                  invoice={doc}
                  subject={
                    opportunity?.Name ||
                    `${account?.FirstName} ${account?.LastName}`
                  }
                />
              </div>
            )
          }
        >
          <IonIcon
            name="medkit"
            style={{ marginRight: 12, transform: "translateY(2px)" }}
          />{" "}
          Access Lab Forms
        </button>
      </div>
    </div>
  );
}
