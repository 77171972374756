import { createContext, useContext, useReducer, useState } from "react";
import { PartnerConsultationReducer } from "./ConsultationReducer";
import { useConsultation } from "../context/ConsultationContext";
import { concat, find, reject } from "lodash";
import { useUI } from "../../../context/UIContext";
import {
  blank_person,
  default_person as default_partner,
  Person,
} from "../../../utils/types";

const isTesting = false;

interface PartnerConsultationValues {
  alert_message?: string;
  default_partner: Person;
  existing: boolean;
  isSubmitting: boolean;
  partner: Person;
  valid_contact: boolean;
  updatePartner: () => void;
  handlePartnerChange: (state: string, value: any) => void;
  loadPartner: (partner: Person) => void;
  setAlertMessage: (message: string | undefined) => void;
  setIsSubmitting: (setting: boolean) => void;
}

export const PartnerConsultationContext =
  createContext<PartnerConsultationValues>({
    alert_message: "",
    default_partner,
    existing: false,
    isSubmitting: false,
    partner: default_partner,
    valid_contact: false,
    updatePartner: () => {},
    handlePartnerChange: () => {},
    loadPartner: () => {},
    setAlertMessage: () => {},
    setIsSubmitting: () => {},
  });

export const usePartnerConsultation = () =>
  useContext(PartnerConsultationContext);

export default function PartnerConsultationContextProvider({
  children,
  _id,
}: {
  _id?: string;
  children: any;
}) {
  const { setLocation } = useUI();
  const { handlePatientChange, partners } = useConsultation();

  function setPartner() {
    const cached_partner = window.localStorage.getItem("partner");
    if (JSON.parse(cached_partner || "{}")?._id === _id) {
      return cached_partner;
    }
    const existing_partner = find(partners, ["_id", _id]);
    if (!!existing_partner) return existing_partner;

    return isTesting
      ? { ...default_partner, type: "partner" }
      : { ...blank_person, _id, type: "partner" };
  }

  const [partner, dispatch] = useReducer(
    PartnerConsultationReducer,
    setPartner()
  );

  const existing = !!partner?._id;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [alert_message, setAlertMessage] = useState<string | undefined>();

  function handlePartnerChange(field: string, value: any) {
    console.log("PARTNER CHANGE", field, value);
    dispatch({
      type: "HANDLE_CHANGE",
      partner: {
        field,
        value,
      },
    });
  }

  function loadPartner(partner: any) {
    dispatch({
      type: "LOAD_PARTNER",
      partner,
    });
  }

  const updatePartner = () => {
    const new_array = reject(partners, ["_id", partner._id]);
    handlePatientChange("partners", concat(new_array, partner));
    window.localStorage.removeItem("partner");
    setLocation("/consultation");
  };

  return (
    <PartnerConsultationContext.Provider
      value={{
        alert_message,
        default_partner,
        existing,
        isSubmitting,
        valid_contact: true,
        handlePartnerChange,
        loadPartner,
        partner,
        setAlertMessage,
        setIsSubmitting,
        updatePartner,
      }}
    >
      {children}
    </PartnerConsultationContext.Provider>
  );
}
