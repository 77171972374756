import { RingLoader } from "react-spinners";

export default function PreLoader({ text = "Loading..." }: { text?: string }) {
  return (
    <div className="p-48 w-full h-full flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-4 h-full">
        <div className="">
          <RingLoader color={"#11669b"} size={130} />
        </div>
        <div className="text-rsc-light-blue  text-xs">{text}</div>
      </div>
    </div>
  );
}
