import { useState } from "react";
import { InfoWindow, Marker } from "@react-google-maps/api";
import { useConsultation } from "../../../context/ConsultationContext";
import { usePartnerConsultation } from "../../../context/PartnerConsultationContext";

export default function MapMarker({ pharmacy }: { pharmacy: any }) {
  const { handlePatientChange, partner: isPartner } = useConsultation();
  const { handlePartnerChange } = usePartnerConsultation();
  const handlePersonChange = isPartner
    ? handlePartnerChange
    : handlePatientChange;

  const [active_item, setActiveItem] = useState<boolean>(false);

  const { Street, City, name, State, Zip_Code, location } = pharmacy;
  const { coordinates } = location;
  return (
    <Marker
      position={{ lat: coordinates[1], lng: coordinates[0] }}
      onClick={() => handlePersonChange("pharmacy", pharmacy)}
      onMouseOver={() => {
        setActiveItem(true);
      }}
      onMouseOut={() => {
        setActiveItem(false);
      }}
    >
      {active_item && (
        <InfoWindow>
          <div
            className="bg-white text-black"
            //style={{ top: globalCoords.y, left: globalCoords.x }}
          >
            <h1 className="font-semibold">{name}</h1>
            <p>
              {Street}
              <br />
              {`${City}, ${State} ${Zip_Code}`}
            </p>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
}
