import { useEffect } from "react";
import axios from "axios";
import braintree from "braintree-web";
import Section from "../../Section";
import { useBraintree } from "../BraintreeContext";
import Visa from "./cc_icons/visa.svg";
import Mastercard from "./cc_icons/mastercard.svg";
import Discover from "./cc_icons/discover.svg";
import Amex from "./cc_icons/amex.svg";

export default function CCPaymentInfo({ submit }: { submit: any }) {
  const { setHostedFieldsInstance } = useBraintree();

  const ccs: { [key: string]: any } = {
    visa: Visa,
    mastercard: Mastercard,
    discover: Discover,
    amex: Amex,
  };

  useEffect(() => {
    const initializeBraintree = async () => {
      const clientToken = await axios("/api/payment/initialize").then(
        response => response.data
      );

      const braintreeInstance = await braintree.client.create({
        authorization: clientToken,
      });
      const hostedFields = await braintree.hostedFields.create({
        client: braintreeInstance,
        fields: {
          number: {
            selector: "#ccnumber",
            placeholder: "1111 1111 1111 1111",
          },
          cvv: {
            selector: "#cvv",
            placeholder: "123",
          },
          expirationDate: {
            selector: "#ccexp",
            placeholder: "MM/YYYY",
          },
          postalCode: {
            selector: "#zipcode",
            placeholder: "11111",
          },
        },
        styles: {
          input: {
            "font-size": "16px",
            "font-family": "Helvetica Neue, Helvetica, Arial, sans-serif",
            color: "#373f4a",
          },
          ":focus": {
            color: "black",
          },
        },
      });

      setHostedFieldsInstance(hostedFields);
    };

    initializeBraintree();
  }, [setHostedFieldsInstance]);

  return (
    <>
      <Section>
        <div className="flex flex-row justify-end mt-2 mb-4 gap-1">
          <div className="flex-grow text-sm">
            *Charges will appear as "RSC HEALTH"
          </div>
          {["visa", "mastercard", "discover", "amex"].map((cc: string) => {
            return (
              <div key={cc}>
                <img src={ccs[cc]} alt={cc} width="30" />
              </div>
            );
          })}
        </div>
        <div className="flex flex-col gap-3 mb-3">
          <div className="flex flex-row gap-4 items-center">
            <div>
              <label>Credit Card Number:</label>
              <div
                id="ccnumber"
                className="flex items-center bg-white text-xl rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
              />
            </div>
            <div className="basis-2/3">
              <label>Expiration:</label>
              <div
                id="ccexp"
                className="flex items-center bg-white text-xl rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
              />
            </div>
          </div>
          <div className="flex flex-row gap-4 items-center">
            <div className="basis-1/3">
              <label>CVV:</label>
              <div
                id="cvv"
                className="flex items-center bg-white text-xl rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
              />
            </div>
            <div className="basis-2/3">
              <label>Zip Code:</label>
              <div
                id="zipcode"
                className="flex items-center bg-white text-xl rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
              />
            </div>
          </div>
        </div>
      </Section>
      <Section>{submit}</Section>
    </>
  );
}
