import { useEffect, useRef } from "react";
import { useUI } from "./context/UIContext";

function usePrevious<T>(value: T): T {
  const ref: any = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  return ref.current;
}

export default function ScrollToTop() {
  const { location } = useUI();

  let prevPath = usePrevious(location);

  useEffect(() => {
    if (prevPath !== location && !window.location.hash) {
      window.scrollTo(0, 0);
    }
  }, [location, prevPath]);

  return null;
}
