import { createContext, useContext, useState } from "react";
import { renderToString } from "react-dom/server";
import { useQuery } from "@apollo/client";
import axios from "axios";
import { isEmpty } from "lodash";
//import pay_later_tx_data from "./tx_data/pay_later.json";
import payment_cc_tx_data from "./tx_data/payment_cc.json";
//import payment_pp_tx_data from "./tx_data/payment_pp.json";
import consultation_transaction from "../../consultation/ScheduleConsultation/context/transaction";
//import test_order_transaction from "./tx_data/order_cc_tx_data.json";
import { PurchaseInvoice as OrderPDFConfirmation } from "../../Confirmation/OrderInvoice";
import ConsultationPDFConfirmation from "../../Confirmation/ConsultationInvoice";
import PaymentPDFConfirmation from "../../Confirmation/PaymentReceipt";
import { GET_DOCTOR } from "../../utils/queries";
// import { useOrder } from "../../order/ScheduleTesting/OrderContext";
import { useUI } from "../../context/UIContext";
import { useBraintree } from "./BraintreeContext";
import { useCart } from "../../context/CartContext";
import { useAccount } from "../../context/AccountContext";

// const GET_DOCTOR = gql`
//   query getDoctor($state: String!) {
//     getDoctor(state: $state) {
//       _id
//       first_name
//       last_name
//       npi
//       state
//     }
//   }
// `;

interface PaymentValues {
  ccnumber: string;
  doctor_data: any;
  errors: any | null;
  method: string;
  tokenObject: any;

  isPaymentMade: boolean;
  loading: boolean;
  loading_doctor: boolean;
  pay_later: boolean;
  tx_data: any | null;
  zip_code: string;
  // renderPayment: () => void;
  goToConfirmation: (payload: any) => void;
  processCreditCard: (amount_validation: any) => Promise<void>;
  processConfirmation: (_data: any) => void;
  setCCNumber: (ccnumber: string) => void;
  setIsPaymentMade: (status: boolean) => void;
  setLoading: (setting: boolean) => void;
  setMethod: (method: string) => void;
  setPayLater: (pay_later: boolean) => void;
  setTransactionData: (tx_data: any) => void;
  setZipCode: (zip_code: any) => void;
}

export const PaymentContext = createContext<PaymentValues>({
  ccnumber: "",
  doctor_data: null,
  errors: null,
  loading_doctor: false,
  method: "credit_card",
  tokenObject: {},

  isPaymentMade: false,
  loading: false,
  pay_later: false,
  tx_data: null,
  zip_code: "",
  // renderPayment: () => {},
  goToConfirmation: () => {},
  processCreditCard: async () => {},
  processConfirmation: () => {},
  setCCNumber: () => {},
  setIsPaymentMade: () => {},
  setLoading: () => {},
  setMethod: () => {},
  setPayLater: () => {},
  setTransactionData: () => {},
  setZipCode: () => {},
});

export const usePayment = () => useContext(PaymentContext);

const PaymentContextProvider: React.FC<{
  children: any;
}> = ({ children }) => {
  const {
    is_development,
    is_testing_confirmation,
    is_testing_order,
    mute_emails,
    mute_payment,
    us_states,
    setLocation,
  } = useUI();
  const initial_tx_data = is_testing_confirmation
    ? consultation_transaction
    : undefined;

  const { account } = useAccount();
  // const { order } = useOrder();
  const { resetCart } = useCart();
  const { submitPayment: submitCreditCardPayment } = useBraintree();

  const [tx_data, setTransactionData] = useState<any | null>(initial_tx_data);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setPayErrors] = useState<any[]>([]);
  const [pay_later, setPayLater] = useState<boolean>(false);
  const [method, setMethod] = useState<string>("credit_card");
  const [ccnumber, setCCNumber] = useState<string>("");
  const [zip_code, setZipCode] = useState<string>("");
  const [isPaymentMade, setIsPaymentMade] = useState(false);
  const order_user_state = tx_data?.mx?.order?.user?.address_state;
  const { loading: loading_doctor, data: doctor_data } = useQuery(GET_DOCTOR, {
    skip: !order_user_state,
    variables: {
      state: us_states[order_user_state || "NY"],
    },
  });

  function goToConfirmation({
    // NOT NEEDED
    reset_cart,
    route,
    tx_data,
  }: {
    reset_cart?: boolean;
    route: string;
    tx_data: any;
  }) {
    reset_cart && resetCart();
    setTransactionData(tx_data);
    setLocation(route);
  }

  async function confirmTransaction(transaction_data: any) {
    const response = await axios.post(
      "/api/payment/confirm",
      transaction_data,
      {
        withCredentials: true,
      }
    );
    const { errors, transaction: tx } = response.data;

    if (errors) {
      return { errors };
    }
    return { tx };
  }

  async function sendConfirmationEmails(data: any) {
    const { email, html, pdf } = data;
    // For Administrator
    await axios
      .post("/api/email/send", {
        html: renderToString(html.admin),
        pdf,
        ...email.admin,
      })
      .catch((error: any) => {
        console.log("ERROR SENDING TO CUSTOMER", error);
      });

    // For Customer

    await axios
      .post("/api/email/send", {
        html: renderToString(html.customer),
        pdf,
        ...email.customer,
      })
      .catch((error: any) => {
        console.log("ERROR SENDING TO CUSTOMER", error);
      });
  }

  const processConfirmation = async (tx_data: any) => {
    const { mx, payment_data = {}, type, tx } = tx_data;

    const PDFConfirmation: any = {
      order: OrderPDFConfirmation,
      consultation: ConsultationPDFConfirmation,
      payment: PaymentPDFConfirmation,
    }[type as string];

    try {
      if (type === "order") {
        // SETUP OPPORTUNITY
        const { data: setup_data } = await axios.post(
          "/api/salesforce/setup_opportunity",
          { account, tx_data }
        );
        // ONLY CREATE OPPORTUNITY
        const { data: salesforce_data } = await axios.post(
          "/api/salesforce/create_opportunity",
          { account, tx_data, ...setup_data }
        );
        tx_data.oid = salesforce_data?.new_opportunity_id;
        //   tx_data.order_id = salesforce_data?.created_order_id;
        // const { data } = await client.query({
        //   query: GET_DOCTOR,
        //   variables,
        // });
        // tx_data.doctor = data?.getDoctor;
      }

      if (type === "payment") {
        await axios.post("/api/salesforce/pay_later_payment", { tx_data });
      }

      const email_settings: { [key: string]: any } = {
        order: {
          email: {
            admin: {
              subject: `${
                tx_data?.oid ? "" : "FAILED "
              }Order Submitted | STD Testing | ${tx?.id}`,
              to: is_development
                ? "maxime.and.associates@gmail.com"
                : ["vince@rapidscreenings.com", "dhines@rapidscreenings.com"],
              //  bcc: !is_development && "maxime.and.associates@gmail.com",
              filename: `Invoice_${tx_data?.oid}.pdf`,
            },
            customer: {
              subject: `Order Confirmation | ${tx?.id}`,
              to: is_development
                ? "maxime.and.associates@gmail.com"
                : mx?.order?.user?.email,
              filename: `Invoice_${tx_data?.oid}.pdf`,
            },
          },
          html: {
            admin: PDFConfirmation({
              data: tx_data,
              doctor: tx_data?.doctor,
            }),
            customer: PDFConfirmation({
              brief: true,
              data: tx_data,
              doctor: tx_data?.doctor,
            }),
          },
          pdf: {
            admin: PDFConfirmation({
              data: tx_data,
              doctor: tx_data?.doctor,
              for_pdf: true,
            }),
            customer: PDFConfirmation({
              brief: true,
              data: tx_data,
              doctor: tx_data?.doctor,
              for_pdf: true,
            }),
          },
        },
        consultation: {
          email: {
            admin: {
              subject: `Order Submitted | Consultation | ${tx?.id}`,
              to: is_development
                ? "maxime.and.associates@gmail.com"
                : [
                    "vince@rapidscreenings.com",
                    "physconsult@kulbershhealthscreeningservices.com",
                    "dhines@rapidscreenings.com",
                  ],
              //   bcc: !is_development && "maxime.and.associates@gmail.com",
              filename: `Invoice_${tx?.id}.pdf`,
            },
            customer: {
              subject: `Order Confirmation | ${tx?.id}`,
              to: is_development
                ? "maxime.and.associates@gmail.com"
                : mx?.patient?.email,
              //  bcc: !is_development && "maxime.and.associates@gmail.com",
              filename: `Invoice_${tx?.id}.pdf`,
            },
          },
          html: {
            admin: PDFConfirmation({
              data: tx_data,
            }),
            customer: PDFConfirmation({
              brief: true,
              data: tx_data,
            }),
          },
          pdf: {
            admin: PDFConfirmation({
              data: tx_data,
              for_pdf: true,
            }),
            customer: PDFConfirmation({
              brief: true,
              data: tx_data,
              for_pdf: true,
            }),
          },
        },
        payment: {
          email: {
            admin: {
              subject: `Payment Submitted | ${tx?.id}`,
              to: is_development
                ? "maxime.and.associates@gmail.com"
                : ["vince@rapidscreenings.com", "dhines@rapidscreenings.com"],
              //  bcc: !is_development && "maxime.and.associates@gmail.com",
              filename: `Invoice_${tx?.id}.pdf`,
            },
            customer: {
              subject: `Payment Confirmation | ${tx?.id}`,
              to: is_development
                ? "maxime.and.associates@gmail.com"
                : mx?.patient?.email,
              //  bcc: !is_development && "maxime.and.associates@gmail.com",
              filename: `Invoice_${tx?.id}.pdf`,
            },
          },
          html: {
            admin: PDFConfirmation({
              data: tx_data,
            }),
            customer: PDFConfirmation({
              brief: true,
              data: tx_data,
            }),
          },
          pdf: {
            admin: PDFConfirmation({
              data: tx_data,
              for_pdf: true,
            }),
            customer: PDFConfirmation({
              brief: true,
              data: tx_data,
              for_pdf: true,
            }),
          },
        },
      };
      const { email, html, pdf: pdf_ } = email_settings[type];

      // Create PDFs
      const { data: pdf } = await axios.post("/api/pdf", {
        html: renderToString(pdf_.admin),
      });

      if (type === "order" && tx_data?.oid) {
        const { pay_later, pay_later_available } = payment_data;
        // Create ContentVersion for Opportunity
        const { data: ContentVersionId } = await axios.post(
          "/api/salesforce/add_document",
          {
            OpportunityId: tx_data.oid,
            pdf,
          }
        );

        if (!pay_later || (pay_later && pay_later_available)) {
          // Create Order
          const { data: OrderId } = await axios.post(
            "/api/salesforce/create_order",
            { OpportunityId: tx_data.oid }
          );

          // Link ContentVersion
          await axios.post("/api/salesforce/link_document", {
            OrderId,
            ContentVersionId,
          });
        }
      }

      if (type === "payment") {
        const { data: OrderId } = await axios.post(
          "/api/salesforce/create_order",
          { OpportunityId: tx_data.oid }
        );

        // Link ContentVersion
        await axios.post("/api/salesforce/link_document", {
          is_payment: true,
          OrderId,
          OpportunityId: tx_data.oid,
        });
      }

      // Attach to Emails
      !mute_emails &&
        (await sendConfirmationEmails({
          email,
          html,
          pdf,
          transaction: tx?.id,
        }));

      window.scrollTo(0, 0);
      if (type === "order") {
        !is_testing_order && window.localStorage.removeItem("order");
        !is_testing_order && window.localStorage.removeItem("shopping_cart");
      }

      await goToConfirmation({
        reset_cart: type === "order",
        route: `/${type}-confirmation`,
        tx_data,
      });
    } catch (error) {
      console.log("ERROR CONFIRMING TRANSACTION", error);
    }
  };

  const processCreditCard = async ({
    amount,
    data,
    setViewErrors,
  }: {
    amount: string | number;
    data: any;
    setViewErrors: any;
  }) => {
    try {
      let payment_data;

      if (mute_payment) {
        await processConfirmation({
          ...data,
          process_type: "credit_card",
          tx: { ...payment_cc_tx_data.tx, type: "credit_card" },
        });
        return;
      }

      if (data.payment_data) {
        payment_data = data.payment_data;
      } else {
        payment_data = await submitCreditCardPayment();
        if (!isEmpty(payment_data?.pay_errors)) {
          setPayErrors(payment_data?.pay_errors);
          setViewErrors(true);
          return;
        }
      }

      const { nonce } = payment_data;
      const { errors, tx } =
        nonce === "pay_later"
          ? {
              errors: [],
              tx: {
                id: "Pay Later",
                amount: 0,
                updatedAt: new Date(),
              },
            }
          : await confirmTransaction({
              amount, // totals.total_due,
              nonce,
            });

      if (!isEmpty(errors)) {
        setPayErrors(errors);
        setViewErrors(true);
        return;
      }

      await processConfirmation({
        ...data,
        process_type: "credit_card",
        tx: { ...tx, type: "credit_card" },
      });
    } catch (err) {
      console.log("ERROR PROCESSING CREDIT CARD", err);
    } finally {
      return Promise.resolve();
    }
  };

  return (
    <PaymentContext.Provider
      value={{
        ccnumber,
        doctor_data: doctor_data?.getDoctor,
        errors,
        method,
        tokenObject: "",
        isPaymentMade,
        loading,
        loading_doctor,
        pay_later,
        tx_data,
        zip_code,
        goToConfirmation,
        processCreditCard,
        processConfirmation,
        setCCNumber,
        setIsPaymentMade,
        setLoading,
        setMethod,
        setPayLater,
        setTransactionData,
        setZipCode,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

export default PaymentContextProvider;
