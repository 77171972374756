import "./content_main.css";
import "../../rsc_health.css";
import Footer from "./Footer";

import Header from "./Header";
import { useUser } from "../../context/UserContext";
import DrawerMenu from "./Drawer";

export default function SiteLayout({ children }: { children: any }) {
  const { is_admin } = useUser();

  return (
    <>
      <Header />
      <DrawerMenu />
      <div
        id="content-main"
        className={`bg-slate-50 w-full flex-grow ${
          is_admin ? "admin" : "user"
        }`}
      >
        {children}
      </div>
      <Footer />
    </>
  );
}
