import { filter, findIndex } from "lodash";
import { useCart } from "../../../context/CartContext";

export default function ShopProducts() {
  const { individual_products, shopping_cart, setShoppingCart } = useCart();

  function toggleItem(sku: string) {
    const index = findIndex(shopping_cart, { sku });
    if (index >= 0) {
      setShoppingCart(shopping_cart.filter(item => item.sku !== sku));
    } else {
      const item = individual_products.find((item: any) => item.sku === sku);
      if (item) {
        setShoppingCart([...shopping_cart, item]);
      }
    }
  }

  return (
    <div className="flex flex-col gap-12 bg-white px-2 py-12 md:p-6 lg:mx-auto w-full rounded-b-md max-w-[1024px]">
      <div className="grid lg:grid-rows-6 lg:grid-flow-col gap-y-4 md:gap-x-12">
        {filter(individual_products, "ind").map((product: any) => {
          const { name, price, sku } = product;
          const has_item = shopping_cart.some(
            (selectedItem: any) => selectedItem.sku === product.sku
          );
          return (
            <div
              key={sku}
              className={`flex flex-row gap-6 px-4 py-1 md:text-[1.25rem] items-center  ${
                has_item ? "bg-slate-100" : "hover:bg-slate-100"
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => toggleItem(sku)}
            >
              <div className="grow flex flex-row items-center">
                <div className="leading-[0px]">
                  <input
                    className="w-[16.5px] h-[16.5px] mr-2 inner-shadow"
                    type="checkbox"
                    checked={has_item}
                    id={sku}
                    name={name}
                    onChange={() => toggleItem(product.sku)}
                  />
                </div>
                <div>{name}</div>
              </div>
              <div className="text-rsc-light-blue font-bold">
                ${price.toFixed(2)}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
