import { useUsers } from "./UsersContext";

export default function KeywordSearch() {
  const { loading, search, getUsers, setSearch } = useUsers();

  let is_ready = search.replace(/\s/g, "").length >= 3;

  return (
    <div className="flex flex-row gap-4 max-w-[350px] w-full">
      <div className="grow">
        <input
          className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
          value={search}
          onChange={(e: any) => setSearch(e.target.value)}
          placeholder={"Email, name or number..."}
        />
      </div>
      <div>
        <button
          className={`${
            is_ready
              ? "bg-rsc-yellow text-white"
              : "bg-gray-300 border border-gray-400/50 text-gray-400"
          } text-xs px-4 h-full rounded-lg`}
          disabled={!is_ready || loading}
          onClick={() => getUsers(search)}
        >
          {loading ? "Searching..." : "Search"}
        </button>
      </div>
    </div>
  );
}
