import axios from "axios";
import IonIcon from "@reacticons/ionicons";
import { useState } from "react";
import { RingLoader } from "react-spinners";
import { useUI } from "../../context/UIContext";
import { useAccount } from "../../context/AccountContext";

export default function ContactSupport() {
  const { is_development, setPopUp } = useUI();
  const { account } = useAccount();
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [sentMessage, setSentMessage] = useState<boolean>(false);
  const canSend = !loading && !!message;

  const sendSupportMessage = async () => {
    setLoading(true);
    const { FirstName, LastName, PersonEmail } = account || {};
    const user_name = `${FirstName} ${LastName}`;
    const subject = `Support Request: ${LastName}, ${FirstName}`;
    const body = `From: ${user_name}<br/>${PersonEmail}<br/>${message}`;
    await axios.post("/api/email/send", {
      bcc: is_development
        ? "maxime.and.associates@gmail.com"
        : ["vince@rapidscreenings.com", "dhines@rapidscreenings.com"],
      body,
      html: body,
      subject,
      to: "support@rschealth.com",
    });

    setSentMessage(true);
    setLoading(false);
  };

  return (
    <>
      <div className="p-4 text-lg text-gray-600 bg-gray-100 border-b border-gray-200 flex flex-row">
        <div className="grow">Contact Support</div>
        <button onClick={() => setPopUp(null)}>
          <IonIcon
            name="close-circle"
            style={{ fontSize: "1.4rem", transform: "translateY(2px)" }}
          />
        </button>
      </div>
      <div className="flex flex-col gap-4 p-4">
        {sentMessage ? (
          <>
            <div className="text-center">
              <label htmlFor="support_message">
                Message Sent Successfully.
              </label>
            </div>
            <div className="text-center">
              <button
                // disabled={!isReady}
                onClick={() => setPopUp(null)}
                className={`${
                  true ? "bg-rsc-blue text-white" : "bg-gray-200 text-gray-500"
                } font-semibold text-center p-3 rounded-md shadow-md`}
              >
                Close
              </button>
            </div>
          </>
        ) : (
          <>
            {loading ? (
              <div className="flex flex-col items-center justify-center my-12 gap-4">
                <div>
                  <RingLoader color={"#11669b"} size={120} />
                </div>
                <div className="text-rsc-light-blue text-xs">
                  Sending message...
                </div>
              </div>
            ) : (
              <div className="">
                <label htmlFor="support_message">Message:</label>
                <textarea
                  id="support_message"
                  className="w-full border border-rsc-light-blue/20 shadow-inner rounded-md overflow-hidden p-2"
                  rows={6}
                  onChange={(e: any) => setMessage(e.target.value)}
                  value={message}
                />
              </div>
            )}
            <div className="flex flex-row gap-6">
              <div className="grow">
                <button
                  // disabled={!isReady}
                  onClick={() => setPopUp(null)}
                  className={`${
                    true
                      ? "bg-rsc-blue text-white"
                      : "bg-gray-200 text-gray-500"
                  } font-semibold text-center p-3 rounded-md shadow-md`}
                >
                  Cancel
                </button>
              </div>
              <div>
                <button
                  className={`${
                    canSend
                      ? "bg-rsc-yellow text-white"
                      : "bg-gray-200 text-gray-500"
                  } font-semibold text-center p-3 rounded-md shadow-md`}
                  disabled={!canSend}
                  onClick={sendSupportMessage}
                >
                  {loading ? "Sending..." : "Send"}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
