import { useState } from "react";
import IonIcon from "@reacticons/ionicons";
import { compact, isEmpty } from "lodash";
import { validate_consultation_person } from "../../../../utils/functions";
import { useUI } from "../../../../context/UIContext";
import { usePartnerConsultation } from "../../context/PartnerConsultationContext";
import OrderCard from "../order_card";

export default function PartnerFinalize() {
  const { setLocation } = useUI();
  const { updatePartner, existing, partner } = usePartnerConsultation();

  const [view_errors, setViewErrors] = useState<boolean>(false);
  const consultation_person_validation =
    validate_consultation_person.safeParse(partner);
  const errors = compact(
    (consultation_person_validation as any)?.error?.issues.map(
      (issue: any) => issue.path[0] && `${issue.path[0]}: ${issue.message}`
    )
  );

  const display = existing ? (
    <>
      <IonIcon name="create" /> Update Partner
    </>
  ) : (
    <>
      <IonIcon name="add" /> Add Partner
    </>
  );
  console.log({ partner });
  return (
    <OrderCard title="Add Partner">
      <div className="flex flex-col gap-6 p-4">
        {view_errors && (
          <div className="">
            <div className="mb-2 font-semibold">
              Please correct the following information:
            </div>
            <div className="flex flex-col gap-2 mx-2">
              {errors.map((error: any, idx: number) => {
                return (
                  <div
                    key={idx}
                    className="border border-rsc-red bg-rsc-red/30 rounded-md p-2 text-xs"
                  >
                    <p>{error}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="flex flex-col gap-6">
          <div>
            <button
              className="rounded rounded-lg p-4 bg-green-700 text-center text-white text-xl font-bold w-full"
              onClick={() => {
                isEmpty(errors) ? updatePartner() : setViewErrors(true);
              }}
            >
              <span className="icon-text">{display}</span>
            </button>
          </div>
          <div>
            <button
              className="rounded rounded-lg p-4 bg-gray-400 text-center text-white text-xl font-bold w-full"
              onClick={() => setLocation("/consultation#partners")}
            >
              <span className="icon-text">
                <IonIcon name="close" />{" "}
                {existing ? "Cancel Changes" : "Cancel"}
              </span>
            </button>
          </div>
        </div>
      </div>
    </OrderCard>
  );
}
