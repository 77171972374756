import { useRegistration } from "../RegistrationContext";
import { validEmail, validPhone } from "../../utils/functions";
import SelectType from "../select";
import LoginHolder from "../holder";
import { useUI } from "../../context/UIContext";
import RSCLoader from "../loader_circle";

export default function LogIn({
  callback,
  pop_up,
}: {
  callback?: any;
  pop_up?: boolean;
}) {
  const { setLocation, setPopUp } = useUI();
  const {
    is_email,
    loading,
    login_type,
    password,
    username,
    handleLogIn,
    setLoginType,
    setPassword,
    setUsername,
  } = useRegistration();

  const good_phone = validPhone(username);
  const good_email = validEmail(username);
  const is_ready = (is_email ? good_email : good_phone) && !!password;
  const input_class = `rounded-lg border border-slate-400 h-10 w-full shadow-inner px-2 ${
    loading ? "bg-slate" : "bg-none"
  }`;

  return (
    <LoginHolder header="Account Login" loading_text="Signing in...">
      <SelectType
        login_input={login_type}
        setInput={setLoginType}
        text="Choose Your Login Type:"
      />
      <div>
        <input
          className={input_class}
          disabled={loading}
          id={is_email ? "email" : "phone"}
          placeholder={is_email ? "Email" : "Mobile Phone"}
          value={username}
          onChange={(e: any) => setUsername(e.target.value)}
        />
      </div>
      <div>
        <input
          className={input_class}
          disabled={loading}
          id={"password"}
          placeholder={"Password"}
          type={"password"}
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
        />
      </div>
      <div className="flex flex-row gap-6">
        <div className="grow">
          <button
            disabled={!is_ready || loading}
            onClick={() =>
              handleLogIn({
                callback,
                user_callback: () =>
                  pop_up ? setPopUp(null) : setLocation("./dashboard"),
              })
            }
            className={`${
              !is_ready || loading
                ? "bg-gray-200 text-gray-500"
                : "bg-rsc-blue text-white"
            } font-semibold text-center p-3 rounded-md shadow-md`}
          >
            {loading ? (
              <div className="flex flex-row gap-2 items-center">
                <RSCLoader /> Logging in...
              </div>
            ) : (
              "Log In"
            )}
          </button>
        </div>
        <div>
          {pop_up ? (
            <button
              onClick={() => {
                setPopUp(null);
              }}
              className="bg-red-600 font-semibold text-white text-center p-3 rounded-md shadow-md"
            >
              Cancel
            </button>
          ) : (
            <button
              onClick={() => setLocation("/register")}
              className="bg-amber-400 font-semibold text-white text-center p-3 rounded-md shadow-md"
            >
              Register
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-row items-end justify-end text-xs text-rsc-light-blue font-semibold">
        <div className="grow">
          {false && (
            <button onClick={() => setLocation("/forgot-password")}>
              Help logging in.
            </button>
          )}
        </div>
        <div className="flex flex-col items-end gap-1">
          <div className="">
            <button
              className="hover:underline"
              onClick={() => {
                setLocation("/forgot-password");
                pop_up && setPopUp(null);
              }}
            >
              Forgot Password?
            </button>
          </div>
          <div className="">
            <button
              className="hover:underline"
              onClick={() => {
                setLocation("/verify");
                pop_up && setPopUp(null);
              }}
            >
              Verify/Confirm Account
            </button>
          </div>
        </div>
      </div>
    </LoginHolder>
  );
}
