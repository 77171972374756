import PersonalInformation from "./PersonalInformation";
import PaymentInfo from "./PaymentInformation";
import ChoosePharmacy from "./ChoosePharmacy";
import ContactInformation from "./ContactInformation";
import MedicalHistory from "./MedicalHistory";
import Partners from "./Partners";
//import Confirmation from "./Confirmation";
import { useConsultation } from "../context/ConsultationContext";
import Acknowledgement from "../../../components/acknowledgement";
import PartnerFinalize from "./Finalize/partner";

export default function ConsultationScheduling() {
  const {
    acknowledged,
    partner: isPartner,
    // payment_required,
    setAcknowledged,
  } = useConsultation();
  //if (tx_data) return <Confirmation tx_data={tx_data} />;
  let payment_required = true;
  return (
    <div className="flex flex-col gap-6 w-full">
      <PersonalInformation />
      <ContactInformation />
      <MedicalHistory />
      {!isPartner && <Partners />}
      {payment_required && <ChoosePharmacy />}
      {isPartner ? (
        <PartnerFinalize />
      ) : (
        <div className="my-12">
          <Acknowledgement
            checked={acknowledged}
            onChange={(e: any) => {
              setAcknowledged(e.target.checked);
            }}
          />
        </div>
      )}
      {!isPartner && !!payment_required && (
        <div className="mb-4">
          <PaymentInfo />
        </div>
      )}
    </div>
  );
}
