import IonIcon from "@reacticons/ionicons";
import LogIn from "../login";
import { useAccount } from "../../context/AccountContext";
import { useUser } from "../../context/UserContext";
import { useUI } from "../../context/UIContext";

export default function PatientLoginButton({
  callback,
  text,
}: {
  callback?: any;
  text?: string;
}) {
  const { setPopUp } = useUI();
  const { account } = useAccount();
  const { is_admin } = useUser();
  const isLoggedIn = !!account?.Id || is_admin;
  if (isLoggedIn) return null;
  return (
    <div
      className="flex flex-row gap-2 border border-slate-200 items-center justify-center my-6 px-6 p-2 bg-slate-50 text-rsc-blue shadow-lg rounded-full cursor-pointer"
      onClick={() =>
        setPopUp(
          <div
            className="bg-white my-6 mx-auto w-full md:min-w-[440px] sm:max-w-md md:border sm:border-rsc-light-blue/10 sm:rounded-xl shadow-lg overflow-hidden"
            onClick={e => e.stopPropagation()}
          >
            <LogIn callback={callback} pop_up />
          </div>
        )
      }
    >
      <div className="text-3xl">
        <IonIcon name="lock-closed" className="translate-y-[2px]" />
      </div>
      <div className="leading-none pr-1 text-sm">
        <span className="font-bold text-lg">{text || "Log In / Sign Up"}</span>
      </div>
    </div>
  );
}
