import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { has } from "lodash";
const { REACT_APP_GOOGLE_MAPS_KEY } = process.env;

export default function LabMap({ center }: { center: any }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY as string,
  });
  const has_center = !has(center, ["lat", "lng"]);
  const isReady = has_center && isLoaded;

  if (!isReady) return null;

  return (
    <div>
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "400px",
        }}
        center={center}
        zoom={14}
        //  onLoad={onLoad}
        // onUnmount={onUnmount}
      >
        <Marker position={center} />
      </GoogleMap>
    </div>
  );
}
