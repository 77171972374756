import moment from "moment";
import { find, trimStart } from "lodash";
import { useMessages } from "../MessagesContext";

export default function MessageInfo() {
  const { messages, viewing_message } = useMessages();
  if (!viewing_message) return null;
  const { CreatedDate, Priority, Subject } = find(messages, [
    "Id",
    viewing_message,
  ]);
  return (
    <div className="flex flex-col justify-items-stretch">
      <div className="items-center mb-2 text-2xl text-gray-600 font-semibold">
        {trimStart(Subject, "Email:")}
      </div>
      <div className="flex flex-col text-sm text-gray-400">
        <div className="">{moment(CreatedDate).format("lll")}</div>
        <div className="">
          Priority:{" "}
          <span className="text-amber-400 font-semibold">{Priority}</span>
        </div>
      </div>
    </div>
  );
}
