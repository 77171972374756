import { includes, without, concat } from "lodash";
export default function TreatingMedicalHistory({
  treating,
  handlePersonChange,
}: {
  treating: string[];
  handlePersonChange: any;
}) {
  return (
    <div className="basis-2/3">
      <label htmlFor="treating">
        Requesting Treatment for:{" "}
        <span className="text-sm text-gray-400 italic">
          (select all that apply)
        </span>
      </label>
      <select
        className="rounded-lg border border-rsc-light-blue/20 h-24 w-full shadow-inner px-2"
        id="treating"
        multiple
        value={treating}
        onChange={(e: any) => {
          let new_array;
          let to_treat = e.target.value;
          if (includes(treating, to_treat)) {
            new_array = without(treating, to_treat);
          } else {
            new_array = concat(treating, to_treat);
          }
          handlePersonChange("treating", new_array);
        }}
      >
        {[
          "Chlamydia",
          "Gonorrhea",
          //   "Hepatitis A, B, or C *",
          "Herpes I",
          "Herpes II",
          //  "HIV *",
          // "Syphillis (RPR) *",
          "Trichomoniasis",
        ].map((ailment: string) => {
          return (
            <option key={ailment} value={ailment}>
              {ailment}
            </option>
          );
        })}
      </select>
      {false && (
        <p className="text-sm text-gray-400 italic">
          * Consultation does not include treatment
        </p>
      )}
    </div>
  );
}
