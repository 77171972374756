import { useAccount } from "../context/AccountContext";
import AccountMenu from "./acct_menu";
import GeneralUser from "./Headers/general";
import { useUser } from "../context/UserContext";
import PreLoader from "../PreLoader";
//const isMobile = window.matchMedia("(max-width: 640px)").matches;

export default function AccountDashboard({ children }: { children?: any }) {
  const { is_admin } = useUser();
  const { account, loading } = useAccount();

  function UserAccountDashboard() {
    if (loading)
      return (
        <div className="flex flex-row mx-auto w-full max-w-screen-lg items-center justify-center overflow-hidden min-h-[400px] md:min-h-[calc(100vh - 500px)]">
          <div className="text-xl md:text-2xl font-bold text-gray-300 text-center">
            <PreLoader
              text={is_admin ? "Loading account..." : "Loading your account..."}
            />
          </div>
        </div>
      );
    return (
      <>
        {account?.Id ? (
          <>
            <AccountMenu />
            <div className="md:flex md:flex-row border border-slate-50 mx-auto w-full max-w-screen-lg bg-white md:shadow-lg md:rounded-bl-lg md:rounded-br-lg overflow-hidden min-h-[450px] md:min-h-[calc(100vh - 500px)]">
              {children}
            </div>
          </>
        ) : (
          <div className="flex flex-row mx-auto w-full max-w-screen-lg items-center justify-center overflow-hidden min-h-[400px] md:min-h-[calc(100vh - 500px)]">
            <div className="text-xl md:text-2xl font-bold text-gray-300 text-center">
              {is_admin
                ? "Enter Patient Email to View Account Information"
                : "No Patient Data Available"}
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <div className="flex flex-col gap-4 w-full justify-center pb-12 pt-2 md:pt-4 md:px-4 min-h-[550px]">
      <GeneralUser />
      <UserAccountDashboard />
    </div>
  );
}
