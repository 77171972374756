import { useDashboard } from "../../../../DashboardContext";
import OverviewPaidInFull from "./PaidInFull";
import OverviewPayLater from "./PayLater";

export default function OverviewOrderDetails() {
  const { opportunity } = useDashboard();
  const opportunity_results_statuses: { [key: string]: any } = {
    "Not Paid": <OverviewPayLater />,
    "Paid in Full": <OverviewPaidInFull />,
    "Pay Later": <OverviewPayLater />,
  };
  return <> {opportunity_results_statuses[opportunity?.Payment_Status__c]}</>;
}
