import moment from "moment";
import DatePicker from "react-datepicker";
import IonIcon from "@reacticons/ionicons";
import { upperFirst } from "lodash";
import OrderCard from "../order_card";
import { useConsultation } from "../../context/ConsultationContext";
import { usePartnerConsultation } from "../../context/PartnerConsultationContext";
function PatientInfo() {
  const {
    handlePatientChange,
    partner: isPartner,
    patient,
  } = useConsultation();
  const { handlePartnerChange, partner } = usePartnerConsultation();

  const handlePersonChange = isPartner
    ? handlePartnerChange
    : handlePatientChange;
  const person = isPartner ? partner : patient;

  const { dob, given_name, family_name, sex } = person;
  const date_of_birth = dob ? moment(dob, "YYYY-MM-DD").toDate() : null;
  const title = (
    <>
      Personal Information{" "}
      {isPartner && (
        <sup className="align-middle text-amber-400 text-sm uppercase">
          Partner
        </sup>
      )}
    </>
  );

  return (
    <OrderCard title={title} card_layout={"z-40"}>
      <div className="p-3 bg-slate-50">
        <div className="grid md:grid-cols-2 gap-3 ">
          <div className="">
            <label>First Name:</label>
            <input
              className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
              id="given_name"
              placeholder="Enter your first name"
              value={given_name}
              onChange={(e: any) =>
                handlePersonChange("given_name", e.target.value)
              }
            />
          </div>
          <div className="">
            <label>Last Name:</label>
            <input
              className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
              id="family_name"
              placeholder="Enter your last name"
              value={family_name}
              onChange={(e: any) =>
                handlePersonChange("family_name", e.target.value)
              }
            />
          </div>
          <div className="">
            <label>
              Date of Birth:{" "}
              <span className="text-sm text-gray-400 italic">(MM/DD/YYYY)</span>
            </label>
            <DatePicker
              id="date_of_group"
              customInput={
                <input
                  className="bg-white rounded-lg border border-rsc-light-blue/30 h-10 w-full shadow-inner px-2 z-50"
                  placeholder="MM/DD/YYYY"
                />
              }
              className="w-full"
              selected={date_of_birth}
              showYearDropdown
              onChange={(e: any) => {
                const dob = moment(e).format("YYYY-MM-DD");
                handlePersonChange("dob", dob);
              }}
            />
          </div>
          <div className="">
            <label>Sex:</label>
            <div className="flex flex-row items-center gap-5 h-10">
              {["Male", "Female"].map((item: string) => {
                const isActive = sex === item;
                return (
                  <div
                    id="sex_group"
                    className="flex flex-row gap-2 items-center"
                    key={item}
                  >
                    <div style={{ lineHeight: 0 }}>
                      <button
                        id={item}
                        className="text-2xl"
                        onClick={() => handlePersonChange("sex", item)}
                      >
                        <IonIcon
                          className={`${
                            isActive ? "text-rsc-blue" : "text-gray-500"
                          } `}
                          style={{ transform: "translateY(2px)" }}
                          name={
                            isActive ? "radio-button-on" : "radio-button-off"
                          }
                        />
                      </button>
                    </div>
                    <div className="grow">
                      <label>{upperFirst(item)}</label>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </OrderCard>
  );
}

export default PatientInfo;
