import { useState } from "react";
import axios from "axios";
import Controls from "./controls";
import PreLoader from "../../../../../PreLoader";
import { useDashboard } from "../../../../DashboardContext";
import { Document, Page, pdfjs } from "react-pdf";
import DetailContainer from "../../../DetailContainer";
//import "react-pdf/dist/esm/Page/TextLayer.css";
//import generatePDF from "./generate";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function OrderViewInvoice() {
  const { opportunity } = useDashboard();
  const [document, setDocument] = useState<any | null>();
  const [attempted, setAttempted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const Id = opportunity?.Orders?.records?.[0]?.Id;

  async function getPDF(VersionData: string) {
    let pdf_result: any;
    try {
      pdf_result =
        VersionData &&
        (
          await axios.post("/api/salesforce/pdf", {
            VersionData,
          })
        )?.data;
    } catch (err) {
      console.log("ERROR PULLING INVOICES", err);
    } finally {
      pdf_result && setDocument(pdf_result);
    }
  }

  async function getVersionData(Id: string) {
    try {
      const { data } = await axios.post("/api/salesforce/order_invoices", {
        Id,
      });
      return data?.[0]?.VersionData;
    } catch (err) {
      console.log("ERROR GETTING VERSION DATA", err);
    }
  }

  async function loadPDF(Id: string) {
    setLoading(true);
    setAttempted(true);
    try {
      const VersionData = await getVersionData(Id);
      if (VersionData) {
        await getPDF(VersionData);
        return;
      }
      return;
      // Generate a PDF
      // const generated_doc = await generatePDF(Id);
      // setDocument(generated_doc);
      // Trigger Load of the document
    } catch (err) {
      console.log("ERROR LOADING", err);
    } finally {
      setLoading(false);
    }
  }

  !attempted && !loading && loadPDF(Id);

  console.log({ Id, document, opportunity });
  if (loading)
    return (
      <div className="max-h-[500px] md:basis-2/3">
        <PreLoader />
      </div>
    );
  if (!document && attempted)
    return <div className="max-h-[500px] md:basis-2/3">Call for Invoice</div>;

  return (
    <div className="flex flex-col justify-items-stretch p-3 md:p-5 gap-6 bg-white md:basis-2/3">
      <DetailContainer title="Order Invoice">
        <Controls pdf={document} />
        <Document
          className="border border-rsc-light-blue/10 shadow-md"
          file={"data:application/pdf;base64," + document}
        >
          <Page
            pageNumber={1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        </Document>
      </DetailContainer>
    </div>
  );
}
