import { isEmpty } from "lodash";
import { useRegistration } from "../RegistrationContext";
import { useUI } from "../../context/UIContext";
import LoginHolder from "../holder";

export default function ResendCodeForm() {
  const { setLocation } = useUI();
  const { resend_ready, username, resendCode, setUsername } = useRegistration();

  return (
    <LoginHolder
      header="Resend Authorization Code"
      loading_text="Sending code..."
    >
      <div className="flex flex-col text-gray-600 gap-5">
        <div className="">
          <input
            className="w-full h-10 px-2 border rounded-lg shadow-inner border-slate-400"
            id={"username"}
            placeholder={"Enter Email or Phone Number"}
            value={username}
            onChange={(e: any) => setUsername(e.target.value)}
          />
        </div>
        {resend_ready?.length > 0 && (
          <div className="flex flex-col text-xs text-red-600 pl-3 italic">
            {resend_ready.map((msg: string, idx: number) => {
              return <div key={idx}>{msg}</div>;
            })}
          </div>
        )}
        <div>
          <button
            onClick={() => resendCode({ route: "/verify" })}
            disabled={!isEmpty(resend_ready)}
            className={`${
              isEmpty(resend_ready) ? "bg-amber-400" : "bg-gray-400"
            } p-3 font-semibold text-center text-white rounded-md shadow-md w-full`}
          >
            Resend Code
          </button>
        </div>
      </div>
      <div className="flex flex-col pt-5">
        <div className="flex flex-row text-xs">
          <div className="grow">
            <button
              className="text-rsc-light-blue font-semibold"
              onClick={() => setLocation("/login")}
            >
              Log In
            </button>
            {" or "}
            <button
              className="text-rsc-light-blue font-semibold"
              onClick={() => setLocation("/register")}
            >
              Register
            </button>
          </div>
          <div>
            <button
              className="text-rsc-light-blue font-semibold"
              onClick={() => setLocation("/verify")}
            >
              Verify Code
            </button>
          </div>
        </div>
      </div>
    </LoginHolder>
  );
}
