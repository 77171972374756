import IonIcon from "@reacticons/ionicons";
import { isEmpty } from "lodash";
import { useCart } from "../../../context/CartContext";
import { useUI } from "../../../context/UIContext";
import MiniOrderCard from "../../../components/MiniCardHolder";
import SummaryItem from "./item";
import Can from "../../../utils/auth/Can";
import { useUser } from "../../../context/UserContext";

export default function OrderSummary() {
  const { setLocation } = useUI();
  const { user } = useUser();
  const {
    reorder_discount,
    shopping_cart,
    totals,
    setReorderDiscount,
    setShoppingCart,
  } = useCart();

  const has_items = !isEmpty(shopping_cart);

  const action = has_items && (
    <button
      className="text-gray-400 hover:text-white uppercase"
      onClick={() => setShoppingCart([])}
    >
      Empty Cart
    </button>
  );

  const { cart_total, reorder_discount_total, total_due } = totals;

  function ReorderDiscountApplied() {
    return (
      <>
        <tr className="">
          <td>
            <div className="flex flex-col text-sm border-t border-slate-300 p-1">
              <div className="flex flex-row font-bold">
                <div className="grow">Subtotal</div>
                <div>${cart_total.toFixed(2)}</div>
              </div>{" "}
              <div className="flex flex-row text-xs text-green-700">
                <div className="flex flex-row gap-2 items-center grow ">
                  Reorder Discount - 10% off
                </div>
                <div>- ${reorder_discount_total.toFixed(2)}</div>
              </div>
            </div>
          </td>
        </tr>
        <tr className="">
          <td>
            <div className="flex flex-row p-1 border-t border-slate-300 font-bold">
              <div className="grow">Total</div>
              <div>${total_due.toFixed(2)}</div>
            </div>
          </td>
        </tr>
      </>
    );
  }

  function ReorderDiscountButton() {
    return (
      <tr className="border-t border-dotted border-slate-200 text-right text-sm hover:bg-slate-200">
        <td className="py-1">
          {reorder_discount ? (
            <button
              className="text-red-700 text-xs font-semibold"
              onClick={() => setReorderDiscount(false)}
            >
              Remove Reorder Discount - 10%
            </button>
          ) : (
            <button
              className="text-green-600 text-xs font-semibold"
              onClick={() => setReorderDiscount(true)}
            >
              Apply Reorder Discount - 10%
            </button>
          )}
        </td>
      </tr>
    );
  }

  return (
    <MiniOrderCard
      action={action}
      title="Order Summary"
      card_layout="w-full min-w-[320px] sm:w-[unset]"
    >
      {false && has_items && (
        <div>
          <button
            className="text-rsc-light-blue text-xs font-semibold"
            onClick={() => setLocation("/test-and-panels")}
          >
            <IonIcon name="caret-back" className="translate-y-[2px]" /> Shop
            More Products
          </button>
        </div>
      )}
      <table className="w-full">
        <thead>
          <tr className="">
            <td>
              <div className="flex flex-row p-1 border-b border-slate-300 font-bold">
                <div className="grow">Test</div>
                <div>Price</div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody className="p-2">
          {has_items ? (
            <>
              {shopping_cart.map((pkg: any, idx: number) => {
                return <SummaryItem key={idx} item={pkg} />;
              })}
              <tr className="border-t border-dotted border-slate-200 text-right text-sm hover:bg-slate-200">
                <td className="py-1">
                  <button
                    className="text-rsc-light-blue text-xs font-semibold"
                    onClick={() => setLocation("/test-and-panels")}
                  >
                    Continue shopping
                  </button>
                </td>
              </tr>
              <Can
                perform="apply:reorder-discount"
                role={user?.roles}
                yes={() => <ReorderDiscountButton />}
              />
            </>
          ) : (
            <tr>
              <td className="text-center text-gray-400 italic py-4">
                cart is empty
                <br />
                <button
                  className="text-rsc-light-blue text-xs font-semibold"
                  onClick={() => setLocation("/test-and-panels")}
                >
                  Shop Products
                </button>
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          {reorder_discount ? (
            <ReorderDiscountApplied />
          ) : (
            <tr className="">
              <td>
                <div className="flex flex-row p-1 border-t border-slate-300 font-bold">
                  <div className="grow">Total</div>
                  <div>${total_due.toFixed(2)}</div>
                </div>
              </td>
            </tr>
          )}
        </tfoot>
      </table>
    </MiniOrderCard>
  );
}
