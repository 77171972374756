import { useState } from "react";
import IonIcon from "@reacticons/ionicons";
import SiteMenu from "./desktop_menu";
import RSCLogo from "../rsc-logo-white.png";
import { useUI } from "../../../context/UIContext";
import ShoppingCartButton from "../../shopping_cart";
import LogInUserButton from "../../login_button";

export default function MiddleHeader() {
  const { is_mobile, setDrawerOpen } = useUI();
  const [search_terms, setSearchTerms] = useState<string>("");
  function goToSearchLink() {
    const URL = `https://www.rschealth.com/search?q=${encodeURI(
      search_terms
    )}*&type=article,page,product`;
    window.open(URL, "_blank");
  }

  function SiteLogo({ className }: { className: string }) {
    return (
      <div className={className}>
        <button
          className="leading-none"
          onClick={() => {
            window.location.href = "https://rschealth.com";
          }}
        >
          <img
            src={RSCLogo}
            alt="RSC Health Logo"
            className="w-[210px] mx-auto pointer-events-auto"
          />
        </button>
      </div>
    );
  }

  return (
    <div
      id="bottom-header"
      className="mx-auto bg-rsc-blue text-white shadow-md w-full z-40"
    >
      <div className="flex flex-col p-2 md:p-4 md:flex-row md:gap-8 mx-auto w-full max-w-7xl grow">
        <div className="flex flex-row gap-6 items-center justify-center">
          <SiteLogo
            className={`translate-y-[5px] ${
              is_mobile ? "absolute left-0 w-full text-center" : "grow"
            }`}
          />
          <div className="flex flex-row items-center justify-start text-2xl p-3 md:hidden w-full">
            <div
              className="flex flex-col items-center justify-center pointer-events-auto"
              onClick={() => setDrawerOpen(true)}
            >
              <IonIcon name="menu" />
            </div>
          </div>
          <div className="flex flex-row gap-4 p-3 items-center justify-end w-full md:w-[unset] pointer-events-none">
            <LogInUserButton
              className="md:p-3 md:pl-0 pointer-events-auto translate-y-[2px]"
              site="rsc_health"
            />
            <ShoppingCartButton
              className="md:p-3 md:pl-0 pointer-events-auto translate-y-[2px]"
              site="rsc_health"
            />
          </div>
        </div>
        {!is_mobile && <SiteMenu />}
        {false && (
          <div className="flex flex-row grow">
            <div className="flex flex-row items-center bg-white h-12 rounded-tl-[3px] rounded-bl-[3px] overflow-hidden grow">
              <div className="h-full grow">
                <input
                  className="border-0 outline-0 font-normal text-black px-2 h-full w-full"
                  onChange={(e: any) => setSearchTerms(e.target.value)}
                  onKeyDown={(e: any) => {
                    if (e.keyCode === 13) {
                      goToSearchLink();
                    }
                  }}
                  placeholder="What are you looking for?"
                  value={search_terms}
                />
              </div>
              {search_terms && (
                <div
                  className="px-3 text-gray-300 hover:text-gray-500 cursor-pointer"
                  onClick={() => setSearchTerms("")}
                >
                  <IonIcon
                    className="text-2xl translate-y-[2px]"
                    name="close-circle"
                  />
                </div>
              )}
            </div>
            <div
              className="flex flex-col items-center justify-center bg-rsc-yellow rounded-tr-[3px] rounded-br-[3px] px-4 h-12 cursor-pointer"
              onClick={() => goToSearchLink()}
            >
              <div>
                <IonIcon className="text-2xl translate-y-[2px]" name="search" />
              </div>
            </div>
          </div>
        )}
        {false && <SiteLogo className="hidden md:inline" />}
      </div>
    </div>
  );
}
