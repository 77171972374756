import SkeletonList from "./skeleton_list";
import List from "./list";
import { useDashboard } from "../../../DashboardContext";

export default function MyOrders() {
  const { loading_opportunities, opportunities } = useDashboard();

  return (
    <>
      <div>
        <h3 className="font-semibold text-rsc-light-blue text-center uppercase">
          My Orders
        </h3>
      </div>
      <div className="flex flex-col gap-8">
        {loading_opportunities || !opportunities ? <SkeletonList /> : <List />}
      </div>
    </>
  );
}
