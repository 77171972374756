import { upperFirst } from "lodash";
import SetZipCode from "./zipcode_set";
import SetHoursOpen from "./hours_set";
import { useTestCenter } from "../TestCenterContext";
import { useUI } from "../../../../context/UIContext";

export default function FindSettings() {
  const { is_mobile, location } = useUI();
  const { setView, view } = useTestCenter();

  return (
    <>
      <div className="py-1 text-2xl font-bold">
        {location === "/find-a-lab" ? "Find a Lab" : "Search Test Centers"}
      </div>
      <p className="text-sm text-slate-700">
        Please note that these STD Test Centers do not accept payment. You must
        place your order and submit payment over the phone or online before
        visiting any of the STD Test Centers.
      </p>
      <SetZipCode />
      <SetHoursOpen />
      {is_mobile && (
        <div className="flex justify-center px-4 pb-4">
          {["list", "map"].map((item: string, idx: number) => {
            const isActive = view === item;
            return (
              <button
                key={item}
                className={`flex-grow ${
                  idx === 0
                    ? "rounded-tl-lg rounded-bl-lg border-r-0"
                    : "rounded-tr-lg rounded-br-lg border-l-0"
                } border-2 border-red-600  text-center text-sm font-semibold py-1 ${
                  isActive
                    ? "bg-red-600 text-white drop-shadow-lg"
                    : "bg-white text-red-600"
                }`}
                onClick={() => setView(item)}
              >
                {upperFirst(item)} View
              </button>
            );
          })}
        </div>
      )}
    </>
  );
}
