import DatePicker from "react-datepicker";
import IonIcon from "@reacticons/ionicons";
import { useAccount } from "../../context/AccountContext";
import Section from "./form_section";
import { upperFirst } from "lodash";
export default function MySettings() {
  const { account } = useAccount();

  if (!account) return null;
  const { FirstName, Gender__pc: sex, LastName, PersonBirthdate } = account;

  const dob = new Date(PersonBirthdate);

  const disabled =
    "rounded-lg border border-rsc-light-blue/30 bg-rsc-light-blue/10 h-10 w-full shadow-inner px-2";
  return (
    <div className="flex flex-col px-4 w-full md:w-1/2">
      <Section>
        <div className="flex flex-col gap-6 ">
          <div>
            <label htmlFor="first_name">First Name:</label>
            <input
              className={disabled}
              id="first_name"
              placeholder="Enter your first name"
              value={FirstName}
              readOnly
              // onChange={(e: any) => setFirstName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="last_name">Last Name:</label>
            <input
              className={disabled}
              id="last_name"
              placeholder="Enter your last name"
              value={LastName}
              readOnly
              // onChange={(e: any) => setLastName(e.target.value)}
            />
          </div>
        </div>
      </Section>
      <Section>
        <div className="flex flex-col gap-6">
          {dob && (
            <div>
              <label htmlFor="date_of_group">Date of Birth:</label>
              <div>
                <DatePicker
                  customInput={
                    <input className="bg-white rounded-lg border border-rsc-light-blue/30 bg-rsc-light-blue/10 h-10 w-full shadow-inner px-2" />
                  }
                  className="w-full"
                  readOnly
                  selected={dob}
                  onChange={(e: any) => null}
                  disabled
                />
              </div>
            </div>
          )}
          <div>
            <label htmlFor="sex_group">Sex:</label>
            <div className="flex flex-row items-center gap-5 h-10">
              {["Male", "Female"].map((item: string) => {
                const isActive = sex === item;
                return (
                  <div className="flex flex-row gap-2 items-center" key={item}>
                    <div style={{ lineHeight: 0 }}>
                      {/* <input
                        className="mr-1 w-full h-5"
                        type="radio"
                        id={item}
                        name="sex"
                        value={item}
                        checked={isActive}
                        readOnly
                        // onChange={() => setSex(item)}
                      /> */}
                      <button className="text-2xl" disabled>
                        <IonIcon
                          className={`${
                            isActive
                              ? "text-rsc-light-blue/60"
                              : "text-gray-500"
                          } `}
                          style={{ transform: "translateY(2px)" }}
                          name={
                            isActive ? "radio-button-on" : "radio-button-off"
                          }
                        />
                      </button>
                    </div>
                    <div className="grow">
                      <label htmlFor={item}>{upperFirst(item)}</label>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
}
