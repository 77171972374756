import { createContext, useContext, useState } from "react";

const isMobile = window.matchMedia("(max-width: 640px)").matches;

interface OrderValues {
  isMobile: boolean;
  //
  destination: number;
  jumpTo: (index: number) => void;
  next: () => void;
  origin: number;
  pages: number[];
  prev: () => void;
}

export const OrderContext = createContext<OrderValues>({
  isMobile: true,
  //
  destination: 0,
  jumpTo: () => {},
  next: () => {},
  origin: 0,
  pages: [0, 1, 2, 3],
  prev: () => {},
});

export const useOrder = () => useContext(OrderContext);

const OrderContextProvider: React.FC<{
  children: any;
}> = ({ children }) => {
  // TEST CENTER
  const [destination, setDestination] = useState(1);
  const [origin, setOrigin] = useState(0);
  const pages = [0, 1, 2, 3];

  function jumpTo(index: number) {
    setOrigin(destination);
    setDestination(index);
  }

  function next() {
    if (destination < pages.length - 1) {
      jumpTo(destination + 1);
    }
  }

  function prev() {
    if (destination > 0) {
      jumpTo(destination - 1);
    }
  }

  return (
    <OrderContext.Provider
      value={{
        isMobile,
        //
        destination,
        jumpTo,
        origin,
        pages,
        prev,
        next,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export default OrderContextProvider;
