import "./loader.css";
export default function LoadingCircle({ size = 15 }: { size?: number }) {
  return (
    <div id="rsc_wrapper" style={{ height: size, width: size }}>
      <div className="rsc_main-loader">
        <div className="rsc_loader">
          <svg className="rsc_circular-loader" viewBox="25 25 50 50">
            <circle
              className="rsc_loader-path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke="#70c542"
              strokeWidth="2"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
