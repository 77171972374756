import { includes, map, orderBy } from "lodash";
import { useCart } from "../../../../context/CartContext";
import ShopCardMobile from "./mobile_card";
import ShopCardDesktop from "./desktop_card";
import { useUI } from "../../../../context/UIContext";

export default function ShopCards() {
  const { is_mobile } = useUI();
  const { package_products, shopping_cart } = useCart();

  const skus = map(shopping_cart, "sku");
  const ShopCard = is_mobile ? ShopCardMobile : ShopCardDesktop;
  return (
    <div className="grid gap-16 sm:grid-cols-2 sm:gap-4 md:gap-0 md:grid-cols-3 p-2 md:p-4 text-rsc-blue ">
      {orderBy(package_products, "grid_order").map((pkg: any, idx: number) => {
        const active = includes(skus, pkg.sku);
        return <ShopCard key={idx} active={active} idx={idx} pkg={pkg} />;
      })}
    </div>
  );
}
