import { useDashboard } from "../../../../../DashboardContext";
import DetailContainer from "../../../../DetailContainer";
import ActionsLabResults from "./Actions";
import InformationLabResults from "./Information";

export default function OverviewPaidInFull() {
  const { opportunity, order } = useDashboard();

  return (
    <DetailContainer title={order?.Status || "Loading..."}>
      <div className="flex flex-col gap-8 md:gap-0 md:flex-row">
        <InformationLabResults order={order} />
        <ActionsLabResults data={{ opportunity, order }} />
      </div>
    </DetailContainer>
  );
}
