import IonIcon from "@reacticons/ionicons";
import { find, flatMap, head, includes, orderBy } from "lodash";
import moment from "moment";
import HistoryDetail from "./history_item";
import { getRecordsCount } from "../../../../utils/functions";
import { useDashboard } from "../../../DashboardContext";

export function MyOrdersTestsDetails() {
  return (
    <div className="flex flex-col justify-items-stretch p-5 gap-6 bg-white md:basis-2/3">
      <div className="flex flex-row my-16 justify-center text-2xl text-gray-300 font-semibold gap-2">
        <div>
          <IonIcon
            name="information-circle"
            style={{ transform: "translateY(4px)" }}
          />
        </div>
        <div>Select a test to see history details</div>
      </div>
    </div>
  );
}

export default function TestsDetails() {
  const { all_test, all_tests, opportunities, restricted_results } =
    useDashboard();

  const latest_result = head(
    orderBy(all_test, function (t: any) {
      const stamp = moment(t.Result_Date_Time__c).unix();
      return stamp;
    })
  );
  if (!latest_result) return null;
  const Sub_Name__c = head(all_test)?.Sub_Name__c;
  const { Order_Number__c, Result__c } = latest_result;
  const latest_result_order = find(flatMap(opportunities, "Orders.records"), {
    Id: Order_Number__c,
  });

  const restrict_result =
    latest_result_order.Status !== "Results Delivered" &&
    includes(restricted_results, Sub_Name__c) &&
    Result__c === "Positive";

  const isGood = Result__c === "Negative";
  return (
    <div className="flex flex-col justify-items-stretch p-5 gap-6 bg-white md:basis-2/3">
      <div className="flex flex-col p-4">
        <div className="border-b border-gray-100 pb-4 mb-4">
          <div className="flex flex-row rounded-md overflow-hidden gap-2">
            {latest_result && !restrict_result ? (
              <div
                className={`w-2 ${isGood ? "bg-green-600" : "bg-red-600"}`}
              ></div>
            ) : (
              <div className="w-2 bg-gray-300" />
            )}
            <div className="grow">
              <h1 className=" text-2xl text-gray-600 font-bold leading-7">
                {Sub_Name__c}
              </h1>
              <h3
                className="font-normal text-xs italic text-gray-400"
                style={{ whiteSpace: "nowrap" }}
              >
                {getRecordsCount(Sub_Name__c, all_tests || [])}
              </h3>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <h1 className="text-2xl text-gray-300 font-bold uppercase">
            Test History
          </h1>
          {all_test?.map((test: any) => (
            <HistoryDetail test={test} key={test.Id} />
          ))}
        </div>
      </div>
    </div>
  );
}
