import { useState } from "react";
import IonIcon from "@reacticons/ionicons";
import { find } from "lodash";
import { useCart } from "../../../../context/CartContext";
import { addPackage } from "../../../../utils/functions";

export default function ShopCardDesktop({
  active,
  idx,
  pkg,
}: {
  active: boolean;
  idx: number;
  pkg: any;
}) {
  const { individual_products, shopping_cart, removeItem, setShoppingCart } =
    useCart();
  const [isHovered, setIsHovered] = useState(false);

  const { items, name, price, sku } = pkg;

  return (
    <div className={`flex flex-col w-full p-2`} key={idx}>
      <div
        className={`flex flex-col py-4 gap-4 items-center ${
          active ? "bg-rsc-light-blue text-white rounded-lg" : ""
        }`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="text-center font-bold text-[1.35rem]">{name}</div>
        <div className="flex flex-row items-center">
          <div className="text-center font-bold text-3xl border-r border-rsc-light-blue pr-2 mr-2">
            ${price}
          </div>
          <div className="text-center">
            {active ? (
              <button
                className="text-white uppercase hover:underline"
                onClick={() => removeItem(sku)}
              >
                {isHovered ? "Remove" : "Selected"}
              </button>
            ) : (
              <button
                className="bg-red-600 font-bold uppercase text-white px-4 py-1 shadow-lg rounded-full"
                onClick={() =>
                  addPackage({ pkg, shopping_cart, setShoppingCart })
                }
              >
                Select
              </button>
            )}
          </div>
        </div>
        <div>
          <ul className="text-[0.9375rem]">
            {items.map((item: string, idx: number) => {
              const pkg = find(individual_products, ["sku", item]);
              if (!pkg) return null;
              return (
                <li key={idx}>
                  <IonIcon
                    className="font-bold translate-y-[2px]"
                    name="checkmark-sharp"
                  />{" "}
                  {pkg.name}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
