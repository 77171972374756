import IonIcon from "@reacticons/ionicons";
import { compact, concat, map } from "lodash";
import { useUI } from "../../context/UIContext";
import menu_items from "./menu_settings";
export default function MenuDrawer() {
  const { setDrawerOpen, setLocation } = useUI();

  const url_items = map(menu_items, "primary");

  const menu_set = concat(
    compact([
      { link: "/", label: "Home" },
      false && {
        link: "./dashboard",
        label: "My Account",
        highlight: true,
      },
      ...url_items,
      { link: "./order", label: "Get Tested", highlight: true },
    ])
  );

  return (
    <div className="flex flex-col items-end gap-6 text-white font-semibold h-full">
      <div className="mb-2 font-medium">
        <button onClick={() => setDrawerOpen(false)}>
          <IonIcon name="close-sharp" size="large" />
        </button>
      </div>
      <div className="flex flex-col items-end gap-6 grow">
        {menu_set.map((menu_item: any, idx: number) => {
          const { highlight, link, label, url } = menu_item;
          return (
            <div
              className={`font-medium ${
                highlight ? "text-amber-400" : "text-white"
              }`}
              key={idx}
            >
              {link ? (
                <button
                  className="uppercase"
                  onClick={() => {
                    setLocation(link);
                    setDrawerOpen(false);
                  }}
                >
                  {label}
                </button>
              ) : (
                <a href={`https://rapidstdtesting.com${url}`}>
                  <button className="uppercase">{label}</button>
                </a>
              )}
            </div>
          );
        })}
      </div>
      <div>
        <button className="uppercase">
          <IonIcon
            name="lock-closed"
            style={{ transform: "translateY(2px)" }}
          />{" "}
          Log In
        </button>
      </div>
    </div>
  );
}
