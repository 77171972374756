import InvoicePatient from "../PatientInformation";
import InvoiceLab from "../Laboratory";
import InvoicePayments from "../Payments";
import InvoiceOrders from "../Purchases";
import DocumentHeader from "../Header";
import { usePayment } from "../../components/Payment/PaymentInfoContext";

export function PurchaseInvoice({
  brief,
  data,
  doctor,
  for_pdf,
}: {
  brief?: boolean;
  data: any;
  doctor: any;
  for_pdf: boolean;
}) {
  const { oid, reorder_discount, mx, totals } = data;
  const user = mx?.order?.user;
  console.log({ orders: mx.shopping_cart });
  return (
    <table
      id="order-confirmation"
      style={{
        fontFamily: "'Poppins', Arial, sans-serif",
        width: "100%",
      }}
    >
      <tbody>
        <tr>
          <td>
            <DocumentHeader
              doctor={doctor}
              for_pdf={for_pdf}
              label="Order Confirmation"
              sublabel={oid}
            />
            {!brief && (
              <>
                <InvoicePatient user={user} />
                <table>
                  <tbody>
                    <tr>
                      <td style={{ paddingBottom: 40 }}></td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
            <>
              <InvoiceLab lab={mx?.order?.selected_center} />{" "}
              <table>
                <tbody>
                  <tr>
                    <td style={{ paddingBottom: 40 }}></td>
                  </tr>
                </tbody>
              </table>
            </>

            {!brief && (
              <InvoiceOrders
                data={{
                  reorder_discount,
                  shopping_cart: mx.shopping_cart,
                  totals,
                }}
              />
            )}
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td
                    style={{
                      fontSize: 24,
                      fontWeight: 600,
                      padding: "112px 0",
                      textAlign: "center",
                    }}
                  >
                    Save 10% on your next order.
                  </td>
                </tr>
              </tbody>
            </table>
            <InvoicePayments data={data} />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default function Confirmation({
  for_pdf,
  data,
}: {
  for_pdf?: boolean;
  data: any;
}) {
  const { doctor_data, loading_doctor: loading } = usePayment();
  if (!data || loading) return <>Loading...</>;
  return (
    <PurchaseInvoice data={data} doctor={doctor_data} for_pdf={!!for_pdf} />
  );
}
