import { useAccount } from "../../context/AccountContext";
import { compact, isEmpty, join, upperFirst } from "lodash";
import { useUser } from "../../context/UserContext";

export default function GeneralUser() {
  const { is_admin, user, logoutUser, setManagingAccount } = useUser();
  const { account, setAccount } = useAccount();
  const name_data = account?.Id
    ? [account?.FirstName, account?.LastName]
    : [user?.given_name, user?.family_name];
  const name = upperFirst(join(compact(name_data), " "));

  function PageTitle() {
    return (
      <div className="flex flex-row md:grow order-2 md:order-1">
        <div className="text-xl text-rsc-blue/90 md:text-2xl font-bold">
          {is_admin ? "Patient Dashboard" : "My Dashboard"}
        </div>

        {/* <div className="text-xl md:text-2xl text-rsc-blue/80 px-3 ml-4 border-l border-gray-400 font-semibold">
          {name}
        </div> */}
      </div>
    );
  }

  function UserInfo() {
    return (
      <div className="flex flex-row items-center justify-end order-1 md:order-2">
        <div className="text-md text-rsc-light-blue md:text-2xl">
          {name || <span className="text-gray-400 italic">name unset</span>}
        </div>
        <div className="text-sm border-l border-rsc-blue pl-3 ml-2 text-sky-600">
          {is_admin ? (
            <button
              onClick={() => {
                setManagingAccount(null);
                setAccount(null);
              }}
            >
              Clear
            </button>
          ) : (
            <button
              onClick={() => {
                logoutUser();
              }}
            >
              Logout
            </button>
          )}
        </div>
      </div>
    );
  }

  const hide_header =
    is_admin && (isEmpty(account) || user.sub === account?.sub);

  return (
    <div className="flex flex-col md:flex-row justify-start md:items-end  md:mb-8 text-gray-700 mx-auto max-w-7xl px-2 w-full">
      {!hide_header && (
        <>
          <PageTitle />
          <UserInfo />
        </>
      )}
    </div>
  );
}
