import { useState } from "react";
import moment from "moment";
import IonIcon from "@reacticons/ionicons";
import { split } from "lodash";

export default function LabHours({ laboratory }: { laboratory: any }) {
  const [showHours, setShowHours] = useState<boolean>(false);

  function toggleView() {
    const flip = !showHours;
    setShowHours(flip);
  }

  function isOpen(hours: any) {
    const now = moment();
    const today = now.format("dddd");
    const { start, end } = hours?.[today] || {};
    if (!start) return false;
    const start_ = moment(start, "LT");
    const end_ = moment(end, "LT");
    const is_open = now.isBetween(start_, end_);
    return is_open;
  }

  const is_open = isOpen(laboratory?.hours);

  if (!laboratory) return null;
  const { Hours__c } = laboratory;
  return (
    <div className="grow flex flex-col text-gray-600 font-semibold items-end">
      {is_open && (
        <div>
          <IonIcon
            name="ellipse"
            className="text-green-600"
            style={{ marginRight: 8, transform: "translateY(3px)" }}
          />
          Open Now
        </div>
      )}
      <div>
        <button className="text-blue-600" onClick={() => toggleView()}>
          <IonIcon
            name={showHours ? "caret-forward" : "caret-down"}
            style={{ transform: "translateY(3px)" }}
          />{" "}
          Show Hours
        </button>
      </div>
      <div className="mt-2 font-normal">
        {showHours && (
          <>
            {split(Hours__c, "|").map((day: string, idx: number) => {
              return <div key={idx}>{day}</div>;
            })}
          </>
        )}
      </div>
    </div>
  );
}
