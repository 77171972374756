const rules = {
  "Super Administrator": {
    static: [
      "apply:reorder-discount",
      "simulate:role",
      "delete:user",
      "edit:admin",
      "edit:user",
      "order:consultation",
      "market:accounts",
      "view:accounts",
      "view:results",
      "view:users",
    ],
  },
  Administrator: {
    static: [
      "apply:reorder-discount",
      "delete:user",
      "edit:admin",
      "edit:user",
      "order:consultation",
      "market:accounts",
      "view:accounts",
      "view:results",
      "view:users",
    ],
  },
  Agent: {
    static: [
      "apply:reorder-discount",
      "delete:user",
      "edit:user",
      "order:consultation",
      "market:accounts",
      "view:accounts",
      "view:results",
      "view:users",
    ],
  },
  User: {
    static: [],
  },
  Visitor: {
    static: [],
  },
};

export default rules;
