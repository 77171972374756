import Section from "../form_section";

export default function ChangePassword({
  code_sent,
  username,
  sendVerificationCode,
  setCodeSent,
}: {
  code_sent: boolean;
  username: string;
  sendVerificationCode: any;
  setCodeSent: any;
}) {
  return (
    <>
      <Section>
        <div>
          <p className="text-sm">
            A reset code will be sent to{" "}
            <span className="font-semibold">{username}</span> for authorizing
            your new password.
          </p>
        </div>
      </Section>
      <Section>
        <div className="flex flex-row items-center">
          <div className="grow">
            <button
              className=" text-xs text-rsc-light-blue font-semibold"
              onClick={() => setCodeSent(true)}
            >
              Verify Code
            </button>
          </div>
          <div className="">
            <button
              //disabled={!isReady}
              onClick={() => sendVerificationCode()}
              className={`p-3 font-semibold text-center text-white rounded-md shadow-md ${
                true ? "bg-rsc-blue text-white" : "bg-gray-300 text-white"
              }`}
            >
              Send Reset Code
            </button>
          </div>
        </div>
      </Section>
    </>
  );
}
