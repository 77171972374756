import { useState } from "react";
import IonIcon from "@reacticons/ionicons";
import { kebabCase, sortBy, toLower, uniqBy } from "lodash";
import { useUI } from "../../../../../context/UIContext";

export default function OrderDetails({
  name,
  tests,
}: {
  name: string;
  tests: any[];
}) {
  const { setLocation } = useUI();
  const [showTests, setShowTests] = useState<boolean>(false);
  // const { setViewingTest } = useDashboard();

  function TestResult({ result }: { result?: any }) {
    if (!result) return null;
    const { Result__c, Reference_Range__c } = result;
    return (
      <div className="flex flex-col justify-end md:justify-center items-end uppercase italic text-gray-500 text-sm">
        <div>
          {(
            <span
              className={`font-semibold ${
                Result__c === "Negative" ? "text-green-600" : "text-red-600"
              }`}
            >
              {Result__c}
            </span>
          ) || <span className="text-gray-300">pending</span>}
        </div>
        {false && Reference_Range__c && (
          <div className="text-xs">{Reference_Range__c}</div>
        )}
      </div>
    );
  }

  return (
    <>
      <div
        className="flex flex-col md:flex-row gap-2 md:gap-0 md:items-center border border-gray-200 shadow-md rounded-md p-3"
        onClick={() => {
          const flip = !showTests;
          setShowTests(flip);
        }}
        style={{ cursor: "pointer" }}
      >
        <div className="flex flex-col grow text-gray-500">
          <div className=" font-semibold">{name}</div>
        </div>
        <div className="uppercase text-sm text-right text-gray-600">
          {showTests ? "Close" : "Expand"}{" "}
          <IonIcon
            name={showTests ? "caret-up" : "caret-down"}
            style={{ transform: "translateY(2px)" }}
          />
        </div>
      </div>
      {showTests &&
        sortBy(uniqBy(tests, "Sub_Name__c"), function (r: any) {
          return toLower(r.Sub_Name__c);
        }).map((test: any) => {
          const {
            Id,
            //    Date_Time_of_Analysis__c,
            Reference_Range__c,
            Result__c,
            Sub_Name__c,
            Test_Name__c,
            //  Value__c,
          } = test;

          const isGood = Result__c === "Negative";

          return (
            <div
              key={Id}
              className="flex flex-row ml-10 border border-gray-200 shadow rounded-md overflow-hidden"
            >
              <div
                className={`w-2 ${isGood ? "bg-green-600" : "bg-red-600"}`}
              ></div>
              <div
                className="grow flex flex-col md:flex-row gap-2 md:gap-0 md:items-center p-3"
                onClick={() => {
                  setLocation(
                    `/dashboard/my-orders/tests/${kebabCase(Sub_Name__c)}`
                  );
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="flex flex-col grow text-gray-500">
                  <div className=" font-semibold">{Sub_Name__c}</div>
                  {false && <div className="text-xs">{Test_Name__c}</div>}
                </div>
                <TestResult result={{ Reference_Range__c, Result__c }} />
              </div>
            </div>
          );
        })}
    </>
  );
}
