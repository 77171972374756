import { useUser } from "./context/UserContext";
import PatientLoginButton from "./login/PatientLogIn";

export default function NotAuthorized() {
  const { user } = useUser();
  if (!user) {
    return (
      <div className="flex flex-col gap-2 items-center justify-center h-full">
        <div className="text-4xl font-bold text-rsc-light-blue">
          Not Authorized
        </div>
        <div>You must be logged in to access this page.</div>
        <PatientLoginButton />
      </div>
    );
  }
  return (
    <div className="h-full">
      <div>Not Authorized</div>
    </div>
  );
}
