export default function DetailContainer({
  action,
  title,
  children,
}: {
  action?: any;
  title?: string;
  children: any;
}) {
  return (
    <>
      <div className="flex flex-col justify-items-stretch mb-12 md:mb-8 gap-6">
        {(action || title) && (
          <div className="flex flex-row items-center border-b border-rsc-light-blue/20 pb-2 md:mb-5">
            {title && (
              <div className="grow text-2xl text-rsc-light-blue font-semibold ">
                {title}
              </div>
            )}
            {action && <div>{action}</div>}
          </div>
        )}
        {children}
      </div>
      {/* <hr className="shadow-md border-0 h-2" /> */}
    </>
  );
}
