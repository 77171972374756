import { useUI } from "../context/UIContext";

export default function ShopHero() {
  const { is_mobile } = useUI();
  const hero_icons = [
    {
      icon: "security",
      text: (
        <>
          We provide 100% <b>Confidential</b> and <b>Secure</b> STD testing
          services.
        </>
      ),
    },
    {
      icon: "working-days",
      text: (
        <>
          Fast <b>Results</b> are available in <b>1-2 Days</b>.
        </>
      ),
    },
    {
      icon: "certified-labs",
      text: (
        <>
          All tests are <b>FDA approved</b> and performed in{" "}
          <b>CLIA-certified</b> labs.
        </>
      ),
    },
    {
      icon: "phone-schedule",
      text: (
        <>
          Test scheduling <b>online</b> or call <b>1-866-872-1888</b>.
        </>
      ),
    },
    {
      icon: "medical-experts",
      text: (
        <>
          Our <b>medical experts</b> and consultants are available for{" "}
          <b>STD care & Treatment</b>.
        </>
      ),
    },
  ];

  function Desktop() {
    return (
      <div className="flex flex-col lg:flex-row px-8 lg:px-0 gap-6 text-rsc-blue justify-center">
        {hero_icons.map((item: any) => {
          const { icon, text } = item;
          return (
            <div
              className="flex flex-row lg:flex-col gap-4 items-center lg:max-w-[171px]"
              key={icon}
            >
              <div className="w-[66px] lg:w-[unset] lg:h-[90px] flex flex-col items-center justify-center">
                <div className="text-center">
                  <img
                    src={`https://res.cloudinary.com/maxime-associates/image/upload/w_80/v1664932779/RapidSTDTesting/${icon}.png`}
                    alt={`${icon} icon`}
                    className=" lg:w-[72px]"
                  />
                </div>
              </div>
              <p className="text-[0.9375rem] leading-[1.375rem] lg:text-center">
                {text}
              </p>
            </div>
          );
        })}
      </div>
    );
  }

  function Mobile() {
    return (
      <table className="mx-4 text-rsc-blue">
        <tbody>
          {hero_icons.map((item: any) => {
            const { icon, text } = item;
            return (
              <tr key={icon}>
                <td className="text-center pb-4">
                  <img
                    src={`https://res.cloudinary.com/maxime-associates/image/upload/w_80/v1664932779/RapidSTDTesting/${icon}.png`}
                    alt={`${icon} icon`}
                    className="max-w-[72px]"
                  />
                </td>
                <td className="pl-4 pb-4 text-[0.9375rem] leading-[1.375rem]">
                  {text}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  return (
    <div className="flex flex-col pt-8 pb-16  gap-8 bg-white w-full">
      <div className="text-center text-rsc-blue px-8">
        <h1 className="leading-[3.75rem] mb-[0.625rem] text-[2.8125rem] font-bold">
          The Best STD Testing Option
        </h1>
        <p className="leading-[1.375rem] text-[0.9375rem]">
          Sexually transmitted disease (STD) testing is the best way to get a
          clear picture of your sexual health.
          <br />
          <span className="font-bold">
            Our STD test panel is the most popular option with our patients for
            a few reasons:
          </span>
        </p>
      </div>
      {is_mobile ? <Mobile /> : <Desktop />}
    </div>
  );
}
