import { Route, Router, Switch } from "wouter";
import AdminSettings from "./admin_settings";
import LogIn from "./login";
import UserVerification from "./login/verification";
import ShopTesting from "./shop";
import ConsultationApp from "./consultation";
import OrderTesting from "./order";
import NotAuthorized from "./NotAuthorized";
import PageNotFound from "./PageNotFound";
import makeCachedMatcher from "wouter/matcher";
import { pathToRegexp } from "path-to-regexp";
import UsersContextProvider from "./users/UsersContext";
import Users from "./users";
import User from "./user";
import FindATestCenter from "./order/ScheduleTesting/Find_Test_Center/find-a-lab";
import Can from "./utils/auth/Can";
import UserEditContextProvider from "./user/UserEditContext";
import AdminCreateUserPage from "./user/create";
import ConsultationContextProvider from "./consultation/ScheduleConsultation/context/ConsultationContext";
import MainDashboard, { DashboardView } from "./account/layout";
import PharmacyContextProvider from "./consultation/ScheduleConsultation/ScheduleForm/ChoosePharmacy/PharmacyContext";
import PartnerConsultationContextProvider from "./consultation/ScheduleConsultation/context/PartnerConsultationContext";
import { useUser } from "./context/UserContext";
import MyOrdersPanel from "./account/MyOrders/_Layout/acct_panel";
import MyOrdersDetails from "./account/MyOrders/_Layout/body";
import OrderDetails, {
  MyOrdersOrderDetails,
} from "./account/MyOrders/Orders/details_orders";
import TestsDetails, {
  MyOrdersTestsDetails,
} from "./account/MyOrders/Tests/details_test";
import MessagesContextProvider from "./account/Messages/MessagesContext";
import MessagesDetails from "./account/Messages/details";
import MessagesPanel from "./account/Messages/msg_panel";
import SettingsDetails, { SettingsPanel } from "./account/Settings";
import OrderViewInvoice from "./account/MyOrders/Orders/details_orders/Invoice";
import OrderMakePayment from "./account/MyOrders/Orders/details_orders/Payment";
import Confirmations from "./Confirmation";
import PaymentContextProvider from "./components/Payment/PaymentInfoContext";
import BraintreeContextProvider from "./components/Payment/BraintreeContext";
//import NewEmailTemplate from "./Confirmation/order-confirmation";

const convertPathToRegexp = (path: string) => {
  let keys: any[] = [];

  const regexp = pathToRegexp(path, keys, { strict: true });
  return { keys, regexp };
};

const customMatcher = makeCachedMatcher(convertPathToRegexp);

function UsersContextShare({ children }: { children: any }) {
  return <UsersContextProvider>{children}</UsersContextProvider>;
}

function Payments({ children }: { children: any }) {
  return (
    <BraintreeContextProvider>
      <PaymentContextProvider>{children}</PaymentContextProvider>
    </BraintreeContextProvider>
  );
}

function ConsultationContextShare({
  children,
  _id,
  partner,
}: {
  children: any;
  _id?: string;
  partner?: boolean;
}) {
  return (
    <ConsultationContextProvider partner={!!partner}>
      {partner ? (
        <PartnerConsultationContextProvider _id={_id}>
          <PharmacyContextProvider>{children}</PharmacyContextProvider>
        </PartnerConsultationContextProvider>
      ) : (
        <PharmacyContextProvider>{children}</PharmacyContextProvider>
      )}
    </ConsultationContextProvider>
  );
}

function UserContextShare({
  username,
  view,
}: {
  username: string;
  view?: string;
}) {
  const { user } = useUser();
  const role = user?.roles;
  return (
    <Can
      role={role}
      perform={"view:users"}
      yes={() => (
        <UsersContextShare>
          <UserEditContextProvider username={username} view={view}>
            <User />
          </UserEditContextProvider>
        </UsersContextShare>
      )}
      no={() => <NotAuthorized />}
    />
  );
}

export default function App() {
  const { user } = useUser();
  const role = user?.roles;

  return (
    <Router matcher={customMatcher}>
      <Switch>
        <Route path="/settings">
          <AdminSettings />
        </Route>
        <Route path="/create/user">
          <AdminCreateUserPage />
        </Route>
        <Route path="/user/:username/:view">
          {({ username, view }) => (
            <UserContextShare username={username} view={view} />
          )}
        </Route>
        <Route path="/user/:username">
          {({ username }) => <UserContextShare username={username} />}
        </Route>
        <Route path="/users">
          <Can
            role={role}
            perform={"view:users"}
            yes={() => (
              <UsersContextShare>
                <Users />
              </UsersContextShare>
            )}
            no={() => <NotAuthorized />}
          />
        </Route>
        <Route path="/dashboard">
          <MainDashboard />
        </Route>
        <Route path="/dashboard/my-orders">
          <MainDashboard>
            <DashboardView
              details={<MyOrdersDetails />}
              panel={<MyOrdersPanel />}
            />
          </MainDashboard>
        </Route>
        <Route path="/dashboard/my-orders/orders">
          <MainDashboard>
            <DashboardView
              details={<MyOrdersOrderDetails />}
              panel={<MyOrdersPanel />}
            />
          </MainDashboard>
        </Route>
        <Route path="/dashboard/my-orders/orders/:Id">
          <MainDashboard>
            <DashboardView
              details={<OrderDetails />}
              panel={<MyOrdersPanel />}
            />
          </MainDashboard>
        </Route>
        <Route path="/dashboard/my-orders/orders/:Id/invoice">
          <MainDashboard>
            <DashboardView
              details={<OrderViewInvoice />}
              panel={<MyOrdersPanel />}
            />
          </MainDashboard>
        </Route>
        <Route path="/dashboard/my-orders/orders/:Id/payment">
          <Payments>
            <MainDashboard>
              <DashboardView
                details={<OrderMakePayment />}
                panel={<MyOrdersPanel />}
              />
            </MainDashboard>
          </Payments>
        </Route>
        <Route path="/dashboard/my-orders/tests">
          <MainDashboard>
            <DashboardView
              details={<MyOrdersTestsDetails />}
              panel={<MyOrdersPanel />}
            />
          </MainDashboard>
        </Route>
        <Route path="/dashboard/my-orders/tests/:Id">
          <MainDashboard>
            <DashboardView
              details={<TestsDetails />}
              panel={<MyOrdersPanel />}
            />
          </MainDashboard>
        </Route>
        <Route path="/dashboard/messages/:Id?">
          <MainDashboard>
            <DashboardView
              context={MessagesContextProvider}
              details={<MessagesDetails />}
              panel={<MessagesPanel />}
            />
          </MainDashboard>
        </Route>
        <Route path="/dashboard/settings/:Id?">
          <MainDashboard>
            <DashboardView
              details={<SettingsDetails />}
              panel={<SettingsPanel />}
            />
          </MainDashboard>
        </Route>
        <Route path="/consultation">
          <Payments>
            <ConsultationContextShare>
              <ConsultationApp />
            </ConsultationContextShare>
          </Payments>
        </Route>
        <Route path="/consultation/:_id">
          {({ _id }: { _id: string }) => (
            <Payments>
              <ConsultationContextShare _id={_id} partner>
                <ConsultationApp />
              </ConsultationContextShare>
            </Payments>
          )}
        </Route>
        <Route path="/test-and-panels">
          <ShopTesting />
        </Route>
        <Route path="/find-a-lab">
          <FindATestCenter />
        </Route>
        <Route path="/order">
          <Payments>
            <OrderTesting />
          </Payments>
        </Route>
        <Route path="/(consultation-confirmation|order-confirmation|payment-confirmation|verification-email)">
          <Payments>
            <Confirmations />
          </Payments>
        </Route>

        <Route path="/(forgot-password|login|register|resend|reset-password|verify|)">
          <LogIn />
        </Route>
        <Route path="/verification">
          <UserVerification />
        </Route>
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </Router>
  );
}
