import IonIcon from "@reacticons/ionicons";
import { useCart } from "../../context/CartContext";
import { useUI } from "../../context/UIContext";
import { useUser } from "../../context/UserContext";

export default function TopHeader() {
  const { setLocation } = useUI();
  const { user, logoutUser } = useUser();
  const { shopping_cart } = useCart();
  const hasItems = shopping_cart.length > 0;
  return (
    <div className="bg-rsc-blue text-xs md:text-[1rem] text-white leading-4 md:leading-[2.375rem]">
      <div className="py-2 mx-auto w-full max-w-7xl ">
        <div className="flex flex-col md:flex-row mx-5 items-center">
          <div className="flex-grow">
            <p className="">{"Quick & Confidential Rapid STD Testing"}</p>
          </div>
          <div className="flex flex-row items-center gap-5">
            <div className="">
              <p className="">
                <a href={"tel:+1866-872-1888"}>(866)872-1888</a>
              </p>
            </div>
            <div className="">
              <p className="">Open 24 Hours</p>
            </div>
            <div className="hidden md:block">
              <div className="flex flex-row gap-3">
                {hasItems && (
                  <div>
                    <button
                      onClick={() => setLocation("./order")}
                      className="px-5 py-1 text-xs  bg-white rounded-full text-rsc-blue font-bold items-center"
                    >
                      <IonIcon
                        name="cart"
                        style={{ transform: "translateY(2px)" }}
                      />{" "}
                      {!!user ? "My Cart" : "Cart"}{" "}
                      <sup className="font-semibold italic text-[.5rem]">
                        {`(${shopping_cart.length})`}
                      </sup>
                    </button>
                  </div>
                )}
                <div>
                  {!!user ? (
                    <button
                      onClick={() => setLocation("/dashboard")}
                      className="px-5 py-1 text-xs bg-white rounded-full text-rsc-blue font-bold items-center"
                    >
                      <IonIcon
                        name="person"
                        style={{ transform: "translateY(2px)" }}
                      />{" "}
                      My Account
                    </button>
                  ) : (
                    <button
                      onClick={() => setLocation("./login")}
                      className="px-5 py-1 text-xs  bg-white rounded-full text-rsc-blue font-bold items-center"
                    >
                      <IonIcon
                        name="lock-closed"
                        style={{ transform: "translateY(2px)" }}
                      />{" "}
                      <span>Log In</span>
                    </button>
                  )}
                </div>
                {!!user && (
                  <div>
                    <button
                      onClick={() => logoutUser()}
                      className="px-5 py-1 text-xs bg-white rounded-full bg-rsc-red text-white font-bold items-center"
                    >
                      <span>Log Out</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
