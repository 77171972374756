import { createContext, useContext, useState } from "react";
import moment from "moment";
import {
  ListUsersCommand,
  ListUsersCommandInput,
} from "@aws-sdk/client-cognito-identity-provider";
import { CognitoClient } from "../utils/aws";
import { useUser } from "../context/UserContext";
import {
  concat,
  filter,
  includes,
  lowerCase,
  map,
  orderBy,
  split,
  startsWith,
  uniqBy,
  zipObject,
} from "lodash";
import { useUI } from "../context/UIContext";

const users_menu = [
  { id: "all", label: "All" },
  { id: "Agent", label: "Agents", search: ["custom:roles = 'Agent'"] },
  {
    id: "admins",
    label: "Administrators",
    search: [
      "custom:roles = 'Administator'",
      "custom:roles = 'Super Administator'",
    ],
  },
  {
    id: "Unconfirmed",
    label: "Unconfirmed",
    search: ["UserStatus = 'UNCONFIRMED'"],
  },
  { id: "Disabled", label: "Disabled", search: ["Enabled = 'false'"] },
];

interface AccountValues {
  alt_menu: any[];
  loading: boolean;
  search: string;
  sort: any;
  users: any[] | null;
  users_menu: any[];
  users_table: any[];
  username?: string;
  view_users: any[];
  viewing: string;
  getUsers: (search: string) => void;
  setSearch: (search: string) => void;
  setSort: (sort: any) => void;
  setViewing: (viewing: string) => void;
}

export const UsersContext = createContext<AccountValues>({
  alt_menu: [],
  loading: false,
  search: "",
  sort: { type: "family_name", dir: "asc" },
  users: null,
  users_menu,
  users_table: [],
  view_users: [],
  viewing: "All",
  getUsers: () => {},
  setSearch: () => {},
  setSort: () => {},
  setViewing: () => {},
});

export const useUsers = () => useContext(UsersContext);

const UsersContextProvider: React.FC<{
  children: any;
  username?: string;
}> = ({ children, username }) => {
  const { setLocation } = useUI();
  const { UserPoolId } = useUser();

  const [viewing, setViewing] = useState<string>("all");
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const [users, setUsers] = useState<{
    id: string;
    data: any[];
  } | null>(null);
  const [sort, setSort] = useState<any>({
    type: "family_name",
    dir: "asc",
  });

  const alt_menu = [
    {
      id: "create",
      className:
        "pl-2 pr-6 py-1 text-green-700 font-bold hover:bg-green-700/10 cursor-pointer",
      label: "Create New User",
      onClick: () => setLocation("/create/user"),
      perform: "create:user",
    },
  ];

  const users_table = [
    {
      id: "status",
    },
    {
      id: "family_name",
      label: "Last Name",
    },
    {
      id: "given_name",
      label: "First Name",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "phone_number",
      label: "Phone Number",
    },
    {
      id: "created",
      label: "Created",
    },
  ];

  function makeUser(user: any) {
    const keys = map(user.Attributes, function (i: any) {
      if (startsWith(i.Name, "custom:")) {
        return split(i.Name, ":")[1];
      }
      return i.Name;
    });
    const values = map(user.Attributes, "Value");
    const attributes = {
      ...user,
      ...zipObject(keys as string[], values),
      created: moment(user.UserCreateDate).format("lll"),
    };
    return { ...user, ...attributes };
  }

  async function queryConcat({
    Filter,
    PaginationToken,
  }: {
    Filter: string;
    PaginationToken?: any;
  }) {
    const input: ListUsersCommandInput = {
      Filter,
      PaginationToken,
      UserPoolId,
    };

    const command = new ListUsersCommand(input);
    const { PaginationToken: new_PaginationToken, Users } =
      await CognitoClient.send(command);

    return {
      Users,
      PaginationToken: new_PaginationToken,
    };
  }

  async function searchSet({ Filter }: { Filter: string }) {
    let user_set: any[] = [];
    try {
      let token;
      do {
        const response: any = await queryConcat({
          Filter,
          PaginationToken: token,
        });
        user_set = concat(user_set, response.Users);
        token = response.PaginationToken;
      } while (!!token);
    } catch (error: any) {
      console.log("FAILED TO GET USERS IN SET", Filter, error);
    } finally {
      return user_set;
    }
  }

  async function getUsers() {
    setLoading(true);
    let user_key_set: any[] = [];
    const search_strings = map(
      ["email", "family_name", "given_name", "phone_number"],
      function (attribute: string): string {
        return `${attribute} ^= "${search}"`;
      }
    );
    console.log({ search_strings, user_key_set });
    try {
      for (const Filter of search_strings) {
        const response: any = await searchSet({ Filter });
        user_key_set = concat(user_key_set, response);
      }
    } catch (error: any) {
      console.log("FAILED TO GET USERS", error);
    } finally {
      const data = map(uniqBy(user_key_set, "Username"), makeUser);
      const users = { id: search, data };
      console.log({ user_key_set });
      console.log("RETURNED USERS", users);
      setUsers(users);
      setLoading(false);
    }
  }

  function filterView(users: any[]) {
    if (viewing === "all") return users;

    const eligible = filter(users, function (u: any) {
      if (viewing === "admins") {
        return includes(["Administrators", "Super Administrators"], u.roles);
      }
      return u.roles === viewing;
    });
    return eligible;
  }

  const sort_fns: { [key: string]: any } = {
    created: (u: any) => {
      const created = new Date(u.created);
      return moment(created).valueOf();
    },
    email: (u: any) => {
      return lowerCase(u.email);
    },
    family_name: (u: any) => {
      return lowerCase(u.family_name);
    },
    given_name: (u: any) => {
      return lowerCase(u.given_name);
    },
    phone_number: (u: any) => {
      return lowerCase(u.phone_number);
    },
  };

  const view_users = orderBy(
    filterView(users?.data || []),
    [sort_fns[sort.type]],
    [sort.dir]
  );
  console.log({ users });
  return (
    <UsersContext.Provider
      value={{
        alt_menu,
        loading,
        search,
        sort,
        username,
        users: users?.data || null,
        users_menu,
        users_table,
        view_users,
        viewing,
        getUsers,
        setSearch,
        setSort,
        setViewing,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export default UsersContextProvider;
