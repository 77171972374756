import IonIcon from "@reacticons/ionicons";
import { useConsultation } from "../../context/ConsultationContext";
import { usePayment } from "../../../../components/Payment/PaymentInfoContext";
import { compact, concat, isEmpty } from "lodash";
import PreLoader from "../../../../PreLoader";

export default function FinalizeConsultation() {
  const {
    errors: pay_errors,
    loading,
    processCreditCard,
    setLoading,
  } = usePayment();
  const { errors, patient, total_due, view_errors, setViewErrors } =
    useConsultation();

  if (loading) return <PreLoader text="Finalizing consultation..." />;
  return (
    <>
      {view_errors && (
        <div className="mb-8">
          <div className="mb-2 font-semibold">
            Please correct the following information:
          </div>
          <div className="flex flex-col gap-2 mx-2">
            {compact(concat(errors, pay_errors)).map(
              (error: any, idx: number) => {
                return (
                  <div
                    key={idx}
                    className="border border-rsc-red bg-rsc-red/30 rounded-md p-2 text-xs"
                  >
                    <p>{error}</p>
                  </div>
                );
              }
            )}
          </div>
        </div>
      )}
      <div className="flex flex-col gap-3">
        <div>
          <button
            onClick={async () => {
              try {
                console.log("PATIENT", patient);
                setLoading(true);
                isEmpty(errors)
                  ? await processCreditCard({
                      amount: total_due,
                      data: { mx: { patient }, type: "consultation" },
                      setViewErrors,
                    })
                  : setViewErrors(true);
              } catch (err) {
                console.log("ERROR AFTER CLICK", err);
              } finally {
                setLoading(false);
              }
            }}
            className={`rounded rounded-lg p-4 ${
              view_errors ? "bg-gray-400" : "bg-amber-600"
            } text-center text-white text-xl font-bold w-full`}
          >
            {view_errors ? (
              <span className="icon-text">
                <IonIcon name="lock-closed" /> Retry Order Consultation
              </span>
            ) : (
              <span className="icon-text">
                <IonIcon name="checkmark-circle" /> Order Consultation
              </span>
            )}
          </button>
        </div>
      </div>
    </>
  );
}
