import IonIcon from "@reacticons/ionicons";
import { useUsers } from "../UsersContext";
import { useUI } from "../../context/UIContext";

export default function UserRow({
  attributes,
  style = {},
}: {
  attributes: any;
  style?: any;
}) {
  const { is_mobile, setLocation } = useUI();
  const { users_table } = useUsers();
  const { status, username } = attributes;

  function MobileRowDisplay() {
    const { email, phone, given_name, family_name } = attributes;
    const hasName = given_name || family_name;
    if (hasName) {
      return (
        <div>
          {hasName && (
            <p>
              {given_name} {family_name}
            </p>
          )}
          <p className="text-xs text-gray-500">{email || phone}</p>
        </div>
      );
    }
    return (
      <div>
        <p>{email || phone}</p>
      </div>
    );
  }

  function MobileRow() {
    return (
      <>
        <td>
          {status === "CONFIRMED" ? (
            <IonIcon
              name="checkmark-done-circle"
              className="translate-y-[2px] text-green-700"
            />
          ) : (
            <IonIcon name="close" className="translate-y-[2px] text-red-700" />
          )}
        </td>
        <td>
          <MobileRowDisplay />
        </td>
        <td>
          <button>
            <IonIcon
              name="pencil"
              className="translate-y-[2px] text-rsc-light-blue"
            />
          </button>
        </td>
      </>
    );
  }

  function DesktopRow() {
    return (
      <>
        {users_table.map((col: any) => {
          const { id } = col;
          if (id === "status") {
            return (
              <td key={id} className="py-2">
                {attributes[id] === "CONFIRMED" ? (
                  <IonIcon
                    name="checkmark-done-circle"
                    className="translate-y-[2px] text-green-700"
                  />
                ) : (
                  <IonIcon
                    name="close"
                    className="translate-y-[2px] text-red-700"
                  />
                )}
              </td>
            );
          }
          return <td key={id}>{attributes[id]}</td>;
        })}
      </>
    );
  }
  return (
    <tr
      className="border-b border-rsc-light-blue/20 last:border-rsc-light-blue/40 text-center text-sm cursor-pointer hover:bg-rsc-light-blue/10"
      style={style}
      onClick={() => setLocation(`./user/${username}`)}
    >
      {is_mobile ? <MobileRow /> : <DesktopRow />}
    </tr>
  );
}
