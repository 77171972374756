import { startsWith } from "lodash";
import { useAccount } from "../../../../context/AccountContext";
import { useUI } from "../../../../context/UIContext";
import { useUser } from "../../../../context/UserContext";
import AdminAccountInput from "./input";

export default function AdminAccount() {
  const { location, setLocation } = useUI();
  const { setManagingAccount } = useUser();
  const { account, setAccount } = useAccount();

  function UserAccount({ account }: { account: any }) {
    const { FirstName, LastName, Username } = account;
    const isActive = startsWith(`/user/${Username}`, location);
    return (
      <div className="flex flex-row items-center text-xs gap-6">
        <button
          className="text-sm text-white hover:underline grow"
          onClick={() => setLocation("/dashboard")}
        >
          {FirstName} {LastName}
        </button>
        {Username ? (
          <button
            className={`hover:underline uppercase text-white/70 ${
              isActive ? "font-semibold" : ""
            }`}
            onClick={() => setLocation(`/user/${Username}`)}
          >
            Manage Access
          </button>
        ) : (
          <button
            className={`hover:underline uppercase text-white/70 ${
              isActive ? "font-semibold" : ""
            }`}
            onClick={() => console.log("NOT AVAILABLE")}
          >
            Create Access
          </button>
        )}
        <button
          className={`text-right text-rsc-yellow rounded-lg uppercase`}
          onClick={async () => {
            setManagingAccount(null);
            setAccount(null);
          }}
        >
          Change/Remove
        </button>
      </div>
    );
  }
  return (
    <>
      {account?.Id ? <UserAccount account={account} /> : <AdminAccountInput />}
    </>
  );
}
