import { useCallback, useState } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { useTestCenter } from "../TestCenterContext";
import FindSettings from "../settings";
import FindList from "../results_list";
import MapMarker from "./marker";
import grayscaleMap from "./map_style";
import { useUI } from "../../../../context/UIContext";

const { REACT_APP_GOOGLE_MAPS_KEY } = process.env;

export default function MapView() {
  const { is_mobile } = useUI();
  const [mapref, setMapRef] = useState<any | null>(null);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: REACT_APP_GOOGLE_MAPS_KEY as string,
  });

  const { center, setBox, setMap, test_centers } = useTestCenter();

  const onLoad = useCallback(function callback(this_map: any) {
    setMapRef(this_map);
  }, []);

  const onUnmount = useCallback(
    function callback(map: any) {
      setMap(null);
    },
    [setMap]
  );

  // useEffect(() => {
  //   // 👇️ get global mouse coordinates
  //   function handleWindowMouseMove(event: any) {
  //     const setting = {
  //       x: event.screenX,
  //       y: event.screenY,
  //     };
  //     console.log("setting", setting);
  //     setGlobalCoords(setting);
  //   }
  //   window.addEventListener("mousemove", handleWindowMouseMove);

  //   return () => {
  //     window.removeEventListener("mousemove", handleWindowMouseMove);
  //   };
  // }, []);

  if (!isLoaded)
    return (
      <div className="flex flex-col md:flex-row gap-6 p-3">
        <div className="flex flex-col gap-6 md:min-w-[320px] h-full">
          <div className="bg-gray-300/50 rounded-md animate-pulse w-full h-[250px]"></div>
          <div className="bg-gray-300/50 rounded-md animate-pulse w-full h-[160px]"></div>
        </div>
        <div className="bg-gray-300/50 rounded-md animate-pulse w-full h-full"></div>
      </div>
    );

  return (
    <>
      <GoogleMap
        //      ref={map}
        mapContainerStyle={{
          width: "100%",
          height: is_mobile ? "400px" : "800px",
        }}
        onBoundsChanged={() => {
          if (!mapref || !center) return;
          let { north, south, east, west } = mapref.getBounds().toJSON();
          const box = [
            [west, south],
            [east, north],
          ];
          setBox(JSON.stringify(box));
        }}
        options={{
          mapTypeControl: false,
          fullscreenControl: false,
          styles: grayscaleMap,
        }}
        center={center || { lng: -98.5795, lat: 39.8283 }}
        zoom={center ? 11 : 4}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {test_centers.map((center: any) => {
          return <MapMarker key={center._id} center={center} />;
        })}
        <div
          className="absolute m-3 w-full"
          style={{
            pointerEvents: "none",
            height: "-webkit-fill-available",
            width: "-webkit-fill-available",
          }}
        >
          {!is_mobile && (
            <div
              className="flex flex-col gap-4 h-full max-w-[280px]"
              style={{ pointerEvents: "auto" }}
            >
              <div className="bg-slate-50 p-3 rounded-md drop-shadow-lg overflow-hidden shrink-0">
                <FindSettings />
              </div>
              <div className="bg-slate-50 p-3 rounded-md drop-shadow-lg overflow-y-scroll">
                <FindList no_redirect />
              </div>
            </div>
          )}
        </div>
      </GoogleMap>
    </>
  );
}
