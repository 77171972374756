import { useState } from "react";
import { useOrder } from "../../OrderContext";
import { upperFirst } from "lodash";
import { usePharmacy } from "../PharmacyContext";
import EnterPharmacy from "./enter";
import SelectPharmacy from "./select";
import { usePartnerConsultation } from "../../../context/PartnerConsultationContext";
import { useConsultation } from "../../../context/ConsultationContext";

export default function FindSettings() {
  const { isMobile } = useOrder();
  const { partner: isPartner } = useConsultation();
  const { handlePartnerChange, partner } = usePartnerConsultation();
  const { setView, view } = usePharmacy();
  const [enter_pharmacy, enterPharmacy] = useState<boolean>(false);
  return (
    <>
      {isPartner && (
        <div className="border-b border-b-rsc-light-blue/30 pb-2">
          <input
            type="checkbox"
            className="ml-1 mr-2"
            checked={partner?.same_pharmacy}
            onChange={(e: any) => {
              handlePartnerChange("same_pharmacy", e.target.checked);
            }}
          />
          <label htmlFor="email_message">Same as patient</label>
        </div>
      )}
      {enter_pharmacy ? (
        <EnterPharmacy enterPharmacy={enterPharmacy} />
      ) : (
        <SelectPharmacy enterPharmacy={enterPharmacy} />
      )}
      {isMobile && (
        <div className="flex justify-center px-4 pb-4">
          {["list", "map"].map((item: string, idx: number) => {
            const isActive = view === item;
            return (
              <button
                key={item}
                className={`flex-grow ${
                  idx === 0
                    ? "rounded-tl-lg rounded-bl-lg border-r-0"
                    : "rounded-tr-lg rounded-br-lg border-l-0"
                } border-2 border-red-600  text-center text-sm font-semibold py-1 ${
                  isActive
                    ? "bg-red-600 text-white drop-shadow-lg"
                    : "bg-white text-red-600"
                }`}
                onClick={() => setView(item)}
              >
                {upperFirst(item)} View
              </button>
            );
          })}
        </div>
      )}
    </>
  );
}
