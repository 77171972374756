import OrderCard from "../order_card";
import ShopCards from "./Cards";
import ShopProductsList from "./products";
import { useOrder } from "../OrderContext";

export default function ShopProducts() {
  const { cart_is_empty } = useOrder();

  return (
    <OrderCard
      bleed
      title={`2. ${cart_is_empty ? "Select Your Tests" : "Your Tests"}`}
    >
      <ShopCards />
      <ShopProductsList />
    </OrderCard>
  );
}
