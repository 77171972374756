import TestingDetailsNoResults from "./NoResults";
import TestDetailsResults from "./Results";
import { useDashboard } from "../../../../DashboardContext";
export default function TestsOrderDetails() {
  const { opportunity, order } = useDashboard();
  const order_lab_statuses: { [key: string]: any } = {
    "Order Submitted": <TestingDetailsNoResults />,
    "Partial Results Received": <TestDetailsResults />,
    "Final Results Received": <TestDetailsResults />,
    "Results Delivered": <TestDetailsResults />,
  };

  if (!order || opportunity?.Payment_Status__c !== "Paid in Full")
    return <TestingDetailsNoResults />;
  return <>{order_lab_statuses[order?.Status || "Results Delivered"]}</>;
}
