import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import { pickBy, trimStart } from "lodash";
import { useAccount } from "../../../../context/AccountContext";
import { useUser } from "../../../../context/UserContext";
import { useUI } from "../../../../context/UIContext";
const phoneUtil = new PhoneNumberUtil();
export default function AdminAccountInput({ UserName }: { UserName: string }) {
  const { setLocation } = useUI();
  const { setManagingAccount } = useUser();
  const { account, loading } = useAccount();

  function evalUserName(UserName: string) {
    function emailCheck(email: string) {
      const validEmailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      ///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      return !!email.match(validEmailRegex) && email;
    }

    function phoneCheck(phone: string) {
      if (!phone || trimStart(phone, "+1")?.length !== 10) return false;
      const validPhoneRegex =
        /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      const isPhone = phone.match(validPhoneRegex);
      if (!isPhone) return false;

      const phone_number = phoneUtil.parseAndKeepRawInput(phone, "US");
      return (
        phone_number && phoneUtil.format(phone_number, PhoneNumberFormat.E164)
      );
    }

    const phone_number = phoneCheck(UserName);
    const email = emailCheck(UserName);

    return {
      data: { email, phone_number },
      isReady: !!phone_number || !!email,
    };
  }

  const { data, isReady } = evalUserName(UserName);

  const is_active = !!account?.Id
    ? "bg-white font-bold border border-rsc-blue/30 text-rsc-light-blue"
    : "bg-rsc-yellow/90 text-white";

  return (
    <div>
      <button
        className={`${
          isReady
            ? is_active
            : "bg-gray-300/10 border border-white/40 text-gray-400"
        } text-xs h-6 px-6 rounded-[3px] shadow-xs`}
        disabled={!isReady || loading}
        onClick={() => {
          const { email, phone_number } = data;
          const get_user_string = JSON.stringify(
            pickBy({
              PersonEmail: email,
              PersonMobilePhone: phone_number,
            })
          );
          setManagingAccount(get_user_string);
          setLocation("/dashboard");
        }}
      >
        {loading ? "Loading..." : "View"}
      </button>
    </div>
  );
}
