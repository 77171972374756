import { find } from "lodash";
import { useUsers } from "./UsersContext";

export default function AdminUsersSidePanel() {
  const { alt_menu, users_menu, view_users, viewing, setViewing } = useUsers();

  return (
    <div className="flex flex-col justify-start gap-8 py-4 min-w-[200px]">
      <div className="text-xl text-rsc-blue/90 md:text-2xl font-bold">
        Users
        {!!view_users.length && (
          <sup className="font-normal text-[.8rem] italic">
            ({view_users.length})
          </sup>
        )}
        {viewing === "all" ? (
          ""
        ) : (
          <span className="text-rsc-blue/60 font-normal">
            {" "}
            | {find(users_menu, ["id", viewing]).label}
          </span>
        )}
      </div>
      <div className="flex flex-col md:border-r border-rsc-light-blue md:pr-4 grow">
        {users_menu.map((menu_item: any) => {
          const { id, label } = menu_item;
          const isActive = id === viewing;
          return (
            <div
              key={id}
              className={`pl-2 pr-6 py-1 text-rsc-light-blue ${
                isActive ? "bg-rsc-light-blue/10 font-bold" : "font-normal"
              } cursor-pointer hover:bg-rsc-light-blue/10 hover:font-bold`}
              onClick={() => setViewing(id)}
            >
              {label}
            </div>
          );
        })}
        {alt_menu.map((menu_item: any) => {
          const { id, className, label, onClick } = menu_item;
          return (
            <div key={id} className={className} onClick={onClick}>
              {label}
            </div>
          );
        })}
      </div>
    </div>
  );
}
