import SkeletonList from "./skeleton_list";
import List from "./list";
import { useDashboard } from "../../../DashboardContext";

export default function MyTests() {
  const { all_tests } = useDashboard();

  return (
    <>
      <div>
        <h3 className="font-semibold text-gray-600 text-center uppercase">
          My Tests
        </h3>
      </div>
      <div className="flex flex-col gap-8">
        {all_tests ? <List /> : <SkeletonList />}
      </div>
    </>
  );
}
