import ListRow from "./list_row";
import { isEmpty, map, zipObject } from "lodash";
import moment from "moment";

export default function ListBody({ users }: { users: any[] }) {
  if (isEmpty(users)) {
    return (
      <tbody>
        <tr>
          <td
            className="font-bold text-2xl p-24 text-rsc-light-blue/40 text-center"
            colSpan={6}
          >
            No Results Fit Criteria
          </td>
        </tr>
      </tbody>
    );
  }
  return (
    <tbody>
      {users?.map((user: any, index: number) => {
        const { Attributes, UserCreateDate, Username, UserStatus } = user;
        const attributes = {
          ...zipObject(map(Attributes, "Name"), map(Attributes, "Value")),
          created: moment(UserCreateDate).format("lll"),
          status: UserStatus,
          username: Username,
        };
        return (
          <ListRow
            key={Username}
            attributes={attributes}
            style={{ backgroundColor: index % 2 === 0 ? "none" : "#eaeff5" }}
          />
        );
      })}
    </tbody>
  );
}
