import IonIcon from "@reacticons/ionicons";

export default function PaymentIncomplete() {
  return (
    <>
      {" "}
      <div className="text-right">
        <button className="w-64 py-2 bg-green-600 text-white font-bold rounded-md">
          Pay Now
        </button>
      </div>
      <div className="flex flex-row justify-end text-right">
        <div className="w-64 p-3 border border-red-600 rounded-md ">
          <div className="flex flex-row items-center text-red-600 text-xs text-left">
            <div className="grow text-base">
              <IonIcon name="alert-circle" />
            </div>
            <div className="font-semibold uppercase">Payment Incomplete</div>
          </div>
          <div className="text-gray-800">
            You must complete payment to view test results. Please select "Pay
            Now" or call{" "}
            <a href="tel:+18668730879" style={{ whiteSpace: "nowrap" }}>
              +1 (866) 873-0879
            </a>{" "}
            to submit your payment.
          </div>
        </div>
      </div>
    </>
  );
}
