import HeaderMiddle from "./header_middle";
import { useUser } from "../../../context/UserContext";
import AdminHeader from "../../Admin/Header";
import Can from "../../../utils/auth/Can";

export default function SiteHeader() {
  const { user } = useUser();

  return (
    <header
      id="top-header"
      className="fixed top-0 w-full bg-white/90 shadow-lg z-50"
    >
      <div className="flex flex-col">
        <div
          id="top-header"
          className="bg-rsc-light-blue text-[.9375rem] text-center text-white font-semibold py-4 shadow-md z-50 "
        >
          <span className="hidden md:block">
            Need help? Have a question? Speak with a care specialist today. Call
            (866) 873-0879
          </span>
          <span className="text-[0.875rem] md:hidden">
            Need help? Have a question? Call (866) 873-0879
          </span>
        </div>
        <HeaderMiddle />
        <Can
          perform="view:users"
          role={user?.roles}
          yes={() => <AdminHeader bg_color="bg-rsc-light-blue" />}
        />
      </div>
    </header>
  );
}
