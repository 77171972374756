const shopping_cart = [
  {
    name: "RapidSTD Test Panel",
    sku: "P-20",
    price: 389,
    items: ["91431", "6447", "5124988472", "36126", "11363"],
    hidden_items: ["512", "498", "8472", "P-1", "11361", "11362"],
    whats_this: {
      key: null,
      ref: null,
      props: {
        children: {
          type: "ul",
          key: null,
          ref: null,
          props: {
            children: [
              {
                type: "li",
                key: null,
                ref: null,
                props: {
                  children: "HIV (Type 1 and 2)",
                },
                _owner: null,
                _store: {},
              },
              {
                type: "li",
                key: null,
                ref: null,
                props: {
                  children: "Herpes (1 and 2)",
                },
                _owner: null,
                _store: {},
              },
              {
                type: "li",
                key: null,
                ref: null,
                props: {
                  children: "Hepatitis A, B, C",
                },
                _owner: null,
                _store: {},
              },
              {
                type: "li",
                key: null,
                ref: null,
                props: {
                  children: "Syphilis",
                },
                _owner: null,
                _store: {},
              },
              {
                type: "li",
                key: null,
                ref: null,
                props: {
                  children: "Chlamydia and Gonorrhea",
                },
                _owner: null,
                _store: {},
              },
            ],
          },
          _owner: null,
          _store: {},
        },
      },
      _owner: null,
      _store: {},
    },
  },
];
export default shopping_cart;
