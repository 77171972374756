//import { useSchedule } from "../ScheduleContext";
import MapView from "./map";

function FindTestCenterDesktop() {
  // const { center, getCurrentLocation, loading } = useSchedule();

  // !center && !loading && getCurrentLocation();

  return <div>{<MapView />}</div>;
}

export default FindTestCenterDesktop;
