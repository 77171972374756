import { getDistance } from "geolib";
import { orderBy } from "lodash";
import { useConsultation } from "../../context/ConsultationContext";
import { usePartnerConsultation } from "../../context/PartnerConsultationContext";
import { usePharmacy } from "./PharmacyContext";

function FindResultsList({ active_item }: { active_item?: string }) {
  const { handlePatientChange, partner: isPartner } = useConsultation();
  const { handlePartnerChange } = usePartnerConsultation();
  const handlePersonChange = isPartner
    ? handlePartnerChange
    : handlePatientChange;

  const { center, pharmacies: pharmacies_ } = usePharmacy();

  const pharmacies = orderBy(pharmacies_, function (tc: any) {
    const { coordinates } = tc.location;
    return returnDistance(coordinates);
  });

  const isReady = pharmacies.length > 0;

  function returnDistance(end: [number, number]) {
    if (!center) return;

    const meters = getDistance(
      { latitude: center.lat, longitude: center.lng },
      { latitude: end[1], longitude: end[0] }
    );

    return meters;
  }

  // function returnDistance(end: [number, number]) {
  //   if (!center) return;

  //   const meters = getPreciseDistance(
  //     { latitude: center.lat, longitude: center.lng },
  //     { latitude: end[1], longitude: end[0] }
  //   );
  //   const miles = meters / 1609.34;
  //   return <>{miles.toFixed(2)} mi.</>;
  // }

  function PharmacyItem({ pharmacy }: { pharmacy: any }) {
    const { _id, Street, City, name, phone, State, Zip_Code } = pharmacy;
    const isActive = _id === active_item;
    return (
      <div
        key={_id}
        className={`p-2 border-b last:border-b-0 border-slate-300 text-sm hover:bg-rsc-light-blue hover:text-white ${
          isActive && " bg-rsc-light-blue text-white"
        }`}
        style={{ cursor: "pointer" }}
        onClick={() => {
          handlePersonChange("pharmacy", pharmacy);
        }}
        // onMouseOver={(e: any) =>
        //   console.log("over element", e.target.offsetTop)
        // }
      >
        <div className="font-semibold">{name}</div>
        <p>
          {Street}
          <br />
          {`${City}, ${State} ${Zip_Code}`}
          <br />
          {phone}
        </p>
      </div>
    );
  }

  return (
    <>
      {isReady ? (
        <div className="flex flex-col">
          {pharmacies.map((pharmacy: any) => {
            return <PharmacyItem key={pharmacy._id} pharmacy={pharmacy} />;
          })}
        </div>
      ) : (
        "Please 'Enter your zip code' above and press search"
      )}
    </>
  );
}

export default FindResultsList;
