import { useCallback, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import Section from "../../../../components/Section";
import OrderCard from "../../../../components/CardHolder";
import { usePayment } from "../../../../components/Payment/PaymentInfoContext";
import CCPaymentInfo from "../../../../components/Payment/cc_payment";
import PayPalPayment from "../../../../components/Payment/paypal_payment";
import FinalizeConsultation from "../Finalize/patient";
import { useConsultation } from "../../context/ConsultationContext";

export default function PaymentInfo() {
  const { method, processConfirmation, setMethod, setLoading } = usePayment();
  const { errors, partners, patient, total_due, view_errors, setViewErrors } =
    useConsultation();

  const [re_render_key, setReRenderKey] = useState(0);
  useEffect(() => {
    setReRenderKey(prevKey => prevKey + 1);
  }, [partners]);

  const forceReRender = [patient, re_render_key, total_due];
  const onPayPalClick = useCallback(
    async (data: any, actions: any) => {
      try {
        const element = document.getElementById("preloader");
        setLoading(true);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
        if (!isEmpty(errors)) {
          setViewErrors(true);
          actions.disable();
        }
      } catch (err) {
        console.log("ERROR IN THE FORM");
      } finally {
        setLoading(false);
      }
    },
    [errors, setLoading, setViewErrors]
  );

  const onApprove = useCallback(
    async (data: any, actions: any) => {
      const capture_data = await actions.order.capture();
      const tx = { ...capture_data, type: "paypal" };
      // console.log(JSON.stringify(tx));
      await processConfirmation({
        mx: { patient },
        totals: { total_due },
        type: "consultation",
        tx,
      });
    },
    [patient, total_due, processConfirmation]
  );

  const createOrder = useCallback(
    async (data: any, action: any) => {
      return action.braintree
        .createPayment({
          flow: "checkout",
          amount: total_due,
          currency: "USD",
          intent: "capture",
        })
        .then((orderId: any) => {
          // Your code here after create the order
          return orderId;
        });
    },
    [total_due]
  );

  return (
    <OrderCard title="5. Payment Information" card_layout="mb-0 w-full">
      <div className="m-2 rounded-md overflow-hidden border border-slate-300">
        <div className="p-4 bg-slate-50">
          <Section>
            <div className="gap-3">
              <label htmlFor="first_name">Choose Payment Method:</label>
              <select
                name="notification"
                id="notification"
                className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
                value={method}
                onChange={(e: any) => setMethod(e.target.value)}
              >
                {[
                  { id: "credit_card", label: "Credit Card" },
                  { id: "pay_pal", label: "PayPal" },
                ].map((type: any) => {
                  const { id, label } = type;
                  return (
                    <option key={id} value={id}>
                      {label}
                    </option>
                  );
                })}
              </select>
            </div>
          </Section>
          {method === "credit_card" ? (
            <CCPaymentInfo submit={<FinalizeConsultation />} />
          ) : (
            <PayPalPayment
              errors={errors}
              view_errors={view_errors}
              createOrder={createOrder}
              forceReRender={forceReRender}
              onApprove={onApprove}
              onPayPalClick={onPayPalClick}
            />
          )}
        </div>
      </div>
    </OrderCard>
  );
}
