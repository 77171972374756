export default function LabAddress({ laboratory }: { laboratory: any }) {
  if (!laboratory) return null;
  const { BillingAddress, Fax, Name } = laboratory;

  if (true) {
    return (
      <div className="grow md:max-w-[60%] text-gray-600">
        <div className="">
          <div className="text-gray-400 font-bold">Your Selected Lab</div>
          <div className="text-md">
            <div className="text-lg text-rsc-light-blue font-semibold pb-2 mb-2 border-b border-rsc-light-blue/10">
              <span className="pb-2 ">{Name}</span>
            </div>
            <div className="italic">
              {BillingAddress?.street}
              <br />
              {BillingAddress?.city}, {BillingAddress?.state}{" "}
              {BillingAddress?.postalCode}
              <br />
              Fax: {Fax}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="grow md:max-w-[60%] text-gray-600">
      <div className="border border-gray-200 shadow-md rounded-md overflow-hidden">
        <div className="p-3 bg-rsc-light-blue text-white font-bold uppercase">
          Your Selected Lab
        </div>
        <div className="p-3 text-md">
          <div className="text-lg text-rsc-light-blue font-semibold pb-2 mb-2 ">
            <span className="pb-2 border-b border-rsc-light-blue/10">
              {Name}
            </span>
          </div>
          <div className="italic">
            {BillingAddress?.street}
            <br />
            {BillingAddress?.city}, {BillingAddress?.state}{" "}
            {BillingAddress?.postalCode}
            <br />
            Fax: {Fax}
          </div>
        </div>
      </div>
    </div>
  );
}
