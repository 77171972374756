import { isEmpty } from "lodash";
import IonIcon from "@reacticons/ionicons";
import { useUI } from "../context/UIContext";
import { useCart } from "../context/CartContext";

export default function ShoppingCartButton({
  className = "",
  site,
}: {
  className?: string;
  site?: string;
}) {
  const { setLocation } = useUI();
  const { reorder_discount, shopping_cart } = useCart();

  const has_items = !isEmpty(shopping_cart);
  if (!has_items && !reorder_discount) return null;
  return (
    <div className={className}>
      <button onClick={() => setLocation("/order")} className="leading-none">
        {site === "rsc_health" ? (
          <div className="flex flex-col items-center justify-center">
            <IonIcon
              className="translate-y-[0px] text-[18px] text-white"
              name="cart"
            />
            {has_items && (
              <div className="absolute flex flex-col items-center justify-center h-[14px] w-[14px] text-[10px] text-white bg-green-600 rounded-full translate-y-[-8px] translate-x-[10px] shadow">
                <div> {shopping_cart.length}</div>
              </div>
            )}
            {reorder_discount && (
              <div className="absolute flex flex-col items-center justify-center py-[2px] px-[3px] text-[9px] text-black bg-rsc-yellow rounded-full translate-y-[9px] translate-x-[18px] shadow-md">
                <div>-10%</div>
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-row items-center">
            <div>
              <IonIcon
                className="translate-y-[0px] text-[28px] text-rsc-light-blue"
                name="cart"
              />
            </div>
            {has_items && (
              <div className="italic text-[10px] text-gray-400/90 translate-y-[2px]">
                {`(${shopping_cart.length})`}
              </div>
            )}
          </div>
        )}
      </button>
    </div>
  );
}
