import IonIcon from "@reacticons/ionicons";
import { useState } from "react";
import { useUI } from "../../../../context/UIContext";
import { useOrder } from "../../OrderContext";

export default function TestCenterItem({
  //is_active,
  center,
  no_redirect,
}: {
  //is_active: boolean;
  center: any;
  no_redirect: boolean;
}) {
  const { setLocation } = useUI();
  const { handleOrderChange } = useOrder();
  const [open, setOpen] = useState<boolean>(false);
  const { _id, address, city, hours, name, state, zip_code } = center;

  return (
    <div
      key={_id}
      className={`flex flex-col p-2 text-sm gap-3 cursor-pointer ${
        false && " bg-rsc-light-blue text-white"
      }`}
    >
      <div className="flex flex-col">
        <div className="text-rsc-blue text-base font-bold">{name}</div>
        <p>
          {address}
          <br />
          {`${city}, ${state} ${zip_code}`}
        </p>
      </div>
      <div className="flex flex-col">
        <button
          className="text-rsc-light-blue font-bold uppercase pointer-cursor"
          onClick={() => {
            const flip = !open;
            setOpen(flip);
          }}
        >
          {open ? "Hide" : "Show"} business Hours{" "}
          <IonIcon
            className="translate-y-[2px]"
            name={open ? "caret-up" : "caret-down"}
          />
        </button>
        {open && (
          <div>
            <table className="w-full">
              <tbody>
                {[
                  "Sunday",
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                ].map((day: string, idx: number) => {
                  const day_hours = hours?.[day];
                  const bg = idx % 2 === 0 ? "bg-white" : "bg-gray-200";
                  if (
                    !day_hours ||
                    !day_hours?.[0]?.start ||
                    day_hours?.[0] === "Closed"
                  )
                    return (
                      <tr key={day} className={bg}>
                        <td className="align-top pl-1">{day}</td>
                        <td className="align-top text-right pr-1">Closed</td>
                      </tr>
                    );

                  return (
                    <tr key={day} className={bg}>
                      <td className="align-top pl-1">{day}</td>
                      <td className="align-top text-right pr-1">
                        {day_hours.map((t: any) => {
                          const { end, start } = t;
                          return (
                            <>
                              {start} - {end}
                              <br />
                            </>
                          );
                        })}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="text-center">
        <button
          className="bg-rsc-red text-white font-bold px-6 py-1 uppercase shadow-md rounded-full"
          onClick={() => {
            handleOrderChange("selected_center", center);
            !no_redirect && setLocation("/test-and-panels");
          }}
        >
          Select this location
        </button>
      </div>
    </div>
  );
}
