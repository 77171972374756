// import { useUser } from "../context/UserContext";
// import Can from "../utils/auth/Can";
// import { useUserEdit } from "./UserEditContext";

export default function ContentHolder({
  children,
  // back,
  // forward,
  title,
}: {
  children: any;
  back?: any;
  forward?: any;
  title?: any;
}) {
  // const { user } = useUserEdit();

  return (
    <div className="flex flex-col gap-8 md:w-[400px]">
      {title}
      {children}
      {/* <div className="flex flex-row gap-6 mt-12 grow">
        <div className="grow">
          <button
            // disabled={!isReady}
            onClick={back.onClick}
            className={`${
              true ? "bg-rsc-blue text-white" : "bg-gray-200 text-gray-500"
            } md:min-w-[100px] px-6 font-semibold text-center p-3 rounded-md shadow-md`}
          >
            {back.label}
          </button>
        </div>
        <Can
          role={user?.roles}
          perform={forward.perform}
          yes={() => (
            <div>
              <button
                className={`${
                  forward.disabled
                    ? "bg-gray-200 text-gray-500"
                    : "bg-rsc-light-blue text-white"
                } md:min-w-[100px] px-6 font-semibold text-center p-3 rounded-md shadow-md`}
                disabled={forward.disabled}
                onClick={forward.onClick}
              >
                {forward.label}
              </button>
            </div>
          )}
        /> 
      </div>*/}
    </div>
  );
}
