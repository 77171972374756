import IonIcon from "@reacticons/ionicons";
import { useTestCenter } from "../TestCenterContext";

function SetZipCode() {
  const {
    getAndSetCenter,
    getCurrentLocation,
    loading,
    setLoading,
    setView,
    setZipCode,
    view,
    zip_code,
  } = useTestCenter();

  const canSubmit = zip_code.length === 5;

  const submitZipCode = () => {
    view === "list" && setView("map");
    getAndSetCenter(zip_code);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter" && !!canSubmit) {
      submitZipCode();
    }
  };
  return (
    <>
      <div className="my-4">
        <button
          className="rounded border border-zinc-200 bg-gray-100 text-zinc-500 p-2"
          onClick={() => {
            if (loading) {
              setLoading(false);
            } else {
              setLoading(true);
              getCurrentLocation();
            }
          }}
        >
          <span className="icon-text text-base">
            {loading ? (
              <>
                <IonIcon name="time" /> Loading...
              </>
            ) : (
              <>
                <IonIcon name="location-sharp" /> Locate Me
              </>
            )}
          </span>
        </button>
      </div>
      <div className=" flex">
        <div className="flex-grow">
          <input
            id="find-a-test-center-zipcode"
            className="rounded-tl-lg rounded-bl-lg border border-r-0 border-slate-400 h-10 w-full shadow-inner px-2"
            placeholder="Enter your zip code"
            style={{
              appearance: "none",
              WebkitAppearance: "none",
            }}
            type="text"
            inputMode="numeric"
            pattern="\d*"
            value={zip_code}
            onChange={(e: any) => {
              e.target.value = e.target.value.replace(/\D/g, "");
              setZipCode(e.target.value);
            }}
            onKeyDown={handleKeyDown}
          />
        </div>
        <button
          className={`rounded-tr-lg rounded-br-lg ${
            canSubmit ? "bg-red-600" : "bg-gray-300"
          } text-white text-xl h-10 py-1.5 px-4`}
          disabled={!canSubmit}
          onClick={submitZipCode}
        >
          <p className="icon-text">
            <IonIcon name="search-sharp" />
          </p>
        </button>
      </div>
    </>
  );
}

export default SetZipCode;
