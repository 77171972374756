import rsc_logo_base64 from "./rsc_logo_base64";
const rsc_logo =
  "https://res.cloudinary.com/maxime-associates/image/upload/v1677713572/RapidSTDTesting/RSCHealth_logo.png";
export default function TopTitleHeader({
  for_pdf,
  label,
  sublabel,
}: {
  for_pdf?: boolean;
  label: string;
  sublabel?: string | false;
}) {
  const color = "rgb(17 102 155 / 0.8)";

  return (
    <table style={{ width: "100%" }}>
      <tbody>
        <tr>
          <td>
            <img
              src={for_pdf ? rsc_logo_base64 : rsc_logo}
              alt="RST logo"
              style={{ maxWidth: 270, width: "100%" }}
            />
          </td>
          <td
            className="header-title"
            style={{
              color,
              fontWeight: 600,
              fontSize: "30px",
              letterSpacing: "-1.1px",
              lineHeight: "14pt",
              textAlign: "right",
              whiteSpace: "nowrap",
              width: 400,
              verticalAlign: "middle",
            }}
          >
            <span style={{ whiteSpace: "nowrap" }}>{label}</span>
            {sublabel && (
              <>
                <br />
                <span
                  className="header-subtitle"
                  style={{
                    color: "rgb(0 0 0 / 0.6)",
                    fontSize: "10pt",
                    fontWeight: 400,
                    letterSpacing: "-.1px",
                  }}
                >
                  {sublabel}
                </span>
              </>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
