export function OrderReducer(state: any, action: any) {
  function orderActions() {
    switch (action.type) {
      case "LOAD_ORDER":
        return action.order;
      case "HANDLE_CHANGE":
        //  return set(state, action.order.field, action.order.value);
        return {
          ...state,

          [action.order.field]: action.order.value,
        };
      case "HANDLE_USER_CHANGE":
        //  return set(state, action.order.field, action.order.value);
        return {
          ...state,
          user: {
            ...state.user,
            [action.user.field]: action.user.value,
          },
        };
      case "CLEAR_ORDER":
        return action.order;
      default:
        return state;
    }
  }
  let new_order = orderActions();
  window.localStorage.setItem("order", JSON.stringify(new_order));
  return new_order;
}
