import IonIcon from "@reacticons/ionicons";
import { useUser } from "../context/UserContext";
import { useUI } from "../context/UIContext";

export default function Directions() {
  const { setLocation } = useUI();
  const { user } = useUser();

  return (
    <div className="flex flex-col items-start md:items-center p-5 py-10 text-gray-600">
      <p className="px-6 max-w-fit">
        {!!user ? (
          <>
            Go to{" "}
            <button onClick={() => setLocation("./dashboard")}>
              <span className="text-rsc-blue font-semibold underline">
                Your Account
              </span>
            </button>{" "}
            to:
          </>
        ) : (
          <>
            If you have already placed your order,{" "}
            <button onClick={() => setLocation("./login")}>
              <span className="text-rsc-blue font-semibold underline">
                log in
              </span>
            </button>{" "}
            here to:
          </>
        )}
      </p>
      <div className="max-w-fit">
        <ul className="mt-3 ml-6">
          {[
            "View your test results",
            "Retrieve secure messages",
            "Review your order history",
            "View your invoices",
          ].map((tip: string) => {
            return (
              <li key={tip}>
                <IonIcon
                  name="checkmark-sharp"
                  style={{
                    fontWeight: "bold",
                    marginRight: 6,
                    transform: "translateY(2px)",
                  }}
                />
                {tip}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
