import DetailContainer from "../../../../DetailContainer";
import ResultsList from "./results";
import TestActions from "./actions";
import WhereResults from "./where_results";
import { useUI } from "../../../../../../context/UIContext";
import { useUser } from "../../../../../../context/UserContext";
import Can from "../../../../../../utils/auth/Can";

export default function TestDetailsResults() {
  const { setPopUp } = useUI();
  const { managing_account, simulated_role, user } = useUser();

  function Results() {
    return (
      <DetailContainer
        action={
          <div className="text-sm text-rsc-light-blue">
            <button
              onClick={() =>
                setPopUp(
                  <div
                    className="bg-white my-6 mx-auto w-full md:min-w-[440px] sm:max-w-md md:border sm:border-rsc-light-blue/10 sm:rounded-xl shadow-lg overflow-hidden"
                    onClick={e => e.stopPropagation()}
                  >
                    <WhereResults />
                  </div>
                )
              }
            >
              Where are my results?
            </button>
          </div>
        }
        title="Test Details"
      >
        <div className="flex flex-col gap-4">
          <TestActions />
          <ResultsList />
        </div>
      </DetailContainer>
    );
  }

  if (!!managing_account) {
    return (
      <Can
        perform="view:results"
        role={simulated_role || user?.roles}
        yes={() => <Results />}
      />
    );
  }
  return <Results />;
}
