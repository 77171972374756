import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useAccount } from "../../context/AccountContext";
import { useDashboard } from "../DashboardContext";
type Message = any;

interface MessagesValues {
  messages?: Partial<Message>;
  viewing_message: string;
  setMessages: (orders: any) => void;
}

export const MessagesContext = createContext<MessagesValues>({
  viewing_message: "",
  setMessages: () => {},
});

export const useMessages = () => useContext(MessagesContext);

const MessagesContextProvider: React.FC<{
  children: any;
}> = ({ children }) => {
  const { account } = useAccount();
  const { view: viewing_message } = useDashboard();
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<any | null>(null);

  async function getAndSetMessages(Id: string) {
    try {
      setLoading(true);
      const { data } = await axios.post(
        `${window.location.origin}/api/salesforce/messages`,
        { Id }
      );
      setMessages({ Id, data: data?.records });
    } catch (err: any) {
      console.log("ERROR GETTING MESSAGES", err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const Id = account?.Id;
    if (!Id || Id === messages?.Id || !!loading) return;
    getAndSetMessages(Id);
  }, [loading, messages, account]);

  return (
    <MessagesContext.Provider
      value={{
        messages: messages?.data,
        setMessages,
        viewing_message,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export default MessagesContextProvider;
