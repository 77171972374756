import IonIcon from "@reacticons/ionicons";
import { isEmpty, reject, startsWith, trimStart } from "lodash";
import moment from "moment";
import { useMessages } from "./MessagesContext";
import { useUI } from "../../context/UIContext";
import { PanelHolder } from "../layout";

export default function MyAccountPanel() {
  const { setLocation } = useUI();
  const { messages, viewing_message } = useMessages();

  return (
    <PanelHolder header_text="My Messages">
      <div className="flex flex-col gap-5 w-full">
        {(
          reject(messages, function (m: any) {
            return startsWith(m.Subject, "Inbound Call:");
          }) || []
        ).map((msg: any) => {
          const {
            Id,
            Attachments,
            CreatedDate,
            // Description,
            Status,
            Subject: subject_,
          } = msg;
          const subject = trimStart(subject_, "Email: ");
          const isActive = Id === viewing_message;
          return (
            <div
              key={Id}
              className={`flex flex-col p-4 gap-3 bg-white shadow-md rounded-md ${
                isActive
                  ? "bg-sky-600 text-white shadow-inner shadow-sky-800"
                  : "bg-white shadow-md"
              }
              `}
              style={{ cursor: "pointer" }}
              onClick={() => setLocation(`/dashboard/messages/${Id}`)}
            >
              <div>
                <span
                  className={`${
                    Status === "Incomplete" || isActive
                      ? "font-bold"
                      : "font-normal"
                  }`}
                >
                  {subject}
                </span>
                {!isEmpty(Attachments) && <IonIcon name="attach" />}
              </div>
              <div
                className={`flex flex-row items-center text-sm ${
                  Status === "Incomplete" || isActive
                    ? "font-semibold"
                    : "font-normal"
                }`}
              >
                {/* <div
                  className={`grow ${
                    isActive ? "text-gray-300" : "text-sky-700"
                  }`}
                >
                  {sender.full_name}
                </div> */}
                <div
                  className={`text-xs ${
                    isActive ? "text-gray-200" : "text-gray-500"
                  }`}
                >
                  {moment(CreatedDate).format("lll")}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </PanelHolder>
  );
}
