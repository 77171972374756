import { useState } from "react";
import IonIcon from "@reacticons/ionicons";
import { useUI } from "../../context/UIContext";
import {
  DeleteUserCommand,
  DeleteUserCommandInput,
} from "@aws-sdk/client-cognito-identity-provider";
import { useUser } from "../../context/UserContext";
import { CognitoClient } from "../../utils/aws";
export default function PopUpTest() {
  const { setPopUp } = useUI();
  const { accessToken, logoutUser } = useUser();
  const [delete_string, setDeleteString] = useState<string>("");
  const match_string = "delete-maxime.and.associates@gmail.com";
  const canDelete = match_string === delete_string;

  async function deleteUser() {
    const input: DeleteUserCommandInput = {
      AccessToken: accessToken,
    };
    const command = new DeleteUserCommand(input);
    const response = await CognitoClient.send(command);

    console.log("DELETE USER", response);
    logoutUser();
    setPopUp(null);
  }

  return (
    <>
      <div className="p-4 text-lg text-gray-600 bg-gray-100 border-b border-gray-200 flex flex-row">
        <div className="grow">Are you sure?</div>
        <button
        //onClick={() => setPopUp(null)}
        >
          <IonIcon
            name="close-circle"
            style={{ fontSize: "1.4rem", transform: "translateY(2px)" }}
          />
        </button>
      </div>
      <div className="p-4 flex flex-col gap-4">
        <div>
          This action will delete your access to the patient portal. Enter this
          text to delete account:
        </div>
        <div className="text-center font-semibold italic">{match_string}</div>
        <div>
          <input
            className={
              "rounded-lg border border-rsc-light-blue/30 h-10 w-full shadow-inner px-2"
            }
            id="delete_field"
            placeholder="Enter the text above"
            value={delete_string}
            onChange={(e: any) => setDeleteString(e.target.value)}
          />
        </div>
        <div className="flex flex-row gap-6">
          <div className="grow">
            <button
              // disabled={!isReady}
              onClick={() => setPopUp(null)}
              className={`${
                true ? "bg-rsc-blue text-white" : "bg-gray-200 text-gray-500"
              } font-semibold text-center p-3 rounded-md shadow-md`}
            >
              Cancel
            </button>
          </div>
          <div>
            <button
              className={`${
                canDelete
                  ? "bg-rsc-red text-white"
                  : "bg-gray-200 text-gray-500"
              } font-semibold text-center p-3 rounded-md shadow-md`}
              disabled={!canDelete}
              onClick={() => {
                deleteUser();
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
