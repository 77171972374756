import { gql } from "@apollo/client";

export const GET_DOCTOR = gql`
  query getDoctor($state: String!) {
    getDoctor(state: $state) {
      _id
      first_name
      last_name
      npi
      state
    }
  }
`;
