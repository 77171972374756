import { useCallback, useEffect, useState } from "react";
import Section from "../../../components/Section";
import OrderCard from "../../../components/CardHolder";
import { usePayment } from "../../../components/Payment/PaymentInfoContext";
import PayLater from "../Finalize/pay_later";
import CCPaymentInfo from "../../../components/Payment/cc_payment";
import PayPalPayment from "../../../components/Payment/paypal_payment";
import FinalizeOrder from "../Finalize";
import SelectPaymentMethod from "../../../components/Payment/select_pay_now_later";
import { GET_DOCTOR } from "../../../utils/queries";
import { useCart } from "../../../context/CartContext";
import { useOrder } from "../OrderContext";
import { useUI } from "../../../context/UIContext";
import { useUser } from "../../../context/UserContext";

export default function PaymentInfo() {
  const { client, us_states } = useUI();
  const { user } = useUser();
  const { errors, order, order_validation, view_errors, setViewErrors } =
    useOrder();
  const { method, pay_later, processConfirmation, setLoading, setMethod } =
    usePayment();
  const { reorder_discount, shopping_cart, totals } = useCart();
  const { total_due } = totals;

  const [re_render_key, setReRenderKey] = useState(0);
  useEffect(() => {
    setReRenderKey(prevKey => prevKey + 1);
  }, [shopping_cart]);

  const forceReRender = [order, reorder_discount, re_render_key, totals];
  const createOrder = useCallback(
    async (data: any, action: any) => {
      const payload = {
        flow: "checkout",
        amount: total_due,
        currency: "USD",
        intent: "capture",
      };
      return action.braintree.createPayment(payload).then((orderId: any) => {
        return orderId;
      });
    },
    [total_due]
  );

  const onApprove = useCallback(
    async (data_: any, actions: any) => {
      const capture_data = await actions.order.capture();
      const { data } = await client.query({
        query: GET_DOCTOR,
        variables: {
          state: us_states[order?.user?.address_state || "NY"],
        },
      });
      await processConfirmation({
        oid: "",
        doctor: data?.getDoctor,
        mx: { order, shopping_cart },
        reorder_discount,
        totals,
        type: "order",
        tx: { ...capture_data, type: "paypal" },
        user_: user,
      });
    },
    [
      client,
      order,
      reorder_discount,
      shopping_cart,
      totals,
      us_states,
      user,
      processConfirmation,
    ]
  );

  const onPayPalClick = useCallback(
    async (data: any, actions: any) => {
      try {
        const element = document.getElementById("preloader");
        setLoading(true);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
        if (!order_validation.success) {
          setViewErrors(true);
          actions.disable();
        }
      } catch (err) {
        console.log("ERROR IN THE FORM");
      } finally {
        setLoading(false);
      }
    },
    [order_validation, setLoading, setViewErrors]
  );

  return (
    <OrderCard title="5. Payment Information" card_layout="mb-0 w-full">
      <div className="m-2 rounded-md overflow-hidden border border-slate-300">
        <SelectPaymentMethod />
        {pay_later ? (
          <PayLater />
        ) : (
          <div className="p-4 bg-slate-50">
            <Section>
              <div className="gap-3">
                <label htmlFor="first_name">Choose Payment Method:</label>
                <select
                  name="notification"
                  id="notification"
                  className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
                  value={method}
                  onChange={(e: any) => setMethod(e.target.value)}
                >
                  {[
                    { id: "credit_card", label: "Credit Card" },
                    { id: "pay_pal", label: "PayPal" },
                  ].map((type: any) => {
                    const { id, label } = type;
                    return (
                      <option key={id} value={id}>
                        {label}
                      </option>
                    );
                  })}
                </select>
              </div>
            </Section>
            {method === "credit_card" ? (
              <CCPaymentInfo submit={<FinalizeOrder />} />
            ) : (
              <PayPalPayment
                errors={errors}
                view_errors={view_errors}
                createOrder={createOrder}
                forceReRender={forceReRender}
                onApprove={onApprove}
                onPayPalClick={onPayPalClick}
              />
            )}
          </div>
        )}
      </div>
    </OrderCard>
  );
}
