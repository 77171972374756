import axios from "axios";
import { createContext, useContext, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { find } from "lodash";

const { REACT_APP_GOOGLE_MAPS_KEY } = process.env;

const GET_PHARMACIES = gql`
  query getPharmacies($box: String!) {
    getPharmacies(box: $box) {
      _id
      direction_url
      name
      Street
      City
      State
      Zip_Code
      phone
      location {
        coordinates
      }
    }
  }
`;

interface PharmacyValues {
  //
  loading: boolean;
  setBox: (box: string) => void;
  setLoading: (state: boolean) => void;
  pharmacies: any[];
  //
  hours: string;
  view: string;
  zip_code: string;
  setHours: (hours: string) => void;
  setView: (view: string) => void;
  setZipCode: (view: string) => void;
  //
  center:
    | {
        lat: number;
        lng: number;
      }
    | undefined;
  getAndSetCenter: (zip_code: string) => void;
  getCurrentLocation: () => void;
  map: any;
  setMap: (data: any) => void;
}

export const PharmacyContext = createContext<PharmacyValues>({
  //
  loading: false,
  setBox: () => {},
  setLoading: () => {},
  pharmacies: [],
  //
  hours: "all",
  view: "list",
  zip_code: "",
  setHours: () => {},
  setView: () => {},
  setZipCode: () => {},
  //
  center: undefined,
  getAndSetCenter: () => {},
  getCurrentLocation: () => {},
  map: {},
  setMap: () => {},
});

export const usePharmacy = () => useContext(PharmacyContext);

const PharmacyContextProvider: React.FC<{
  children: any;
}> = ({ children }) => {
  // TEST CENTER
  const [loading, setLoading] = useState<boolean>(false);
  const [zip_code, setZipCode] = useState<string>("");
  const [hours, setHours] = useState<string>("all");
  const [view, setView] = useState<string>("list");

  // MAP STATE
  const [map, setMap] = useState<any>({
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    geoCoder: null,
    places: [],
    center: [],
    zoom: 9,
    address: "",
    draggable: true,
    lat: null,
    lng: null,
  });

  // MAPPING DATA
  const [center, setCenter] = useState<
    | {
        lat: number;
        lng: number;
      }
    | undefined
  >(undefined);
  const [box, setBox] = useState<string | undefined>(undefined);

  // {
  //   __typename: "Pharmacy",
  //   _id: "62d9c49620ee7a1f3f488d70",
  //   name: "Quest Diagnostics",
  //   address: "2910 South Maguire Rd Suite1010",
  //   city: "Ocoee",
  //   state: "FL",
  //   zip: "34761-4719",
  //   country: "USA",
  //   fax: "407-654-0829",
  //   url: "https://www.google.com/maps/dir/Current+Location/2910+South+Maguire+Rd+Suite1010,+Ocoee,+FL,+34761,+USA/",
  //   location: {
  //     __typename: "CenterPoint",
  //     coordinates: [-81.541165, 28.528843],
  //   },
  // }

  const { data, loading: query_loading } = useQuery(GET_PHARMACIES, {
    variables: { box },
    skip: !box,
    onError: (e: any) => {
      console.log("Error", e);
    },
  });

  function findZipCode(response: any) {
    const zip_code_result = find(
      response.data.results[0].address_components,
      function (item: any) {
        return item.types[0] === "postal_code";
      }
    )?.long_name;

    return zip_code_result;
  }

  async function getCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        const { latitude: lat, longitude: lng } = position.coords;
        const browser_center = {
          lat,
          lng,
        };
        setCenter(browser_center);
        getAndSetZipCode(browser_center);
      });
    }
  }

  async function getAndSetZipCode(center: any) {
    if (!center) return;
    const { lat, lng } = center;
    const request = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&result_type=postal_code&key=${REACT_APP_GOOGLE_MAPS_KEY}`;

    await axios.get(request).then((response: any) => {
      const zip = findZipCode(response);
      console.log("this is view", view);
      view === "list" && setView("map");
      setZipCode(zip);
    });
    setLoading(false);
  }

  async function getAndSetCenter(zip_code: string) {
    const request = `https://maps.googleapis.com/maps/api/geocode/json?address=${zip_code}&result_type=postal_code&key=${REACT_APP_GOOGLE_MAPS_KEY}`;
    console.log("request", request);
    await axios.get(request).then((response: any) => {
      console.log("response", response);
      const coordinates = response?.data?.results?.[0]?.geometry?.location;
      setCenter(coordinates);
    });
  }

  return (
    <PharmacyContext.Provider
      value={{
        loading: loading || query_loading,
        setBox,
        setLoading,
        pharmacies: data?.getPharmacies || [],
        //
        hours,
        view,
        setHours,
        setView,
        setZipCode,
        zip_code,
        //
        center,
        getAndSetCenter,
        getCurrentLocation,
        map,
        setMap,
      }}
    >
      {children}
    </PharmacyContext.Provider>
  );
};

export default PharmacyContextProvider;
