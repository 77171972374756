import DetailContainer from "../../../../DetailContainer";
import { useDashboard } from "../../../../../DashboardContext";
import PaymentOverride from "./override_rows";

export default function OrderMakePayment() {
  const {
    loading_opportunity_tests: loading,
    opportunity,
    tests,
  } = useDashboard();
  console.log(opportunity);
  if (!tests || loading) return <>Loading...</>;

  return (
    <DetailContainer
      // action={
      //   <div className="text-sm text-rsc-light-blue">
      //     <button onClick={() => setPopUp(<WhereResults />)}>
      //       Where are my results?
      //     </button>
      //   </div>
      // }
      title="Order"
    >
      <table>
        <tbody>
          {tests.map((test: any) => {
            const {
              Id,
              //    Date_Time_of_Analysis__c,
              Name,
              //  Description,
              TotalPrice,
            } = test;

            return (
              <tr key={Id}>
                <td>{Name}</td>
                <td className="text-right">${TotalPrice?.toFixed(2)}</td>
              </tr>
            );
          })}
          <PaymentOverride opportunity={opportunity} />
        </tbody>
      </table>
    </DetailContainer>
  );
}
