import PreLoader from "../../../../../PreLoader";
import { useDashboard } from "../../../../DashboardContext";
import BillingDetails from "./Billing";
import PayLaterPaymentForm from "./Payment";

export default function OrderMakePayment() {
  const {
    loading_opportunity_tests: loading,
    opportunity,
    tests,
  } = useDashboard();
  console.log(opportunity);
  if (!tests || loading)
    return (
      <div className="max-h-[500px] md:basis-2/3">
        <PreLoader />
      </div>
    );

  return (
    <div className="flex flex-col justify-items-stretch p-3 md:p-5 gap-6 bg-white md:basis-2/3">
      <BillingDetails />
      <PayLaterPaymentForm />
    </div>
  );
}
