import ContentHolder from "./content_holder";
import RSCLoader from "../components/loader_circle";
import { useUserEdit } from "./UserEditContext";
import { useState } from "react";

export default function AccountView() {
  const { deleted, loading, user, deleteUser } = useUserEdit();
  const { family_name, given_name, username, Username } = user || {};
  const [user_confirmation, setUserConfirmation] = useState<string>("");
  const confirmation_reference = username || Username;
  if (deleted)
    return (
      <div className="flex flex-col items-center justify-center w-full min-h-[400px]">
        <div className="text-xl text-red-600 md:text-2xl font-bold">
          User Successfully Deleted
        </div>
      </div>
    );
  return (
    <ContentHolder
      title={
        <div className="flex flex-row gap-6 items-center">
          <div className="text-xl text-red-600 md:text-2xl font-bold">
            Delete User
          </div>
        </div>
      }
    >
      <div className="flex flex-col text-lg gap-4">
        <div>
          To delete user, {given_name} {family_name}, from the portal,
          enter/copy the user's identification name in the field below:
          <br />
          <b>{confirmation_reference}</b>
        </div>
        <div>
          <label htmlFor="html">Username ID</label>
          <input
            className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
            value={user_confirmation}
            onChange={(e: any) => setUserConfirmation(e.target.value)}
          />
        </div>
        <div className="flex flex-row justify-end">
          <button
            className={`${
              user_confirmation !== confirmation_reference || loading
                ? "bg-gray-200 text-gray-500"
                : "bg-rsc-light-blue text-white"
            } md:min-w-[100px] px-6 font-semibold text-center p-3 rounded-md shadow-md`}
            disabled={user_confirmation !== confirmation_reference || loading}
            onClick={() => deleteUser(confirmation_reference)}
          >
            {loading ? (
              <div className="flex flex-row gap-2 items-center">
                <RSCLoader /> Deleting...
              </div>
            ) : (
              "Delete User"
            )}
          </button>
        </div>
      </div>
    </ContentHolder>
  );
}
