import { getDistance } from "geolib";
import { orderBy } from "lodash";
import TestCenterItem from "./item";
import { useTestCenter } from "../TestCenterContext";

function FindResultsList({ no_redirect }: { no_redirect?: boolean }) {
  const { center, test_centers: test_centers_ } = useTestCenter();

  const test_centers = orderBy(test_centers_, function (tc: any) {
    const { coordinates } = tc.location;
    return returnDistance(coordinates);
  });

  const isReady = test_centers.length > 0;

  function returnDistance(end: [number, number]) {
    if (!center) return;

    const meters = getDistance(
      { latitude: center.lat, longitude: center.lng },
      { latitude: end[1], longitude: end[0] }
    );

    return meters;
  }

  return (
    <>
      {isReady ? (
        <div className="flex flex-col gap-6 w-full overflow-y-auto">
          {test_centers.map((center: any) => {
            const { _id } = center;
            // const is_active = _id === active_item;
            return (
              <TestCenterItem
                key={_id}
                center={center}
                no_redirect={!!no_redirect}
              />
            );
          })}
        </div>
      ) : (
        "Please 'Enter your zip code' above and press search"
      )}
    </>
  );
}

export default FindResultsList;
