import IonIcon from "@reacticons/ionicons";

export default function VerificationFail({ data }: { data: any }) {
  const { message, response } = data;
  return (
    <>
      <div className="text-2xl font-bold text-rsc-light-blue">{message}</div>
      <div>{response}</div>
      <div className="flex flex-row items-center gap-6">
        <a href="/login">
          <div className="flex flex-row gap-2 border border-slate-200 items-center justify-center my-6 px-6 p-2 bg-slate-50 text-rsc-blue shadow-lg rounded-full cursor-pointer">
            <div className="text-3xl">
              <IonIcon name="lock-closed" className="translate-y-[2px]" />
            </div>
            <div className="leading-none pr-1 text-sm">
              <span className="font-bold text-lg">Register / Log In</span>
            </div>
          </div>
        </a>
        <a href="/resend">
          <div
            className="flex flex-row gap-2 border border-slate-200 items-center justify-center my-6 px-6 p-2 bg-slate-50 text-rsc-blue shadow-lg rounded-full cursor-pointer"
            // onClick={() => setPopUp(<LogIn />)}
          >
            <div className="text-3xl">
              <IonIcon name="lock-closed" className="translate-y-[2px]" />
            </div>
            <div className="leading-none pr-1 text-sm">
              <span className="font-bold text-lg">Resend Code</span>
            </div>
          </div>
        </a>
      </div>
    </>
  );
}
