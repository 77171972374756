import { useState } from "react";
import moment from "moment";
import { showResult } from "../../../../utils/functions";
import { find, flatMap, get, includes, replace } from "lodash";
import { useDashboard } from "../../../DashboardContext";
import { useUI } from "../../../../context/UIContext";

export default function DetailsPage({ test }: { test: any }) {
  const { setLocation } = useUI();
  const { opportunities, restricted_results } = useDashboard();
  const {
    //  Id,
    Date_Time_of_Analysis__c,
    Order_Number__c,
    Reference_Range__c,
    Result__c,
    Result_Notes__c,
    Sub_Name__c,
    Test_Name__c,
    Value__c,
  } = test;

  const opportunity = find(opportunities, (opportunity: any) => {
    const order = get(opportunity, "Orders.records", []).find(
      (record: any) => record.Id === Order_Number__c
    );
    return order !== undefined;
  });
  const test_order = find(flatMap(opportunities, "Orders.records"), {
    Id: Order_Number__c,
  });
  const restrict_result =
    includes(restricted_results, Sub_Name__c) &&
    test_order?.Status !== "Results Delivered" &&
    Result__c === "Positive";
  const [showDetail, setShowDetail] = useState<boolean>(false);

  function toggleDetail() {
    const flip = !showDetail;
    setShowDetail(flip);
  }

  return (
    <div className="border border-gray-200 shadow-md rounded-md">
      <button className="text-left w-full" onClick={toggleDetail}>
        <div className="flex flex-row p-3">
          <div className="grow">
            {moment(Date_Time_of_Analysis__c).format("lll")}
          </div>
          {restrict_result ? (
            <span className="text-gray-400 italic uppercase">Pending</span>
          ) : (
            showResult({ Result__c })
          )}
        </div>
      </button>
      {showDetail && (
        <div className="p-3 flex flex-col gap-4">
          <div>
            <p>{Test_Name__c}</p>
          </div>
          <div className="">
            <p>
              Result: {Result__c}
              <br />
              Value: {Value__c}
              <br />
              Reference Range: {Reference_Range__c}
            </p>
          </div>
          <div>
            <p className="text-sm text-gray-500">
              {replace(Result_Notes__c, `. Note Source: L Comment:`, "")}
            </p>
          </div>
          <div className="flex flex-row justify-end">
            <button
              className="text-rsc-light-blue font-bold"
              onClick={() =>
                setLocation(`/dashboard/my-orders/orders/${opportunity?.Id}`)
              }
            >
              View Order
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
