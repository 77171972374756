import { useCallback, useEffect, useRef } from "react";
import {
  PayPalScriptProvider,
  BraintreePayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { usePayment } from "../PaymentInfoContext";
import PreLoader from "../../../PreLoader";
import { useBraintree } from "../BraintreeContext";

const ButtonWrapper = ({ currency, fn }: { currency: string; fn: any }) => {
  const {
    errors,
    view_errors,
    createOrder,
    forceReRender,
    onApprove,
    onPayPalClick,
  } = fn;
  const { loading } = usePayment();

  // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
  // This is the main reason to wrap the PayPalButtons in a new component
  const [{ options }, dispatch] = usePayPalScriptReducer();

  // Define a ref to store the previous currency value
  const prevCurrencyRef = useRef(currency);

  //const validation_errors = findErrors()
  // Define the dispatch function using useCallback to memoize it
  const dispatchWithMemo = useCallback(
    (value: any) => {
      dispatch({
        type: "resetOptions",
        value: {
          ...value,
          currency: currency,
        },
      });
    },
    [currency, dispatch]
  );

  // Use useEffect to check if the currency has changed and dispatch accordingly
  useEffect(() => {
    // Check if the currency has changed
    if (prevCurrencyRef.current !== currency) {
      // Dispatch the action using the memoized dispatch function
      dispatchWithMemo(options);

      // Update the previous currency value in the ref
      prevCurrencyRef.current = currency;
    }
  }, [currency, dispatchWithMemo, options]);

  return (
    <>
      {loading && <PreLoader text="Processing your order..." />}
      {view_errors && errors && (
        <div className="w-full">
          <div className="mb-2 font-semibold">
            Please correct the following information:
          </div>
          <div className="flex flex-col gap-2 mx-2">
            {errors.map((error: any, idx: number) => {
              return (
                <div
                  key={idx}
                  className="border border-rsc-red bg-rsc-red/30 rounded-md p-2 text-xs"
                >
                  <p>{error}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}
      <BraintreePayPalButtons
        className="flex flex-row items-center justify-center max-w-[625px] w-full mx-auto md:my-16"
        forceReRender={forceReRender}
        onApprove={onApprove}
        onCancel={(data, action) => {}}
        createOrder={createOrder}
        onClick={onPayPalClick}
        style={{ layout: "horizontal" }}
      />
    </>
  );
};

export default function BraintreePayPalButtonWrapper(fn: any) {
  const { clientToken } = useBraintree();
  if (!clientToken) return <PreLoader text="Loading PayPal..." />;
  return (
    <PayPalScriptProvider
      options={{
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID as string,
        components: "buttons",
        // dataUserIdToken: "your-tokenization-key-here",
        dataClientToken: clientToken,
        intent: "capture",
        vault: false,
      }}
    >
      <ButtonWrapper currency="USD" fn={fn} />
    </PayPalScriptProvider>
  );
}
