import { useState } from "react";
import { useUI } from "../../context/UIContext";

export default function MenuItem({ item }: { item: any }) {
  const { setLocation } = useUI();
  const { primary, sub_items } = item;
  const { label, link, url } = primary;

  const [showSubMenu, setShowSubMenu] = useState<boolean>(false);

  function SubMenu() {
    return (
      <div className="absolute pt-2 z-50">
        <div className="flex flex-col p-3 gap-3 bg-rsc-light-blue text-white font-medium rounded-b-md shadow-lg">
          {sub_items.map((item: any) => {
            const { label, link, url } = item;
            return (
              <div
                key={link || url}
                className="hover:text-amber-400"
                style={{ cursor: "pointer" }}
              >
                {link ? (
                  <button
                    className="uppercase"
                    onClick={() => setLocation(link)}
                  >
                    {label}
                  </button>
                ) : (
                  <a href={link}>
                    <button className="uppercase">{label}</button>
                  </a>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div
      onMouseEnter={() => setShowSubMenu(true)}
      onMouseLeave={() => setShowSubMenu(false)}
      className="hover:text-amber-500"
    >
      {link ? (
        <button
          className="font-medium md:text-[0.875rem] lg:text-[1rem] uppercase"
          onClick={() => setLocation(link)}
        >
          {label}
        </button>
      ) : (
        <a href={`https://rapidstdtesting.com${url}`}>
          <button className="font-medium md:text-[0.875rem] lg:text-[1rem] uppercase">
            {label}
          </button>
        </a>
      )}
      {showSubMenu && <SubMenu />}
    </div>
  );
}
