import IonIcon from "@reacticons/ionicons";
import { useUsers } from "../UsersContext";
import { useUI } from "../../context/UIContext";

export default function ListHeader() {
  const { is_mobile } = useUI();
  const { sort, users_table, setSort } = useUsers();
  const { dir, type } = sort;

  function DesktopHeader() {
    return (
      <tr className="border-b border-rsc-light-blue/40 text-rsc-light-blue">
        {users_table.map((col: any) => {
          const { id, label } = col;
          const isActive = id === type;
          return (
            <th
              key={id}
              className="cursor-pointer"
              onClick={() => {
                if (isActive) {
                  const flip = dir === "asc" ? "desc" : "asc";
                  setSort({ ...sort, dir: flip });
                } else {
                  setSort({ ...sort, type: id });
                }
              }}
            >
              {label}
              {isActive && (
                <>
                  <IonIcon
                    name={dir === "asc" ? "caret-up" : "caret-down"}
                    className="translate-y-[2px] ml-1"
                  />
                </>
              )}
            </th>
          );
        })}
      </tr>
    );
  }

  function MobileHeader() {
    return (
      <tr>
        <th></th>
        <th></th>
        <th></th>
      </tr>
    );
  }

  return <thead>{is_mobile ? <MobileHeader /> : <DesktopHeader />}</thead>;
}
