import BasicOrderTemplate from "./order_card";
import BasicOpportunityTemplate from "./opportunity_card";
export default function OpportunityOrderItem({
  order,
  opportunity,
}: {
  order: any;
  opportunity: any;
}) {
  return (
    <div className="p-3">
      {order ? (
        <BasicOrderTemplate opportunity={opportunity} order={order} />
      ) : (
        <BasicOpportunityTemplate opportunity={opportunity} />
      )}
    </div>
  );
}
