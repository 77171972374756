import { useTestCenter } from "../TestCenterContext";

export default function SetHoursOpen() {
  const { open_saturdays, setOpenSaturdays } = useTestCenter();

  return (
    <div className="flex gap-5 justify-center py-6">
      <div className="">
        <input
          className="mr-1"
          type="radio"
          id="all"
          name="test_hours"
          value="All"
          checked={!open_saturdays}
          onChange={() => setOpenSaturdays(false)}
        />
        <label htmlFor="all">All</label>
      </div>
      <div className="">
        <input
          className="mr-1"
          type="radio"
          id="saturdays"
          name="test_hours"
          value="Saturdays"
          checked={!!open_saturdays}
          onChange={() => setOpenSaturdays(true)}
        />
        <label htmlFor="saturdays">Open Saturdays</label>
      </div>
    </div>
  );
}
