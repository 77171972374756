import OrderCard from "../order_card";

import Section from "../form_section";
import { useOrder } from "../OrderContext";
const states = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
export default function ContactInformation() {
  const { order, handleUserChange } = useOrder();

  if (!order?.user) return null;
  const {
    address,
    address_state,
    city,
    email,
    email_message,
    phone_message,
    phone_number,
    zip_code,
  } = order.user;

  return (
    <OrderCard title={"4. Contact Information"} card_layout={"mb-0 z-0"}>
      <div className="p-3 pb-0 flex flex-col bg-slate-50">
        <Section>
          <div className="grid md:grid-cols-4 gap-4">
            <div className="md:col-span-4">
              <label htmlFor="patient-address">Address:</label>
              <input
                className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
                id="patient-address"
                placeholder="Enter your address"
                value={address}
                onChange={(e: any) =>
                  handleUserChange("address", e.target.value)
                }
              />
            </div>
            <div className="md:col-span-2">
              <label htmlFor="patient-city">City:</label>
              <input
                className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
                id="patient-city"
                placeholder="Enter your city"
                value={city}
                onChange={(e: any) => handleUserChange("city", e.target.value)}
              />
            </div>
            <div className="md:col-span-1">
              <label htmlFor="patient-state">State:</label>
              <select
                id="patient-state"
                className="rounded-lg border border-slate-400 h-10 w-full shadow-inner px-2"
                onChange={(e: any) =>
                  handleUserChange("address_state", e.target.value)
                }
                value={address_state}
              >
                <option>Select</option>
                {states.map((state: string) => {
                  return (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="md:col-span-1">
              <label htmlFor="patient-zip_code">Zip Code:</label>
              <input
                className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
                id="patient-zip_code"
                placeholder="Enter Zip Code"
                value={zip_code}
                onChange={(e: any) =>
                  handleUserChange("zip_code", e.target.value)
                }
              />
            </div>
          </div>
        </Section>
        <Section>
          <div className="grid md:grid-cols-2 gap-4">
            <div className="">
              <label htmlFor="patient-first_name">Email:</label>
              <input
                className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
                id="patient-first_name"
                placeholder="Enter your email"
                value={email}
                onChange={(e: any) => handleUserChange("email", e.target.value)}
                onKeyDown={(e: any) => {
                  if (e.key === " ") {
                    e.preventDefault(); // Prevents entering the space character
                  }
                }}
              />
              <div className="my-2">
                <input
                  type="checkbox"
                  className="ml-1 mr-2"
                  checked={email_message}
                  onChange={(e: any) => {
                    handleUserChange("email_message", e.target.checked);
                  }}
                />
                <label htmlFor="email_message">Can email treatment plan</label>
              </div>
            </div>
            <div className="">
              <label htmlFor="patient-last_name">Phone Number:</label>
              <input
                className="rounded-lg border  border-slate-400 h-10 w-full shadow-inner px-2"
                id="patient-last_name"
                placeholder="Enter your phone number"
                value={phone_number}
                onChange={(e: any) =>
                  handleUserChange("phone_number", e.target.value)
                }
              />
              <div className="my-2">
                <input
                  id="can-phone_message"
                  type="checkbox"
                  className="ml-1 mr-2"
                  checked={phone_message}
                  onChange={(e: any) => {
                    handleUserChange("phone_message", e.target.checked);
                  }}
                />
                <label htmlFor="can-phone_message">
                  Can leave phone message
                </label>
              </div>
            </div>
          </div>
        </Section>
      </div>
    </OrderCard>
  );
}
