import ReactDOM from "react-dom/client";
import App from "./App";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { createHttpLink } from "apollo-link-http";
import reportWebVitals from "./reportWebVitals";
import RapidSTDTestingLayout from "./layout/RapidSTDTesting";
import RSCHealthLayout from "./layout/RSCHealth";
import PopUp from "./PopUp";
import UserContextProvider from "./context/UserContext";
import UIContextProvider from "./context/UIContext";
import AccountContextProvider from "./context/AccountContext";
import ScrollToTop from "./scrollToTop";
import CartContextProvider from "./context/CartContext";
import OrderContextProvider from "./order/ScheduleTesting/OrderContext";
import TestCenterContextProvider from "./order/ScheduleTesting/Find_Test_Center/TestCenterContext";
import ConsultationContextProvider from "./consultation/ScheduleConsultation/context/ConsultationContext";
import "./index.css";
import RegistrationContextProvider from "./login/RegistrationContext";
const { ENV, REACT_APP_SITE } = process.env;
ENV === "development" && console.log("ENVIRONMENT", ENV);
const SiteLayout = {
  rapid_std: RapidSTDTestingLayout,
  rsc_health: RSCHealthLayout,
}[REACT_APP_SITE || "rsc_health"];

const uri = `${window.location.origin}/api/graphql`;

const link = new createHttpLink({
  uri,
  // credentials: "include",
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>

  <ApolloProvider client={client}>
    <UIContextProvider client={client}>
      <UserContextProvider>
        <RegistrationContextProvider>
          <AccountContextProvider>
            <CartContextProvider>
              <OrderContextProvider>
                <TestCenterContextProvider>
                  <ConsultationContextProvider>
                    <ScrollToTop />
                    <SiteLayout>
                      <App />
                    </SiteLayout>
                    <PopUp />
                  </ConsultationContextProvider>
                </TestCenterContextProvider>
              </OrderContextProvider>
            </CartContextProvider>
          </AccountContextProvider>
        </RegistrationContextProvider>
      </UserContextProvider>
    </UIContextProvider>
  </ApolloProvider>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
