import InformationCell from "../TD_Cell";
import TopTitleHeader from "./TitleHeader";
export default function DocumentHeader({
  doctor,
  for_pdf,
  label,
  sublabel = "ORDERNOTCREATED",
}: {
  doctor?: any;
  for_pdf?: boolean;
  label: string;
  sublabel?: string;
}) {
  const color = "rgb(17 102 155 / 0.8)";
  const backgroundColor = "rgb(17 102 155 / 0.025)";

  function ReferringPhysician({ doctor }: { doctor: any }) {
    if (!doctor) return null;
    const { first_name, last_name, npi } = doctor;
    return (
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <td>
              <InformationCell
                label="Reffering Physician"
                value={
                  <>
                    {last_name}, {first_name}
                  </>
                }
              />
            </td>
            <td>
              <InformationCell label="NPI" value={npi} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  function ConfirmationHeader() {
    return (
      <>
        <table style={{ backgroundColor, width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ verticalAlign: "top" }}>
                <InformationCell
                  value={
                    <>
                      <span style={{ color, fontSize: 16, fontWeight: 600 }}>
                        RSC Health
                      </span>
                      <br />
                      310 Pelican Avenue
                      <br />
                      St Louis, MO 63122
                      <br />
                      <a
                        href="https://rschealth.com"
                        target="_blank"
                        rel="noreferrer"
                        style={{ color, textDecoration: "underline" }}
                      >
                        rschealth.com
                      </a>
                    </>
                  }
                />
              </td>
              <td style={{ verticalAlign: "top" }}>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <td>
                        <InformationCell
                          label="Phone"
                          link="phone"
                          value={"8668730879"}
                        />
                      </td>
                      <td>
                        <InformationCell label="Fax" value="(866) 926-7715" />
                      </td>
                      <td>
                        <InformationCell label="Tax ID" value="90-0402056" />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ReferringPhysician doctor={doctor} />
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }

  return (
    <table
      style={{
        fontSize: "11pt",
        width: "100%",
      }}
    >
      <tbody>
        <tr>
          <td style={{ paddingBottom: 40 }}>
            <TopTitleHeader
              for_pdf={for_pdf}
              label={label}
              sublabel={sublabel !== "none" && sublabel}
            />
          </td>
        </tr>
        <tr>
          <td>
            <ConfirmationHeader />
          </td>
        </tr>
        <tr>
          <td style={{ paddingBottom: 40 }}></td>
        </tr>
      </tbody>
    </table>
  );
}
