import { compact } from "lodash";
export default function Totals({
  reorder_discount,
  totals,
}: {
  reorder_discount: boolean;
  totals: any;
}) {
  const { cart_total, grand_total, reorder_discount_total, total_due } = totals;
  const savings = grand_total - cart_total;
  return (
    <>
      {compact([
        {
          label: "Grand Total",
          style: { fontWeight: 400 },
          value: grand_total,
        },
        savings > 0 && {
          label: "RSC Health Savings",
          decrement: true,
          style: { fontWeight: 400 },
          value: savings,
        },
        reorder_discount && {
          label: "10% Reorder Discount",
          decrement: true,
          style: { fontWeight: 400 },
          value: reorder_discount_total,
        },
        { label: "Total Due", value: total_due },
      ]).map((item: any) => {
        const { decrement, label, style, value } = item;
        if (value === 0) return null;
        return (
          <tr key={label}>
            <td
              style={{
                borderBottom: "1px solid rgb(0 0 0 / .05)",
                fontWeight: 600,
                ...style,
              }}
            >
              <table style={{ fontSize: 12, width: "100%" }}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        borderRight: "1px solid rgb(0 0 0 / .05)",
                        padding: "8px 16px",
                        textAlign: "right",
                      }}
                    >
                      <span>{label}</span>
                    </td>
                    <td
                      style={{
                        paddingRight: 16,
                        textAlign: "right",
                        width: 85,
                        verticalAlign: "middle",
                      }}
                    >
                      {decrement && "-"}${(value || 0).toFixed(2)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        );
      })}
    </>
  );
}
