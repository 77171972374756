import moment from "moment";
import {
  concat,
  filter,
  includes,
  map,
  merge,
  omit,
  pickBy,
  reject,
  replace,
  split,
  startsWith,
  zipObject,
} from "lodash";
import passwordValidator from "password-validator";
import z from "zod";
import ObjectID from "bson-objectid";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import { package_products } from "../context/CartContext";
import { blank_person } from "./types";

const phoneUtil = PhoneNumberUtil.getInstance();

export function isValidJSONString(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}

export async function addPackage({
  pkg,
  shopping_cart,
  callback,
  setShoppingCart,
}: {
  pkg: any;
  shopping_cart: any[];
  callback?: any;
  setShoppingCart: any;
}) {
  const matching_skus = concat(
    map(package_products, "sku"),
    pkg.items,
    pkg.hidden_items
  );
  const cart_removed_items = reject(shopping_cart, function (s: any) {
    return includes(matching_skus, s.sku);
  });
  const new_cart = concat(cart_removed_items, pkg);
  setShoppingCart(new_cart);
  callback?.();
}

export function showResult({ Result__c }: { Result__c?: string }) {
  if (!Result__c)
    return (
      <div>
        <span className="uppercase text-gray-300">Pending</span>
      </div>
    );
  const className = `uppercase text-sm font-semibold ${
    Result__c === "Negative" ? "text-green-600" : "text-red-600"
  }`;
  return (
    <div>
      <span className={className}>{Result__c}</span>
    </div>
  );
}

export function getRecordsCount(Sub_Name__c: string, all_tests: any[]) {
  const count = filter(all_tests, ["Sub_Name__c", Sub_Name__c])?.length;
  const display = count > 1 ? `${count} Records` : `${count} Record`;
  return display;
}

export function validEmail(email_: string) {
  const validEmailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const email = replace(email_, /\s+/g, "");
  return !!email?.match(validEmailRegex) && email;
}

export function validPhone(phone: string) {
  if (!phone) return false;
  const validPhoneRegex =
    /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  const isPhone = phone.match(validPhoneRegex);
  if (!isPhone) return false;
  const phoneUtil = new PhoneNumberUtil();
  const phone_number =
    phone?.length >= 10 && phoneUtil.parseAndKeepRawInput(phone, "US");
  return phone_number && phoneUtil.isValidNumber(phone_number) && phone_number;
}

export function formatPhone(phone: string) {
  const validPhoneRegex =
    /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
  const isPhone = phone.match(validPhoneRegex);
  if (!isPhone) return false;

  const phone_number =
    phone?.length >= 10 && phoneUtil.parseAndKeepRawInput(phone, "US");
  if (!phone_number || !phoneUtil.isValidNumber(phone_number)) return null;
  return phoneUtil.format(phone_number, PhoneNumberFormat.E164);
}

export function formatPhoneDisplay(phone: string) {
  try {
    const number = phoneUtil.parseAndKeepRawInput(phone, "US");
    const formattedNumber = phoneUtil.format(
      number,
      PhoneNumberFormat.NATIONAL
    );
    return formattedNumber;
  } catch (error) {
    return "Invalid Phone Number";
  }
}

export function formatPhoneNumberToHref(phoneNumber: string, style?: any) {
  try {
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, "US");
    const formattedNumber = phoneUtil.format(
      number,
      PhoneNumberFormat.NATIONAL
    );
    const hrefLink = (
      <a href={`tel:${formattedNumber}`} style={style || {}}>
        {formattedNumber}
      </a>
    );
    return hrefLink;
  } catch (error) {
    return "Invalid Phone Number";
  }
}

export function processAccountUser(account: any) {
  const {
    Gender__pc,
    Id,
    FirstName,
    LastName,
    PersonBirthdate: PersonBirthdate_,
    PersonEmail,
    PersonMobilePhone,
  } = account;
  const PersonBirthdate = PersonBirthdate_
    ? moment(PersonBirthdate_, "YYYY-MM-DD").toDate()
    : null;

  const user = merge(
    blank_person,
    pickBy({
      Id,
      dob: PersonBirthdate,
      sex: Gender__pc || "",
      given_name: FirstName || "",
      family_name: LastName || "",
      email: PersonEmail || "",
      phone_number: PersonMobilePhone || "",
    })
  );
  return user;
}
const pharmacy_validation = z
  .object({
    _id: z.string().refine(value => ObjectID.isValid(value), {
      message: "A test center must be selected.",
    }),
  })
  .refine(value => typeof value === "object" && value !== null, {
    message: "A test center must be selected.",
  });

export const validate_consultation_person = z
  .object({
    address: z.string({
      required_error: "Street address is required.",
    }),
    address_state: z.string({
      required_error: "State is required.",
    }),
    allergies: z.string(),
    city: z.string({
      required_error: "An address city is required.",
    }),
    dob: z.string({
      required_error: "Please enter a valid date of birth.",
    }),
    email: z
      .string()
      .refine(value => value.trim() === "" || validEmail(value), {
        message: "Email is not valid.",
      }),
    email_message: z.boolean(),
    family_name: z.string({
      required_error: "Last name is required.",
    }),
    given_name: z.string({
      required_error: "First name is required.",
    }),
    no_allergies: z.boolean(),
    no_medications: z.boolean(),
    pharmacy: z.lazy(() => z.union([pharmacy_validation, z.undefined()])),
    phone_message: z.boolean(),
    phone_number: z
      .string({
        // Not required, so empty string is allowed
      })
      .refine(value => value.trim() === "" || validPhone(value), {
        message: "Invalid phone number",
      })
      .transform(formatPhone),

    medications: z.string(),
    race_ethnicity: z.string({
      required_error: "Race and ethnicity is required.",
    }),
    same_pharmacy: z.boolean().optional(),
    sex: z.string({
      required_error: "A sex is required.",
    }),
    symptoms: z.string({
      required_error: "Symptoms are required.",
    }),
    test_date: z
      .string({
        required_error: "A test date is required.",
      })
      .refine(
        (value: string) => {
          return moment(value, "YYYY-MM-DD", true).isValid();
        },
        {
          message: "A valid test date is required.",
        }
      ),
    treating: z.array(z.string().nonempty()).min(1, {
      message: "Selecting ailment being treated is required.",
    }),
    zip_code: z.string({
      required_error: "Zip code is required.",
    }),
  })
  .refine(data => data.email_message || data.phone_message, {
    message: "You must accept to receive either an email or phone message.",
  })
  .refine(
    data => {
      return data.medications !== "" || data.no_medications === true;
    },
    {
      message:
        "Either include medications being taken or select 'no medications'.",
    }
  )
  .refine(
    data => {
      return data.allergies !== "" || data.no_allergies === true;
    },
    {
      message: "Either include allergies being taken or select 'no allergies'.",
    }
  )
  .refine(
    data => {
      // If email_message is true, there must be a valid email
      return (
        !data.phone_message || (!!data.phone_message && !!data.phone_number)
      );
    },
    {
      message: "Phone number is required when message by phone is accepted.",
    }
  )
  .refine(
    data => {
      // If email_message is true, there must be a valid email
      return !data.email_message || (!!data.email_message && !!data.email);
    },
    {
      message: "Email is required when message by email is accepted.",
    }
  )
  .refine(
    (data: any) => {
      return (
        (data.same_pharmacy === true && data?.type === "partner") ||
        ObjectID.isValid(data.pharmacy?._id)
      );
    },
    {
      message: "Invalid pharmacy information.",
    }
  );

export const validate_consultation = z.object({
  acknowledged: z.boolean().refine(value => value === true, {
    message: "Must acknowledge terms of service.",
  }),
  patient: validate_consultation_person,
  partners: z.array(validate_consultation_person),
});

export const validate_payment = z.object({
  acknowledged: z.boolean().refine(value => value === true, {
    message: "Must acknowledge terms of service.",
  }),
});

const selected_center = z.object({
  _id: z.string().refine(value => ObjectID.isValid(value), {
    message: "A test center must be selected.",
  }),
});

const selected_center_with_message = z
  .union([selected_center, z.literal(null)])
  .refine(
    value => {
      if (value === null) {
        return false; // Reject null values
      }
      return typeof value === "object";
    },
    {
      message: "A test center must be selected.",
    }
  );

// export const validate_order = z.object({
//   acknowledged: z.boolean().refine(value => value === true, {
//     message: "Must acknowledge terms of service.",
//   }),
//   selected_center: selected_center_with_message,
//   user: z
//     .object({
//       address: z.string({
//         required_error: "Street address is required.",
//       }),
//       address_state: z.string({
//         required_error: "State is required.",
//       }),
//       city: z.string({
//         required_error: "Street address is required.",
//       }),
//       dob: z
//         .string({
//           required_error: "A date of birth is required.",
//         })
//         .refine(
//           (value: string) => {
//             return moment(value, "YYYY-MM-DD", true).isValid();
//           },
//           {
//             message: "A valid date of birth is required.",
//           }
//         ),
//       email: z.string().email({
//         message: "Email is not valid.",
//       }),
//       email_message: z.boolean(),
//       family_name: z.string({
//         required_error: "Last name is required.",
//       }),
//       given_name: z.string({
//         required_error: "First name is required.",
//       }),
//       phone_message: z.boolean(),
//       phone_number: z
//         .string({
//           required_error: "A phone number is required.",
//         })
//         .refine(
//           (value: string) => {
//             return validPhone(value);
//           },
//           {
//             message: "Invalid phone number",
//           }
//         )
//         .transform(formatPhone),

//       sex: z
//         .string({
//           required_error: "A phone number is required.",
//         })
//         .refine(
//           (value: string) => {
//             return includes(["Male", "Female"], value);
//           },
//           {
//             message: "A gender is required.",
//           }
//         ),
//       zip_code: z.string({
//         required_error: "Zip code is required.",
//       }),
//     })
//     .refine(data => data.email_message || data.phone_message, {
//       message: "You must accept to receive either an email or phone message.",
//     }),
// });

const isValidPhone = (data: any) => {
  // Only check phone_number if phone_message is true
  if (data.phone_message) {
    return validPhone(data.phone);
  }
  return true;
};

const isValidEmail = (data: any) => {
  // Only check Email_number if Email_message is true
  if (data.email_message) {
    return validEmail(data.email);
  }
  return true;
};

const main_user_order = omit(
  {
    address: z.string({
      required_error: "Street address is required.",
    }),
    address_state: z.string({
      required_error: "State is required.",
    }),
    city: z.string({
      required_error: "Street address is required.",
    }),
    dob: z
      .string({
        required_error: "A date of birth is required.",
      })
      .refine(
        (value: string) => {
          return moment(value, "YYYY-MM-DD", true).isValid();
        },
        {
          message: "A valid date of birth is required.",
        }
      ),
    email: z
      .string({
        required_error: "An email is required.",
      })
      .refine(isValidEmail, {
        message: "Invalid email address.",
      }),
    email_message: z.boolean(),
    family_name: z.string({
      required_error: "Last name is required.",
    }),
    given_name: z.string({
      required_error: "First name is required.",
    }),
    phone_message: z.boolean(),
    phone_number: z
      .string({
        required_error: "A phone number is required.",
      })
      .refine(isValidPhone, {
        message: "Invalid phone number",
      })
      .transform(formatPhone),

    sex: z
      .string({
        required_error: "A phone number is required.",
      })
      .refine(
        (value: string) => {
          return includes(["Male", "Female"], value);
        },
        {
          message: "A gender is required.",
        }
      ),
    zip_code: z.string({
      required_error: "Zip code is required.",
    }),
  },
  []
);

export const validate_order = z.object({
  acknowledged: z.boolean().refine(value => value === true, {
    message: "Must acknowledge terms of service.",
  }),
  selected_center: selected_center_with_message,
  user: z
    .object(main_user_order)
    .refine(
      data => {
        const { email_message, phone_message } = data;
        return email_message || phone_message;
      },
      {
        message: "You must select email or phone message communication.",
      }
    )
    .refine(
      data => {
        const { email, email_message } = data;

        if (email_message && !validEmail(email || "")) {
          return false; // If email_message is true, email must be provided
        }

        return true;
      },
      {
        message: "You must provide a valid email.",
      }
    )
    .refine(
      data => {
        const { phone_message, phone_number } = data;

        if (phone_message && !validPhone(phone_number || "")) {
          return false; // If email_message is true, email must be provided
        }
        return true;
      },
      {
        message: "You must provide a valid phone number.",
      }
    ),
});

export function makeUser(user: any) {
  const keys = map(user.Attributes || user.UserAttributes, function (i: any) {
    if (startsWith(i.Name, "custom:")) {
      return split(i.Name, ":")[1];
    }
    return i.Name;
  });
  const values = map(user.Attributes || user.UserAttributes, "Value");
  const attributes = {
    ...user,
    ...zipObject(keys as string[], values),
    created: moment(user.UserCreateDate).format("lll"),
  };
  return { ...user, ...attributes };
}

const pw_schema = new passwordValidator();

pw_schema
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(16) // Maximum length 100
  .has()
  .uppercase() // Must have uppercase letters
  .has()
  .lowercase() // Must have lowercase letters
  .has()
  .digits(2) // Must have at least 2 digits
  .has()
  .not()
  .spaces() // Should not have spaces
  .is()
  .not()
  .oneOf(["Passw0rd", "Password123"]);

export const password_schema = pw_schema;
