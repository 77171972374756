import moment from "moment";
import InformationCell from "../TD_Cell";

export default function PatientInformation({ user }: { user: any }) {
  const color = "rgb(17 102 155 / 0.8)";
  const backgroundColor = "rgb(17 102 155 / 0.025)";
  const padding = "6px 16px";
  if (!user) return null;
  const {
    address,
    address_state,
    city,
    dob,
    email,
    family_name,
    given_name,
    phone_number,
    zip_code,
  } = user;

  return (
    <table
      style={{
        backgroundColor,
        fontSize: "11pt",
        width: "100%",
      }}
    >
      <tbody>
        <tr>
          <td style={{ color, fontSize: 24, fontWeight: 600, padding }}>
            Patient
          </td>
        </tr>
        <tr>
          <td>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ width: 225, verticalAlign: "top" }}>
                    <InformationCell
                      value={
                        <>
                          <span
                            style={{ color, fontSize: 16, fontWeight: 600 }}
                          >
                            {given_name} {family_name}
                          </span>
                          <br />
                          {address}
                          <br />
                          {city}, {address_state} {zip_code}
                        </>
                      }
                    />
                  </td>
                  <td>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            <InformationCell
                              label="Date of Birth"
                              value={moment(dob).format("LL")}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <InformationCell
                                      label="Phone"
                                      link="phone"
                                      value={phone_number}
                                    />
                                  </td>
                                  <td>
                                    <InformationCell
                                      label="Email"
                                      link={"email"}
                                      value={email}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
