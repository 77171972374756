import { createContext, useCallback, useContext, useState } from "react";
import axios from "axios";
import moment from "moment";
import {
  filter,
  find,
  groupBy,
  head,
  join,
  kebabCase,
  map,
  orderBy,
  split,
} from "lodash";
import { useUI } from "../context/UIContext";
import { useAccount } from "../context/AccountContext";

const restricted_results = [
  "FTA-ABS",
  "HEPATITIS A IGM",
  "HEPATITIS B SURFACE ANTIGEN",
  "HEPATITIS C ANTIBODY",
  "HIV 1 ANTIBODY",
  "HIV 2 ANTIBODY",
  "HIV AG/AB, 4TH GEN",
  "RPR (DX) W/REFL TITER AND CONFIRMATORY TESTING",
  "RPR TITER",
];

type Order = any;

interface DashboardValues {
  all_test: any[];
  all_tests?: any[];
  docs: any[] | null;
  // lab_data: any;
  laboratory?: any;
  loading_opportunities: boolean;
  loading_opportunity_tests: boolean;
  main_view: string;
  opportunities: any[] | null;
  opportunity?: any;
  order?: any;
  order_data: any;
  order_details?: any;
  order_tests?: any;
  orders?: Partial<Order>;
  products: any[] | null;
  restricted_results: string[];
  results: any[] | null;
  tests_data: any;
  tests: any | null;
  view: string;
  viewing_id: string | null;
  viewing_opportunity: string | false;
  viewing_test: string | false;
  getAndSetOpportunities: (data: any | null) => void;
  setLaboratory: (lab: any) => void;
  setLoadingOpportunities: (loading_opportunities: boolean) => void;
  setOpportunities: (opps: any[]) => void;
  setOrderDetails: (details: any) => void;
  setOrders: (orders: any) => void;
  setAllTests: (all_tests: any | null) => void;
  setOrderTests: (tests_data: any) => void;
  setTests: (tests: any[]) => void;
}

export const DashboardContext = createContext<DashboardValues>({
  all_test: [],
  docs: null,
  // lab_data: {},
  loading_opportunities: false,
  loading_opportunity_tests: false,
  main_view: "",
  opportunities: null,
  order: null,
  order_data: {},
  products: null,
  restricted_results,
  results: null,
  tests: null,
  tests_data: {},
  view: "orders",
  viewing_id: null,
  viewing_opportunity: false,
  viewing_test: false,
  getAndSetOpportunities: () => {},
  setLaboratory: () => {},
  setLoadingOpportunities: () => {},
  setOpportunities: () => {},
  setOrderDetails: () => {},
  setOrderTests: () => {},
  setOrders: () => {},
  setAllTests: () => {},
  setTests: () => {},
});

export const useDashboard = () => useContext(DashboardContext);

const DashboardContextProvider: React.FC<{
  children: any;
}> = ({ children }) => {
  const { location } = useUI();
  const { account } = useAccount();

  const [opportunities, setOpportunities] = useState<any | null>(null);
  const [loading_opportunities, setLoadingOpportunities] =
    useState<boolean>(false);

  const [orders, setOrders] = useState<any | undefined>();

  const [laboratory, setLaboratory] = useState<any | null>(null);
  const [loading_laboratory, setLoadingLaboratory] = useState(false);

  const [docs, setDocs] = useState<any | null>(null);
  const [loading_docs, setLoadingDocs] = useState(false);

  const [all_tests, setAllTests] = useState<any | null>(null);
  const [loading_all_tests, setLoadingAllTests] = useState<boolean>(false);

  const [tests, setTests] = useState<any | null>(null);
  const [loading_opportunity_tests, setLoadingOpportunityTests] =
    useState<boolean>(false);

  const [products, setProducts] = useState<any | null>(null);
  const [loading_products, setLoadingProducts] = useState<boolean>(false);

  const [results, setResults] = useState<any | null>(null);
  const [loading_results, setLoadingResults] = useState(false);
  // const [viewing_opportunity, setViewingOpportunity] = useState<
  //   string | undefined
  // >();
  // "0066Q000025S5QDQA0"
  // const [viewing_test, setViewingTest] = useState<any | undefined>();
  const [order_details, setOrderDetails] = useState<any | null>(null);
  const loc = split(location, "/");
  const main_view = loc[2];
  const view = loc[3];
  const viewing_id = loc[4];

  const opportunity =
    view === "orders" && find(opportunities?.data, ["Id", viewing_id]);
  //const order_id = order_details?.order?.Id;
  const lab_id = opportunity?.Lab_Account__c;

  const [order_tests, setOrderTests] = useState<any | undefined>();

  const getAndSetOpportunities = useCallback(async (Id: string) => {
    let opportunities;
    try {
      setLoadingOpportunities(true);
      const { data } = await axios.post(`/api/salesforce/opportunity`, {
        AccountId: Id,
      });
      opportunities = orderBy(
        data?.records,
        function (o: any) {
          const stamp = moment(o.CreatedDate).unix();
          return stamp;
        },
        "desc"
      );
    } catch (err) {
      console.log("ERROR LOADING OPPORTUNITIES", err);
    } finally {
      setOpportunities({ Id, data: opportunities });
      setLoadingOpportunities(false);
    }
  }, []);

  const getAndSetAllTests = useCallback(
    async ({
      tests_id: Id,
      valid_opportunities,
    }: {
      tests_id: string;
      valid_opportunities: string[];
    }) => {
      if (loading_all_tests) return;
      try {
        setLoadingAllTests(true);
        const { data } = await axios.post(`/api/salesforce/all_results`, {
          Ids: valid_opportunities,
        });

        setAllTests({ Id, data: data?.records });
      } catch (err) {
        console.log("ERROR LOADING OPPORTUNITIES", err);
      } finally {
        setLoadingAllTests(false);
      }
    },
    [loading_all_tests]
  );

  const getAndSetOpportunityTests = useCallback(
    async (Id: string) => {
      if (loading_opportunity_tests) return;
      try {
        setLoadingOpportunityTests(true);
        const { data } = await axios.post(`/api/salesforce/products`, { Id });
        setTests({ Id, data });
      } catch (err) {
        console.log("There was an error", err);
      } finally {
        setLoadingOpportunityTests(false);
      }
    },
    [loading_opportunity_tests]
  );

  async function getDocs(Id: string) {
    let data;
    try {
      setLoadingDocs(true);
      data = await axios
        .post(`/api/salesforce/order_docs`, {
          Id,
        })
        .then((response: any) => response.data);
    } catch (error) {
      console.log("There was an error", error);
    } finally {
      setDocs({ Id, data });
      setLoadingDocs(false);
    }
  }

  async function getLab(Id: string) {
    let data;
    try {
      setLoadingLaboratory(true);
      const result = await axios
        .post(`/api/salesforce/laboratory`, { Id })
        .then((response: any) => response.data);
      data = result?.records?.[0];
    } catch (err) {
      console.log("There was an error", err);
    } finally {
      setLaboratory({ Id, data });
      setLoadingLaboratory(false);
    }
  }

  const grouped_tests = groupBy(all_tests?.data, function (t: any) {
    return kebabCase(t.Sub_Name__c);
  });
  const all_test = view === "tests" ? grouped_tests[viewing_id] : [];

  !loading_opportunities &&
    !!account?.Id &&
    account.Id !== opportunities?.Id &&
    getAndSetOpportunities(account.Id);

  const valid_opportunities = map(
    filter(opportunities?.data, { Payment_Status__c: "Paid in Full" }),
    "Orders.records.[0].Id" // (o: any) => o.Orders?.records?.[0]
  );
  const tests_id = join(valid_opportunities, "");
  // console.log({ opportunities, valid_opportunities, tests_id });

  !loading_all_tests &&
    !!account?.Id &&
    valid_opportunities.length > 0 &&
    tests_id !== all_tests?.Id &&
    getAndSetAllTests({ tests_id, valid_opportunities });

  !!opportunity?.Id &&
    (!tests || tests?.Id !== opportunity?.Id) &&
    !loading_opportunity_tests &&
    getAndSetOpportunityTests(opportunity.Id);

  !!lab_id &&
    laboratory?.Id !== lab_id &&
    !loading_laboratory &&
    getLab(lab_id);

  async function getProducts(Id: string) {
    let data;
    try {
      setLoadingProducts(true);
      data = await axios
        .post(`/api/salesforce/products`, { Id })
        .then((response: any) => response.data);
    } catch (err) {
      console.log("There was an error", err);
    } finally {
      setProducts({ Id, data });
      setLoadingProducts(false);
    }
  }

  async function getResults(Id: string) {
    let results;
    try {
      setLoadingResults(true);
      const { data } = await axios.post(`/api/salesforce/order_results`, {
        Id,
      });
      results = data?.results?.records;
    } catch (err) {
      console.log("There was an error", err);
    } finally {
      setResults({ Id, data: results });
      setLoadingResults(false);
    }
  }

  !!opportunity?.Id &&
    products?.Id !== opportunity.Id &&
    !loading_products &&
    getProducts(opportunity.Id);

  const order: any = head(opportunity?.Orders?.records);
  const order_id = order?.Id;
  !!order?.Id && docs?.Id !== order.Id && !loading_docs && getDocs(order.Id);

  opportunity?.Payment_Status__c === "Paid in Full" &&
    !!order_id &&
    results?.Id !== order_id &&
    !loading_results &&
    getResults(order_id);

  return (
    <DashboardContext.Provider
      value={{
        all_test,
        all_tests: all_tests?.data,
        docs: docs?.data,
        //    lab_data: { lab_id, lab_request_id },
        laboratory: laboratory?.data,
        loading_opportunities,
        loading_opportunity_tests,
        main_view,
        order,
        order_data: { order_id }, // REMOVE IT !!
        order_details,
        order_tests,
        opportunity,
        opportunities: opportunities?.data,
        orders,
        products: products?.data,
        restricted_results,
        results: results?.data,
        tests: tests?.data,
        tests_data: {
          // REMOVE IT !!
          test_request_order_id: order_id,
          test_order_id: order_tests?.Id,
        },

        view,
        viewing_id,
        viewing_opportunity: view === "orders" && viewing_id,
        viewing_test: view === "tests" && viewing_id,
        getAndSetOpportunities,
        setAllTests,
        setLaboratory,
        setLoadingOpportunities,
        setOpportunities,
        setOrderDetails,
        setOrderTests,
        setOrders,
        setTests,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContextProvider;
